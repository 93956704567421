import React from "react";
import { HeaderSearchInput, SearchBarInput } from "../../../util/theme/styled-components";
import { Search, SearchCancel } from "../images";
import { Box, useTheme } from "@mui/material";
import { searchButtonWidth } from "../../containers";
import { useLanguageContext } from "../../../util/contexts/language.context";

interface BarProps {
	handleChange: (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void;
	setClose: () => void;
	filter: string;
}

export const SearchBar: React.FC<BarProps> = ({ setClose, handleChange, filter }) => (
	<SearchBarInput
		autoFocus
		value={filter}
		onChange={handleChange}
		endAdornment={
			<Box onClick={setClose} sx={{ cursor: "pointer" }}>
				<SearchCancel />
			</Box>
		}
		fullWidth
		autoComplete="off"
	/>
);

interface ButtonProps {
	isOpen: boolean;
	setOpen: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	filter: string;
	searchRef: React.MutableRefObject<undefined>;
}

export const HeaderSearchButton: React.FC<ButtonProps> = ({
	isOpen,
	setOpen,
	filter,
	searchRef
}) => {
	const theme = useTheme();
	const { getLocalizedText } = useLanguageContext();
	const text = getLocalizedText("search_bar_placeholder");

	return (
		<Box onClick={setOpen}>
			<HeaderSearchInput
				disabled
				ref={searchRef}
				$isopen={isOpen}
				placeholder={text}
				startAdornment={
					<Box>
						<Search />
					</Box>
				}
				value={filter}
				sx={{
					width: { xs: "100%", md: searchButtonWidth },
					cursor: "pointer !important",
					".MuiInputBase-input": {
						textAlign: "left",
						color: theme.palette.primary.main,
						marginLeft: "0.8rem",
						fontSize: "0.875rem",
						pointerEvents: "none"
					}
				}}
			/>
		</Box>
	);
};
