import { CheckReportLiderLogo } from "../images";
import { CheckReportDialogContentProps } from "../types";
import { Box, Stack } from "@mui/material";
import { CheckReportDialogContentStack } from "../../../util/theme/styled-components";

import backgroundIcon from "../../../assets/images/dashboard/dashboard-select-dialog-background.svg";
import mobileBackgroundIcon from "../../../assets/images/dashboard/dashboard-select-dialog-mobile-background.svg";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

export const CheckReportDialogContent: React.FC<CheckReportDialogContentProps> = ({
	PrimaryInfo,
	SecondaryInfo = <Box />,
	infoContainerStyles
}) => {
	const { isMobile } = useResponsive();

	const [Logo, backgroundStyles] = isMobile
		? [
				<Box />,
				{
					backgroundImage: `url(${mobileBackgroundIcon})`,
					backgroundPosition: "bottom 0 right 0"
				}
		  ]
		: [
				<CheckReportLiderLogo />,
				{
					backgroundImage: `url(${backgroundIcon})`,
					backgroundPosition: "bottom 0 right 1.3rem"
				}
		  ];

	return (
		<Box position="relative" width="100%" height="12.5rem">
			<CheckReportDialogContentStack
				direction="row"
				justifyContent="space-between"
				width={{ xs: "calc(100% - 0.97rem)", md: "calc(100% - 1.63rem)" }}
				height="calc(100% - 1.24rem)"
				sx={backgroundStyles}
				position="absolute"
				left="0"
				bottom="0"
				zIndex={3}
			>
				<Stack direction="row" alignItems="center" height="100%" sx={infoContainerStyles}>
					{PrimaryInfo}
				</Stack>
				<Stack
					direction="column"
					justifyContent="space-between"
					height="100%"
					paddingY={{ xs: "1.47rem", md: "1rem" }}
					paddingRight={{ xs: "1.38rem", md: "1.5rem" }}
				>
					{SecondaryInfo}
					{Logo}
				</Stack>
			</CheckReportDialogContentStack>
			<CheckReportDialogContentStack
				width={{ xs: "calc(100% - 0.97rem)", md: "calc(100% - 1.63rem)" }}
				height="calc(100% - 1.24rem)"
				position="absolute"
				sx={{ background: ColorDictionary["#f5f5f5"] }}
				marginLeft="auto"
				marginRight="auto"
				marginTop="auto"
				marginBottom="auto"
				left="0"
				right="0"
				top="0"
				bottom="0"
				zIndex={2}
			/>
			<CheckReportDialogContentStack
				width={{ xs: "calc(100% - 0.97rem)", md: "calc(100% - 1.63rem)" }}
				height="calc(100% - 1.24rem)"
				position="absolute"
				sx={{ backgroundColor: "grey.300" }}
				right="0"
				top="0"
				zIndex={1}
			/>
		</Box>
	);
};
