import { Box, Stack, useTheme } from "@mui/material";
import { LocalizedGenericBody } from "../../elements/body.element";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

export const LoginBreadLink: React.FC<{
	localizationKey: string;
	endItem?: boolean;
}> = ({ localizationKey, endItem = false }) => {
	const theme = useTheme();

	return (
		<Stack direction="row" alignItems="center" sx={{ a: { textDecoration: "none" } }}>
			<LocalizedGenericBody
				localizationKey={localizationKey}
				variant="body3-primary-semi-bold"
			/>
			{endItem ? null : (
				<Box
					sx={{
						width: "0.1875rem",
						height: "0.1875rem",
						borderRadius: "2rem",
						marginX: "0.75rem",
						background: theme.palette.primary.main
					}}
				/>
			)}
		</Stack>
	);
};

export const LoginBreadLinks: React.FC = () => {
	const { isMobile } = useResponsive();

	const localizations = [
		"login_bread_home",
		"login_bread_terms",
		"login_bread_privacy",
		"login_bread_contact"
	];

	const getEndItemCondition = (index: number) =>
		isMobile ? index % 2 === 1 : index === localizations.length - 1;

	return (
		<Stack
			direction="row"
			width={{ xs: "16rem", md: "unset" }}
			alignItems="center"
			justifyContent="center"
			useFlexGap
			rowGap="0.5rem"
			flexWrap="wrap"
			mt={{ xs: "1.5rem", md: "unset" }}
			sx={{ opacity: 0.5 }}
		>
			{localizations.map((localizationKey, index) => (
				<LoginBreadLink
					key={localizationKey}
					localizationKey={localizationKey}
					endItem={getEndItemCondition(index)}
				/>
			))}
		</Stack>
	);
};
