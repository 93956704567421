import { Drawer, styled } from "@mui/material";

export const CareerDetailDrawer = styled(Drawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		padding: "3.5%",
		background: `linear-gradient(180deg,
			${theme.palette.tertiary.main} 0.52%,
			${theme.palette.primary.main} 20.64%)`,
		width: "85%",
		borderBottomLeftRadius: "0.500rem",
		borderTopLeftRadius: "0.500rem",
		overflowY: "auto"
	}
}));

export const HeaderSectionDrawer = styled(Drawer)(
	({
		theme: {
			palette: { primary }
		}
	}) => ({
		width: "16.0rem",
		maxWidth: "75%",
		"& .MuiDrawer-paper": {
			paddingLeft: "0.938rem",
			width: "16.0rem",
			maxWidth: "75%",
			background: primary.dark
		}
	})
);

export const SidebarDrawer = styled(Drawer)<{ topoffset: string }>(
	({ theme, topoffset }) => ({
		"& .MuiDrawer-paper": {
			width: "15.0rem",
			height: "47.9rem",
			maxHeight: "100vh",

			position: "absolute",
			top: topoffset,
			left: "4.50rem",

			[theme.breakpoints.up("xl")]: {
				width: "19.1rem"
			},

			[theme.breakpoints.up("xxl")]: {
				left: "0"
			},

			background: `linear-gradient(180.02deg,
			${theme.palette.primary.main} 32.01%,
			${theme.palette.secondary.light} 60.89%,
			${theme.palette.primary.main}00 99.98%)`,

			boxShadow: `inset 0px 4px 4px ${theme.palette.common.black}40`,
			border: "none",
			overflow: "auto",
			transitionProperty: "top",
			transitionDuration: "1.5s"
		}
	})
);
