import { Stack } from "@mui/material";
import { LocalizedGenericBody } from "../../elements";
import { CompanyInformationDisclaimerImage } from "../images";

export const CompanyInformationDisclaimerRow: React.FC = () => (
	<Stack direction="row" spacing="0.5rem" padding="1rem 0.75rem" alignItems="center">
		<CompanyInformationDisclaimerImage />
		<LocalizedGenericBody
			variant="body3-primary"
			localizationKey="company-information-disclaimer-text"
			sx={{ a: { color: "secondary.main", "&:hover": { color: "tertiary.main" } } }}
		/>
	</Stack>
);
