import { Checkbox, FormControlLabel, FormGroup, useTheme } from "@mui/material";
import { useFormContext } from "../../../util/contexts/form.context";
import { useLanguageContext } from "../../../util/contexts/language.context";
import React from "react";
interface CheckboxFactoryOptions {
	localizationKey: string;
	infoField: string;
}

type CheckboxFactory = (factoryOptions: CheckboxFactoryOptions) => React.FC;

const CheckboxInputFactory: CheckboxFactory =
	({ localizationKey, infoField }) =>
	() => {
		const theme = useTheme();

		const { getLocalizedText } = useLanguageContext();
		const text = getLocalizedText(localizationKey);

		const { values, setValues, handleChange } = useFormContext();

		React.useEffect(() => {
			setValues((previous: Record<string, any>) => ({
				...previous,
				[infoField]: !!previous[infoField]
			}));
		}, []);

		return (
			<FormGroup>
				<FormControlLabel
					sx={{
						"& .MuiFormControlLabel-label": {
							fontSize: theme.typography.body3.fontSize
						}
					}}
					control={
						<Checkbox
							checked={!!values[infoField]}
							onChange={event => handleChange(infoField, event)}
						/>
					}
					label={text}
				/>
			</FormGroup>
		);
	};

export const AdminPanelHighlightedCheck = CheckboxInputFactory({
	localizationKey: "highlight_checkbox_input_label",
	infoField: "highlighted"
});

export const AdminPanelSectionsVisibilityCheck = CheckboxInputFactory({
	localizationKey: "section_visibility_checkbox_input_label",
	infoField: "visible"
});
