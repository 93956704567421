import { Box, Drawer, Stack } from "@mui/material";
import { DashboardPageItem } from "../../types";
import { DashboardSidebarItem, DashboardSidebarUserItem } from "../items";
import { DashboardSidebarCollapse, DashboardSidebarLogo } from "../images";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { DashboardSidebarBox } from "../../../util/theme/styled-components";
import { UserRoleEnum } from "../../../util/dictionaries/types";
import { FastEditButton, PublishButton } from "../buttons";
import { DialogKey } from "../../containers/types";
import sidebarBackground from "../../../assets/images/dashboard/dashboard-sidebar-background.svg";
import React from "react";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

interface Props {
	setActiveRoute: (route: string) => void;
	setExpanded: (expanded: boolean) => void;
	handleDialogToggle: (dialogKey: DialogKey) => void;
	isAdmin: boolean;
	activeRoute: string;
	dialogOpen: Record<DialogKey, boolean>;
	expanded: boolean;
	areClientsSynced: boolean;
	syncInProgress: boolean;
	isPublished: boolean;
	items: DashboardPageItem[];
	lastPublishDate: Date;
	userRole: UserRoleEnum;
	name: string | undefined;
}

export const DashboardSidebar: React.FC<Props> = ({
	setActiveRoute,
	setExpanded,
	handleDialogToggle,
	isAdmin,
	activeRoute,
	expanded,
	areClientsSynced,
	syncInProgress,
	items,
	isPublished,
	lastPublishDate,
	userRole,
	name
}) => {
	const { isTablet } = useResponsive();
	const { isOpen, setIsOpen, setTransitionComplete } = useSidebarContext();

	const [paddingBottomExpanded, paddingBottom] = isOpen
		? ["20.5rem", "12rem"]
		: ["16.5rem", "8.5rem"];

	const sidebarWidthExpanded = "19.5rem";
	const sidebarWidthCollapsed = "5.5rem";
	const sidebarWidth = isOpen ? sidebarWidthExpanded : sidebarWidthCollapsed;

	const handleLogoClick = () => {
		setActiveRoute("/");
		if (isTablet && isOpen) closeSidebar();
	};

	const closeSidebar = () => setIsOpen(false);

	const [Wrapper, wrapperProps] = isTablet
		? [Drawer, { open: isOpen, onClose: closeSidebar }]
		: [React.Fragment, {}];

	React.useEffect(() => {
		setTransitionComplete(false);
		setTimeout(() => setTransitionComplete(true), 500);
	}, [isOpen]);

	return (
		<Wrapper {...wrapperProps}>
			<DashboardSidebarBox
				data-cy="dashboard-sidebar"
				sx={{
					backgroundImage: `url(${sidebarBackground})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "0 calc(100% - 4.3rem)",
					width: sidebarWidth,
					transition: "width 0.5s ease",
					paddingBottom: expanded ? paddingBottomExpanded : paddingBottom
				}}
			>
				<Stack
					p="1rem 1.5rem"
					direction="row"
					justifyContent={isOpen ? "space-between" : "center"}
					alignItems="center"
					height="5.75rem"
				>
					{isOpen ? <DashboardSidebarLogo handleClick={handleLogoClick} /> : null}
					<DashboardSidebarCollapse
						isSidebarOpen={isOpen}
						handleClick={() => setIsOpen(!isOpen)}
					/>
				</Stack>
				{isAdmin ? (
					<Box pl="1.5rem" pr="1.5rem" pt="0.5rem" pb="1rem">
						<FastEditButton />
					</Box>
				) : null}
				<Stack
					pt="1.5rem"
					pl="1.5rem"
					pr={isOpen ? "1.5rem" : "0"}
					spacing="0.5rem"
					sx={{
						overflowY: "auto",
						overflowX: "hidden"
					}}
				>
					{items
						.filter(({ hidden }) => !hidden)
						.sort((a, b) => a.index - b.index)
						.map(item => (
							<DashboardSidebarItem
								item={item}
								activeRoute={activeRoute}
								setActiveRoute={setActiveRoute}
								key={item.route}
							/>
						))}
				</Stack>
				<Stack
					spacing="0.75rem"
					sx={{
						position: "absolute",
						bottom: 0,
						left: 0,
						width: "100%",
						backgroundColor: "common.white"
					}}
				>
					{isAdmin ? (
						<Box pr="0.75rem" pl="0.75rem">
							<PublishButton
								handleClick={() => handleDialogToggle("publish")}
								isCollapsed={!isOpen}
								isPublished={isPublished}
								lastPublishDate={lastPublishDate}
							/>
						</Box>
					) : null}
					<DashboardSidebarUserItem
						setActiveRoute={setActiveRoute}
						handleDialogToggle={handleDialogToggle}
						setExpanded={setExpanded}
						areClientsSynced={areClientsSynced}
						syncInProgress={syncInProgress}
						expanded={expanded}
						userRole={userRole}
						name={name}
					/>
				</Stack>
			</DashboardSidebarBox>
		</Wrapper>
	);
};
