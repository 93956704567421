import { Box, Grid, Stack } from "@mui/material";
import { AdminFormCreateUpdateButton } from "../buttons";
import {
	LanguageSelect,
	AdminPanelHighlightedCheck,
	ImageLinkInput,
	BranchesAddressInput,
	BranchesFaxInput,
	BranchesHoursInput,
	AdminNameInput,
	PhoneNumberInput,
	BranchesWhatsappInput,
	BranchesLatitudeInput,
	BranchesLongitudeInput,
	CityInput
} from "../inputs";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminSEODialog } from "../dialogs";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";

export const AdminPanelBranchesForm: React.FC<FormProps> = ({ handleSubmit }) => (
	<Box
		component="form"
		width={{ xs: "100%", md: "15rem" }}
		paddingY={{ xs: "5%", md: "1.25rem" }}
		paddingX={{ xs: "5%", md: "0.625rem" }}
	>
		<Stack spacing="0.75rem">
			<AdminNameInput />
			<BranchesAddressInput />
			<CityInput />
			<Grid container gap="0.75rem">
				<Grid item xs>
					<BranchesLatitudeInput />
				</Grid>
				<Grid item xs>
					<BranchesLongitudeInput />
				</Grid>
			</Grid>
			<ImageLinkInput />
			<BranchesHoursInput />
			<AdminPanelBranchesDivider />
			<PhoneNumberInput />
			<BranchesWhatsappInput />
			<BranchesFaxInput />
			<AdminPanelBranchesDivider />
			<LanguageSelect />
			<AdminSEODialog />
			<AdminPanelHighlightedCheck />
			<AdminFormCreateUpdateButton handleClick={handleSubmit} />
		</Stack>
	</Box>
);

export const AdminPanelBranchesDivider: React.FC = () => (
	<Box sx={{ height: "1rem", display: "flex", alignItems: "center" }}>
		<Box
			sx={{
				height: "1px",
				width: "100%",
				background: ColorDictionary["#e6e6e6"]
			}}
		/>
	</Box>
);
