import React from "react";
import { Route } from "react-router-dom";
import { PageView, FullScreenSpinner } from "./views";
import { useLanguageContext } from "./util/contexts/language.context";
import { usePagesContext } from "./util/contexts/pages.context";
import { useSectionsContext } from "./util/contexts/sections.context";
import { LanguageEnum } from "./util/dictionaries/types";
import { SentryRoutes } from "./util/dictionaries/routes.dictionary";
import { useGeneratorEffect } from "./util/hooks/useGeneratorEffect.hook";
import { useLocalizedNavigate } from "./util/hooks/useLocalizedNavigate";
import { useUpdateEffect } from "./util/hooks/useUpdateEffect.hook";
import { LanguageFieldGenerator } from "./util/generators/language-field.generator";
import { RouteGenerator } from "./util/generators/route.generator";
import {
	PublicLanguageGenerator,
	PublicLocalizationGenerator,
	PublicPageGenerator,
	PublicSectionsByLanguageIdGenerator
} from "./util/generators/public.generator";
import { PageDAO } from "./util/generators/types";
import { useRefreshContext } from "./util/contexts/refresh.context";

const Website = () => {
	const { crudRefreshTrigger } = useRefreshContext();
	const {
		language,
		setLanguage,
		languages,
		setLanguages,
		setLocalizationObject,
		triggerLanguageRender
	} = useLanguageContext();
	const { navigate } = useLocalizedNavigate();

	const { setSections, setPageCounts } = useSectionsContext();
	const { pages, setPages } = usePagesContext();

	const initialState = LanguageFieldGenerator();

	const [routes, setRoutes] =
		React.useState<Record<LanguageEnum, React.ReactElement[]>>(initialState);

	React.useEffect(() => {
		const noscript = document.createElement("noscript");
		noscript.textContent = `
		<iframe
		src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_ID}"
		height="0"
		width="0"
		style="display: none; visibility: hidden"
		></iframe>
		`;
		document.body.appendChild(noscript);

		return () => {
			document.body.removeChild(noscript);
		};
	}, []);

	React.useEffect(() => {
		RouteGenerator(pages).then(routes => {
			setRoutes(routes);
		});
	}, [pages]);

	const pageGenerator = new PublicPageGenerator();
	const sectionGenerator = new PublicSectionsByLanguageIdGenerator();
	const languageGenerator = new PublicLanguageGenerator();
	const localizationGenerator = new PublicLocalizationGenerator();

	const routeLanguage = () =>
		languages.find(
			languageOption => languageOption === window.location.pathname.split("/")[1]
		) || (process.env.REACT_APP_DEFAULT_LANGUAGE as LanguageEnum);

	const pageReadyToRender = () => pages.length && routeLanguage() === language;
	const setLanguageFromPath = () => {
		if (language !== routeLanguage()) setLanguage(routeLanguage());
	};

	useUpdateEffect(() => {
		navigate("/");
	}, [triggerLanguageRender]);

	React.useEffect(() => {
		if (!languages || languages.length === 0) return;
		setLanguageFromPath();
	}, [languages, routeLanguage()]);

	const setPagesAndPageCounts = (pages: PageDAO[]) => {
		setPages(pages);

		const pageCounts: Record<string, number> = {};
		pages.forEach(page => {
			pageCounts[page.sectionId]
				? (pageCounts[page.sectionId] += 1)
				: (pageCounts[page.sectionId] = 1);
		});
		setPageCounts(pageCounts);
	};

	const generatorEffects = {
		pages: {
			effect: () => pageGenerator.many("sort=sectionId,index"),
			onSuccess: setPagesAndPageCounts,
			callback: pageGenerator.abort
		},
		sections: {
			effect: () => sectionGenerator.many("sort=index"),
			onSuccess: setSections,
			callback: sectionGenerator.abort
		},
		languages: {
			effect: languageGenerator.all,
			onSuccess: setLanguages,
			callback: languageGenerator.abort
		},
		localizations: {
			effect: () => localizationGenerator.many(`language=${language}`),
			onSuccess: setLocalizationObject,
			callback: localizationGenerator.abort
		}
	};

	useGeneratorEffect(generatorEffects.pages, [crudRefreshTrigger]);
	useGeneratorEffect(generatorEffects.sections, []);
	useGeneratorEffect(generatorEffects.languages, []);
	useGeneratorEffect(generatorEffects.localizations, [crudRefreshTrigger, language]);

	const allRoutes = (
		<SentryRoutes>
			{languages.map(language => {
				return (
					<Route
						key={language}
						path={`/${language}/*`}
						element={<SentryRoutes>{routes[language]}</SentryRoutes>}
					/>
				);
			})}
			{routes[process.env.REACT_APP_DEFAULT_LANGUAGE as LanguageEnum]}
		</SentryRoutes>
	);

	return (
		<>
			{pageReadyToRender() ? (
				<PageView data-cy="website">{allRoutes}</PageView>
			) : (
				<FullScreenSpinner />
			)}
		</>
	);
};

export default Website;
