import React from "react";
import {
	CareerDetailDrawer,
	CareerDetailStack
} from "../../../util/theme/styled-components";
import { FadingText } from "../../elements";
import { LocalizationContainer } from "../../containers";
import { DetailItem } from "../types";

interface MobileDetailItem extends DetailItem {
	isOpen: boolean;
	handleDrawerClose: () => void;
}

export const CareerDetail: React.FC<DetailItem> = ({
	title,
	city,
	description,
	requirements
}) => (
	<>
		<FadingText variant="h5-white">{title}</FadingText>
		<LocalizationContainer localizationKey="job_openings_location_text">
			{({ localizedText }) => (
				<FadingText variant="h6-white" mb={3}>{`${localizedText}: ${city}`}</FadingText>
			)}
		</LocalizationContainer>
		<LocalizationContainer localizationKey="job_openings_description_heading">
			{({ localizedText }) => (
				<FadingText variant="h5-white" mb={1}>
					{localizedText}
				</FadingText>
			)}
		</LocalizationContainer>
		<FadingText
			variant="body1-white"
			dangerouslySetInnerHTML={{ __html: description }}
			mb={3}
		></FadingText>
		<LocalizationContainer localizationKey="job_openings_requirements_heading">
			{({ localizedText }) => (
				<FadingText variant="h5-white" mb={1}>
					{localizedText}
				</FadingText>
			)}
		</LocalizationContainer>
		<FadingText
			variant="body1-white"
			dangerouslySetInnerHTML={{ __html: requirements }}
		></FadingText>
	</>
);

export const CareerDetailMobile: React.FC<MobileDetailItem> = ({
	isOpen,
	handleDrawerClose,
	...props
}) => {
	return (
		<CareerDetailDrawer
			variant="temporary"
			anchor="right"
			open={isOpen}
			onClose={handleDrawerClose}
		>
			<CareerDetail {...props} />
		</CareerDetailDrawer>
	);
};

export const CareerDetailDesktop: React.FC<DetailItem> = props => {
	return (
		<CareerDetailStack>
			<CareerDetail {...props} />
		</CareerDetailStack>
	);
};
