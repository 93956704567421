import { GenericImageContainer } from "../../elements";
import emptyStateImageSrc from "../../../assets/images/recent-checks-empty-state.svg";

export const RecentChecksTableImage: React.FC = () => (
	<GenericImageContainer
		imageSrc={emptyStateImageSrc}
		imageAlt="no-recent-checks"
		width="9.4375rem"
		height="6.25rem"
	/>
);
