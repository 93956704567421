import { Stack } from "@mui/material";
import React from "react";
import { PlainSubcontentList } from "../components";
import { EditableGenericBody } from "../elements";
import { PageIdProps } from "../types";
import { Content, Subcontent } from "./types";

interface Props extends PageIdProps {
	content: Content;
	subcontents: Subcontent[];
}

export const PlainContentSubcontentContainer: React.FC<Props> = ({
	content,
	subcontents,
	pageId
}) => (
	<Stack spacing={{ xs: 3, lg: 7 }}>
		<Stack spacing={{ xs: 1, md: 3 }}>
			<EditableGenericBody
				text={content.title}
				variants={{ desktop: "h2-secondary", mobile: "h3-secondary" }}
				propertyKey="title_1"
				pageId={pageId}
				component="h1"
			/>
			<EditableGenericBody
				innerHTML={content.body}
				variant="body2-primary"
				propertyKey="body_1"
				pageId={pageId}
			/>
		</Stack>
		<PlainSubcontentList subcontents={subcontents} pageId={pageId} />
	</Stack>
);
