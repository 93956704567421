import { Box, Divider, Grid, Stack } from "@mui/material";
import { useFormContext } from "../../../util/contexts/form.context";
import { AdminFormCreateUpdateButton } from "../buttons";
import {
	DescriptionInput,
	ImageLinkInput,
	IndexInput,
	LanguageSelect,
	AdminNameInput,
	NavigationPathInput,
	PagesBodyInput,
	PagesRouteInput,
	PagesSectionSelect,
	PagesSubtitleInput,
	PagesTemplateSelect,
	TitleInput
} from "../inputs";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminSEODialog } from "../dialogs";

interface textFieldProps {
	index?: number;
}

interface Fields {
	count: number;
	CustomTextField: React.FC<textFieldProps>;
}

type TextFieldGenerator = (fields: Fields) => React.ReactElement[] | undefined;

const generateTextField: TextFieldGenerator = ({ count, CustomTextField }) => {
	if (!count) return;
	const textFields: React.ReactElement[] = [];
	for (let index = 1; index <= count; index++) {
		textFields.push(<CustomTextField key={index} index={index} />);
	}
	return textFields;
};

export const AdminPanelPageForm: React.FC<FormProps> = ({ handleSubmit }) => {
	const { sectionList, selectedTemplate, templateList } = useFormContext();

	const sections = entityListNameReducer(sectionList);

	const templates = entityListNameReducer(templateList);

	return (
		<Box
			component="form"
			width={{ xs: "100%", md: "15rem" }}
			paddingY={{ xs: "5%", md: "1.25rem" }}
			paddingX={{ xs: "5%", md: "0.625rem" }}
		>
			<Stack spacing="0.75rem">
				<PagesTemplateSelect items={templates} />
				<Grid container gap="0.75rem">
					<Grid item xs>
						<IndexInput />
					</Grid>
					<Grid item xs>
						<PagesSectionSelect items={sections} />
					</Grid>
				</Grid>
				<LanguageSelect />
				<AdminSEODialog />
				<Divider />
				{!!selectedTemplate ? (
					<>
						<AdminNameInput />
						<DescriptionInput />
						<PagesRouteInput />
						<Divider />
						{generateTextField({
							count: selectedTemplate.titleCount,
							CustomTextField: TitleInput
						})}
						{generateTextField({
							count: selectedTemplate.subtitleCount,
							CustomTextField: PagesSubtitleInput
						})}
						{generateTextField({
							count: selectedTemplate.bodyCount,
							CustomTextField: PagesBodyInput
						})}
						{generateTextField({
							count: selectedTemplate.imageCount,
							CustomTextField: ImageLinkInput
						})}
						{generateTextField({
							count: selectedTemplate.navigationPathCount,
							CustomTextField: NavigationPathInput
						})}
						<AdminFormCreateUpdateButton handleClick={handleSubmit} />
					</>
				) : null}
			</Stack>
		</Box>
	);
};

const entityListNameReducer = (entityList: Record<string, any>) =>
	Object.entries(entityList).reduce(
		(returnObject, entry) => ({ ...returnObject, [entry[0]]: entry[1].name }),
		{}
	);
