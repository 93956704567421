import { Collapse, ListItemButton } from "@mui/material";
import React from "react";
import { SideMenuSectionList } from "../sections";
import { PageDAO } from "../../../util/generators/types";
import {
	SideMenuExpandLess,
	SideMenuExpandMore,
	SideMenuListItemText
} from "../../../util/theme/styled-components";

interface SideMenuCollapsibleItemProps {
	id: string;
	name: string;
	pages: PageDAO[];
	handleDrawerToggle: () => void;
}

export const SideMenuCollapsibleItem: React.FC<SideMenuCollapsibleItemProps> = ({
	id,
	name,
	pages,
	handleDrawerToggle
}) => {
	const [open, setOpen] = React.useState(false);
	const handleClick = () => setOpen(previous => !previous);

	return (
		<>
			<ListItemButton onClick={handleClick}>
				<SideMenuListItemText primary={name} />
				{open ? <SideMenuExpandLess /> : <SideMenuExpandMore />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<SideMenuSectionList key={id} pages={pages} closeDrawer={handleDrawerToggle} />
			</Collapse>
		</>
	);
};
