import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	useTheme
} from "@mui/material";
import React from "react";
import { DocumentDAO } from "../../../util/generators/types";
import { DocumentItem } from "../items";

interface Props {
	documents: DocumentDAO[];
	hasInfo?: boolean;
}

export const DocumentList: React.FC<Props> = ({ documents, hasInfo }) => {
	const theme = useTheme();

	return (
		<TableContainer
			sx={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: "0.500rem" }}
		>
			<Table>
				<TableBody>
					{documents
						.sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)))
						.map(item => (
							<TableRow
								key={item.id}
								sx={{
									"&:nth-of-type(odd)": {
										backgroundColor: "#fcfcfc"
									}
								}}
							>
								<TableCell sx={{ padding: 0 }}>
									<DocumentItem document={item} hasInfo={hasInfo} />
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
