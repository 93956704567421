import {
	ClientLoginFormHelperBox,
	LoginStack
} from "../../../../util/theme/styled-components";
import { LocalizedGenericBody } from "../../../elements";
import { LiderLoginLogo } from "../../images";
import { CLientLoginProps } from "./types";

export const ClientLoginForm: React.FC<CLientLoginProps> = ({ element }) => (
	<LoginStack
		maxWidth="35.875rem"
		spacing={{ xs: "2rem", md: "3.75rem" }}
		alignItems="center"
		pt="3.188rem"
	>
		<LiderLoginLogo />
		{element}
		<ClientLoginFormHelperBox py="1.5rem" px={{ xs: "1rem", md: "2.094rem" }}>
			<LocalizedGenericBody
				localizationKey="client_login_contact_text"
				variant="body3-primary-medium"
				sx={{ textAlign: "center", a: { fontWeight: "bold" } }}
			/>
		</ClientLoginFormHelperBox>
	</LoginStack>
);
