import React from "react";
import { GenericImageContainer } from "../../elements";

import ballotIcon from "../../../assets/images/dashboard/dashboard-client-home-ballot-icon.svg";
import calendarIcon from "../../../assets/images/dashboard/dashboard-client-home-calendar-icon.svg";

export const ClientHomeBallotIcon: React.FC = () => (
	<GenericImageContainer imageSrc={ballotIcon} imageAlt="ballot icon" width="3.75rem" />
);

export const ClientHomeCalendarIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={calendarIcon}
		imageAlt="calendar icon"
		width="3.75rem"
	/>
);
