import { Stack } from "@mui/material";
import { DashboardTableConfig } from "../../../util/configs/dashboard-table";
import { useTableContext } from "../../../util/contexts/table.context";
import { DashboardFiltersContainer, DashboardSearchContainer } from "../../containers";
import { AdminPanelCreateButton } from "../buttons";
import {
	DashboardTablePaper,
	SearchAndFiltersStack
} from "../../../util/theme/styled-components";
import { DashboardTableWrapper } from "./dashboard.table";
import { SkeletonTable } from "./skeleton.table";
import { DashboardTableHeaderRow } from "../rows";
import { DashboardTableBody } from "../bodies";
import { DashboardTableFooter } from "../footers";
import { EmptyTableViewContent } from "../contents";
import { useFormContext } from "../../../util/contexts/form.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { MobileTable } from "./mobile.table";
import { useTableQueryParams } from "../../../util/hooks/useTableQueryParams.hook";

interface Props {
	openDialog: () => void;
}

export const AdminTable: React.FC<Props> = ({ openDialog, ...props }) => {
	const { isMobile } = useResponsive();
	useTableQueryParams();
	const { resetStates } = useFormContext();
	const { clearSelectedElements, table, tableElements, isLoading } = useTableContext();
	const { dialog } = DashboardTableConfig[table];

	const numberOfTableEntries = tableElements.length;

	const openCreateDialog = () => {
		clearSelectedElements();
		resetStates();
		openDialog();
	};

	const [desktopTable, mobileTable] = isLoading
		? [
				<DashboardTablePaper>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow
							variant="secondary"
							data-cy="dashboard-table-header-row"
						/>
						<SkeletonTable />
					</DashboardTableWrapper>
					<DashboardTableFooter />
				</DashboardTablePaper>,
				<MobileTable />
		  ]
		: numberOfTableEntries > 0
		? [
				<DashboardTablePaper>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow
							variant="secondary"
							data-cy="dashboard-table-header-row"
						/>
						<DashboardTableBody />
					</DashboardTableWrapper>
					<DashboardTableFooter />
				</DashboardTablePaper>,
				<MobileTable />
		  ]
		: [
				<DashboardTablePaper>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow
							variant="secondary"
							data-cy="dashboard-table-header-row"
						/>
					</DashboardTableWrapper>
					<EmptyTableViewContent />
				</DashboardTablePaper>,
				<MobileTable />
		  ];

	const tableContent = isMobile ? mobileTable : desktopTable;

	return (
		<Stack spacing={{ xs: "1rem", md: "1.5rem" }} mt="-0.5rem" {...props}>
			<Stack
				spacing="1rem"
				direction={{ xs: "column", md: "row" }}
				justifyContent="space-between"
				useFlexGap
				flexWrap="wrap"
				alignItems={{ xs: "unset", md: "end" }}
			>
				<SearchAndFiltersStack>
					<DashboardSearchContainer />
					<DashboardFiltersContainer />
				</SearchAndFiltersStack>
				{dialog ? (
					<AdminPanelCreateButton
						handleClick={openCreateDialog}
						data-cy="admin-create-button"
					/>
				) : null}
			</Stack>
			{tableContent}
		</Stack>
	);
};
