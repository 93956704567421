import React from "react";

interface IRefreshContext {
	triggerCRUDRefresh: () => void;
	crudRefreshTrigger: boolean;
}

export const RefreshContext = React.createContext<IRefreshContext>({
	triggerCRUDRefresh: () => null,
	crudRefreshTrigger: false
});

export const useRefreshContext = () => React.useContext(RefreshContext);

export const RefreshProvider: React.FC = ({ children }) => {
	const [crudRefreshTrigger, setCRUDRefreshTrigger] = React.useState(false);

	const triggerCRUDRefresh = () => setCRUDRefreshTrigger(prevvious => !prevvious);

	const contextValue = { triggerCRUDRefresh, crudRefreshTrigger };

	return (
		<RefreshContext.Provider value={contextValue}>{children}</RefreshContext.Provider>
	);
};
