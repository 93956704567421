import { MetaTag } from "./base.meta";

interface MetaProps {
	name: string;
	content: string;
}

interface Props {
	title: string;
	meta: MetaProps | MetaProps[];
}

export const MetaWithTitle: React.FC<Props> = ({ title, meta }) => (
	<MetaTag tagContent={meta}>
		<title>{title}</title>
	</MetaTag>
);
