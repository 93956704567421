import { ListAndMediaContainer, PageWithSEO } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	title_1: string;
	body_1: string;
	body_2: string;
	subtitle_1: string;
	subtitle_2: string;
	imageLink_1: string;
}

export const ListAndMedia: React.FC<Props> = ({
	title_1,
	body_1,
	body_2,
	subtitle_1,
	subtitle_2,
	imageLink_1,
	pageId,
	...seoProps
}) => {
	return (
		<PageWithSEO withSidebar {...seoProps}>
			<ListAndMediaContainer
				{...{ title_1, body_1, body_2, subtitle_1, subtitle_2, imageLink_1, pageId }}
			/>
		</PageWithSEO>
	);
};
