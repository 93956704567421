import { LocalizationContainer } from "../../../containers";
import { LoginInputProps } from "./types";
import React from "react";
import { LoginTextField } from "../../../../util/theme/styled-components";

export const LoginInput: React.FC<LoginInputProps> = ({
	handleChange,
	endAdornment,
	type,
	localizationKey,
	value,
	modifiedField,
	submissionStatus,
	...props
}) => (
	<LocalizationContainer localizationKey={localizationKey} sx={{ width: "100%" }}>
		{({ localizedText }) => (
			<LoginTextField
				{...props}
				required
				$submissionStatus={submissionStatus}
				variant="filled"
				label={localizedText}
				value={value}
				onChange={event => handleChange(modifiedField, event.target.value)}
				type={type}
				InputProps={{
					disableUnderline: true,
					endAdornment
				}}
			/>
		)}
	</LocalizationContainer>
);
