import { DateTime } from "luxon";
import { ChartProvider } from "./chart.context";
import { useLanguageContext } from "./language.context";
import { useEmptyChartData } from "../hooks/useEmptyChartData.hook";
import { useClientCheckQueries } from "../hooks/useClientCheckQueries.hook";

export const BarChartProvider: React.FC = ({ children }) => {
	const { language } = useLanguageContext();
	const { emptyBarChartData } = useEmptyChartData();
	const { barChartChecksQuery } = useClientCheckQueries();

	const apiRequestArguments = ["checkAmount", "checkDueDate", barChartChecksQuery];

	const getFormattedCheckMonth = (checkMonth: string) =>
		DateTime.fromFormat(checkMonth, "MM/yyyy", { zone: "utc" })
			.setLocale(language)
			.toFormat("MMMM yy");

	return (
		<ChartProvider
			labelFormatter={getFormattedCheckMonth}
			emptyChartData={emptyBarChartData}
			apiRequestArguments={apiRequestArguments}
		>
			{children}
		</ChartProvider>
	);
};
