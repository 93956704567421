import { Stack } from "@mui/material";
import { useTableContext } from "../../../util/contexts/table.context";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import { DateTime } from "luxon";
import { LanguageEnum } from "../../../util/dictionaries/types";
import { useLanguageContext } from "../../../util/contexts/language.context";

export const CheckReportSingleItemContent: React.FC = () => {
	const { language } = useLanguageContext();
	const { selectedElements } = useTableContext();
	return (
		<Stack
			direction="column"
			justifyContent={{ xs: "space-between", md: "unset" }}
			height={{ xs: "100%", md: "unset" }}
			spacing={{ md: "0.38rem" }}
		>
			{checkProperties.map(property => (
				<Stack direction="row" spacing={{ xs: "0.62rem", md: "1rem" }} key={property}>
					<LocalizedGenericBody
						key={property}
						variant="body4-primary"
						sx={{ fontWeight: "600", textAlign: "right", width: "5rem" }}
						localizationKey={`table_checks_${property}`}
					/>
					<GenericBody
						key={property}
						variant="body4-primary"
						text={infoFormatter(property, selectedElements[0][property], language)}
						lineClamp={1}
					/>
				</Stack>
			))}
		</Stack>
	);
};

const infoFormatter = (infoField: string, value: any, language: LanguageEnum) => {
	switch (infoField) {
		case "checkDueDate":
			return DateTime.fromISO(value)
				.setLocale(language)
				.toLocaleString(DateTime.DATE_SHORT);
		case "checkAmount":
			return "₺" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		default:
			return value;
	}
};

const checkProperties = [
	"checkAmount",
	"checkDueDate",
	"checkBankName",
	"checkBranchName",
	"checkAccountNumber",
	"checkDrawer"
];
