import React from "react";
import {
	AdminHomepageClientsCardContainer,
	AdminHomepageFormContainer,
	AdminHomepageTableContainer
} from "../containers";
import { Stack } from "@mui/material";
import { TableEnum } from "../../util/types/dashboard-table.types";

export const AdminHomepage: React.FC = () => (
	<Stack spacing="1.5rem">
		<Stack direction={{ xs: "column", md: "row" }} spacing="1.5rem">
			<AdminHomepageFormContainer table={TableEnum.news} />
			<AdminHomepageFormContainer table={TableEnum.documents} />
			<AdminHomepageClientsCardContainer />
		</Stack>
		<AdminHomepageTableContainer />
	</Stack>
);
