import { Table, TableContainer, useTheme } from "@mui/material";
import React from "react";

interface Props {
	stickyHeader?: boolean;
	withoutFooter?: boolean;
}

export const DashboardTableWrapper: React.FC<Props> = ({
	stickyHeader = false,
	withoutFooter = false,
	children
}) => {
	const theme = useTheme();

	const containerStyles = withoutFooter
		? { borderRadius: "inherit" }
		: {
				borderRadius: "inherit",
				borderBottomRightRadius: "0",
				borderBottomLeftRadius: "0"
		  };

	const maxActivitiesTableHeight = "37.75rem";

	return (
		<TableContainer
			sx={{
				...containerStyles,
				maxHeight: stickyHeader ? maxActivitiesTableHeight : "100%",
				".MuiTable-stickyHeader": {
					td: { borderBottom: `1px solid ${theme.palette.grey[300]}` }
				}
			}}
		>
			<Table size="small" stickyHeader={stickyHeader}>
				{children}
			</Table>
		</TableContainer>
	);
};
