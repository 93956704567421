import {
	ClientHomepageStack,
	ClientHomepageTableHeaderStack,
	DashboardTablePaper
} from "../../../util/theme/styled-components";
import { TimeToggleContainer } from "../../containers";
import { DashboardTableHeaderRow } from "../rows";
import { DashboardTableBody } from "../bodies";
import { DashboardTableWrapper } from "./dashboard.table";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { SkeletonTable } from "./skeleton.table";
import { MobileTable } from "./mobile.table";
import { DashboardTableFooter } from "../footers";
import { useTableContext } from "../../../util/contexts/table.context";
import { EmptyTableViewContent } from "../contents";
import { ClientHomepageTableHeading } from "../headings";

export const ClientHomepageTable: React.FC = () => {
	const { isMobile } = useResponsive();
	const { isLoading, tableElements } = useTableContext();

	const numberOfDisplayableChecks = tableElements.length;

	const [desktopTable, mobileTable] = isLoading
		? [
				<DashboardTablePaper $inBlock>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow variant="grey" />
						<SkeletonTable />
					</DashboardTableWrapper>
					<DashboardTableFooter pageSizeFixed />
				</DashboardTablePaper>,
				<MobileTable />
		  ]
		: numberOfDisplayableChecks > 0
		? [
				<DashboardTablePaper $inBlock>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow variant="grey" />
						<DashboardTableBody />
					</DashboardTableWrapper>
					<DashboardTableFooter pageSizeFixed />
				</DashboardTablePaper>,
				<MobileTable />
		  ]
		: [
				<DashboardTablePaper $inBlock>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow variant="grey" />
					</DashboardTableWrapper>
					<EmptyTableViewContent />
				</DashboardTablePaper>,
				<MobileTable />
		  ];

	const tableContent = isMobile ? mobileTable : desktopTable;

	return (
		<ClientHomepageStack border={{ xs: "none", md: "default" }}>
			<ClientHomepageTableHeaderStack>
				<ClientHomepageTableHeading />
				<TimeToggleContainer />
			</ClientHomepageTableHeaderStack>
			{tableContent}
		</ClientHomepageStack>
	);
};
