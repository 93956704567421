import { Stack } from "@mui/material";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ApplicationsPublicCommunicator } from "../../communicators/lider.http.communicator";
import { ApplicationForm } from "../components";
import { EditableGenericBody } from "../elements";
import { PageIdProps } from "../types";
import { ApplicationFileValidation } from "../../util/validations/application-file.validation";
import { RegularActionWithDelayedCallback } from "../../util/ActionWithDelayedCallback";
import {
	ApplicationFormInfoFields,
	ApplicationFormTextValidations,
	ApplicationFormValues,
	SubmissionStatus,
	SubmissionStatusEnum,
	TermsAgreementFields
} from "./types";

interface Props extends PageIdProps {
	body_1: string;
	title_1: string;
}

const initialTermsAgreementState: Record<TermsAgreementFields, boolean> = {
	kvkk: false,
	agreementForm: false
};

const initialFormValues: ApplicationFormValues = {
	first_name: "",
	last_name: "",
	email: "",
	phone_number: "",
	notes: "",
	invoices: [],
	checks: []
};

const initialTextValidations: ApplicationFormTextValidations = {
	first_name: null,
	last_name: null,
	email: null,
	phone_number: null
};

export const ApplicationContainer: React.FC<Props> = ({ body_1, title_1, pageId }) => {
	const recaptchaRef = React.useRef<ReCAPTCHA>(null);

	const [captchaToken, setCaptchaToken] = React.useState<string | null | undefined>(null);
	const [formValues, setFormValues] =
		React.useState<ApplicationFormValues>(initialFormValues);
	const [submissionStatus, setSubmissionStatus] = React.useState<SubmissionStatus>(null);
	const [textValidations, setTextValidations] =
		React.useState<ApplicationFormTextValidations>(initialTextValidations);
	const [fileValidationWarning, setFileValidationWarning] = React.useState("");
	const [filesInvalid, setFilesInvalid] = React.useState(false);
	const [termsAgreementState, setTermsAgreementState] = React.useState<
		Record<TermsAgreementFields, boolean>
	>(initialTermsAgreementState);
	const [termsAgreementWarning, setTermsAgreementWarning] = React.useState(false);
	const [agreementFormOpen, setAgreementFormOpen] = React.useState(false);

	const communicator = new ApplicationsPublicCommunicator();

	const createFormDataWithCaptchaToken = (captchaToken: string) => {
		const payload = { ...formValues, captchaToken };

		const formData = new FormData();
		Object.entries(payload).forEach(([key, value]) => {
			Array.isArray(value)
				? value.forEach(item => formData.append(key, item))
				: formData.set(key, value);
		});
		return formData;
	};

	const submitFormData = (formData: FormData) =>
		communicator
			.createOne(formData)
			.then(() => setSubmissionStatus(SubmissionStatusEnum.success))
			.catch(() => setSubmissionStatus(SubmissionStatusEnum.error));

	const resetFormStates = () => {
		setFormValues(initialFormValues);
		setTermsAgreementState(initialTermsAgreementState);
	};

	React.useEffect(() => {
		if (!captchaToken) return;
		const formData = createFormDataWithCaptchaToken(captchaToken);
		submitFormData(formData);
		resetFormStates();
	}, [captchaToken]);

	const toggleAgreementFormOpen = () => setAgreementFormOpen(previous => !previous);

	const closeSnackbar = () => setSubmissionStatus(null);

	const handleCheckboxChange = (
		infoField: TermsAgreementFields,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const {
			target: { checked }
		} = event;

		setTermsAgreementState(previous => ({ ...previous, [infoField]: checked }));
	};

	const handleFileChange = (
		infoField: "invoices" | "checks",
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const {
			target: { files }
		} = event;
		if (!files) return;
		const uploadedFiles = Object.values(files);
		const updatedFiles = [...formValues[infoField], ...uploadedFiles];

		const { validationWarning } = new ApplicationFileValidation(updatedFiles).validate();
		if (validationWarning) {
			setFileValidationWarning(validationWarning);
			return displayWarning(setFilesInvalid);
		}

		setFormValues(previous => ({ ...previous, [infoField]: updatedFiles }));
	};

	const handleSubmit = async () => {
		const invalidFields = getInvalidFields();
		const thereAreInvalidFields = invalidFields.length > 0;
		if (thereAreInvalidFields) return setInvalidForFields(invalidFields);

		const areTermsAgreed = termsAgreementState.kvkk && termsAgreementState.agreementForm;
		if (!areTermsAgreed) return displayWarning(setTermsAgreementWarning);

		await generateAndSetCaptchaToken();
	};

	const getInvalidFields = () =>
		Object.entries(textValidations)
			.filter(([, isValid]) => !isValid)
			.map(([field]) => field);

	const setInvalidForFields = (invalidFields: string[]) => {
		const invalidTextValidations = invalidFields.reduce(
			(accumulator, field) => ({ ...accumulator, [field]: false }),
			{}
		);
		setTextValidations(previous => ({ ...previous, ...invalidTextValidations }));
	};

	const generateAndSetCaptchaToken = async () => {
		const token = await recaptchaRef.current?.executeAsync();
		recaptchaRef.current?.reset();
		setCaptchaToken(token);
	};

	const handleTextChange = (
		infoField: ApplicationFormInfoFields,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const {
			target: { value }
		} = event;
		setFormValues(previous => ({ ...previous, [infoField]: value }));
	};

	const removeFileFromField = (infoField: "invoices" | "checks", fileName: string) => {
		const fileList = formValues[infoField].filter(({ name }) => name !== fileName);
		setFormValues(previous => ({ ...previous, [infoField]: fileList }));
	};

	const setIsValidForField = (
		infoField: ApplicationFormInfoFields,
		isValid: null | boolean
	) => setTextValidations(previous => ({ ...previous, [infoField]: isValid }));

	const formProps = {
		closeSnackbar,
		handleCheckboxChange,
		handleFileChange,
		handleSubmit,
		handleTextChange,
		removeFileFromField,
		toggleAgreementFormOpen,
		setIsValidForField,
		values: formValues,
		submissionStatus,
		textValidations,
		filesInvalid,
		fileValidationWarning,
		agreementFormOpen,
		termsAgreementState,
		termsAgreementWarning
	};

	return (
		<Stack alignItems="center" spacing={2}>
			<Stack spacing={2}>
				<EditableGenericBody
					variants={{ desktop: "h2-secondary", mobile: "h3-secondary" }}
					text={title_1}
					pageId={pageId}
					propertyKey="title_1"
					component="h1"
				/>
				<EditableGenericBody
					variant="body2-primary"
					innerHTML={body_1}
					pageId={pageId}
					propertyKey="body_1"
				/>
			</Stack>
			<ApplicationForm {...formProps} />
			<ReCAPTCHA
				ref={recaptchaRef}
				sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
				size="invisible"
			/>
		</Stack>
	);
};

const displayWarning = (setDisplay: (display: boolean) => void) =>
	new RegularActionWithDelayedCallback(
		() => setDisplay(true),
		() => setDisplay(false)
	).start();
