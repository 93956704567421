import { Container } from "@mui/material";
import { CareersContainer, PageWithSEO } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	title_1: string;
}

export const CareersPage: React.FC<Props> = ({ title_1, pageId, ...seoProps }) => {
	return (
		<PageWithSEO footerVariant="dark" {...seoProps}>
			<Container maxWidth="xl">
				<CareersContainer {...{ title_1, pageId }} />
			</Container>
		</PageWithSEO>
	);
};
