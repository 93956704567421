import { Stack } from "@mui/material";
import { GenericImageContainer, LocalizedGenericBody } from "../../elements";
import { AdminHomepageTableHeadingProps } from "../types";

export const AdminHomepageTableHeading: React.FC<AdminHomepageTableHeadingProps> = ({
	endIcon,
	imageSrc,
	localizationKey
}) => (
	<Stack direction="row" spacing="0.75rem" alignItems="center">
		<GenericImageContainer imageAlt="resource-icon" imageSrc={imageSrc} width="1.25rem" />
		<LocalizedGenericBody variant="h4-primary" localizationKey={localizationKey} />
		{endIcon}
	</Stack>
);
