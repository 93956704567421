import React from "react";

interface ITemplatesContext {
	activeTemplateName: string | null;
	setActiveTemplateName: (templateName: string) => void;
}

export const TemplatesContext = React.createContext<ITemplatesContext>({
	activeTemplateName: null,
	setActiveTemplateName: () => null
});

export const useTemplatesContext = () => React.useContext(TemplatesContext);

export const TemplatesProvider: React.FC = ({ children }) => {
	const [activeTemplateName, setActiveTemplateName] = React.useState<string | null>(null);
	const contextValue = { activeTemplateName, setActiveTemplateName };

	return (
		<TemplatesContext.Provider value={contextValue}>{children}</TemplatesContext.Provider>
	);
};
