import { Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { EditableGenericButton, EditableGenericBody } from "../../elements";
import { PageIdProps } from "../../types";

interface Props extends PageIdProps {
	body: string;
	buttonText: string;
	navigationPath: string;
	title: string;
}

export const ContactTeamContent: React.FC<Props> = ({
	body,
	buttonText,
	navigationPath,
	title,
	pageId
}) => {
	const navigate = useNavigate();

	return (
		<Stack spacing={5} alignItems="center">
			<Stack spacing={2}>
				<EditableGenericBody
					variant="h3-primary-center"
					text={title}
					propertyKey="title_1"
					pageId={pageId}
				/>
				<EditableGenericBody
					variant="subtitle1-primary-center"
					text={body}
					propertyKey="body_1"
					pageId={pageId}
				/>
			</Stack>
			<EditableGenericButton
				propertyKey1="body_1"
				text1={buttonText}
				propertyKey2="navigationPath_1"
				text2={navigationPath}
				pageId={pageId}
				text={buttonText}
				handleClick={() => navigate(`/${navigationPath}`)}
			/>
		</Stack>
	);
};
