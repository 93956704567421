import { Box, Grid } from "@mui/material";

export const PageMainContentWrapper: React.FC = ({ children }) => {
	return (
		<Box minHeight="51.6rem">
			<Grid container rowSpacing={3}>
				{children}
			</Grid>
		</Box>
	);
};
