import { Theme } from "@mui/material";
import { ColorDictionary } from "../../dictionaries/color.dictionary";
import { Column } from "../../configs/dashboard-table";

interface StyleDependencies {
	theme: Theme;
	isHovered: boolean;
	isOpen: boolean;
	isSelected: boolean;
	columnType: Column["type"];
}

export const getFilterStyles = ({
	theme,
	columnType,
	isHovered,
	isOpen,
	isSelected
}: StyleDependencies) => {
	return {
		label: {
			backgroundOpacity: isHovered || isOpen ? 1 : 0.5,
			paddingLeft: "1rem",
			opacity: columnType.includes("dateRange") ? 0 : 1
		},
		select: {
			inputField: {
				overflow: "hidden",
				height: "2rem",
				borderRadius: "1.25rem",
				border: isSelected
					? `0.0625rem solid ${theme.palette.primary.main}66`
					: `0.0625rem solid ${theme.palette.grey[300]}`,
				background: isSelected
					? `${ColorDictionary["#60528f"]}0d`
					: theme.palette.common.white,
				boxShadow: isHovered
					? `0 0.25rem 0.8125rem 0 ${theme.palette.common.black}12`
					: "none",
				cursor: "pointer",
				".MuiOutlinedInput-notchedOutline": { border: "none" },
				".MuiSelect-select": {
					display: "flex",
					alignItems: "center"
				}
			},
			startImage: {
				opacity: isHovered || isOpen || isSelected ? "1" : "0.5",
				cursor: "pointer"
			},
			endImage: {
				box: {
					height: "100%",
					background: isSelected
						? `linear-gradient(to right, transparent, ${ColorDictionary["#60528f"]}0d)`
						: theme.palette.common.white,
					borderTopRightRadius: "20rem",
					borderBottomRightRadius: "20rem"
				},
				arrow: {
					opacity: isHovered || isOpen ? 1 : 0.5
				}
			},
			menu: {
				".MuiPaper-root": {
					borderRadius: "0.5rem",
					".MuiList-root": {
						padding: 0
					}
				}
			}
		}
	};
};
