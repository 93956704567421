import { GenericImageContainer } from "../../elements";

interface Props {
	imageSrc: string;
}

export const DateWithTimeCellImage: React.FC<Props> = ({ imageSrc }) => (
	<GenericImageContainer
		imageAlt={"date-with-time-cell"}
		imageSrc={imageSrc}
		width="0.875rem"
	/>
);
