import { Box, Stack } from "@mui/material";
import { DashboardFileUploadImage } from "../../images";
import { GenericBody } from "../../../elements";
import { ColorDictionary } from "../../../../util/dictionaries/color.dictionary";
import { ClearButton } from "../../buttons";

interface Props {
	fileName: string;
	fileSize: number;
	handleClear: () => void;
}

export const DndUploadPreview: React.FC<Props> = ({
	fileName,
	fileSize,
	handleClear
}) => {
	const convertSize = () => {
		if (!+fileSize) return "0 Bytes";

		const sizes = ["Bytes", "KB", "MB", "GB"];
		const power = Math.floor(Math.log(fileSize) / Math.log(1024));

		return `${parseFloat((fileSize / Math.pow(1024, power)).toFixed(2))} ${sizes[power]}`;
	};

	return (
		<Box
			sx={{
				background: `${ColorDictionary["#60528f"]}14`,
				padding: "1rem",
				borderRadius: "0.5rem"
			}}
		>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Stack direction="row" spacing="0.75rem" width="100%">
					<DashboardFileUploadImage />
					<Stack justifyContent="center" width="100%">
						<GenericBody
							sx={{ wordBreak: "break-all" }}
							variant="body3-secondary-semi-bold"
							text={fileName}
						/>
						<GenericBody
							variant="body3-primary-medium"
							sx={{ opacity: 0.42 }}
							text={convertSize()}
						/>
					</Stack>
				</Stack>
				<Box>
					<ClearButton handleClear={handleClear} />
				</Box>
			</Stack>
		</Box>
	);
};
