import { styled, Typography } from "@mui/material";

export const FaqSearchItemAnswer = styled(Typography)(({ theme }) => ({
	a: {
		color: theme.palette.secondary.main,
		"&:hover": { color: theme.palette.tertiary.main }
	}
}));

export const HeaderSectionTypography = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.body3.fontSize,
	fontWeight: 600,
	"&:hover": { color: theme.palette.tertiary.main }
}));
