export const getMuiToolBarStyles = () => {
	return {
		styleOverrides: {
			root: {
				padding: "0 !important",
				height: "100%"
			}
		}
	};
};
