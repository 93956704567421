import React from "react";
import { AdminDialogProps } from "../types";
import { UserCreateResponseDialog } from "./user-create-response.dialog";
import { UserCreateErrorImage } from "../images";
import { SubmissionStatusEnum } from "../../containers/types";

export const UserCreateErrorDialog: React.FC<AdminDialogProps> = props => (
	<UserCreateResponseDialog
		Image={UserCreateErrorImage}
		variant={SubmissionStatusEnum.error}
		{...props}
	/>
);
