import { Typography } from "@mui/material";
import React from "react";
import { BannerAnnouncementBox } from "../../../util/theme/styled-components";
import { AnnouncementsDAO } from "../../../util/generators/types";
import { DateTime } from "luxon";
import { useLanguageContext } from "../../../util/contexts/language.context";

interface Props {
	announcement: AnnouncementsDAO;
}

export const BannerAnnouncement: React.FC<Props> = ({
	announcement: { title, date, content }
}) => {
	const { language } = useLanguageContext();

	const displayDate = DateTime.fromISO(date)
		.setLocale(language)
		.toLocaleString(DateTime.DATE_FULL);

	return (
		<BannerAnnouncementBox>
			<Typography variant="h5-white">{title}</Typography>
			<Typography variant="h6-white" mb={2}>
				{displayDate}
			</Typography>
			<Typography variant="body1-white">{content}</Typography>
		</BannerAnnouncementBox>
	);
};
