import {
	ContentMediaImageContainer,
	SocialMediaImageContainer,
	BranchNavigationDots,
	ExtensionIconContainer,
	ContentFourSubcontentImageContainer
} from "../../../util/theme/styled-components";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { GenericImageContainer } from "../../elements";
import { DocumentExtension } from "../../../util/dictionaries/types";

interface Props {
	imageAlt: string;
	imageSrc: string;
	style?: Record<string, string>;
}

type Factory = (component?: any) => React.FC<Props>;

const ImageContainerFactory: Factory =
	component =>
	({ imageAlt, imageSrc, style }) => {
		return (
			<GenericImageContainer
				imageAlt={imageAlt}
				imageSrc={imageSrc}
				component={component}
				style={style}
				width="fit-content"
			/>
		);
	};

export const ContentMediaPageImage = ImageContainerFactory(ContentMediaImageContainer);
export const SocialMediaLogo = ImageContainerFactory(SocialMediaImageContainer);

export const BranchDetailsDots: React.FC = () => {
	const { imageAlt, imageSrc } = ImageDictionary.BranchDetails.dots;

	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			component={BranchNavigationDots}
			width="fit-content"
		/>
	);
};

interface ExtensionProps {
	variant: DocumentExtension;
}

export const ExtensionIcon: React.FC<ExtensionProps> = ({ variant }) => {
	const { imageAlt, imageSrc } = ImageDictionary.Extension[variant];
	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			component={ExtensionIconContainer}
			width="0.93181rem"
			height="1.25rem"
		/>
	);
};

export const ContentFourSubcontentIcon: React.FC<{ variant: "left" | "right" }> = ({
	variant
}) => {
	const { imageAlt, imageSrc } = ImageDictionary.ContentFourSubcontent[variant];
	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			component={ContentFourSubcontentImageContainer(variant)}
			width="fit-content"
		/>
	);
};
