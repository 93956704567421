import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { usePagesContext } from "../../../util/contexts/pages.context";
import { GenericBody } from "../../elements";
import { FooterSectionItem } from "../items";

interface Props {
	sectionId: string;
	name: string;
	variant?: "dark" | "light";
}

export const FooterSection: React.FC<Props> = ({ sectionId, name, variant }) => {
	const { pages: contextPages } = usePagesContext();
	const pages = contextPages.filter(page => page.sectionId === sectionId);

	const sectionItems: React.ReactElement[] = pages.map(({ name, route, id }) => (
		<FooterSectionItem name={name} route={route} key={id} variant={variant} />
	));

	return (
		<Grid item xs="auto">
			<Box pb="0.375rem" width="13.1rem">
				<GenericBody
					text={name}
					variant={
						variant === "dark" ? "body1-white-semi-bold" : "body1-primary-semi-bold"
					}
					sx={{ marginBottom: "0.875rem" }}
				/>
				<Stack spacing="0.500rem">{sectionItems}</Stack>
			</Box>
		</Grid>
	);
};
