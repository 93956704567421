import { TextEditIcon, TextEditIconButton } from "../../../util/theme/styled-components";

interface Props {
	handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const TextEditButton: React.FC<Props> = ({ handleClick }) => (
	<TextEditIconButton onClick={handleClick}>
		<TextEditIcon />
	</TextEditIconButton>
);
