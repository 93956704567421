import React from "react";
import { BarPlot, ChartsAxis, ResponsiveChartContainer } from "@mui/x-charts";
import { useTheme } from "@mui/material";
import { currencyFormatter } from "../../../util/currency.formatter";
import { BarChartBarItem, BarChartEmptyBarItem } from "../items";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";
import { BarChartPopper } from "../poppers";
import { useChartContext } from "../../../util/contexts/chart.context";
import { useScreenContext } from "../../../util/contexts/screen.context";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";

export const containerMargin = { top: 10, left: 50, right: 0, bottom: 50 };
export const containerHeight = 150;

const tickLabelStyles = {
	fontSize: "0.625rem",
	fontFamily: "Raleway",
	fontWeight: 400
};

export const BarChart: React.FC<{
	containerRef: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ containerRef }) => {
	const { setHighlightedIndex, chartItems: items, itemCount } = useChartContext();
	const { screenWidth } = useScreenContext();
	const { transitionComplete: sidebarTransitionComplete } = useSidebarContext();

	const theme = useTheme();

	const isEmpty = itemCount === 0;

	const marginLeft = isEmpty ? 25 : containerMargin.left;

	const tooltipAnchor = React.useRef<null | HTMLElement>(null);

	const [containerWidth, setContainerWidth] = React.useState(0);

	const mouseOverEvent = (
		event: React.MouseEvent<SVGUseElement, MouseEvent>,
		index: number
	) => {
		tooltipAnchor.current = event.target as HTMLElement;
		setHighlightedIndex(index);
	};
	const mouseOutEvent = () => {
		tooltipAnchor.current = null;
		setHighlightedIndex(null);
	};

	React.useEffect(() => {
		if (containerRef.current && sidebarTransitionComplete) {
			setContainerWidth(containerRef.current.clientWidth);
		}
	}, [sidebarTransitionComplete, screenWidth]);

	return (
		<ResponsiveChartContainer
			height={containerHeight}
			width={containerWidth}
			series={[{ data: items.map(({ value }) => (isEmpty ? 1 : value)), type: "bar" }]}
			margin={{ ...containerMargin, left: marginLeft }}
			xAxis={[{ id: "xAxis", scaleType: "band", data: items.map(({ label }) => label) }]}
			yAxis={[
				{
					id: "yAxis",
					scaleType: "linear",
					valueFormatter: isEmpty ? () => "" : currencyFormatter,
					tickNumber: 4
				}
			]}
		>
			<defs>
				{getLinearGradient(
					"highlightedGradient",
					ColorDictionary["#b9ace6"],
					ColorDictionary["#8664f9"]
				)}
				{getLinearGradient(
					"defaultGradient",
					ColorDictionary["#786aa8"],
					ColorDictionary["#342171"]
				)}
				{getLinearGradient(
					"greyGradient",
					theme.palette.grey[300],
					theme.palette.grey[500]
				)}
			</defs>
			<BarPlot
				slots={{
					bar: isEmpty ? BarChartEmptyBarItem : BarChartBarItem
				}}
			/>
			<ChartsAxis
				bottomAxis={{
					axisId: "xAxis",
					disableTicks: true,
					disableLine: true,
					slots: {
						axisTickLabel: ({ children, ...rest }) => (
							<text
								{...rest}
								{...tickLabelStyles}
								opacity={0.65}
								fill={theme.palette.primary.main}
								transform="translate(-12 15) rotate(-45)"
							>
								{children}
							</text>
						)
					}
				}}
				leftAxis={{
					axisId: "yAxis",
					disableLine: true,
					slots: {
						axisTickLabel: ({ children }) => (
							<text
								x={-6}
								y={2}
								{...tickLabelStyles}
								fill={ColorDictionary["#6B7280"]}
								textAnchor="end"
							>
								{children === "0₺" ? "" : children}
							</text>
						),
						axisTick: () => (
							<line
								x1={0}
								x2={containerWidth}
								stroke={theme.palette.grey[300]}
								strokeWidth={2}
							/>
						)
					}
				}}
			/>
			{items.map((_, index) => (
				<use
					key={index}
					xlinkHref={`#bar-slot-#${index}`}
					onMouseOver={event => mouseOverEvent(event, index)}
					onMouseOut={mouseOutEvent}
				/>
			))}
			<BarChartPopper anchorEl={tooltipAnchor.current} />
		</ResponsiveChartContainer>
	);
};

const getLinearGradient = (id: string, color1: string, color2: string) => (
	<linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
		<stop offset="0%" style={{ stopColor: color1, stopOpacity: 1 }} />
		<stop offset="100%" style={{ stopColor: color2, stopOpacity: 1 }} />
	</linearGradient>
);
