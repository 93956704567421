import { Dialog, Stack } from "@mui/material";
import { LocalizedGenericBody, LocalizedButton } from "../../elements";
import { ClearButton } from "../buttons";
import { CheckReportDialogContent } from "../contents";
import { CheckReportDialogTemplateProps } from "../types";
import { CheckReportEmailBar } from "../bars";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { BasicSpinner } from "../spinners";

export const CheckReportDialogTemplate: React.FC<CheckReportDialogTemplateProps> = ({
	sendMail,
	closeDialog,
	buttonLocalizationKey,
	contentProps,
	dialogOpen,
	emailBarProps,
	isLoading
}) => {
	const { isMobile } = useResponsive();
	const styles = isMobile
		? {
				".MuiDialog-container": { alignItems: "flex-end", padding: "0" },
				".MuiPaper-root": { margin: "0", width: "100%", maxWidth: "100%" }
		  }
		: { ".MuiDialog-container": {}, ".MuiPaper-root": {} };
	return (
		<Dialog
			fullWidth={true}
			maxWidth="sm"
			open={dialogOpen}
			onClose={closeDialog}
			sx={styles}
		>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				p="0.75rem 1rem"
				sx={{ borderBottom: "1px solid", borderBottomColor: "grey.300" }}
			>
				<LocalizedGenericBody
					localizationKey="select_dialog_send_check_heading"
					variant="body3-primary-medium"
				/>
				<ClearButton handleClear={closeDialog} />
			</Stack>
			<Stack padding="1rem" spacing="1rem">
				<CheckReportEmailBar {...emailBarProps} />
				<CheckReportDialogContent {...contentProps} />
				{isLoading ? (
					<BasicSpinner size={28} />
				) : (
					<LocalizedButton
						onClick={sendMail}
						disabled={!emailBarProps.validEmails.length}
						variant="newPrimary"
						localizationKey={buttonLocalizationKey}
						sx={{ width: "100%" }}
					/>
				)}
			</Stack>
		</Dialog>
	);
};
