import React from "react";
import { useTableContext } from "../../../util/contexts/table.context";
import { Stack } from "@mui/material";
import { MobileTableItem } from "../items";
import { LoadMoreButton } from "../buttons";
import { EmptyTableViewContent } from "../contents";

interface Props {
	loadMoreDisabled?: boolean;
}

export const MobileTable: React.FC<Props> = ({ loadMoreDisabled = false }) => {
	const { handleLoadMore, shouldLoadMore, tableElements } = useTableContext();

	const elements = tableElements.map((element, index) => (
		<MobileTableItem key={index} element={element} />
	));

	const content = elements.length > 0 ? elements : <EmptyTableViewContent />;

	return (
		<Stack spacing="1rem">
			{content}
			{!loadMoreDisabled && shouldLoadMore ? (
				<LoadMoreButton
					handleNextClick={handleLoadMore}
					styles={{ button: { alignSelf: "center" } }}
				/>
			) : null}
		</Stack>
	);
};
