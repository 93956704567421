import React from "react";
import { SubmissionStatus, SubmissionStatusEnum } from "../../views/containers/types";

export const useAuthFormStatus = () => {
	const [submissionStatus, setSubmissionStatus] = React.useState<SubmissionStatus>(null);

	const handleSubmissionError = (error: any) => {
		if (!error.response) throw error;
		setSubmissionStatus(SubmissionStatusEnum.error);
	};

	const setSubmissionInProgress = () =>
		setSubmissionStatus(SubmissionStatusEnum.inProgress);
	const setSubmissionSuccess = () => setSubmissionStatus(SubmissionStatusEnum.success);
	const setSubmissionError = () => setSubmissionStatus(SubmissionStatusEnum.error);
	const resetSubmissionStatus = () => setSubmissionStatus(null);

	return {
		submissionStatus,
		handleSubmissionError,
		resetSubmissionStatus,
		setSubmissionSuccess,
		setSubmissionError,
		setSubmissionInProgress,
		setSubmissionStatus
	};
};
