import React from "react";
import { FilterableColumn } from "../../util/configs/dashboard-table/filterable-column";
import { FilterContainer } from "./filter.container";
import { DashboardTableConfig } from "../../util/configs/dashboard-table";
import { useTableContext } from "../../util/contexts/table.context";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";

export const DashboardFiltersContainer: React.FC = () => {
	const { filteredColumns, table, setFilteredColumns, setFilterQueryString } =
		useTableContext();

	const [clearEvent, setClearEvent] = React.useState<React.MouseEvent | null>(null);

	const filterableColumns = DashboardTableConfig[table].filterable;

	React.useEffect(() => {
		clearFilteredColumns();
	}, [table]);

	useUpdateEffect(() => {
		updateFilterQueryString();
	}, [filteredColumns]);

	const updateFilterQueryString = () => {
		const filterQueryString = createFilterQueryString(filteredColumns);
		setFilterQueryString(filterQueryString);
	};

	const clearFilteredColumns = (event?: any) => {
		if (event) setClearEvent(event);
		clearSelectedFilterValues();
		setFilteredColumns([]);
	};

	const clearSelectedFilterValues = () =>
		filterableColumns.forEach(column => (column.selectedFilterValues = []));

	return (
		<FilterContainer
			clearFilteredColumns={clearFilteredColumns}
			clearEvent={clearEvent}
			filteredColumns={filteredColumns}
			setFilteredColumns={setFilteredColumns}
		/>
	);
};

const createFilterQueryString = (filteredColumns: FilterableColumn[]) =>
	filteredColumns.map(column => column.createQuery()).join("&");
