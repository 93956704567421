import { Box, IconButton } from "@mui/material";
import React from "react";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import { NavLink } from "react-router-dom";

export const HeaderLogo: React.FC = () => {
	const { imageAlt, imageSrc } = ImageDictionary.Header.logo;
	const { prefix } = useLocalizedNavigate();

	return (
		<IconButton
			disableRipple
			sx={{
				borderRadius: 0,
				padding: 0,
				width: { xs: "10.5rem", md: "15.0rem", xl: "19.1rem" }
			}}
		>
			<NavLink to={`${prefix()}/`}>
				<Box
					height={{ xs: "5.00rem", md: "6.25rem" }}
					width={{ xs: "10.5rem", md: "15.0rem", xl: "19.1rem" }}
				>
					<img style={{ height: "100%", width: "auto" }} src={imageSrc} alt={imageAlt} />
				</Box>
			</NavLink>
		</IconButton>
	);
};

export const HeaderLogoNegative: React.FC = () => {
	const { imageAlt, imageSrc } = ImageDictionary.Header.logoNegative;
	const { prefix } = useLocalizedNavigate();

	return (
		<IconButton
			disableRipple
			sx={{
				borderRadius: 0,
				px: 0,
				paddingTop: "0.375rem",
				width: "100%"
			}}
		>
			<NavLink to={`${prefix()}/`}>
				<Box
					height={{ xs: "5.00rem", xl: "6.25rem" }}
					width={{ xs: "10.5rem", xl: "19.1rem" }}
				>
					<img style={{ height: "100%", width: "auto" }} src={imageSrc} alt={imageAlt} />
				</Box>
			</NavLink>
		</IconButton>
	);
};
