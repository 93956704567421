import { DashboardSidebarLogoutButtonStack } from "../../../util/theme/styled-components";
import { LocalizedGenericBody } from "../../elements";
import { DashboardSidebarLogoutButtonImage } from "../images";

interface Props {
	handleClick: () => void;
}

export const DashboardSidebarLogoutButton: React.FC<Props> = ({ handleClick }) => (
	<DashboardSidebarLogoutButtonStack
		onClick={handleClick}
		alignItems="center"
		spacing="0.125rem"
	>
		<DashboardSidebarLogoutButtonImage />
		<LocalizedGenericBody
			localizationKey="dashboard_user_logout"
			variant="body4-primary-semi-bold"
			sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
		/>
	</DashboardSidebarLogoutButtonStack>
);
