import React from "react";
import { getMuiBreakpoints } from "./breakpoints.theme";
import { getMuiComponentStyles } from "./components.theme";
import { getMuiPalette } from "./palette.theme";
import { getMuiTypography } from "./typography.theme";
import { trTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material";

let theme = createTheme(
	{
		breakpoints: getMuiBreakpoints(),
		typography: {
			fontFamily: "Raleway"
		}
	},
	trTR
);

theme = createTheme(
	theme,
	{
		palette: getMuiPalette(),
		typography: getMuiTypography()
	},
	trTR
);

theme = createTheme(
	theme,
	{
		components: getMuiComponentStyles(theme)
	},
	trTR
);

export const LiderThemeProvider: React.FC = ({ children }) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
