import { flags } from "../../../assets/images/localizations/flags-dictionary";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import {
	DesktopFlag,
	DesktopFlagAndLabel,
	DocumentSelectStack,
	LocalizationButtonBox
} from "../../../util/theme/styled-components";
import { DocumentSelectChevron, LanguageSelectChevron } from "../images";
import { GenericBody, GenericImageContainer, LocalizedGenericBody } from "../../elements";
import { Stack, Typography } from "@mui/material";
import CalendarIcon from "../../../assets/images/document-select-icon.svg";
import { allYearsOption } from "../../containers";

interface Props {
	anchorEl: HTMLElement | null;
	handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const LocalizationSelectButton: React.FC<Props> = ({ anchorEl, handleClick }) => {
	const { isMobile } = useResponsive();
	const { language } = useLanguageContext();

	return (
		<LocalizationButtonBox
			onClick={handleClick}
			sx={{ marginLeft: isMobile ? "0.938rem" : "" }}
		>
			<DesktopFlagAndLabel>
				<DesktopFlag>{flags[language]}</DesktopFlag>
				<GenericBody
					variants={{
						desktop: "body4-primary-semi-bold",
						mobile: "body2-primary-semi-bold"
					}}
					text={language.toUpperCase()}
				/>
			</DesktopFlagAndLabel>
			<LanguageSelectChevron anchorEl={anchorEl} />
		</LocalizationButtonBox>
	);
};

interface DocumentProps {
	handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	anchorEl: HTMLElement | null;
	selectedYear: string;
}

export const DocumentYearSelectButton: React.FC<DocumentProps> = ({
	handleClick,
	anchorEl,
	selectedYear
}) => (
	<DocumentSelectStack
		onClick={handleClick}
		direction="row"
		alignItems="center"
		justifyContent="space-between"
		width={{ xs: "9.00rem", md: "7.75rem" }}
		height={{ xs: "2.25rem", md: "2.00rem" }}
	>
		<Stack direction="row" alignItems="center" spacing={1}>
			<GenericImageContainer
				imageSrc={CalendarIcon}
				imageAlt="document-select-icon"
				width="fit-content"
			/>
			{selectedYear === allYearsOption ? (
				<LocalizedGenericBody
					variant="body3-primary-medium"
					localizationKey="document_select_a_year"
				/>
			) : (
				<Typography variant="body3-primary-medium">{selectedYear}</Typography>
			)}
		</Stack>
		<DocumentSelectChevron anchorEl={anchorEl} />
	</DocumentSelectStack>
);
