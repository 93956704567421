import { FormControl, InputLabel, Select, Stack, useTheme } from "@mui/material";
import { InputFactory } from "./input-factory";
import {
	FilterArrowDown,
	UserRolesSelectMenuItem
} from "../../../util/theme/styled-components";
import { UserRoleOptions } from "../../../util/dictionaries/userRole.dictionary";
import { UserRoleEnum } from "../../../util/dictionaries/types";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import { useFormContext } from "../../../util/contexts/form.context";
import { UserRoleSelectItem } from "../items";
import { TableEnum } from "../../../util/types/dashboard-table.types";

export const LanguageSelect = InputFactory("language");

export const DocumentsExtensionSelect = InputFactory("documentExtension");
export const DocumentsPageSelect = InputFactory("documentPageName");

export const AnnouncementsTypeSelect = InputFactory("announcementType");

export const PagesSectionSelect = InputFactory("pageSection");
export const PagesTemplateSelect = InputFactory("pageTemplate");

export const UserRolesSelectInput: React.FC = () => {
	const { values, handleChange } = useFormContext();

	const theme = useTheme();

	const userRoles = values.userRoles ?? [];

	return (
		<FormControl
			sx={{
				width: "100%",
				height: "100%",
				borderRadius: "0.5rem",
				background: theme.palette.grey[300],
				".MuiOutlinedInput-notchedOutline": { border: "none" },
				".MuiSelect-outlined": {
					padding: "1.5625rem 0.75rem 0.5rem 0.75rem"
				}
			}}
		>
			<InputLabel variant="filled">
				<LocalizedGenericBody
					localizationKey="table_users_userRoles"
					variant="body3-primary-medium"
				/>
			</InputLabel>
			<Select
				value={userRoles}
				multiple={true}
				onChange={(event: any) => handleChange("userRoles", event)}
				IconComponent={FilterArrowDown}
				renderValue={(selected: any) => (
					<Stack direction="row" divider={<GenericBody text=",&nbsp;" />}>
						{selected.map((userRole: UserRoleEnum) => (
							<LocalizedGenericBody
								key={userRole}
								localizationKey={`table_${TableEnum.users}_userRoles_${userRole}`}
								variant="body1-primary"
							/>
						))}
					</Stack>
				)}
				MenuProps={{
					sx: {
						".MuiPaper-root": {
							borderRadius: "0.5rem",
							".MuiList-root": {
								padding: 0
							}
						},
						".MuiBackdrop-root": {
							backgroundColor: `${theme.palette.common.black}b3`
						}
					}
				}}
			>
				{UserRoleOptions.map((userRole, index) => {
					const isSelected = userRoles.includes(userRole);
					return (
						<UserRolesSelectMenuItem key={index} value={userRole}>
							<UserRoleSelectItem userRole={userRole} isSelected={isSelected} />
						</UserRolesSelectMenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
