import { Clear } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { FileIcon } from "../../../util/theme/styled-components";

interface Props {
	handleDelete: () => void;
	file: File;
}

export const ApplicationFileItem: React.FC<Props> = ({
	handleDelete,
	file: { name }
}) => (
	<Stack direction="row" spacing={1} sx={{ wordBreak: "break-all" }}>
		<FileIcon />
		<Typography>{name}</Typography>
		<IconButton onClick={handleDelete}>
			<Clear />
		</IconButton>
	</Stack>
);
