import { Box, Button, TextareaAutosize } from "@mui/material";
import React from "react";
import { useAppModeContext } from "../../util/contexts/app-mode.context";
import { useAuthContext } from "../../util/contexts/auth.context";
import { useLanguageContext } from "../../util/contexts/language.context";
import { PageIdProps } from "../types";
import { TextEditButton, TextEditPopover } from "../components";
import { useRefreshContext } from "../../util/contexts/refresh.context";
import { TextEditBox } from "../../util/theme/styled-components";
import { AdminPagesGenerator } from "../../util/generators/private.generator";

interface Props extends PageIdProps {
	propertyKey1: string;
	text1: string;
	propertyKey2?: string;
	text2?: string;
}

export const InputLocalizationKeyDictionary: Record<string, string> = {
	description: "description_input_placeholder",
	title_1: "title_input_placeholder",
	title_2: "title_input_placeholder",
	title_3: "title_input_placeholder",
	subtitle_1: "subtitle_input_placeholder",
	subtitle_2: "subtitle_input_placeholder",
	subtitle_3: "subtitle_input_placeholder",
	subtitle_4: "subtitle_input_placeholder",
	body_1: "body_input_placeholder",
	body_2: "body_input_placeholder",
	body_3: "body_input_placeholder",
	body_4: "body_input_placeholder",
	body_5: "body_input_placeholder",
	imageLink_1: "imageLink_input_placeholder",
	imageLink_2: "imageLink_input_placeholder",
	imageLink_3: "imageLink_input_placeholder",
	imageLink_4: "imageLink_input_placeholder",
	navigationPath_1: "navigationPath_input_placeholder",
	navigationPath_2: "navigationPath_input_placeholder",
	navigationPath_3: "navigationPath_input_placeholder",
	navigationPath_4: "navigationPath_input_placeholder"
};

export const AdminTextEditContainer: React.FC<Props> = ({
	pageId,
	propertyKey1,
	propertyKey2,
	text1,
	text2,
	children
}) => {
	const { triggerCRUDRefresh } = useRefreshContext();
	const { getLocalizedTexts } = useLanguageContext();
	const [buttonText, placeholder1, placeholder2] = getLocalizedTexts([
		"create_or_update_button_placeholder",
		InputLocalizationKeyDictionary[propertyKey1],
		InputLocalizationKeyDictionary[propertyKey2 as string]
	]);

	const { user, userHasRole } = useAuthContext();

	const { appMode } = useAppModeContext();
	const isFastEdit = appMode === "fast-edit";

	const edit = userHasRole("admin", user) && isFastEdit;

	const initialValues = propertyKey2
		? { [propertyKey1]: text1, [propertyKey2]: text2 }
		: { [propertyKey1]: text1 };

	const [values, setValues] = React.useState(initialValues);

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
		setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		infoField: string
	) => {
		const { value } = event.target;

		setValues(previous => ({ ...previous, [infoField]: value }));
	};

	const generator = new AdminPagesGenerator();

	const handleSubmit = async () => {
		await generator.createOrUpdate({
			id: pageId,
			args: values
		});

		triggerCRUDRefresh();
		handleClose();
	};

	return (
		<Box sx={{ position: "relative", width: "100%" }}>
			{children}
			{edit ? (
				<TextEditBox>
					<TextEditButton handleClick={handleClick} />
					<TextEditPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
						<TextareaAutosize
							value={values[propertyKey1]}
							onChange={event => handleChange(event, propertyKey1)}
							placeholder={placeholder1}
						/>
						{propertyKey2 ? (
							<TextareaAutosize
								value={values[propertyKey2]}
								onChange={event => handleChange(event, propertyKey2)}
								placeholder={placeholder2}
							/>
						) : null}
						<Button onClick={handleSubmit}>{buttonText}</Button>
					</TextEditPopover>
				</TextEditBox>
			) : null}
		</Box>
	);
};
