import fileUploadThumbnail from "../../../assets/images/dashboard/dashboard-file-upload-thumbnail.svg";
import { GenericImageContainer } from "../../elements";

export const DashboardFileUploadImage: React.FC = () => (
	<GenericImageContainer
		imageSrc={fileUploadThumbnail}
		imageAlt="uploaded file"
		width="2.5rem"
	/>
);
