import React from "react";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { GenericImageContainer } from "../../elements";
import { Card } from "@mui/material";

export const TitleButtonThreeSubtitleImage: React.FC = () => {
	const { imageAlt, imageSrc } = ImageDictionary.TitleButtonThreeSubtitle;

	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			width={{ xs: "19.9rem", md: "18.8rem", lg: "21.3rem", xl: "23.8rem" }}
			style={{
				background: "transparent",
				boxShadow: "none"
			}}
			component={Card}
		/>
	);
};
