import { Wrapper } from "@googlemaps/react-wrapper";
import React from "react";
import { useNavigate, Route } from "react-router-dom";
import { useLanguageContext } from "../../util/contexts/language.context";
import { turkishToEnglish } from "../../util/converters/string.converter";
import { SentryRoutes } from "../../util/dictionaries/routes.dictionary";
import { BranchDAO } from "../../util/generators/types";
import { PublicBranchesGenerator } from "../../util/generators/public.generator";
import { BasicSpinner, CityList } from "../components";
import { NotFoundPage } from "../pages";
import { BranchDetailsContainer } from "./branch-details.container";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { City, StoredBranches } from "./types";

export const BranchesContainer: React.FC = () => {
	const { language } = useLanguageContext();
	const [branches, setBranches] = React.useState<StoredBranches>({} as StoredBranches);

	const navigate = useNavigate();

	const generator = new PublicBranchesGenerator();

	useGeneratorEffect(
		{
			effect: generator.all,
			onSuccess: setBranches,
			callback: generator.abort
		},
		[]
	);

	const handleClick = (route: string) => navigate(route);

	const cities: City[] = [];
	const capitalCities: City[] = [];
	const highlightedBranches: BranchDAO[] = [];

	if (branches[language])
		Object.entries(branches[language]).forEach(entry => {
			const [city, branchesArray] = entry;
			const route = encodeURIComponent(turkishToEnglish(city).toLowerCase());
			highlightedBranches.push(...branchesArray.filter(branch => branch.highlighted));
			const { imageLink } = branchesArray[0];
			capitalCityList.includes(city)
				? capitalCities.push({ city, imageLink, route })
				: cities.push({ city, imageLink, route });
		});

	cities.sort((a, b) => a.city.localeCompare(b.city));
	capitalCities.sort((a, b) => b.city.localeCompare(a.city));

	cities.unshift(...capitalCities);

	const cityRoutes = cities.map(({ city, route }, indexOfCity) => {
		return (
			<Route
				key={city}
				path={route}
				element={
					<BranchDetailsContainer
						city={city}
						indexOfCity={indexOfCity}
						cities={cities}
						branches={branches[language]}
						handleClick={handleClick}
					/>
				}
			/>
		);
	});

	return (
		<Wrapper
			apiKey={process.env.REACT_APP_MAPS_API_KEY as string}
			render={() => <BasicSpinner size={80} />}
		>
			<SentryRoutes>
				<Route
					index
					element={
						<CityList
							handleClick={handleClick}
							cities={cities}
							highlightedBranches={highlightedBranches}
						/>
					}
				/>
				{cityRoutes}
				{cityRoutes.length > 0 ? (
					<Route key={"catch"} path="*" element={<NotFoundPage showSidebar={true} />} />
				) : null}
			</SentryRoutes>
		</Wrapper>
	);
};

const capitalCityList = ["İstanbul", "Ankara"];
