import { TableEnum } from "../../../util/types/dashboard-table.types";
import { AdminDocumentsCmsContainer } from "../admin-documents-cms.container";
import { AdminTableContainer } from "../admin-table.container";

export const DocumentsContainer: React.FC = () => (
	<AdminTableContainer
		table={TableEnum.documents}
		CmsContainer={AdminDocumentsCmsContainer}
	/>
);
