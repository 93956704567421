import { Box, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { CareerItemBar, CareerItemBox } from "../../../util/theme/styled-components";
import { ClockIcon } from "../images";
import { LocalizationContainer } from "../../containers";

interface CareerItemProps {
	selected: boolean;
	handleClick: () => void;
	item: {
		title: string;
		city: string;
		referenceId: string;
		createdAt: string;
	};
}

export const CareerItem: React.FC<CareerItemProps> = ({
	selected,
	handleClick,
	item: { title, city, referenceId, createdAt }
}) => {
	const typographyVariants: Record<string, any> = selected
		? {
				header: "h5-primary",
				subtitle1: "subtitle1-primary",
				body2: "body2-primary"
		  }
		: {
				header: "h5-white",
				subtitle1: "subtitle1-white",
				body2: "body2-white"
		  };

	const formattedDate = DateTime.fromISO(createdAt).toFormat("dd/MM/yy");

	const iconVariant = selected ? "greyClockIcon" : "whiteClockIcon";

	return (
		<CareerItemBox onClick={handleClick} $selected={selected}>
			<Grid container spacing="0.625rem">
				<Grid item>
					<CareerItemBar $selected={selected} />
				</Grid>
				<Grid item>
					<Typography variant={typographyVariants.header}>{title}</Typography>
					<Typography variant={typographyVariants.subtitle1}>#{referenceId}</Typography>
				</Grid>
			</Grid>
			<Box display="flex" alignItems="center" pt="0.625rem" pb="0.125rem">
				<ClockIcon variant={iconVariant} />
				<LocalizationContainer localizationKey="job_opening_posted_on_text">
					{({ localizedText }) => (
						<Typography variant={typographyVariants.body2} pl="0.625rem">
							{`${localizedText} ${formattedDate}`}
						</Typography>
					)}
				</LocalizationContainer>
			</Box>
			<Box display="flex" alignItems="center">
				<Box
					sx={{
						background:
							"linear-gradient(180deg, rgba(80, 205, 94, 0.4) 0%, #4D8AEB 100%, #605294 100%)",
						width: "0.875rem",
						height: "0.875rem",
						borderRadius: "50%"
					}}
				/>
				<Typography variant={typographyVariants.body2} pl="0.625rem">
					{city}
				</Typography>
			</Box>
		</CareerItemBox>
	);
};
