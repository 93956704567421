import { Stack } from "@mui/material";
import { EditableGenericBody } from "../../elements";
import { HomeTextField } from "../types";

interface Props {
	pageId: string;
	pageIndex: number;
	textFields: HomeTextField[];
}

export const HomepageSlider: React.FC<Props> = ({ pageId, pageIndex, textFields }) => (
	<Stack
		direction="row"
		width="100%"
		sx={{
			transform: `translateX(${-pageIndex * 100}%)`,
			transition: "all 1.15s"
		}}
	>
		{textFields.map((textField, index) => (
			<HomepageSlide
				key={index}
				textField={textField}
				keys={{ title: `title_${index + 1}`, body: `body_${index + 1}` }}
				pageId={pageId}
			/>
		))}
	</Stack>
);

interface SlideProps {
	textField: HomeTextField;
	keys: { title: string; body: string };
	pageId: string;
}

export const HomepageSlide: React.FC<SlideProps> = ({
	textField: { headingVariants, title, body },
	keys,
	pageId
}) => {
	return (
		<Stack spacing={{ xs: "0.5rem", md: "1rem" }} width="100%" flexShrink={0}>
			<EditableGenericBody
				variants={headingVariants}
				text={title}
				propertyKey={keys.title}
				pageId={pageId}
				component="h1"
			/>
			<EditableGenericBody
				variants={{ desktop: "body1-primary", mobile: "body3-primary" }}
				innerHTML={body}
				propertyKey={keys.body}
				pageId={pageId}
			/>
		</Stack>
	);
};
