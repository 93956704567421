import { TransparentCard } from "../../../util/theme/styled-components";
import { EditableGenericBody } from "../../elements";
import { AdminEditPageIdProps } from "../../types";

interface Props extends AdminEditPageIdProps {
	title: string;
	body: string;
	titleVariant: string;
	bodyVariant: string;
}

export const BasicTextCard: React.FC<Props> = ({
	title,
	titleProperty,
	body,
	bodyProperty,
	titleVariant,
	bodyVariant,
	pageId
}) => {
	return (
		<TransparentCard
			sx={{
				width: "30%",
				minWidth: "14.4rem",
				height: "16.9rem",
				mr: "1.25rem",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-evenly"
			}}
		>
			<EditableGenericBody
				variant={titleVariant}
				text={title}
				propertyKey={titleProperty}
				pageId={pageId}
			/>
			<EditableGenericBody
				variant={bodyVariant}
				text={body}
				propertyKey={bodyProperty}
				pageId={pageId}
			/>
		</TransparentCard>
	);
};
