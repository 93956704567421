import { PageWithSEO, TitleWithListContainer } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	title_1: string;
	body_1: string;
	body_2: string;
	subtitle_1: string;
	subtitle_2: string;
}

export const TitleWithListPage: React.FC<Props> = ({
	title_1,
	body_1,
	body_2,
	subtitle_1,
	subtitle_2,
	pageId,
	...seoProps
}) => {
	return (
		<PageWithSEO withSidebar {...seoProps}>
			<TitleWithListContainer
				{...{
					title_1,
					body_1,
					body_2,
					subtitle_1,
					subtitle_2,
					pageId
				}}
			/>
		</PageWithSEO>
	);
};
