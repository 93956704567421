import { Route } from "react-router-dom";
import { PageDAO } from "./types";
import { PageTemplateMatcher } from "../matchers/template.matcher";
import React from "react";
import { LanguageEnum } from "../dictionaries/types";
import { FileReroutePage, NotFoundPage } from "../../views";

type GeneratedObject = Record<LanguageEnum, React.ReactElement[]>;

export const RouteGenerator = async (pages: PageDAO[]) => {
	const generatedObject: GeneratedObject = pages.reduce(
		mapGeneratorProps,
		{} as GeneratedObject
	);

	const getFileRerouteRoute = (
		routeKey: "documents" | "files",
		language: LanguageEnum
	) => (
		<Route
			key={`${routeKey}-${generatedObject[language].length - 1}-${language}`}
			path={`${routeKey}/:fileName`}
			element={<FileReroutePage />}
		/>
	);

	const pushRoutesByLanguage = (language: LanguageEnum) => {
		{
			const fileRoute = getFileRerouteRoute("files", language);
			const documentRoute = getFileRerouteRoute("documents", language);
			const notFoundRoute = (
				<Route key={"catch"} path="*" element={<NotFoundPage showSidebar={false} />} />
			);

			[fileRoute, documentRoute, notFoundRoute].forEach(route =>
				generatedObject[language].push(route)
			);
		}
	};

	Object.keys(generatedObject).forEach(pushRoutesByLanguage);
	return generatedObject;
};

const mapGeneratorProps = (
	generatedObject: GeneratedObject,
	{ route, language, ...props }: PageDAO
) => {
	const TemplateContainer = PageTemplateMatcher(props);
	const path = route === "/" ? "/" : `${route}/*`;

	(generatedObject[language] = generatedObject[language] || []).push(
		<Route
			key={route ? `${route}-${language}` : generatedObject[language].length - 1}
			index={path === "/"}
			path={path}
			element={<TemplateContainer />}
		/>
	);
	return generatedObject;
};
