import {
	BranchesPage,
	CareersPage,
	ContactTeamPage,
	ContentFourSubcontentPage,
	DocumentsPage,
	ThreeTitledContentPage,
	Homepage,
	NewsListPage,
	ThreeCardsContentPage,
	TitleWithListPage,
	ListAndMedia,
	ThreePointContentWithMediaPage,
	ThreeCardsWithIconsPage,
	FaqsPage,
	TitleThreeSubtitlePage,
	TitleButtonThreeSubtitlePage,
	NotFoundPageTemplate,
	ApplicationPage,
	InfoFourPartPage
} from "../../views";

export const TemplateDictionary: Record<string, any> = {
	application: (props: any) => <ApplicationPage {...props} />,
	branches: (props: any) => <BranchesPage {...props} />,
	careers: (props: any) => <CareersPage {...props} />,
	contactTeam: (props: any) => <ContactTeamPage {...props} />,
	contentFourSubcontent: (props: any) => <ContentFourSubcontentPage {...props} />,
	infoFourPart: (props: any) => <InfoFourPartPage {...props} />,
	contentListAndMedia: (props: any) => <ListAndMedia {...props} />,
	documents: (props: any) => <DocumentsPage {...props} />,
	documentsDateless: (props: any) => (
		<DocumentsPage {...props} hasDate={false} hasInfo={true} />
	),
	faqs: (props: any) => <FaqsPage {...props} />,
	homepage: (props: any) => <Homepage {...props} />,
	newsList: (props: any) => <NewsListPage {...props} />,
	notFoundPage: (props: any) => <NotFoundPageTemplate {...props} />,
	threeCardsContent: (props: any) => <ThreeCardsContentPage {...props} />,
	threeCardsContentWithIcons: (props: any) => <ThreeCardsWithIconsPage {...props} />,
	threePointContentWithMedia: (props: any) => (
		<ThreePointContentWithMediaPage {...props} />
	),
	threeTitledContent: (props: any) => <ThreeTitledContentPage {...props} />,
	titleThreeSubtitle: (props: any) => <TitleThreeSubtitlePage {...props} />,
	titleButtonThreeSubtitle: (props: any) => <TitleButtonThreeSubtitlePage {...props} />,
	titleTwoSubtitleTwoBody: (props: any) => <TitleWithListPage {...props} />
};
