import React from "react";
import { Box, Stack } from "@mui/material";
import { LocalizedGenericBody } from "../../elements";
import { EmailChip } from "../chips";
import { CheckReportEmailInput } from "../inputs";
import { CheckReportEmailBarProps } from "../types";

export const CheckReportEmailBar: React.FC<CheckReportEmailBarProps> = ({
	handleEmailChange,
	handleEmailRemove,
	passValidationResult,
	email,
	validationResult: { isValid, errorKey },
	validEmails
}) => (
	<Stack direction="column" width="100%" alignItems="center" spacing="0.1rem">
		<Stack
			direction="row"
			p="0.5rem 0.75rem"
			spacing="0.5rem"
			width="100%"
			minHeight="3rem"
			height="100%"
			alignItems="center"
			sx={{
				backgroundColor: "grey.300",
				borderRadius: "0.5rem"
			}}
		>
			<Stack direction="column" height="100%" alignSelf="flex-start">
				<LocalizedGenericBody
					localizationKey="select_dialog_send_mail_to"
					variant="body2-primary-medium"
					boxStyles={{
						height: "2rem",
						display: "flex",
						flexDirection: "row",
						alignItems: "center"
					}}
				/>
			</Stack>
			<Stack
				direction="row"
				width="100%"
				height="100%"
				alignItems="center"
				flexWrap="wrap"
				rowGap="0.25rem"
			>
				{validEmails.map(email => (
					<Box paddingRight="0.5rem" key={email}>
						<EmailChip key={email} email={email} handleEmailRemove={handleEmailRemove} />
					</Box>
				))}
				<CheckReportEmailInput
					handleEmailChange={handleEmailChange}
					handleEmailRemove={handleEmailRemove}
					email={email}
					validEmails={validEmails}
					isValid={isValid}
					passValidationResult={passValidationResult}
				/>
			</Stack>
		</Stack>
		{isValid === false ? (
			<LocalizedGenericBody localizationKey={errorKey} variant="body4-error" />
		) : null}
	</Stack>
);
