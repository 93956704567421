import { Theme } from "@mui/material";

import {
	IColorType,
	IStylingType,
	ITypographyExtension,
	CombinedVariantName,
	ExtendedTypographyVariant
} from "./types.theme";

export const getMuiTypographyStyles = (theme: Theme) => {
	const textTypes: ExtendedTypographyVariant[] = [
		"body1",
		"body2",
		"body3",
		"body4",
		"subtitle1",
		"subtitle2",
		"hxl",
		"h1",
		"h2",
		"h3",
		"h4",
		"h5",
		"h6",
		"h7"
	];

	const colorTypes: IColorType[] = [
		{ key: "primary", color: theme.palette.primary.main },
		{ key: "secondary", color: theme.palette.secondary.main },
		{ key: "tertiary", color: theme.palette.tertiary.main },
		{ key: "white", color: theme.palette.common.white },
		{ key: "error", color: theme.palette.error.main },
		{ key: "dark-grey", color: theme.palette.grey[900] },
		{ key: "grey", color: theme.palette.grey[700] },
		{ key: "light-grey", color: theme.palette.grey[500] },
		{ key: "success", color: theme.palette.success.main }
	];

	const additionalStyling: IStylingType[] = [
		{ key: undefined },
		{ key: "center", display: "block", textAlign: "center" },
		{ key: "medium", fontWeight: "500" },
		{ key: "semi-bold", fontWeight: "600" },
		{ key: "bold", fontWeight: "700" },
		{
			key: "h1-like",
			fontSize: "4.00rem",
			[theme.breakpoints.down("md")]: {
				fontSize: "2.38rem"
			}
		}
	];

	const oneOffVariants = [
		{
			props: { variant: "white-animated" },
			style: {
				color: theme.palette.common.white,
				paddingLeft: "100%",
				whiteSpace: "nowrap",
				"@keyframes flowing": {
					"0%": {
						transform: "translate(0, 0)"
					},
					"100%": {
						transform: "translate(-100%, 0)"
					}
				}
			}
		}
	];

	const variants = generateVariants(theme, textTypes, colorTypes, additionalStyling);
	return { variants: [...variants, ...oneOffVariants] };
};

const generateVariants = (
	theme: Theme,
	textTypes: ExtendedTypographyVariant[],
	colorTypes: IColorType[],
	additionalStyling: IStylingType[]
): ITypographyExtension[] => {
	const variants: ITypographyExtension[] = [];
	textTypes.forEach(textType =>
		colorTypes.forEach(({ key: colorKey, color }) =>
			additionalStyling.forEach(({ key: styleKey, ...styles }) => {
				const variantName: keyof CombinedVariantName = styleKey
					? `${textType}-${colorKey}-${styleKey}`
					: `${textType}-${colorKey}`;

				variants.push({
					props: { variant: variantName },
					style: {
						...theme.typography[textType],
						color,
						...styles
					}
				});
			})
		)
	);
	return variants;
};
