import { useLocalizedNavigate } from "./useLocalizedNavigate";

export const useNavigateToElement = () => {
	const { navigate } = useLocalizedNavigate();

	const waitForElement = (selector: string) =>
		new Promise(resolve => {
			const selectElement = () => document.querySelector(selector);

			const element = selectElement();
			if (element) return resolve(element);

			const observer = new MutationObserver(() => {
				const element = selectElement();
				if (!element) return resolve(element);
				setTimeout(() => {
					resolve(element);
					observer.disconnect();
				}, 100);
			});

			observer.observe(document.body, {
				childList: true,
				subtree: true
			});
		});

	const navigateToElement = (path: string, elementId: string) => {
		navigate(path);

		waitForElement(`#${elementId}`).then((element: any) => {
			if (element) element.scrollIntoView({ behavior: "smooth", block: "center" });
		});
	};

	return navigateToElement;
};
