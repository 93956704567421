import { List } from "@mui/material";
import React from "react";
import { HeaderSection } from "../sections";
import { usePagesContext } from "../../../util/contexts/pages.context";
import { SideMenuCollapsibleItem, SideMenuSectionItem } from "../items";

interface HeaderSection {
	name: string;
	route: string;
	index: number;
}

interface HeaderSections {
	[key: string]: HeaderSection;
}

interface SideMenuCollapsibleListProps {
	sections: HeaderSections;
	handleDrawerToggle: () => void;
}

export const SideMenuCollapsibleList: React.FC<SideMenuCollapsibleListProps> = ({
	sections,
	handleDrawerToggle
}) => {
	const { pages: contextPages } = usePagesContext();

	const sortedSections = Object.entries(sections);
	sortedSections.sort((a, b) => a[1].index - b[1].index);

	return (
		<List sx={{ paddingTop: "1.88rem" }}>
			{sortedSections.map(([id, { name, route }]) => {
				const pages = contextPages.filter(({ sectionId }) => sectionId === id);

				return (
					<React.Fragment key={id}>
						{pages.length > 1 ? (
							<SideMenuCollapsibleItem
								id={id}
								name={name}
								pages={pages}
								handleDrawerToggle={handleDrawerToggle}
							/>
						) : (
							<SideMenuSectionItem
								name={name}
								route={route}
								closeDrawer={handleDrawerToggle}
							/>
						)}
					</React.Fragment>
				);
			})}
		</List>
	);
};
