import { Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { TitleThreeSubtitleDot } from "../../../util/theme/styled-components";
import { EditableGenericBody, EditableGenericButton } from "../../elements";
import { AdminEditPageIdProps, PageIdProps } from "../../types";

interface ContentProps extends PageIdProps {
	body: string;
	buttonText: string;
	navigationPath: string;
	title: string;
}

export const TitleButtonThreeSubtitleContent: React.FC<ContentProps> = ({
	body,
	buttonText,
	navigationPath,
	title,
	pageId
}) => {
	const navigate = useNavigate();

	return (
		<Stack spacing="1.25rem" alignItems={{ xs: "center", md: "flex-start" }}>
			<EditableGenericBody
				variant="h3-primary"
				text={title}
				propertyKey="title_1"
				pageId={pageId}
			/>
			<EditableGenericBody
				variant="subtitle1-primary"
				text={body}
				propertyKey="body_1"
				pageId={pageId}
			/>
			<EditableGenericButton
				text={buttonText}
				handleClick={() => navigate(`/${navigationPath}`)}
				text1={buttonText}
				propertyKey1="body_2"
				text2={navigationPath}
				propertyKey2="navigationPath_1"
				pageId={pageId}
			/>
		</Stack>
	);
};

interface SubcontentProps extends AdminEditPageIdProps {
	body: string;
	title: string;
}

export const TitleButtonThreeSubtitleSubcontent: React.FC<SubcontentProps> = ({
	body,
	bodyProperty,
	title,
	titleProperty,
	pageId
}) => (
	<Stack spacing="0.625rem" alignItems={{ xs: "center", md: "flex-start" }}>
		<TitleThreeSubtitleDot />
		<EditableGenericBody
			variant="subtitle2-primary"
			text={title}
			propertyKey={bodyProperty}
			pageId={pageId}
		/>
		<EditableGenericBody
			variant="subtitle1-primary"
			text={body}
			propertyKey={titleProperty}
			pageId={pageId}
		/>
	</Stack>
);
