import { Box, Stack, useTheme } from "@mui/material";
import { ChartItem } from "../../containers/types";
import React from "react";
import { GenericBody, LocalizedTypographyWithVariableSubject } from "../../elements";

interface Props {
	chartItem: ChartItem;
	index: number;
	highlighted: boolean;
}

export const PieChartLegendItem: React.FC<Props> = ({
	chartItem: { label, count },
	index,
	highlighted
}) => {
	const theme = useTheme();
	const fillColor = Object.values(theme.palette.chip)[index];

	const [border, background] = highlighted
		? [`0.0625rem solid ${fillColor}`, fillColor + "1a"]
		: ["unset", "unset"];

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			borderRadius="1.25rem"
			border={border}
			sx={{ background, transform: { md: "translateX(0.625rem)" } }}
			alignItems="center"
			padding="0.25rem 0.62rem"
		>
			<Stack direction="row" spacing="0.3125rem" alignItems="center">
				<Box
					width="0.625rem"
					height="0.625rem"
					borderRadius="50%"
					sx={{ background: !!count ? fillColor : theme.palette.grey[300] }}
				/>
				<GenericBody text={label} variant="body4-primary" sx={{ lineHeight: "100%" }} />
			</Stack>
			<LocalizedTypographyWithVariableSubject
				localizationKey="pie_chart_legend_count_text"
				variable={count.toString()}
				variant="body4-primary"
				sx={{ fontWeight: "600", lineHeight: "100%" }}
			/>
		</Stack>
	);
};
