import React from "react";

interface IScreenContext {
	screenWidth: number;
}

export const ScreeenContext = React.createContext<IScreenContext>({
	screenWidth: window.innerWidth
});

export const useScreenContext = () => React.useContext(ScreeenContext);

export const ScreenProvider: React.FC = ({ children }) => {
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

	window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

	const contextValue = { screenWidth };

	return (
		<ScreeenContext.Provider value={contextValue}>{children}</ScreeenContext.Provider>
	);
};
