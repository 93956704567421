import React from "react";
import { useFormContext } from "../../util/contexts/form.context";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";
import {
	AdminSectionsByLanguageIdGenerator,
	AdminTemplatesByIdGenerator
} from "../../util/generators/private.generator";
import { AdminCmsContainer } from "./admin-cms.container";
import { CmsContainerProps } from "./types";

export const AdminPagesCmsContainer: React.FC<CmsContainerProps> = props => {
	const { selectedTemplate, values, setTemplateList, setSectionList, setValues } =
		useFormContext();

	const templateGenerator = new AdminTemplatesByIdGenerator();
	const sectionGenerator = new AdminSectionsByLanguageIdGenerator();

	useGeneratorEffect(
		{
			effect: templateGenerator.all,
			onSuccess: setTemplateList,
			callback: templateGenerator.abort
		},
		[]
	);

	useGeneratorEffect(
		{
			effect: () => sectionGenerator.many("sort=index"),
			onSuccess: setSectionList,
			callback: sectionGenerator.abort
		},
		[]
	);

	useUpdateEffect(() => {
		setValues({ ...values });
	}, [selectedTemplate]);

	return <AdminCmsContainer {...props} />;
};
