import React from "react";
import { useParams } from "react-router-dom";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { FullScreenSpinner } from "../components";
import { NotFoundPage } from "./not-found.page";
import { PdfViewer } from "../components/PdfViewer.component";
import { LiderPublicAssetsCommunicator } from "../../communicators/lider.http.communicator";

export const FileReroutePage: React.FC = () => {
	const { fileName } = useParams();

	const [fileURL, setFileURL] = React.useState<string>("");
	const [fileNotFound, setFileNotFound] = React.useState<boolean>(false);

	const communicator = new LiderPublicAssetsCommunicator();

	const cleanup = () => {
		URL.revokeObjectURL(fileURL);
		communicator.abort();
	};

	const createFileAndSetURL = (response: Blob) => {
		const file = new Blob([response], { type: "application/pdf" });
		setFileURL(URL.createObjectURL(file));
	};

	useGeneratorEffect(
		{
			effect: () =>
				communicator.getDocument(fileName as string).catch(() => setFileNotFound(true)),
			onSuccess: createFileAndSetURL,
			callback: cleanup
		},
		[]
	);

	return fileNotFound ? (
		<NotFoundPage showSidebar={false} />
	) : fileURL ? (
		<PdfViewer fileURL={fileURL} />
	) : (
		<FullScreenSpinner />
	);
};
