import { GenericImageContainer } from "../../elements";
import dashboardClose from "../../../assets/images/dashboard/dashboard-dialog-close-icon.svg";

export const DashboardDialogClose: React.FC = () => (
	<GenericImageContainer
		imageSrc={dashboardClose}
		imageAlt="close dialog"
		width="0.625rem"
	/>
);
