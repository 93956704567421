import React from "react";
import { Box, Grid } from "@mui/material";
import { AssetDAO } from "../../../util/generators/types";
import { AssetItem } from "../items";

interface Props {
	assets: AssetDAO[];
	onDelete: (asset: AssetDAO) => void;
	onDownload: (asset: AssetDAO) => void;
}

export const AssetsList: React.FC<Props> = ({ assets, onDelete, onDownload }) => (
	<Box sx={{ pt: "0.25rem" }}>
		<Grid container spacing={3}>
			{assets.map((asset, index) => (
				<Grid item xs={6} sm={4} md={3} lg={2.4} xl={2} key={index}>
					<AssetItem asset={asset} onDelete={onDelete} onDownload={onDownload} />
				</Grid>
			))}
		</Grid>
	</Box>
);
