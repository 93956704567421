import { Grid } from "@mui/material";
import React from "react";
import { EditableGenericBody } from "../../elements";
import { AdminEditPageIdProps } from "../../types";

interface Props extends AdminEditPageIdProps {
	title: string;
	body: string;
	headingVariant?: string;
	headingVariants?: { desktop: string; mobile: string };
}

export const ThreeTitledContentPageContent: React.FC<Props> = ({
	body,
	bodyProperty,
	title,
	titleProperty,
	headingVariant,
	headingVariants,
	pageId
}) => (
	<Grid item xs={12}>
		<EditableGenericBody
			variant={headingVariant}
			variants={headingVariants}
			text={title}
			propertyKey={titleProperty}
			pageId={pageId}
			boxStyles={{ marginBottom: { xs: "0.5rem", md: "1.5rem" } }}
			component="h2"
		/>
		<EditableGenericBody
			variant="body2-primary"
			innerHTML={body}
			propertyKey={bodyProperty}
			pageId={pageId}
		/>
	</Grid>
);
