import { GenericImageContainer } from "../../elements";
import publishSuccess from "../../../assets/images/publish-success.svg";
import publishError from "../../../assets/images/publish-error.svg";
import publishSuccessAlert from "../../../assets/images/publish-success-alert.svg";
import publishErrorAlert from "../../../assets/images/publish-error-alert.svg";
import React from "react";

export const PublishSuccessIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={publishSuccess}
		imageAlt="publish success"
		width="1.25rem"
	/>
);

export const PublishErrorIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={publishError}
		imageAlt="publish error"
		width="1.25rem"
	/>
);

export const PublishSuccessAlert: React.FC = () => (
	<GenericImageContainer
		imageSrc={publishSuccessAlert}
		imageAlt="publish success close"
		width="1.92rem"
	/>
);

export const PublishErrorAlert: React.FC = () => (
	<GenericImageContainer
		imageSrc={publishErrorAlert}
		imageAlt="publish error close"
		width="1.92rem"
	/>
);
