import { IconButton } from "@mui/material";
import { TableSelectSnackbarClearIcon } from "../images";

interface Props {
	handleClear: () => void;
}

export const ClearButton: React.FC<Props> = ({ handleClear, ...props }) => (
	<IconButton {...props} onClick={handleClear} sx={{ p: "0" }}>
		<TableSelectSnackbarClearIcon />
	</IconButton>
);
