import { Select } from "@mui/material";
import { FilterOption } from "../../../types";
import { DashboardSelectEndAdornment } from "./dashboard-select-end-adornment";
import { FilterMenuItem } from "../../../../util/theme/styled-components";
import { GenericBody } from "../../../elements";
import { Column } from "../../../../util/configs/dashboard-table";
import { DateRangeFilterContainer } from "../../../containers";
import { FilterInputStyles } from "../../types";

interface Props {
	isOpen: boolean;
	styles: FilterInputStyles;
	labelId: string;
	defaultOptionText: string;
	allowMultipleSelection: boolean;
	selectedFilterValues: string[];
	localizedText: string;
	columnType: Column["type"];
	clearEvent: React.MouseEvent | null;
	filterOptionsForColumn: FilterOption[];
	handleChange: (value: string[]) => void;
	handleOpen: () => void;
	handleClose: () => void;
	handleArrowClick: () => void;
}

export const DashboardFilterInput: React.FC<Props> = ({
	styles,
	isOpen,
	labelId,
	defaultOptionText,
	allowMultipleSelection,
	selectedFilterValues,
	localizedText,
	columnType,
	clearEvent,
	filterOptionsForColumn,
	handleChange,
	handleOpen,
	handleClose,
	handleArrowClick
}) => {
	switch (columnType) {
		case "dateRange":
		case "dateRangeWithTime":
			return (
				<DateRangeFilterContainer
					clearEvent={clearEvent}
					handleChange={handleChange}
					filterOptionsForColumn={filterOptionsForColumn}
					localizedText={localizedText}
					selectedFilterValues={selectedFilterValues}
					styles={styles}
					handleSelectOpen={handleOpen}
					handleSelectClose={handleClose}
				/>
			);
		default:
			return (
				<Select
					data-cy={`filter-input-${labelId}`}
					open={isOpen}
					multiple={allowMultipleSelection}
					value={selectedFilterValues}
					labelId={labelId}
					onChange={event => handleChange([event.target.value].flat())}
					onOpen={handleOpen}
					onClose={handleClose}
					sx={styles.inputField}
					IconComponent={(props: any) => (
						<DashboardSelectEndAdornment
							styles={styles.endImage}
							handleClick={handleArrowClick}
							{...props}
						/>
					)}
					MenuProps={{ sx: styles.menu }}
					renderValue={() => {
						const selectedFilterOptions = filterOptionsForColumn.filter(
							({ requestValues }) =>
								selectedFilterValues.includes(requestValues.join(","))
						);
						return (
							<GenericBody
								variant="body3-primary-medium"
								text={selectedFilterOptions
									.map(({ displayValue }) => displayValue)
									.join(", ")}
								sx={{ maxWidth: "7.5rem", overflow: "hidden", textOverflow: "ellipsis" }}
							/>
						);
					}}
				>
					<FilterMenuItem value="" onClick={handleClose}>
						<GenericBody variant="body3-primary-medium" text={defaultOptionText} />
					</FilterMenuItem>
					{filterOptionsForColumn.map(({ displayValue, requestValues }, index) => (
						<FilterMenuItem key={index} value={requestValues.join(",")}>
							<GenericBody text={displayValue} variant="body3-primary-medium" />
						</FilterMenuItem>
					))}
				</Select>
			);
	}
};
