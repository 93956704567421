import React from "react";
import { useTableContext } from "../../util/contexts/table.context";
import { DashboardTableConfig } from "../../util/configs/dashboard-table";
import { AdminDialog, ConfirmationDialog } from "../components";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";
import { useFormContext } from "../../util/contexts/form.context";
import { CmsContainerProps, SubmissionStatusEnum } from "./types";
import { useRefreshContext } from "../../util/contexts/refresh.context";

export const AdminCmsContainer: React.FC<CmsContainerProps> = ({
	closeDialog,
	openDialog,
	dialogOpen,
	table
}) => {
	const { triggerCRUDRefresh, crudRefreshTrigger } = useRefreshContext();
	const { resetStates, setValues, formSubmissionStatus } = useFormContext();
	const {
		clearSelectedElements,
		handleDelete,
		refreshTable,
		selectedElements,
		tableAction
	} = useTableContext();
	const { dialog } = DashboardTableConfig[table];

	const formSubmissionSuccess = formSubmissionStatus === SubmissionStatusEnum.success;

	const selectedElement = selectedElements[0];
	const elementSelected = !!selectedElement;
	const elementSelectedToDelete = elementSelected && tableAction === "delete";

	const handleDialogClose = () => {
		closeDialog();
		clearSelectedElements();
		resetStates();
	};

	const handleFormSubmissionSuccess = () => {
		handleDialogClose();
		triggerCRUDRefresh();
	};

	const handleSelectElement = () => {
		setValues(selectedElement);
		openDialog();
	};

	useUpdateEffect(() => {
		if (elementSelected && !elementSelectedToDelete) return handleSelectElement();
	}, [selectedElement]);

	useUpdateEffect(() => {
		if (formSubmissionSuccess) handleFormSubmissionSuccess();
	}, [formSubmissionStatus]);

	useUpdateEffect(() => {
		refreshTable();
	}, [crudRefreshTrigger]);

	return dialog ? (
		<>
			<AdminDialog
				handleDialogClose={handleDialogClose}
				dialog={dialog}
				dialogOpen={dialogOpen}
			/>
			<ConfirmationDialog
				variant="deleteEntity"
				dialogOpen={elementSelectedToDelete}
				handleClose={clearSelectedElements}
				handleConfirmation={() => handleDelete(triggerCRUDRefresh)}
			/>
		</>
	) : null;
};
