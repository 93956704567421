import React from "react";
import {
	ActivateUserWhiteIcon,
	DeactivateUserWhiteIcon,
	LocalizedGenericButton,
	PublishButtonImage
} from "../../views";
import { ConfirmationVariant } from "../../views/components/types";

const primaryFullWidthCommonStyles = {
	variant: "primary",
	disableEdit: true,
	sx: { width: "100%" }
} as const;

export const ConfirmationButtonDictionary: Record<
	ConfirmationVariant,
	React.FC<{ handleClick: () => void }>
> = {
	activateUser: props =>
		LocalizedGenericButton({
			localizationKey: "select_bar_activateUser_button",
			startIcon: <ActivateUserWhiteIcon />,
			...primaryFullWidthCommonStyles,
			...props
		}),
	activateUser_plural: props =>
		LocalizedGenericButton({
			localizationKey: "select_bar_activateUser_button_plural",
			startIcon: <ActivateUserWhiteIcon />,
			...primaryFullWidthCommonStyles,
			...props
		}),
	deactivateUser: props =>
		LocalizedGenericButton({
			localizationKey: "select_bar_deactivateUser_button",
			startIcon: <DeactivateUserWhiteIcon />,
			...primaryFullWidthCommonStyles,
			...props
		}),
	deactivateUser_plural: props =>
		LocalizedGenericButton({
			localizationKey: "select_bar_deactivateUser_button_plural",
			startIcon: <DeactivateUserWhiteIcon />,
			...primaryFullWidthCommonStyles,
			...props
		}),
	deleteEntity: props =>
		LocalizedGenericButton({
			localizationKey: "delete_entity_button",
			...primaryFullWidthCommonStyles,
			...props
		}),
	publish: props =>
		LocalizedGenericButton({
			localizationKey: "publish_button_placeholder",
			variant: "publish",
			textVariant: "body2-white-medium",
			...props,
			disableEdit: true,
			startIcon: <PublishButtonImage />,
			sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }
		}),
	resetPassword: props =>
		LocalizedGenericButton({
			localizationKey: "reset_password_button_placeholder",
			textVariant: "body2-white-medium",
			...primaryFullWidthCommonStyles,
			...props
		}),
	syncClients: props =>
		LocalizedGenericButton({
			localizationKey: "dashboard_sidebar_syncClients_text",
			...primaryFullWidthCommonStyles,
			...props
		}),
	logout: props =>
		LocalizedGenericButton({
			localizationKey: "dashboard_user_logout",
			...primaryFullWidthCommonStyles,
			...props
		})
};
