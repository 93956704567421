import { MenuItem } from "@mui/material";
import { LanguageOptions } from "../../../util/dictionaries/localization.dictionary";

interface InputOptions {
	infoField: string;
	multiline?: boolean;
	rows?: number;
	localizationKey: string;
	select?: boolean;
	children?: JSX.Element[];
	type?: React.InputHTMLAttributes<unknown>["type"];
}

const createMenuItems = (items: string[] | typeof LanguageOptions) =>
	items.map(name => (
		<MenuItem key={name} value={name}>
			{name}
		</MenuItem>
	));

export const inputs: Record<string, InputOptions> = {
	category: {
		infoField: "category",
		localizationKey: "category_input_placeholder"
	},
	city: {
		infoField: "city",
		localizationKey: "city_input_placeholder"
	},
	date: {
		infoField: "date",
		type: "date",
		localizationKey: "date_input_placeholder"
	},
	description: {
		infoField: "description",
		localizationKey: "description_input_placeholder"
	},
	language: {
		infoField: "language",
		localizationKey: "language_select_button_placeholder",
		children: createMenuItems(LanguageOptions),
		select: true
	},
	key: { infoField: "key", localizationKey: "key_input_placeholder" },
	link: {
		infoField: "link",
		localizationKey: "link_input_placeholder"
	},
	navigationPath: {
		infoField: "navigationPath",
		localizationKey: "navigationPath_input_placeholder"
	},
	imageLink: {
		infoField: "imageLink",
		localizationKey: "imageLink_input_placeholder"
	},
	name: {
		infoField: "name",
		localizationKey: "name_input_placeholder"
	},
	title: {
		infoField: "title",
		localizationKey: "title_input_placeholder"
	},
	announcementContent: {
		infoField: "content",
		localizationKey: "content_input_placeholder"
	},
	branchAddress: {
		infoField: "address",
		multiline: true,
		rows: 3,
		localizationKey: "address_input_placeholder"
	},
	branchLatitude: {
		infoField: "latitude",
		localizationKey: "latitude_input_placeholder",
		type: "number"
	},
	branchLongitude: {
		infoField: "longitude",
		localizationKey: "longitude_input_placeholder",
		type: "number"
	},
	branchFax: {
		infoField: "fax",
		localizationKey: "fax_input_placeholder"
	},
	branchHours: {
		infoField: "hoursOfOperation",
		localizationKey: "hours_of_operation_input_placeholder"
	},
	phoneNumber: {
		infoField: "phoneNumber",
		localizationKey: "phone_number_input_placeholder"
	},
	branchWhatsapp: {
		infoField: "whatsapp",
		localizationKey: "whatsapp_input_placeholder"
	},
	documentLink: {
		infoField: "link",
		localizationKey: "link_input_placeholder"
	},
	faqQuestion: {
		infoField: "question",
		localizationKey: "question_input_placeholder"
	},
	faqAnswer: {
		infoField: "answer",
		localizationKey: "answer_input_placeholder"
	},
	openingReference: {
		infoField: "referenceId",
		localizationKey: "reference_id_input_placeholder"
	},
	openingRequirements: {
		infoField: "requirements",
		localizationKey: "requirements_input_placeholder"
	},
	documentPageName: {
		infoField: "pageId",
		localizationKey: "page_name_input_placeholder",
		select: true
	},
	pageBody: {
		infoField: "body",
		localizationKey: "body_input_placeholder"
	},
	pageRoute: {
		infoField: "route",
		localizationKey: "route_input_placeholder"
	},
	pageSection: {
		infoField: "sectionId",
		localizationKey: "section_id_input_placeholder",
		select: true
	},
	pageSubtitle: {
		infoField: "subtitle",
		localizationKey: "subtitle_input_placeholder"
	},
	pageTemplate: {
		infoField: "templateId",
		localizationKey: "template_id_input_placeholder",
		select: true
	},
	announcementType: {
		infoField: "type",
		localizationKey: "type_input_placeholder",
		children: createMenuItems(["banner", "popup", "sticky"]),
		select: true
	},
	documentExtension: {
		infoField: "fileExtension",
		localizationKey: "file_extension_input_placeholder",
		children: createMenuItems(["doc", "pdf", "csv", "xls", "ppt", "zip", "txt"]),
		select: true
	},
	index: {
		infoField: "index",
		localizationKey: "index_input_placeholder",
		type: "number"
	},
	seoTitle: {
		infoField: "seoTitle",
		localizationKey: "seo_title_input_placeholder"
	},
	seoMetaName: {
		infoField: "seoMetaName",
		localizationKey: "seo_meta_name_input_placeholder"
	},
	seoMetaContent: {
		infoField: "seoMetaContent",
		localizationKey: "seo_meta_content_input_placeholder",
		multiline: true,
		rows: 2
	},
	text: {
		infoField: "text",
		localizationKey: "text_input_placeholder"
	},
	adminName: {
		infoField: "name",
		localizationKey: "admin_name_input_placeholder"
	},
	email: {
		infoField: "email",
		localizationKey: "email_input_placeholder"
	},
	firstName: {
		infoField: "firstName",
		localizationKey: "firstName_input_placeholder"
	},
	lastName: {
		infoField: "lastName",
		localizationKey: "lastName_input_placeholder"
	},
	username: {
		infoField: "username",
		localizationKey: "username_input_placeholder"
	},
	message: {
		infoField: "message",
		localizationKey: "create_user_message_input_placeholder"
	}
};
