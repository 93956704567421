import { MetaWithTitle } from "../../components";
import { SeoProps } from "../types";

export const SEOWrapper: React.FC<SeoProps> = ({
	seoTitle,
	seoMetaName_1,
	seoMetaContent_1,
	seoMetaName_2,
	seoMetaContent_2,
	seoMetaName_3,
	seoMetaContent_3,
	seoMetaName_4,
	seoMetaContent_4,
	children
}) => {
	const seo = {
		title: seoTitle,
		meta: [
			{ name: seoMetaName_1, content: seoMetaContent_1 },

			...[
				{ name: seoMetaName_2, content: seoMetaContent_2 },
				{ name: seoMetaName_3, content: seoMetaContent_3 },
				{ name: seoMetaName_4, content: seoMetaContent_4 }
			].filter(
				(item): item is { name: string; content: string } =>
					item.name !== undefined && item.content !== undefined
			)
		]
	};

	return (
		<>
			<MetaWithTitle {...seo} />
			{children}
		</>
	);
};
