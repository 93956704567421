import { Typography } from "@mui/material";
import React from "react";
import { LanguageCheckmark } from "../../../assets/images/localizations/language-checkmark";
import { languageDetails } from "../../../assets/images/localizations/language-details";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { LanguageEnum } from "../../../util/dictionaries/types";
import {
	FlagContainer,
	DesktopCheckbox,
	DesktopLanguageSelect,
	LanguageSelectItem
} from "../../../util/theme/styled-components";
interface Props {
	handleChange: (language: LanguageEnum) => void;
	language: LanguageEnum;
}

export const LocalizationItem: React.FC<Props> = ({ handleChange, language }) => {
	const { language: selectedLanguage } = useLanguageContext();

	return (
		<LanguageSelectItem onClick={() => handleChange(language)}>
			<DesktopLanguageSelect>
				<FlagContainer>{languageDetails[language].flag}</FlagContainer>
				<Typography variant="body3-primary-semi-bold">
					{languageDetails[language].nativeName}
				</Typography>
			</DesktopLanguageSelect>
			{language === selectedLanguage ? (
				<DesktopCheckbox>{LanguageCheckmark({ fill: "#222447" })}</DesktopCheckbox>
			) : null}
		</LanguageSelectItem>
	);
};
