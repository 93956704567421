import { DateTime } from "luxon";

export const useClientCheckQueries = () => {
	const today = new Date();
	const todayDateTime = DateTime.utc(
		today.getFullYear(),
		today.getMonth() + 1,
		today.getDate()
	).toISO();
	const todayNextYearDateTime = DateTime.utc(
		today.getFullYear() + 1,
		today.getMonth() + 1,
		today.getDate()
	).toISO();

	const barChartChecksQuery = `dateFormat=%m/%Y&${checkStatusesInCollectionQuery}&sort=checkDueDate&checkDueDate[$between]=${todayDateTime}&checkDueDate[$between]=${todayNextYearDateTime}`;
	const barChartRedirectUrl = `/checks-inquiry?sort=-checkDueDate&${barChartCheckStatusSearchParam}`;

	return { barChartChecksQuery, barChartRedirectUrl };
};

const checkStatusesInCollection = ["0", "1", "A", "B"];
const checkStatusesInCollectionQuery = checkStatusesInCollection
	.map(checkStatus => `checkStatus[$in]=${checkStatus}`)
	.join("&");
const barChartCheckStatusSearchParam = `checkStatus=${checkStatusesInCollection.join(
	","
)}`;
