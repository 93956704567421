import React from "react";
import { useAuthContext } from "./auth.context";
import { AppMode } from "./types";

interface IAppModeContext {
	appMode: AppMode;
	setAppMode: (mode: AppMode) => void;
}

export const AppModeContext = React.createContext<IAppModeContext>({
	appMode: "website",
	setAppMode: () => null
});

export const useAppModeContext = () => React.useContext(AppModeContext);

export const AppModeProvider: React.FC = ({ children }) => {
	const { user, userHasRole } = useAuthContext();

	const subdomain =
		process.env.REACT_APP_NODE_ENV === "development" ||
		process.env.REACT_APP_NODE_ENV === "e2e"
			? process.env.REACT_APP_SUBDOMAIN
			: window.location.host.split(".")[0].split("-")[0];

	const appModeTranslator = (subdomain: string | undefined) => {
		switch (subdomain) {
			case "admin":
				return subdomain;
			case "musteri":
				return "client";
			default:
				return "website";
		}
	};

	const isFastEditAllowed = subdomain === "admin" && userHasRole("admin", user);

	const wasFastEditMode =
		subdomain === "admin" && localStorage.getItem("isFastEditMode") === "true";

	const [appMode, setAppMode] = React.useState<AppMode>(
		wasFastEditMode ? "fast-edit" : appModeTranslator(subdomain)
	);

	const appModeSetter = (mode: AppMode) => {
		if (mode !== "fast-edit" || isFastEditAllowed) return setAppMode(mode);
		return setAppMode("website");
	};

	const context = {
		appMode,
		setAppMode: appModeSetter
	};
	return <AppModeContext.Provider value={context}>{children}</AppModeContext.Provider>;
};
