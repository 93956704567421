import branchDetailsDots from "../../assets/images/branch-details-dots.svg";

import inquiryImage from "../../assets/images/contact/contact-image.svg";
import inquiryEmailIcon from "../../assets/images/contact/email.svg";
import inquiryFacebookIcon from "../../assets/images/contact/facebook.svg";
import inquiryLinkedInIcon from "../../assets/images/contact/linkedin.svg";
import inquiryPhoneIcon from "../../assets/images/contact/phone.svg";
import inquiryTwitterIcon from "../../assets/images/contact/twitter.svg";
import contactTeamImage from "../../assets/images/contact/contact-team-image.svg";

import contentFourSubcontentLeft from "../../assets/images/content-fourSubcontent/left.svg";
import contentFourSubcontentRight from "../../assets/images/content-fourSubcontent/right.svg";

import docIcon from "../../assets/images/extensions/doc.svg";
import pdfIcon from "../../assets/images/extensions/pdf.svg";
import csvIcon from "../../assets/images/extensions/csv.svg";
import xlsIcon from "../../assets/images/extensions/xls.svg";
import pptIcon from "../../assets/images/extensions/ppt.svg";
import zipIcon from "../../assets/images/extensions/zip.svg";
import txtIcon from "../../assets/images/extensions/txt.svg";

import homepageImage from "../../assets/images/homepage-image.svg";
import homepageImageMobile from "../../assets/images/homepage-image.svg";
import liderLogo from "../../assets/images/header-logo.svg";
import liderLogoNegative from "../../assets/images/header-logo-negative.svg";

import threeCardIcon1 from "../../assets/images/pages/three-cards/card-icon-1.svg";
import threeCardIcon2 from "../../assets/images/pages/three-cards/card-icon-2.svg";
import threeCardIcon3 from "../../assets/images/pages/three-cards/card-icon-3.svg";

import bddkLogo from "../../assets/images/institutions/BDDK_logo.jpg";
import kapLogo from "../../assets/images/institutions/KAP-logo.png";
import borsaIstanbulLogo from "../../assets/images/institutions/Borsa_İstanbul_logo.png";
import fciLogo from "../../assets/images/institutions/fci-logo.png";
import finkurbirLogo from "../../assets/images/institutions/finkurbir_logo.svg";
import kkbLogo from "../../assets/images/institutions/KKB-logo.png";
import masakLogo from "../../assets/images/institutions/Mali_Suclari_Arastirma_Kurulu_Logosu.png";

import facebookLogoLight from "../../assets/images/social-media/facebook-logo-light.svg";
import twitterLogoLight from "../../assets/images/social-media/twitter-logo-light.svg";
import linkedInLogoLight from "../../assets/images/social-media/linkedIn-logo-light.svg";

import facebookLogoDark from "../../assets/images/social-media/facebook-logo-dark.svg";
import twitterLogoDark from "../../assets/images/social-media/twitter-logo-dark.svg";
import linkedInLogoDark from "../../assets/images/social-media/linkedIn-logo-dark.svg";

import whiteClockIcon from "../../assets/images/career/white-clock-icon.svg";
import greyClockIcon from "../../assets/images/career/grey-clock-icon.svg";

import titleThreeSubtitle from "../../assets/images/title-three-subtitle-image.svg";

import titleButtonThreeSubtitle from "../../assets/images/title-button-three-subtitle.svg";

const BranchDetails = {
	dots: { imageAlt: "branch-details-dots", imageSrc: branchDetailsDots }
};

const Career = {
	whiteClockIcon: { imageAlt: "white-clock-icon", imageSrc: whiteClockIcon },
	greyClockIcon: { imageAlt: "grey-clock-icon", imageSrc: greyClockIcon }
};

const Institution = {
	bddk: {
		imageAlt: "bddk-logo",
		imageSrc: bddkLogo,
		path: "https://www.bddk.org.tr"
	},
	kap: {
		imageAlt: "kap logo",
		imageSrc: kapLogo,
		path: "https://www.kap.org.tr/"
	},
	finkurbir: {
		imageAlt: "finkurbir-logo",
		imageSrc: finkurbirLogo,
		path: "https://www.fkb.org.tr/"
	},
	borsaIstanbul: {
		imageAlt: "borsaIstanbul-logo",
		imageSrc: borsaIstanbulLogo,
		path: "https://www.borsaistanbul.com/tr/"
	},
	kkb: {
		imageAlt: "kkb-logo",
		imageSrc: kkbLogo,
		path: "https://www.kkb.com.tr/"
	},
	masak: {
		imageAlt: "masak-logo",
		imageSrc: masakLogo,
		path: "http://www.masak.gov.tr/"
	},
	fci: {
		imageAlt: "fci-logo",
		imageSrc: fciLogo,
		path: "https://fci.nl/en/home/factoring"
	}
};

const Contact = {
	contactImage: { imageAlt: "contact-us-image", imageSrc: inquiryImage },
	email: {
		imageAlt: "contact-us-email",
		imageSrc: inquiryEmailIcon,
		path: `mailto:${process.env.REACT_APP_LIDER_CONTACT_EMAIL}`
	},
	facebook: {
		imageAlt: "contact-us-facebook",
		imageSrc: inquiryFacebookIcon,
		path: process.env.REACT_APP_LIDER_CONTACT_FACEBOOK
	},
	linkedIn: {
		imageAlt: "contact-us-linkedIn",
		imageSrc: inquiryLinkedInIcon,
		path: process.env.REACT_APP_LIDER_CONTACT_LINKEDIN
	},
	phone: {
		imageAlt: "contact-us-phone",
		imageSrc: inquiryPhoneIcon,
		path: `tel:${process.env.REACT_APP_LIDER_CONTACT_PHONE}`
	},
	twitter: {
		imageAlt: "contact-us-twitter",
		imageSrc: inquiryTwitterIcon,
		path: process.env.REACT_APP_LIDER_CONTACT_TWITTER
	},
	team: { imageAlt: "contact-team", imageSrc: contactTeamImage }
};

const ContentFourSubcontent = {
	left: { imageAlt: "left", imageSrc: contentFourSubcontentLeft },
	right: { imageAlt: "right", imageSrc: contentFourSubcontentRight }
};

const Extension = {
	doc: { imageAlt: "doc-icon", imageSrc: docIcon },
	pdf: { imageAlt: "pdf-icon", imageSrc: pdfIcon },
	csv: { imageAlt: "csv-icon", imageSrc: csvIcon },
	xls: { imageAlt: "xls-icon", imageSrc: xlsIcon },
	ppt: { imageAlt: "ppt-icon", imageSrc: pptIcon },
	zip: { imageAlt: "zip-icon", imageSrc: zipIcon },
	txt: { imageAlt: "txt-icon", imageSrc: txtIcon }
};

const Header = {
	logo: { imageAlt: "header logo", imageSrc: liderLogo },
	logoNegative: { imageAlt: "header logo", imageSrc: liderLogoNegative }
};

const Footer = {
	logo: { imageAlt: "footer logo", imageSrc: liderLogo },
	logoNegative: { imageAlt: "footer logo", imageSrc: liderLogoNegative }
};

const Home = {
	logo: { imageAlt: "Lider Faktoring", imageSrc: homepageImage },
	logoMobile: { imageAlt: "Lider Faktoring", imageSrc: homepageImageMobile }
};

const ThreeCardIcons = {
	threeCardIcon1,
	threeCardIcon2,
	threeCardIcon3
};

const SocialMedia = {
	dark: {
		facebook: {
			imageAlt: "facebook-logo",
			imageSrc: facebookLogoDark,
			path: "https://www.facebook.com/LiderFaktoring"
		},
		twitter: {
			imageAlt: "twitter-logo",
			imageSrc: twitterLogoDark,
			path: "https://www.twitter.com/liderfaktoring1"
		},
		linkedIn: {
			imageAlt: "linkedIn-logo",
			imageSrc: linkedInLogoDark,
			path: "https://www.linkedin.com/company/lider-faktoring/"
		}
	},
	light: {
		facebook: {
			imageAlt: "facebook-logo",
			imageSrc: facebookLogoLight,
			path: "https://www.facebook.com/LiderFaktoring"
		},
		twitter: {
			imageAlt: "twitter-logo",
			imageSrc: twitterLogoLight,
			path: "https://www.twitter.com/liderfaktoring1"
		},
		linkedIn: {
			imageAlt: "linkedIn-logo",
			imageSrc: linkedInLogoLight,
			path: "https://www.linkedin.com/company/lider-faktoring/"
		}
	}
};

const TitleThreeSubtitle = {
	imageAlt: "title-three-subtitle",
	imageSrc: titleThreeSubtitle
};

const TitleButtonThreeSubtitle = {
	imageAlt: "title-button-three-subtitle",
	imageSrc: titleButtonThreeSubtitle
};

export const ImageDictionary = {
	Career,
	BranchDetails,
	ThreeCardIcons,
	Contact,
	ContentFourSubcontent,
	Extension,
	Header,
	Footer,
	Home,
	Institution,
	SocialMedia,
	TitleThreeSubtitle,
	TitleButtonThreeSubtitle
};
