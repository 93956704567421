import { useSearchParams } from "react-router-dom";
import { DashboardTableConfig, FilterableColumn } from "../configs/dashboard-table";
import { useTableContext } from "../contexts/table.context";
import React from "react";
import { useUpdateEffect } from "./useUpdateEffect.hook";
import { SortOrder } from "../contexts/types";
import { TableEnum } from "../types/dashboard-table.types";

export const useTableQueryParams = () => {
	const {
		filteredColumns,
		filterQueryString,
		sortOrder,
		sortedField,
		sortQueryString,
		table,
		setFilteredColumns,
		setSortedField,
		setSortOrder
	} = useTableContext();

	const [searchParams, setSearchParams] = useSearchParams();

	React.useEffect(() => {
		setSortOptionsFromSearchParams();
		setFilteredColumnsFromSearchParams();
	}, [table]);

	useUpdateEffect(() => {
		updateSearchParams();
	}, [filterQueryString, sortQueryString]);

	const setSortOptionsFromSearchParams = () => {
		const sortSearchParam = searchParams.get("sort");
		if (sortSearchParam) {
			const sortOptions = getSortOptions(sortSearchParam, table);
			if (!sortOptions) return;
			setSortOrder(sortOptions.sortOrder);
			setSortedField(sortOptions.sortedField);
		}
	};

	const setFilteredColumnsFromSearchParams = () => {
		const filteredColumns = getFilteredColumnsFromSearchParams(searchParams, table);
		setFilteredColumns(filteredColumns);
	};

	const updateSearchParams = () => {
		const filteredValueDictionary = getFilteredValues(filteredColumns);
		setSearchParams({ sort: `${sortOrder}${sortedField}`, ...filteredValueDictionary });
	};
};

const getFilteredValues = (filteredColumns: FilterableColumn[]) =>
	filteredColumns.reduce(
		(filteredValueDictionary, { requestBy, selectedFilterValues }) => {
			filteredValueDictionary[requestBy] = selectedFilterValues;
			return filteredValueDictionary;
		},
		{} as Record<string, (string | string[])[]>
	);

const getSortOptions = (sortSearchParam: string, table: TableEnum) => {
	const { sortOrder, sortedField } = getSortOptionFromSearchParams(sortSearchParam);
	const sortedColumn = DashboardTableConfig[table].visible.find(
		({ requestBy }) => requestBy === sortedField
	);
	if (!sortedColumn) return;
	return { sortOrder, sortedField };
};

const getSortOptionFromSearchParams = (sortSearchParam: string) => {
	const sortOrder: SortOrder = sortSearchParam.startsWith("-") ? "-" : "";
	const sortedField = sortSearchParam.replace("-", "");
	return { sortOrder, sortedField } as const;
};

const getFilteredColumnsFromSearchParams = (
	searchParams: URLSearchParams,
	table: TableEnum
) =>
	DashboardTableConfig[table].filterable.filter(column => {
		setSelectedFilterValuesFromSearchParams(column, searchParams);
		return column.selectedFilterValues.length > 0;
	});

const setSelectedFilterValuesFromSearchParams = (
	column: FilterableColumn,
	searchParams: URLSearchParams
) => {
	const selectedFilterValues = searchParams.getAll(column.requestBy);
	column.selectedFilterValues = selectedFilterValues;
};
