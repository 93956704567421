import { AccordionDetails, AccordionSummary, Box, Stack, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import {
	FaqSearchItemAnswer,
	FaqSearchItemAccordion,
	SearchItemBox
} from "../../../util/theme/styled-components";
import { BranchDAO, DocumentDAO, FaqsDAO, NewsDAO } from "../../../util/generators/types";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { usePagesContext } from "../../../util/contexts/pages.context";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import {
	DocumentSearchButtonIcon,
	DocumentSearchIcon,
	FaqSearchIcon,
	FaqSearchArrow,
	NewsSearchIcon,
	BranchSearchIcon,
	BranchSearchPhoneIcon,
	BranchSearchFaxIcon,
	BottomNavigationChevronRight
} from "../images";
import {
	LocalizedGenericButton,
	LocalizedGenericBody,
	GenericBody
} from "../../elements";
import { NavLink } from "react-router-dom";
import { turkishToEnglish } from "../../../util/converters/string.converter";
import { ItemFilter } from "../../containers/types";
import { SearchItemContent } from "../contents/search-item.content";
import { SearchItemHeading } from "../headings";

interface DocumentProps {
	item: DocumentDAO;
	setClose: () => void;
}

export const DocumentsSearchItem: React.FC<DocumentProps> = ({
	item: { name, link, date }
}) => {
	const { isMobile } = useResponsive();
	const { language } = useLanguageContext();

	const formattedDate = DateTime.fromISO(date)
		.setLocale(language)
		.toLocaleString(DateTime.DATE_FULL);

	const fileName = link.split("/").pop();

	return isMobile ? (
		<NavLink
			to={`//${link.split("https:")[1]}`}
			target="_blank"
			style={{
				padding: "0.5rem 0.75rem",
				borderRadius: "0.25rem",
				textDecoration: "none"
			}}
		>
			<SearchItemContent text={name} Icon={DocumentSearchIcon} date={formattedDate} />
		</NavLink>
	) : (
		<SearchItemBox
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center"
			}}
		>
			<SearchItemContent text={name} Icon={DocumentSearchIcon} date={formattedDate} />
			<LocalizedGenericButton
				variant="secondary"
				mini
				href={`/files/${fileName}`}
				target="_blank"
				startIcon={<DocumentSearchButtonIcon />}
				textVariant="body4-primary-medium"
				localizationKey="document_search_button_text"
				sx={{
					minHeight: "unset",
					height: "1.75rem"
				}}
			/>
		</SearchItemBox>
	);
};

interface FaqProps {
	item: FaqsDAO;
	setClose: () => void;
}

export const FaqsSearchItem: React.FC<FaqProps> = ({ item: { question, answer } }) => {
	return (
		<FaqSearchItemAccordion disableGutters>
			<AccordionSummary
				expandIcon={<FaqSearchArrow />}
				sx={{ padding: "0.5rem 0.75rem", minHeight: "unset" }}
			>
				<Stack direction="row" alignItems="center" spacing="0.5rem" height="0.25rem">
					<FaqSearchIcon />
					<GenericBody variant="body3-primary-medium" text={question} />
				</Stack>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					padding: "0.5rem 0.75rem"
				}}
			>
				<FaqSearchItemAnswer
					variant="body3-primary"
					dangerouslySetInnerHTML={{ __html: answer }}
					sx={{ opacity: "0.7" }}
				></FaqSearchItemAnswer>
			</AccordionDetails>
		</FaqSearchItemAccordion>
	);
};

interface NewsProps {
	item: NewsDAO;
	setClose: () => void;
}

export const NewsSearchItem: React.FC<NewsProps> = ({
	item: { date, description, title },
	setClose
}) => {
	const { pages } = usePagesContext();
	const { navigate } = useLocalizedNavigate();
	const { language } = useLanguageContext();

	const formattedDate = DateTime.fromISO(date)
		.setLocale(language)
		.toLocaleString(DateTime.DATE_FULL);

	const parentRoute = pages.find(page => page.templateName === "newsList")?.route;
	const navigationPath = `${parentRoute}/${date}/${title}`;

	return (
		<SearchItemBox
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				gap: "1rem"
			}}
		>
			<SearchItemContent text={title} Icon={NewsSearchIcon} date={formattedDate} />
			<Box
				sx={{
					display: "-webkit-box",
					WebkitLineClamp: "2",
					WebkitBoxOrient: "vertical",
					overflow: "hidden",
					textOverflow: "ellipsis"
				}}
			>
				<GenericBody
					variant="body3-primary"
					sx={{ opacity: "0.7", pl: "1.5rem" }}
					text={description}
				/>
			</Box>
			<LocalizedGenericButton
				variant="secondary"
				mini
				endIcon={<BottomNavigationChevronRight />}
				textVariant="body4-primary-medium"
				localizationKey="read_more_button_placeholder"
				sx={{
					minHeight: "unset",
					height: "1.75rem",
					pl: "1rem",
					border: "none",
					background: "transparent",
					textTransform: "none",
					"&:hover": {
						backgroundColor: "transparent"
					}
				}}
				handleClick={() => {
					navigate(navigationPath);
					setClose();
				}}
			/>
		</SearchItemBox>
	);
};

interface BranchProps {
	item: BranchDAO;
	setClose: () => void;
}

export const BranchSearchItem: React.FC<BranchProps> = ({
	item: { name, city, address, phoneNumber, fax },
	setClose
}) => {
	const { pages } = usePagesContext();
	const { navigate } = useLocalizedNavigate();
	const { isMobile } = useResponsive();

	const parentRoute = pages.find(page => page.templateName === "branches")?.route;
	const itemRoute = encodeURIComponent(turkishToEnglish(city).toLowerCase());
	const navigationPath = `${parentRoute}/${itemRoute}`;

	return (
		<SearchItemBox
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				gap: "0.5rem"
			}}
			onClick={() => {
				if (!isMobile) return;
				navigate(navigationPath);
				setClose();
			}}
		>
			<Stack direction="row" justifyContent="space-between" width="100%">
				<SearchItemHeading text={`${city}, ${name}`} Icon={BranchSearchIcon} />
				{isMobile ? null : (
					<LocalizedGenericButton
						variant="secondary"
						mini
						startIcon={<DocumentSearchButtonIcon />}
						textVariant="body4-primary-medium"
						localizationKey="branch_search_button_text"
						sx={{
							minHeight: "unset",
							height: "1.75rem"
						}}
						handleClick={() => {
							navigate(navigationPath);
							setClose();
						}}
					/>
				)}
			</Stack>
			<GenericBody
				variant="body3-primary"
				sx={{ opacity: "0.7", pl: "1.5rem" }}
				text={address}
			/>
			<Stack direction="row" spacing="0.5rem" sx={{ pl: "1.5rem" }}>
				<BranchSearchPhoneIcon />
				<GenericBody variant="body3-primary" sx={{ opacity: "0.7" }} text={phoneNumber} />
			</Stack>
			{!!fax && (
				<Stack direction="row" spacing="0.5rem" sx={{ pl: "1.5rem" }}>
					<BranchSearchFaxIcon />
					<GenericBody variant="body3-primary" sx={{ opacity: "0.7" }} text={fax} />
				</Stack>
			)}
		</SearchItemBox>
	);
};

interface TabItemProps {
	handleClick: (itemFilter: ItemFilter) => void;
	IconComponent: React.FC<{ isSelected: boolean }>;
	itemFilter: ItemFilter;
	selectedFilter: ItemFilter;
}

export const SearchTabItem: React.FC<TabItemProps> = ({
	handleClick,
	IconComponent,
	itemFilter,
	selectedFilter
}) => {
	const isSelected = itemFilter === selectedFilter;
	const theme = useTheme();
	const { isMobile } = useResponsive();

	const displayText = !isMobile || isSelected;

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing="0.25rem"
			sx={{
				padding: "0 0.5rem 0.5rem 0.25rem",
				borderBottom: isSelected ? `1px solid ${theme.palette.secondary.main}` : "none",
				opacity: isSelected ? 1 : 0.4,
				cursor: "pointer"
			}}
			onClick={() => handleClick(itemFilter)}
		>
			<IconComponent isSelected={isSelected} />
			{displayText && (
				<LocalizedGenericBody
					variants={
						isSelected
							? {
									mobile: "body4-secondary-semi-bold",
									desktop: "body3-secondary-semi-bold"
							  }
							: {
									mobile: "body4-primary-semi-bold",
									desktop: "body3-primary-medium"
							  }
					}
					localizationKey={`search_results_${itemFilter}_filter`}
				/>
			)}
		</Stack>
	);
};
