import { useNavigate } from "react-router";
import { AdminLoginForm, FormSnackbar } from "../../components";
import { LoginContainerTemplate } from "./login-container.template";
import { useAuthContext } from "../../../util/contexts/auth.context";
import { LiderAdminAuthCommunicator } from "../../../communicators/lider.http.communicator";
import React from "react";
import { useAuthFormStatus } from "../../../util/hooks/useAuthFormStatus.hook";

export const AdminLoginContainer: React.FC = () => {
	const { isLoggedIn, login } = useAuthContext();

	const navigate = useNavigate();

	const communicator = new LiderAdminAuthCommunicator();

	const initialState = { email: "", password: "" };
	const [fieldValues, setFieldValues] = React.useState<{
		email: string;
		password: string;
	}>(initialState);

	const { submissionStatus, handleSubmissionError, resetSubmissionStatus } =
		useAuthFormStatus();

	React.useEffect(() => {
		if (isLoggedIn()) navigate("/");
	}, []);

	const handleChange = (infoField: string, value: string) =>
		setFieldValues(previous => ({ ...previous, [infoField]: value }));

	const handleSubmit = (event?: React.FormEvent) => {
		event?.preventDefault();
		return communicator
			.login(fieldValues)
			.then(response => {
				login(response.data.token);
			})
			.then(() => navigate("/"))
			.catch(error => {
				handleSubmissionError(error);
			})
			.finally(() => setFieldValues(initialState));
	};

	const validate = () => {
		const { email, password } = fieldValues;
		return email.length > 0 && password.length > 0;
	};

	const props = { handleChange, handleSubmit, fieldValues, validate, submissionStatus };
	return (
		<LoginContainerTemplate>
			<AdminLoginForm {...props} />
			<FormSnackbar
				autoHideDuration={5000}
				handleClose={resetSubmissionStatus}
				submissionStatus={submissionStatus}
				formVariant="login"
			/>
		</LoginContainerTemplate>
	);
};
