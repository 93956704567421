import React from "react";

interface ISidebarContext {
	isOpen: boolean;
	wasOpen: boolean;
	transitionComplete: boolean;
	setIsOpen: (isOpen: boolean) => void;
	setTransitionComplete: (transitionComplete: boolean) => void;
}

export const SidebarContext = React.createContext<ISidebarContext>({
	isOpen: false,
	transitionComplete: true,
	wasOpen: false,
	setIsOpen: () => null,
	setTransitionComplete: () => null
});

export const useSidebarContext = () => React.useContext(SidebarContext);

export const SidebarProvider: React.FC = ({ children }) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [wasOpen, setWasOpen] = React.useState<boolean>(false);
	const [transitionComplete, setTransitionComplete] = React.useState<boolean>(true);

	const setIsOpenOnCallback = React.useCallback(
		value => {
			setIsOpen(value);
		},
		[wasOpen]
	);

	const contextValue = {
		isOpen,
		transitionComplete,
		wasOpen,
		setIsOpen: (value: boolean) => {
			setWasOpen(isOpen);
			setIsOpenOnCallback(value);
		},
		setTransitionComplete
	};

	return (
		<SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>
	);
};
