import { CardMedia } from "@mui/material";
import { TransparentCard } from "../../util/theme/styled-components";

interface Props {
	handleClick?: () => void;
	imageAlt: string;
	imageSrc: string;
	width: string | Record<string, string>;
	height?: string | Record<string, string>;
	secondaryImageSrc?: string;
	secondaryImageRendered?: boolean;
	component?: any;
	rotate?: string;
	style?: Record<string, string | Record<string, string>>;
}

export const GenericImageContainer: React.FC<Props> = ({
	handleClick,
	imageAlt,
	imageSrc,
	width,
	height = width,
	secondaryImageSrc,
	secondaryImageRendered,
	component,
	rotate = "0deg",
	style,
	...props
}) => {
	const ImageContainer = component ?? TransparentCard;
	return (
		<ImageContainer
			{...props}
			onClick={handleClick}
			sx={{ transform: `rotate(${rotate})`, width, height, ...style }}
		>
			<CardMedia
				component="img"
				height="100%"
				image={secondaryImageRendered ? secondaryImageSrc : imageSrc}
				alt={imageAlt}
			/>
		</ImageContainer>
	);
};
