import { Box, Grid } from "@mui/material";
import React from "react";
import { ContactTeamContent, ContactImage } from "../components";
import { PageIdProps } from "../types";

interface Props extends PageIdProps {
	body_1: string;
	body_2: string;
	navigationPath_1: string;
	title_1: string;
}

export const ContactTeamContainer: React.FC<Props> = ({
	body_1,
	body_2,
	navigationPath_1,
	title_1,
	pageId
}) => {
	const textFields = {
		body: body_1,
		buttonText: body_2,
		navigationPath: navigationPath_1,
		title: title_1
	};

	return (
		<Grid container columnSpacing={5} minHeight="37.5rem">
			<Grid
				item
				xs={12}
				md={3}
				sx={{
					display: "flex",
					flexDirection: { md: "column" },
					justifyContent: { md: "flex-end", xs: "center" }
				}}
			>
				<Box maxWidth={"29.4rem"} minWidth={"15.6rem"}>
					<ContactImage variant="team" />
				</Box>
			</Grid>
			<Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
				<ContactTeamContent {...textFields} pageId={pageId} />
			</Grid>
		</Grid>
	);
};
