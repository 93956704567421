import { DialogEnum } from "../../../views/components/types";
import { Column } from "./column";
import { FilterableColumn } from "./filterable-column";
import { SearchableColumn } from "./searchable-column";

export class TableConfig {
	private actionTypes: string[] = ["delete", "select"];

	constructor(
		private columns: Column[],
		public dialog: DialogEnum | null = null,
		public tableInteractionDisabled = false
	) {}

	get all() {
		return this.columns;
	}

	get hidden() {
		return this.columns.filter(column => column.hidden);
	}

	get action() {
		return this.columns.filter(column => this.actionTypes.includes(column.type));
	}

	get visible() {
		return this.columns.filter(column => !column.hidden);
	}

	get data() {
		return this.columns.filter(column => !this.actionTypes.includes(column.type));
	}

	get filterable() {
		return this.columns.filter(column => column.filterable) as FilterableColumn[];
	}

	get searchable() {
		return this.columns.filter(column => column.searchable) as SearchableColumn[];
	}
}
