import { Grid } from "@mui/material";
import { EditableGenericBody } from "../../elements";
import { PageIdProps } from "../../types";
import { PageMainContentWrapper } from "./page-main-content-wrapper.container";

interface TextFieldProps {
	text?: string;
	innerHTML?: string;
	variant?: string;
	variants?: { desktop: string; mobile: string };
}
interface Props extends PageIdProps {
	headingProps: TextFieldProps;
	bodyProps: TextFieldProps;
}

export const TitleAndDescriptionWrapper: React.FC<Props> = ({
	headingProps,
	bodyProps,
	pageId,
	children
}) => (
	<PageMainContentWrapper>
		<Grid item xs={12}>
			<EditableGenericBody
				{...headingProps}
				propertyKey="title_1"
				pageId={pageId}
				component="h1"
			/>
		</Grid>
		<Grid item xs={12}>
			<EditableGenericBody {...bodyProps} propertyKey="body_1" pageId={pageId} />
		</Grid>
		{children}
	</PageMainContentWrapper>
);
