import { Card, CardHeader, styled } from "@mui/material";

export const TransparentCard = styled(Card)(() => ({
	background: "transparent",
	border: "none",
	boxShadow: "none"
}));

export const CardHeaderWhite = styled(CardHeader)(({ theme }) => ({
	color: theme.palette.common.white
}));

export const ThreeCardsPageCard = styled(TransparentCard)(({ theme }) => ({
	minHeight: "15.4rem",
	[theme.breakpoints.down("xl")]: {
		padding: "2.63rem 1.00rem 1.00rem 2.00rem"
	},
	[theme.breakpoints.down("lg")]: {
		height: "unset",
		minHeight: "unset",
		justifyContent: "flex-end",
		alignItems: "center",
		padding: "1.00rem"
	},

	padding: "4.50rem 1.00rem 1.00rem 2.00rem",

	borderRadius: "0.500rem",

	display: "flex",
	flexDirection: "column",
	alignItems: "center",

	gap: "0.250rem",

	position: "relative",
	marginBottom: "1.25rem"
}));
