import { LanguageEnum } from "../../util/dictionaries/types";
import { BranchDAO } from "../../util/generators/types";
import { TableEnum } from "../../util/types/dashboard-table.types";

export interface SeoProps {
	seoTitle: string;
	seoMetaName_1: string;
	seoMetaContent_1: string;
	seoMetaName_2?: string;
	seoMetaContent_2?: string;
	seoMetaName_3?: string;
	seoMetaContent_3?: string;
	seoMetaName_4?: string;
	seoMetaContent_4?: string;
}

export interface AdminEditProps {
	bodyProperty: string;
	titleProperty: string;
}

export type ApplicationFormInfoFields =
	| "first_name"
	| "last_name"
	| "email"
	| "phone_number"
	| "notes";

export interface ApplicationFormValues {
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	notes: string;
	invoices: File[];
	checks: File[];
}

export interface ApplicationFormTextValidations {
	first_name: null | boolean;
	last_name: null | boolean;
	email: null | boolean;
	phone_number: null | boolean;
}

export type TermsAgreementFields = "kvkk" | "agreementForm";

export type City = { city: string; imageLink: string; route: string };

export type StoredBranches = {
	[language in LanguageEnum]: {
		[city: string]: BranchDAO[];
	};
};

interface Data {
	[key: string]: string | number | Date;
}

export interface ChartItem extends Data {
	label: string;
	value: number;
	count: number;
}

export type PublishStatus = SubmissionStatus;

export type DialogKey =
	| "activities"
	| "logout"
	| "publish"
	| "resetPassword"
	| "syncClients";

export type SubmissionStatus = SubmissionStatusEnum | null;

export enum SubmissionStatusEnum {
	inProgress = "inProgress",
	success = "success",
	error = "error"
}

export type ContactFormInfoFields = "name" | "email" | "subject" | "message";

export interface ContactFormValidations {
	name: null | boolean;
	email: null | boolean;
	subject: null | boolean;
	message: null | boolean;
}

export type ItemFilter = "none" | "faq" | "news" | "document" | "branch";

export type TimeTogglePeriod = "day" | "week" | "month";

export interface IPageWithSEO extends SeoProps {
	withSidebar?: boolean;
	footerVariant?: FooterProps["variant"];
}

export interface CmsContainerProps {
	closeDialog: () => void;
	openDialog: () => void;
	dialogOpen: boolean;
	table: TableEnum;
}

export interface FooterProps {
	variant?: "dark" | "light";
}

export interface Content {
	body: string;
	title: string;
}

export interface Subcontent extends Content, AdminEditProps {}
