export class ApplicationFileValidation {
	fileCountLimit = 3;
	fileSizeLimit = 2 * 10 ** 6;
	validationWarning: string | null = null;

	constructor(public files: File[]) {}

	validate = () => this.validation();

	private validation = () => {
		this.validationWarning = null;
		if (this.fileCountExceedsLimit()) this.validationWarning = "fileCountWarning";
		if (this.fileSizeExceedsLimit()) this.validationWarning = "fileSizeWarning";
		return this;
	};

	private fileCountExceedsLimit = () => this.files.length > this.fileCountLimit;

	private fileSizeExceedsLimit = () =>
		this.files.some(({ size }) => size > this.fileSizeLimit);
}
