import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { AwardDAO } from "../../../util/generators/types";
import { AwardItemStack } from "../../../util/theme/styled-components";

interface Props {
	handleClick: (index: number) => void;
	award: AwardDAO;
	index: number;
	selectedIndex: number;
}

export const AwardItem: React.FC<Props> = ({
	handleClick,
	award: { date, name, description },
	index,
	selectedIndex
}) => {
	const selected = selectedIndex === index;

	return (
		<Box className="award-item">
			<AwardItemStack
				selected={selected}
				justifyContent="space-between"
				alignItems="flex-start"
				onClick={() => handleClick(index)}
			>
				<Typography variant={selected ? "h3-primary" : "h3-white"}>
					{new Date(date).getFullYear()}
				</Typography>
				<Stack spacing={1}>
					<Typography
						variant={selected ? "body1-primary-semi-bold" : "body1-white-semi-bold"}
					>
						{name}
					</Typography>
					{selected ? (
						<Typography variant="body3-secondary">{description}</Typography>
					) : null}
				</Stack>
			</AwardItemStack>
		</Box>
	);
};
