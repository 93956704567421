import { useTheme } from "@mui/material";
import { LocalizedGenericButton } from "../../elements";
import { BottomNavigationChevronRight } from "../images";

export const SEOOpenButton: React.FC<{ handleClick: () => void }> = ({ handleClick }) => {
	const theme = useTheme();

	return (
		<LocalizedGenericButton
			variant="primary"
			localizationKey="dashboard_seo_open_button_text"
			endIcon={<BottomNavigationChevronRight opacity="0.5" />}
			sx={{
				background: `${theme.palette.secondary.main}24`,
				width: "100%",
				maxWidth: "unset",
				".MuiBox-root": {
					width: "100%",
					display: "flex",
					justifyContent: "left"
				},
				".MuiTypography-root": {
					color: "primary.main"
				},
				":hover": {
					".MuiTypography-root": {
						color: "common.white"
					}
				}
			}}
			handleClick={handleClick}
		/>
	);
};
