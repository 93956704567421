import { Box, Typography } from "@mui/material";
import React from "react";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import { AdminTextEditContainer, LocalizationContainer } from "../containers";
import { PageIdProps } from "../types";

interface Props {
	handleClick?: () => void;
	component?: "h1" | "h2";
	text?: string;
	variant?: any;
	variants?: { mobile: any; desktop: any };
	innerHTML?: string;
	boxStyles?: any;
	sx?: any;
	lineClamp?: number;
}

export const GenericBody: React.FC<Props> = ({
	handleClick,
	component = "span" as const,
	text,
	innerHTML,
	variant,
	variants,
	boxStyles,
	sx,
	lineClamp
}) => {
	const { isMobile } = useResponsive();
	const textAlign = isMobile ? "left" : "";

	const getSelectedVariant = () => {
		if (variant) return variant;
		if (variants) return isMobile ? variants.mobile : variants.desktop;
		return "";
	};

	const selectedVariant = getSelectedVariant();

	const clampStyles = {
		display: "-webkit-box",
		WebkitLineClamp: lineClamp?.toString(),
		WebkitBoxOrient: "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis"
	};

	const textStyles = lineClamp ? { ...clampStyles, ...sx } : sx;

	return (
		<Box sx={{ textAlign, ...boxStyles }} onClick={handleClick}>
			{innerHTML ? (
				<Typography
					component={component}
					variant={selectedVariant}
					dangerouslySetInnerHTML={{ __html: innerHTML }}
					sx={textStyles}
				></Typography>
			) : (
				<Typography component={component} variant={selectedVariant} sx={textStyles}>
					{text}
				</Typography>
			)}
		</Box>
	);
};

interface LocalizedProps extends Props {
	localizationKey: string;
	disableEdit?: boolean;
}

export const LocalizedGenericBody: React.FC<LocalizedProps> = ({
	localizationKey,
	disableEdit,
	...props
}) => (
	<LocalizationContainer disableEdit={disableEdit} localizationKey={localizationKey}>
		{({ localizedText }) => <GenericBody innerHTML={localizedText} {...props} />}
	</LocalizationContainer>
);

interface VariableProps extends Omit<LocalizedProps, "localizationKey"> {
	localizationKey?: string;
	variable: string;
}

export const LocalizedTypographyWithVariableSubject: React.FC<VariableProps> = ({
	localizationKey = "variable_only_key",
	variable,
	...props
}) => (
	<LocalizationContainer localizationKey={localizationKey}>
		{({ localizedText }) => {
			localizedText = localizedText.replace("<VARIABLE>", variable);
			return <GenericBody innerHTML={localizedText} {...props} />;
		}}
	</LocalizationContainer>
);

interface AdminProps extends Props, PageIdProps {
	text?: string;
	innerHTML?: string;
	propertyKey: string;
}

export const EditableGenericBody: React.FC<AdminProps> = ({
	pageId,
	propertyKey,
	text,
	innerHTML,
	...props
}) => (
	<AdminTextEditContainer
		pageId={pageId}
		propertyKey1={propertyKey}
		text1={(innerHTML ?? text) as string}
	>
		<GenericBody text={text} innerHTML={innerHTML} {...props} />
	</AdminTextEditContainer>
);
