import { Alert, Stack } from "@mui/material";
import React from "react";
import { useFormContext } from "../../../util/contexts/form.context";
import {
	AdminPanelAnnouncementsForm,
	AdminPanelAwardsForm,
	AdminPanelDocumentsForm,
	AdminPanelFaqsForm,
	AdminPanelJobOpeningsForm,
	AdminPanelLocalizationsForm,
	AdminPanelNewsForm,
	AdminPanelSectionsForm,
	AdminPanelUsersForm
} from "../forms";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminBranchesDialog } from "./admin-branches.dialog";
import { AdminPagesDialog } from "./admin-pages.dialog";
import { AdminDialogTemplate } from "./admin-dialog.template";
import { useTableContext } from "../../../util/contexts/table.context";
import { AdminDialogProps, DialogEnum } from "../types";

type DialogFactory = (
	AdminForm: React.FC<FormProps>,
	headerLocalizationKey: string,
	cancelButtonEnabled?: boolean
) => React.FC<AdminDialogProps>;

const DialogFactory: DialogFactory =
	(AdminForm, headerLocalizationKey, cancelButtonEnabled) =>
	({ handleDialogClose, dialogOpen }) => {
		const { handleFormSubmit, formError } = useFormContext();
		const { selectedElements } = useTableContext();
		const selectedElementId = selectedElements[0]?.id;

		const handleSubmit = (_: React.MouseEvent<Element, MouseEvent>, payload?: any) =>
			handleFormSubmit(selectedElementId, payload);

		return (
			<AdminDialogTemplate
				dialogOpen={dialogOpen}
				handleClose={handleDialogClose}
				headerLocalizationKey={headerLocalizationKey}
				cancelButtonEnabled={cancelButtonEnabled}
			>
				<Stack spacing="1rem" padding="1rem">
					{formError && <Alert severity="error">{formError}</Alert>}
					<AdminForm handleSubmit={handleSubmit} />
				</Stack>
			</AdminDialogTemplate>
		);
	};

export const AdminAnnouncementsDialog = DialogFactory(
	AdminPanelAnnouncementsForm,
	"admin_panel_create_announcement_text"
);
export const AdminAwardsDialog = DialogFactory(
	AdminPanelAwardsForm,
	"admin_panel_create_award_text"
);
export const AdminDocumentsDialog = DialogFactory(
	AdminPanelDocumentsForm,
	"admin_panel_create_document_text"
);
export const AdminFaqsDialog = DialogFactory(
	AdminPanelFaqsForm,
	"admin_panel_create_faq_text"
);
export const AdminJobOpeningsDialog = DialogFactory(
	AdminPanelJobOpeningsForm,
	"admin_panel_create_job_opening_text"
);
export const AdminLocalizationsDialog = DialogFactory(
	AdminPanelLocalizationsForm,
	"admin_panel_create_localization_text"
);
export const AdminNewsDialog = DialogFactory(
	AdminPanelNewsForm,
	"admin_panel_create_news_text"
);
export const AdminSectionsDialog = DialogFactory(
	AdminPanelSectionsForm,
	"admin_panel_create_section_text"
);
export const AdminUsersDialog: React.FC<AdminDialogProps> = props => {
	const { selectedElements } = useTableContext();

	const [localizationKey, cancelButtonEnabled] = !!selectedElements.length
		? ["admin_panel_update_user_text", true]
		: ["admin_panel_create_user_text", false];

	return DialogFactory(AdminPanelUsersForm, localizationKey, cancelButtonEnabled)(props);
};

export const AdminDialogs: Record<DialogEnum, React.FC<AdminDialogProps>> = {
	AdminAnnouncementsDialog,
	AdminBranchesDialog,
	AdminAwardsDialog,
	AdminDocumentsDialog,
	AdminFaqsDialog,
	AdminJobOpeningsDialog,
	AdminLocalizationsDialog,
	AdminNewsDialog,
	AdminPagesDialog,
	AdminSectionsDialog,
	AdminUsersDialog
};

interface Props extends AdminDialogProps {
	dialog: DialogEnum;
}

export const AdminDialog: React.FC<Props> = ({ dialog, ...dialogProps }) => {
	const Dialog = AdminDialogs[dialog];

	return <Dialog {...dialogProps} />;
};
