import { Stack } from "@mui/material";
import React from "react";
import { CityItemsStack } from "../../../util/theme/styled-components";
import { LocalizedGenericBody } from "../../elements";
import { CityHeading } from "../headings";
import { CityItemChevron } from "../images";

interface Props {
	handleClick: (route: string) => void;
	city: string;
	route: string;
	imageLink: string;
}

export const CityItem: React.FC<Props> = ({ handleClick, city, route, imageLink }) => {
	const [hovered, setHovered] = React.useState(false);

	return (
		<CityItemsStack
			imagelink={imageLink}
			minWidth={{ xs: "10.2rem", md: "13.1rem" }}
			height={{ xs: "10.0rem", md: "18.8rem" }}
			onClick={() => handleClick(route)}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<CityHeading city={city} />
			{hovered ? (
				<Stack direction="row" spacing="0.750rem" alignItems="center">
					<LocalizedGenericBody
						variant="body2-white-medium"
						localizationKey="city_item_hover_text"
					/>
					<CityItemChevron />
				</Stack>
			) : null}
		</CityItemsStack>
	);
};
