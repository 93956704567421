export const API_URL_CONFIG = {
	apiUrl: "/api/v1",
	accessLevels: { public: "/public", private: "/private" },
	userRoles: { admin: "/admin", client: "/client", employee: "/employee" },
	endpoints: {
		adminActivities: "/admin-activities",
		announcements: "/announcements",
		applications: "/applications",
		assets: "/assets",
		awards: "/awards",
		branches: "/branches",
		checks: "/checks",
		clientInformation: "/client-information",
		contact: "/contact",
		documents: "/documents",
		faqs: "/faqs",
		invoices: "/invoices",
		jobOpenings: "/job_openings",
		languages: "/sections/options/language",
		localizations: "/localizations",
		login: "/auth/login",
		news: "/news",
		pages: "/pages",
		sections: "/sections",
		stockPrices: "/stock-prices",
		templates: "/templates",
		userRoleSubscriptions: "/user-role-subscriptions",
		users: "/users"
	}
};

export const API_DB_CONNECTION_NAMES = { draft: "draft" };
