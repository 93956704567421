import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import {
	BrowserRouter,
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes
} from "react-router-dom";
import "./index.css";
import "./klaro.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./util/contexts/auth.context";
import { LanguageProvider } from "./util/contexts/language.context";
import { SectionsProvider } from "./util/contexts/sections.context";
import { LiderThemeProvider } from "./util/theme/theme";
import { PagesProvider } from "./util/contexts/pages.context";
import { SidebarProvider } from "./util/contexts/sidebar.context";
import { TemplatesProvider } from "./util/contexts/templates.context";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";
import { ExtraErrorData, HttpClient } from "@sentry/integrations";
import { AppModeProvider } from "./util/contexts/app-mode.context";
import { ScreenProvider } from "./util/contexts/screen.context";
import { RefreshProvider } from "./util/contexts/refresh.context";
import "./util/extensions/string.extensions";
import { SocketProvider } from "./util/contexts/socket.context";

declare global {
	interface Window {
		dataLayer: any;
	}
}

Sentry.init({
	enabled: (process.env.NODE_ENV as string) !== "development",
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.REACT_APP_NODE_ENV,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			)
		}),
		new Sentry.BrowserProfilingIntegration(),
		new Sentry.Replay(),
		new ExtraErrorData(),
		new HttpClient()
	],
	tracesSampleRate: ["production", "staging"].includes(process.env.NODE_ENV as string)
		? 1.0
		: 0.0,
	profilesSampleRate: ["production", "staging"].includes(process.env.NODE_ENV as string)
		? 1.0
		: 0.0,
	replaysOnErrorSampleRate: ["production", "staging"].includes(
		process.env.NODE_ENV as string
	)
		? 1.0
		: 0.0,
	sendDefaultPii: true
});

const rootElement = document.getElementById("root");

const application = (
	<React.StrictMode>
		<BrowserRouter>
			<RefreshProvider>
				<LanguageProvider>
					<AuthProvider>
						<AppModeProvider>
							<SidebarProvider>
								<TemplatesProvider>
									<PagesProvider>
										<SectionsProvider>
											<LiderThemeProvider>
												<ScreenProvider>
													<SocketProvider>
														<Helmet>
															<script>
																{`
													(function (w, d, s, l, i) {
														w[l] = w[l] || [];
														w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
														var f = d.getElementsByTagName(s)[0],
														j = d.createElement(s),
														dl = l != "dataLayer" ? "&l=" + l : "";
														j.async = true;
														j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
														f.parentNode.insertBefore(j, f);
													})(window, document, "script", "dataLayer", "${process.env.REACT_APP_GTM_ID}");
												`}
															</script>
															<script
																async
																src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA4_ID}`}
															></script>
															<script>
																{`
													window.dataLayer = window.dataLayer || [];
													function gtag() {
													dataLayer.push(arguments);
													}
													gtag("js", new Date());

													gtag("config", "${process.env.REACT_APP_GA4_ID}");
												`}
															</script>
														</Helmet>

														<App />
													</SocketProvider>
												</ScreenProvider>
											</LiderThemeProvider>
										</SectionsProvider>
									</PagesProvider>
								</TemplatesProvider>
							</SidebarProvider>
						</AppModeProvider>
					</AuthProvider>
				</LanguageProvider>
			</RefreshProvider>
		</BrowserRouter>
	</React.StrictMode>
);

ReactDOM[rootElement?.hasChildNodes() ? "hydrate" : "render"](application, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
