import React from "react";
import { ContactTeamContainer, LayoutWrapper, PageWithSEO } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	body_1: string;
	body_2: string;
	navigationPath_1: string;
	title_1: string;
}

export const ContactTeamPage: React.FC<Props> = ({
	body_1,
	body_2,
	navigationPath_1,
	title_1,
	pageId,
	...seoProps
}) => (
	<PageWithSEO {...seoProps}>
		<LayoutWrapper>
			<ContactTeamContainer {...{ body_1, body_2, navigationPath_1, title_1, pageId }} />
		</LayoutWrapper>
	</PageWithSEO>
);
