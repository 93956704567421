import React from "react";
import { useLanguageContext } from "./language.context";
import { TableEnum } from "../types/dashboard-table.types";
import { PrivateEntityByPrimaryKeyGeneratorDictionary } from "../generators/private.generator";
import { SubmissionStatus, SubmissionStatusEnum } from "../../views/containers/types";

interface Template {
	id: string;
	name: string;
	titleCount: number;
	subtitleCount: number;
	bodyCount: number;
	imageCount: number;
	navigationPathCount: number;
}

export interface IFormContext {
	categoryList: string[];
	extensionList: string[];
	formError: string;
	formSubmissionStatus: SubmissionStatus;
	isLoading: boolean;
	sectionList: Record<string, any>;
	selectedTemplate: Template | null;
	templateList: Record<string, any>;
	values: Record<string, any>;
	handleChange: (
		infoField: string,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	handleFormSubmit: (elementId?: string, payload?: Record<string, any>) => Promise<void>;
	resetStates: () => void;
	setCategoryList: (categoryList: string[]) => void;
	setExtensionList: (extensionList: string[]) => void;
	setSectionList: (sectionList: Record<string, any>) => void;
	setTemplateList: (templateList: Record<string, any>) => void;
	setValues: (values: Record<string, any>) => void;
}

export const FormContext = React.createContext<IFormContext>({
	categoryList: [],
	extensionList: [],
	formError: "",
	formSubmissionStatus: null,
	isLoading: false,
	sectionList: {},
	selectedTemplate: null,
	templateList: {},
	values: {},
	handleChange: () => null,
	handleFormSubmit: () => Promise.resolve(),
	resetStates: () => null,
	setCategoryList: () => null,
	setExtensionList: () => null,
	setSectionList: () => null,
	setTemplateList: () => null,
	setValues: () => null
});

export const useFormContext = () => React.useContext(FormContext);

interface ProviderProps {
	table: TableEnum;
}

export const FormProvider: React.FC<ProviderProps> = ({ table, children }) => {
	const generator = new PrivateEntityByPrimaryKeyGeneratorDictionary[table]();

	const { language: storedLanguage } = useLanguageContext();
	const initialValues = { language: storedLanguage };

	const [categoryList, setCategoryList] = React.useState<string[]>([]);
	const [extensionList, setExtensionList] = React.useState<string[]>([]);
	const [formSubmissionStatus, setFormSubmissionStatus] =
		React.useState<SubmissionStatus>(null);
	const [formError, setFormError] = React.useState<string>("");
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [sectionList, setSectionList] = React.useState<Record<string, any>>({});
	const [templateList, setTemplateList] = React.useState<Record<string, Template | null>>(
		{}
	);
	const [values, setValues] = React.useState<Record<string, any>>(initialValues);

	const handleChange = (
		infoField: string,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { target } = event;
		let value: any;
		switch (target.type) {
			case "checkbox":
				value = (target as EventTarget & HTMLInputElement).checked;
				break;
			case "number":
				value = Number(target.value);
				break;
			default:
				value = target.value;
				break;
		}

		setValues(previousValues => ({ ...previousValues, [infoField]: value }));
	};

	const resetStates = () => {
		setValues(initialValues);
		setFormSubmissionStatus(null);
		setFormError("");
	};

	const handleFormSubmit = (
		elementId?: string,
		payload?: Record<string, any> | FormData
	) => {
		setIsLoading(true);
		setFormSubmissionStatus(null);
		const trimmedValues = trimStringValues(values);
		const submissionData = payload ?? trimmedValues;
		return generator
			.createOrUpdate({
				id: elementId,
				args: submissionData
			})
			.then(() => setFormSubmissionStatus(SubmissionStatusEnum.success))
			.catch((error: any) => {
				setFormSubmissionStatus(SubmissionStatusEnum.error);
				setFormError(error.message);
			})
			.finally(() => setIsLoading(false));
	};

	const selectedTemplate = templateList[values.templateId] || null;

	const contextValue = {
		categoryList,
		extensionList,
		formError,
		formSubmissionStatus,
		isLoading,
		sectionList: sectionList[values.language] || {},
		selectedTemplate,
		templateList,
		values,
		handleChange,
		handleFormSubmit,
		resetStates,
		setCategoryList,
		setExtensionList,
		setTemplateList,
		setSectionList,
		setValues
	};

	return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

const trimStringValues = (data: Record<string, any>) =>
	Object.entries(data).reduce(
		(accumulator, [key, value]) => ({
			...accumulator,
			[key]: typeof value === "string" ? value.trim() : value
		}),
		{}
	);
