import { Box, Divider, Stack } from "@mui/material";
import {
	PieChart,
	innerRadius,
	outerRadius,
	pieArcHighlightExpansion
} from "./pie.chart";
import { PieChartContent, RiskAnalysisContent } from "../contents";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";
import { PieChartLegendList } from "../lists";

export const ClientHomepagePieChart: React.FC = () => (
	<Stack
		width="100%"
		alignItems="center"
		spacing={{ xs: "1rem", md: "0.75rem" }}
		divider={
			<Divider
				sx={{ width: "100%", border: `1px solid ${ColorDictionary["#ececec"]}` }}
			/>
		}
	>
		<Stack
			width="100%"
			direction={{ xs: "column", md: "row" }}
			justifyContent={{ xs: "center", md: "space-between" }}
			spacing={{ xs: "0.75rem", md: "0" }}
			alignItems="center"
			px={{ md: "1.5rem" }}
		>
			<Stack position="relative" sx={{ left: { md: -1 * pieArcHighlightExpansion } }}>
				<PieChart />
				<Box
					position="absolute"
					bottom="0.5rem"
					width={2 * innerRadius}
					left={outerRadius - innerRadius + pieArcHighlightExpansion}
				>
					<PieChartContent />
				</Box>
			</Stack>
			<PieChartLegendList />
		</Stack>
		<RiskAnalysisContent />
	</Stack>
);
