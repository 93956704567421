import { useNavigate } from "react-router";
import { useClientCheckQueries } from "../../../util/hooks/useClientCheckQueries.hook";
import { Stack } from "@mui/material";
import { LocalizedButton, LocalizedGenericBody } from "../../elements";
import { ChevronRight } from "@mui/icons-material";

export const ClientHomepageTableHeading: React.FC = () => {
	const navigate = useNavigate();
	const { barChartRedirectUrl } = useClientCheckQueries();

	return (
		<Stack
			direction="row"
			spacing={{ md: "1rem" }}
			alignItems="center"
			width={{ xs: "100%", md: "unset" }}
			justifyContent="space-between"
			paddingLeft={{ xs: "0.5rem", md: "0" }}
			paddingRight={{ xs: "0.25rem", md: "0" }}
		>
			<LocalizedGenericBody
				localizationKey="client_homepage_table_heading"
				variants={{ desktop: "h4-primary", mobile: "h6-primary" }}
				sx={{ lineHeight: "100%" }}
			/>
			<LocalizedButton
				variant="newPrimary-dashboard"
				endIcon={<ChevronRight />}
				localizationKey="client_homepage_table_heading_button_text"
				onClick={() => navigate(barChartRedirectUrl)}
				sx={{ paddingRight: "0.25rem", height: "1.81rem" }}
			/>
		</Stack>
	);
};
