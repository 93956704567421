export const ColorDictionary: Record<string, string> = {
	"#b9ace6": "#b9ace6",
	"#c7c7c7": "#c7c7c7",
	"#ececec": "#ececec",
	"#D4D4D4": "#D4D4D4",
	"#e2e2e2": "#e2e2e2",
	"#e6e6e6": "#e6e6e6",
	"#60528f": "#60528f",
	"#f3f3f3": "#f3f3f3",
	"#f3f4f6": "#f3f4f6",
	"#f4f4f4": "#f4f4f4",
	"#f5f5f5": "#f5f5f5",
	"#342171": "#342171",
	"#60528f1f": "#60528f1f",
	"#60528f40": "#60528f40",
	"#6B7280": "#6B7280",
	"#786aa8": "#786aa8",
	"#818181": "#818181",
	"#838383": "#838383",
	"#8664f9": "#8664f9",
	"#0d0f2b": "#0d0f2b",
	"#cbcbcb36": "#cbcbcb36",
	"#c832111a": "#c832111a",
	"#ece8f2": "#ece8f2",
	"#a69fbe": "#a69fbe",
	green50: "#ecfdf5",
	green800: "#065F46",
	red50: "#fef2f2",
	red800: "#991b1b"
};
