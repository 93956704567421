import { Stack } from "@mui/material";
import {
	LocalizedButton,
	LocalizedGenericBody,
	LocalizedTypographyWithVariableSubject
} from "../../elements";
import { ChevronRight } from "@mui/icons-material";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import { ChartBadgeProps } from "../types";
import { useChartContext } from "../../../util/contexts/chart.context";

export const ClientChartInfoContent: React.FC<ChartBadgeProps> = ({
	Icon,
	localizationKeys,
	link
}) => {
	const { navigate } = useLocalizedNavigate();
	const { itemCount } = useChartContext();

	return (
		<Stack
			spacing={{ xs: "1.62rem", md: "1.5rem" }}
			alignItems="center"
			direction={{ xs: "column", md: "row" }}
			width="100%"
		>
			<Icon />
			<Stack
				width="100%"
				spacing="0.5rem"
				alignItems={{ xs: "center", md: "flex-start" }}
			>
				<Stack
					spacing={{ xs: "0.5rem", md: "0.38rem" }}
					alignItems={{ xs: "center", md: "flex-start" }}
				>
					<LocalizedGenericBody
						variant="h6-primary"
						localizationKey={localizationKeys.title}
					/>
					<LocalizedTypographyWithVariableSubject
						variant="body3-primary"
						sx={{ opacity: 0.65 }}
						variable={itemCount.toString()}
						localizationKey={localizationKeys.text}
					/>
				</Stack>
				<LocalizedButton
					variant="newPrimary-dashboard"
					endIcon={<ChevronRight />}
					localizationKey={localizationKeys.button}
					onClick={() => navigate(link)}
				/>
			</Stack>
		</Stack>
	);
};
