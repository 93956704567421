import React from "react";
import { Grid, Stack } from "@mui/material";
import { AdminFormCreateUpdateButton } from "../buttons";
import { useFormContext } from "../../../util/contexts/form.context";
import { usePagesContext } from "../../../util/contexts/pages.context";
import {
	DateInput,
	DescriptionInput,
	DocumentsExtensionSelect,
	LanguageSelect,
	AdminNameInput,
	DocumentsPageSelect
} from "../inputs";
import { FormProps } from "../../../util/types/admin-form.types";
import { DragAndDropInput } from "../inputs";

interface Props extends FormProps {
	handleSubmit: (event: React.MouseEvent, file: FormData) => void;
}

export const AdminPanelDocumentsForm: React.FC<Props> = ({ handleSubmit }) => {
	const { values } = useFormContext();
	const { pages } = usePagesContext();
	const handleFormSubmit = (event: React.MouseEvent) => {
		const formData = new FormData();
		Object.entries(values).forEach(([key, value]) =>
			typeof value === "string"
				? formData.set(key, value.trim())
				: formData.set(key, value)
		);
		handleSubmit(event, formData);
	};

	const pageNames = pages
		.filter(({ language }) => language === values.language)
		.reduce(
			(returnObject, { id, name }) => ({
				...returnObject,
				[id]: name
			}),
			{}
		);

	return (
		<Stack spacing="0.75rem">
			<Grid container gap="0.75rem">
				<Grid item xs={12} sm>
					<AdminNameInput data-cy="admin-name-input" />
				</Grid>
			</Grid>
			<Grid container gap="0.75rem">
				<Grid item xs={12} sm>
					<DocumentsExtensionSelect data-cy="admin-extension-input" />
				</Grid>
				<Grid item xs={12} sm>
					<LanguageSelect data-cy="admin-language-input" />
				</Grid>
			</Grid>
			<Grid container gap="0.75rem">
				<Grid item xs={12} sm>
					<DocumentsPageSelect items={pageNames} data-cy="admin-page-select-input" />
				</Grid>
				<Grid item xs={12} sm>
					<DateInput data-cy="admin-date-input" />
				</Grid>
			</Grid>
			<DescriptionInput data-cy="admin-description-input" />
			<DragAndDropInput data-cy="admin-dnd-input" />
			<AdminFormCreateUpdateButton
				data-cy="admin-documents-send-button"
				handleClick={handleFormSubmit}
			/>
		</Stack>
	);
};
