import { Helmet } from "react-helmet";

interface MetaProps {
	name: string;
	content: string;
}

interface Props {
	tagContent: MetaProps | MetaProps[];
}

export const MetaTag: React.FC<Props> = ({ tagContent, children }) => (
	<Helmet>
		{children}
		{Array.isArray(tagContent) ? (
			tagContent.map((item, index) => <meta key={index} {...item} />)
		) : (
			<meta {...tagContent} />
		)}
	</Helmet>
);
