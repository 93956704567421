import { Container, Grid, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useParams } from "react-router-dom";
import { useLanguageContext } from "../../util/contexts/language.context";
import { NewsDAO } from "../../util/generators/types";
import { PublicNewsGenerator } from "../../util/generators/public.generator";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import {
	FullScreenSpinner,
	MetaWithTitle,
	NewsInspiredPageContent,
	NewsSideList
} from "../components";
import { NewsBreadcrumbs } from "../components/breadcrumbs";
import { NotFoundPage } from "../pages";
import { usePublicGeneratorRef } from "../../util/hooks/usePublicGeneratorRef.hook";
interface Props {
	passNews: (news: NewsDAO) => void;
}
export const NewsInspiredContainer: React.FC<Props> = ({ passNews }) => {
	const { date, title } = useParams();
	const theme = useTheme();
	const { language } = useLanguageContext();
	const { isMobile } = useResponsive();

	const pageSize = isMobile ? 3 : 10;
	const { generatorRef, refreshGenerator } = usePublicGeneratorRef(PublicNewsGenerator, {
		pagination: { pageSize }
	});

	const displayedNewsGenerator = new PublicNewsGenerator();

	const [displayedNews, setDisplayedNews] = React.useState<NewsDAO>();
	const [newsNotFound, setNewsNotFound] = React.useState<boolean>(false);
	const [newsList, setNewsList] = React.useState<NewsDAO[]>([]);
	const [shouldLoadMore, setShouldLoadMore] = React.useState<boolean>(true);

	const setPaginationButtonVisibility = () =>
		setShouldLoadMore(generatorRef.current.hasMore);

	const setNewsListAndButtonVisibility = (response: NewsDAO[]) => {
		setNewsList(response || []);
		setPaginationButtonVisibility();
	};

	const fetchListNews = () =>
		generatorRef.current.manyForLanguage(language, "&sort=-date");

	const fetchNewsToDisplay = () =>
		displayedNewsGenerator
			.many(`date=${date}&title=${title}`)
			.catch(() => setNewsNotFound(true));

	const displayNews = (response: NewsDAO[]) => {
		response[0] ? setDisplayedNews(response[0]) : setNewsNotFound(true);
	};

	React.useEffect(() => {
		refreshGenerator({ pagination: { pageSize } });
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, [isMobile]);

	useGeneratorEffect(
		{
			effect: fetchListNews,
			onSuccess: setNewsListAndButtonVisibility,
			callback: generatorRef.current.abort
		},
		[isMobile]
	);

	useGeneratorEffect(
		{
			effect: fetchNewsToDisplay,
			onSuccess: displayNews,
			callback: displayedNewsGenerator.abort
		},
		[date, title]
	);

	const handleNextClick = () => {
		let isMounted = true;
		generatorRef.current.manyForLanguage(language, "&sort=-date").then(response => {
			if (isMounted) {
				setNewsList([...newsList, ...response]);
				setPaginationButtonVisibility();
			}
		});

		return () => {
			isMounted = false;
			generatorRef.current.abort();
		};
	};

	const convertDate = (date: string): string =>
		DateTime.fromISO(date).setLocale(language).toLocaleString({
			month: "long",
			day: "numeric",
			year: "numeric"
		});

	return newsNotFound ? (
		<NotFoundPage showSidebar={true} />
	) : displayedNews ? (
		<>
			<MetaWithTitle
				title={displayedNews.seoTitle}
				meta={{
					name: displayedNews.seoMetaName_1,
					content: displayedNews.seoMetaContent_1
				}}
			/>
			<Container maxWidth="xl" sx={{ padding: "0 !important", position: "relative" }}>
				<Grid
					container
					overflow={"hidden"}
					marginBottom={{ xs: "1rem", md: "1.5rem" }}
					marginTop={{ xs: "1rem", md: "2.63rem" }}
				>
					<Grid item xs={12}>
						<NewsBreadcrumbs news={displayedNews} />
					</Grid>
					<Grid
						item
						xs={12}
						md={9}
						marginBottom={{ xs: "2.56rem", md: "unset" }}
						paddingRight={{ xs: 0, md: "2.5rem" }}
					>
						<NewsInspiredPageContent convertDate={convertDate} news={displayedNews} />
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						paddingTop={{ xs: 0, md: "1.5rem" }}
						marginTop={{ xs: "1.25rem", md: 0 }}
						sx={{
							borderLeft: {
								xs: "unset",
								md: `1px solid ${theme.palette.primary.main}`
							},
							borderTop: { xs: `1px solid ${theme.palette.primary.main}`, md: "unset" }
						}}
					>
						<NewsSideList
							selectedNews={displayedNews}
							news={newsList}
							passNews={passNews}
							convertDate={convertDate}
							handleNextClick={handleNextClick}
							shouldLoadMore={shouldLoadMore}
						/>
					</Grid>
				</Grid>
			</Container>
		</>
	) : (
		<FullScreenSpinner />
	);
};
