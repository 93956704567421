import { Grid, Stack } from "@mui/material";
import React from "react";
import { EditableGenericBody } from "../../elements";
import { PageIdProps } from "../../types";
import { Subcontent } from "../../containers/types";

interface Props extends PageIdProps {
	subcontents: Subcontent[];
}

export const PlainSubcontentList: React.FC<Props> = ({ pageId, subcontents }) => {
	const subcontentArray = subcontents.map(
		({ title, titleProperty, body, bodyProperty }, index) => (
			<Grid
				item
				xs
				key={index}
				sx={{ display: "flex", justifyContent: { xs: "center", md: "left" } }}
			>
				<Stack
					spacing={1}
					maxWidth={{ xs: "100%", lg: "18.8rem" }}
					minWidth={{ xs: "18.8rem", lg: "8.13rem" }}
					marginRight={{ xs: 0, md: "1.25rem" }}
				>
					<EditableGenericBody
						text={title}
						variant="body1-tertiary-semi-bold"
						propertyKey={titleProperty}
						pageId={pageId}
					/>
					<EditableGenericBody
						text={body}
						variant="body2-primary"
						propertyKey={bodyProperty}
						pageId={pageId}
					/>
				</Stack>
			</Grid>
		)
	);

	return (
		<Grid container rowSpacing={2} justifyContent="space-between">
			{subcontentArray}
		</Grid>
	);
};
