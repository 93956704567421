import { Grid, Stack } from "@mui/material";
import React from "react";
import { JobOpeningsDAO } from "../../util/generators/types";
import { PublicJobOpeningsGenerator } from "../../util/generators/public.generator";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { useLanguageContext } from "../../util/contexts/language.context";
import { CareerDetailDesktop, CareerDetailMobile, CareerItem } from "../components";
import { EditableGenericBody } from "../elements";
import { PageIdProps } from "../types";
import { DetailItem } from "../components/types";

interface Props extends PageIdProps {
	title_1: string;
}

export const CareersContainer: React.FC<Props> = ({ title_1, pageId }) => {
	const { language } = useLanguageContext();
	const { isMobile } = useResponsive();

	const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

	const [jobOpenings, setJobOpenings] = React.useState<JobOpeningsDAO[]>([]);
	const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
	const [detail, setDetail] = React.useState<DetailItem>({
		title: "",
		city: "",
		description: "",
		requirements: ""
	});

	const generator = new PublicJobOpeningsGenerator();

	const setJobOpeningsData = (response: JobOpeningsDAO[]) => {
		setJobOpenings(response);

		if (response.length > 0) {
			const { title, city, description, requirements } = response[0];
			setDetail({ title, city, description, requirements });
		}

		setSelectedIndex(0);
	};

	useGeneratorEffect(
		{
			effect: () => generator.manyForLanguage(language),
			onSuccess: setJobOpeningsData,
			callback: generator.abort
		},
		[]
	);

	const handleClick = (index: number, detail: DetailItem) => {
		setDetail(detail);
		setDrawerOpen(true);
		setSelectedIndex(index);
	};

	const careerItems = jobOpenings.map(
		({ title, city, referenceId, createdAt, description, requirements }, index) => (
			<CareerItem
				key={index}
				selected={selectedIndex === index}
				item={{ title, city, referenceId, createdAt }}
				handleClick={() => handleClick(index, { title, city, description, requirements })}
			/>
		)
	);

	return (
		<Grid
			container
			minHeight="51.6rem"
			columnSpacing="0.625rem"
			paddingTop="1.25rem"
			marginBottom="1.88rem"
		>
			<Grid item xs={12} md={5}>
				<EditableGenericBody
					variant="h4-primary"
					text={title_1}
					propertyKey="title_1"
					pageId={pageId}
					sx={{ paddingBottom: "1.88rem" }}
				/>
				<Stack
					spacing="1.25rem"
					pr={{ sx: 0, md: "3.00rem" }}
					paddingY="1.25rem"
					alignItems="center"
					width="100%"
				>
					{careerItems}
				</Stack>
			</Grid>
			<Grid item xs>
				{isMobile ? (
					<CareerDetailMobile
						{...detail}
						handleDrawerClose={() => setDrawerOpen(false)}
						isOpen={drawerOpen}
					/>
				) : (
					<CareerDetailDesktop {...detail} />
				)}
			</Grid>
		</Grid>
	);
};
