import { Link } from "@mui/material";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import {
	FullWidthImageContainer,
	SocialMediaImageContainer
} from "../../../util/theme/styled-components";
import { GenericImageContainer } from "../../elements";
import { SocialMediaEnum } from "../types";

interface ContactProps {
	variant: "contactImage" | "team";
}

export const ContactImage: React.FC<ContactProps> = ({ variant }) => {
	const {
		[variant]: { imageAlt, imageSrc }
	} = ImageDictionary.Contact;

	const imageSizes = {
		contactImage: {
			width: { xs: "20.9rem", md: "27.6rem" },
			height: { xs: "20.9rem", md: "16.0rem" }
		},
		team: { width: "fit-content" }
	} as const;

	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			component={FullWidthImageContainer}
			{...imageSizes[variant]}
		/>
	);
};

interface InquiryProps {
	imageKey: SocialMediaEnum;
}

export const InquiryImage: React.FC<InquiryProps> = ({ imageKey }) => {
	const {
		[imageKey]: { imageAlt, imageSrc, path }
	} = ImageDictionary.Contact;

	return (
		<Link href={`${path}`} target="_blank" rel="external">
			<GenericImageContainer
				imageAlt={imageAlt}
				imageSrc={imageSrc}
				width="fit-content"
				component={SocialMediaImageContainer}
			/>
		</Link>
	);
};
