import React from "react";
import { DashboardSidebar, FullScreenSpinner } from "../components";
import { DashboardPageItem } from "../types";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { useAuthContext } from "../../util/contexts/auth.context";
import { useSidebarContext } from "../../util/contexts/sidebar.context";
import { UserRoleEnum } from "../../util/dictionaries/types";
import {
	ActivitiesAdminCommunicator,
	UsersAdminCommunicator,
	UsersPrivateCommunicator,
	ClientInformationClientCommunicator
} from "../../communicators/lider.http.communicator";
import { CommunicatorResponse } from "../../communicators/types";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";
import { DashboardContentWrapper } from "./wrappers";
import {
	ClientInformationDAO,
	ReleaseStatusDAO,
	SyncStatusDAO,
	UserDAO
} from "../../util/generators/types";
import { DialogKey } from "./types";
import { useRefreshContext } from "../../util/contexts/refresh.context";
import { DashboardSidebarDialogContainer } from "./dashboard-sidebar-dialog.container";
import { useSocketEvent } from "../../util/hooks/useSocketEvent.hook";

interface Props {
	items: DashboardPageItem[];
	userRole: UserRoleEnum;
}

export const DashboardContainer: React.FC<Props> = ({ items, children, userRole }) => {
	const navigate = useNavigate();
	const { user, userHasRole, logout, setUser } = useAuthContext();
	const { setIsOpen } = useSidebarContext();
	const { isTablet } = useResponsive();
	const { crudRefreshTrigger } = useRefreshContext();

	const [activeRoute, setActiveRoute] = React.useState(window.location.pathname);
	const [dialogOpen, setDialogOpen] = React.useState<Record<DialogKey, boolean>>({
		activities: false,
		logout: false,
		resetPassword: false,
		syncClients: false,
		publish: false
	});
	const [areClientsSynced, setAreClientsSynced] = React.useState(false);
	const [syncInProgress, setSyncInProgress] = React.useState(false);
	const [isPublished, setIsPublished] = React.useState(true);
	const [lastPublishDate, setLastPublishDate] = React.useState(new Date(0));

	const [userItemExpanded, setUserItemExpanded] = React.useState<boolean>(false);

	const [clientInformation, setClientInformation] =
		React.useState<ClientInformationDAO | null>(null);

	const activitiesCommunicator = new ActivitiesAdminCommunicator();

	const usersCommunicator = new UsersPrivateCommunicator();
	const adminCommunicator = new UsersAdminCommunicator();
	const clientInformationCommunicator = new ClientInformationClientCommunicator();

	React.useEffect(() => {
		setActiveRoute(window.location.pathname);
	}, [window.location.pathname]);

	React.useEffect(() => {
		setIsOpen(!isTablet);
	}, [isTablet]);

	const setUserAndCheckRole = ({ data }: { data: UserDAO | null }) => {
		setUser(data);
		if (!userHasRole(userRole, data)) {
			logout();
			navigate("/login");
		}
	};

	useGeneratorEffect(
		{
			effect: () => usersCommunicator.getUser(userRole),
			onSuccess: setUserAndCheckRole,
			callback: usersCommunicator.abort
		},
		[]
	);

	const checkRoleAndGetClientInformation = async () => {
		if (!user || userRole !== "client") return;
		return await clientInformationCommunicator.findOne();
	};

	const updateClientInformation = (result: { data: ClientInformationDAO }) => {
		if (!result || !result.data) return;
		setClientInformation(result.data);
	};

	useGeneratorEffect(
		{
			effect: checkRoleAndGetClientInformation,
			onSuccess: updateClientInformation,
			callback: clientInformationCommunicator.abort
		},
		[user]
	);

	useUpdateEffect(() => {
		if (!user) navigate("/login");
	}, [user]);

	const checkRoleAndSetPublishStatus = async () => {
		if (!user || userRole !== "admin") return;
		return await activitiesCommunicator.getPublishStatus();
	};

	const checkRoleAndSetSyncStatus = async () => {
		if (!user || userRole !== "admin") return;
		return await activitiesCommunicator.getSyncStatus();
	};

	const setPublishStatus = (response: CommunicatorResponse<ReleaseStatusDAO>) => {
		if (!(response && response.data)) return;
		setIsPublished(response.data.released);
		setLastPublishDate(new Date(response.data.lastReleasedAt));
	};

	const setSyncStatus = (response: CommunicatorResponse<SyncStatusDAO>) => {
		if (!(response && response.data)) return;
		updateSyncStatus(response.data);
	};

	const updateSyncStatus = ({ synced, syncStatus }: SyncStatusDAO) => {
		setAreClientsSynced(synced);
		setSyncInProgress(syncStatus === "inProgress");
	};

	useSocketEvent("clientSync", updateSyncStatus);

	useGeneratorEffect(
		{
			effect: checkRoleAndSetPublishStatus,
			onSuccess: setPublishStatus,
			callback: activitiesCommunicator.abort
		},
		[user, crudRefreshTrigger, isPublished]
	);

	useGeneratorEffect(
		{
			effect: checkRoleAndSetSyncStatus,
			onSuccess: setSyncStatus,
			callback: activitiesCommunicator.abort
		},
		[user, crudRefreshTrigger, isPublished]
	);

	const handleDialogToggle = (dialogKey: DialogKey) =>
		setDialogOpen(previous => ({ ...previous, [dialogKey]: !previous[dialogKey] }));

	const handleRouteChange = (route: string) => navigate(route);

	const activeRoutePageItem = items.find(({ route }) => route === activeRoute);

	const sidebarZIndex = 1;
	const isAdmin = userRole === "admin";

	const name = isAdmin
		? `${user?.firstName} ${user?.lastName}`
		: clientInformation?.clientName;

	return (
		<Stack direction="row" sx={{ height: "100vh", overflow: "hidden" }}>
			{user ? (
				<>
					<Box zIndex={sidebarZIndex}>
						<DashboardSidebar
							setActiveRoute={handleRouteChange}
							setExpanded={setUserItemExpanded}
							handleDialogToggle={handleDialogToggle}
							isAdmin={isAdmin}
							activeRoute={activeRoute}
							dialogOpen={dialogOpen}
							expanded={userItemExpanded}
							areClientsSynced={areClientsSynced}
							syncInProgress={syncInProgress}
							isPublished={isPublished}
							items={items}
							lastPublishDate={lastPublishDate}
							userRole={userRole}
							name={name}
						/>
					</Box>
					{activeRoutePageItem ? (
						<Stack
							p={{ xs: "1rem", lg: "6.25rem 3.25rem" }}
							alignItems="center"
							sx={{ overflow: "auto", width: "100%" }}
							zIndex={sidebarZIndex - 1}
						>
							<DashboardContentWrapper pageItem={activeRoutePageItem}>
								{children}
							</DashboardContentWrapper>
						</Stack>
					) : null}
				</>
			) : (
				<FullScreenSpinner />
			)}
			<DashboardSidebarDialogContainer
				user={user}
				isAdmin={isAdmin}
				activitiesCommunicator={activitiesCommunicator}
				adminCommunicator={adminCommunicator}
				dialogOpen={dialogOpen}
				logout={logout}
				setIsPublished={setIsPublished}
				handleDialogToggle={handleDialogToggle}
			/>
		</Stack>
	);
};
