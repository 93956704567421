import { Menu, styled } from "@mui/material";

export const HeaderCollapseMenu = styled(Menu)(({ theme }) => ({
	elevation: 0,
	marginTop: "0.250rem",
	border: `1px solid ${theme.palette.grey[300]}`,
	filter: "drop-shadow(0 0.250rem 0.813rem rgba(0, 0, 0, 0.07))",
	borderRadius: "0.500rem",
	overflow: "visible",
	".MuiList-root": {
		padding: 0
	}
}));
