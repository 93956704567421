import { useTableContext } from "../../../util/contexts/table.context";
import { CheckReportDialogProps } from "../types";
import { CheckReportDialogTemplate } from "./check-report.dialog-template";
import { LocalizedTypographyWithVariableSubject } from "../../elements";
import {
	CheckReportMultipleItemsContent,
	CheckReportSingleItemContent
} from "../contents";

export const CheckReportEmailDialog: React.FC<CheckReportDialogProps> = ({
	closeDialog,
	sendMail,
	dialogOpen,
	emailBarProps,
	isLoading
}) => {
	const { selectedElements } = useTableContext();

	const multipleElementsSelected = selectedElements.length > 1;

	const localizationKeyPluralAddition = multipleElementsSelected ? "_plural" : "";

	const contentProps = multipleElementsSelected
		? {
				PrimaryInfo: <CheckReportMultipleItemsContent />,
				infoContainerStyles: { paddingLeft: { xs: "1.54rem", md: "2.5rem" } }
		  }
		: {
				PrimaryInfo: <CheckReportSingleItemContent />,
				SecondaryInfo: (
					<LocalizedTypographyWithVariableSubject
						variants={{ mobile: "body4-primary-bold", desktop: "body3-primary-bold" }}
						variable={selectedElements[0].checkNumber.toString()}
						localizationKey="select_dialog_selected_check_message"
						sx={{ width: "max-content" }}
					/>
				),
				infoContainerStyles: { paddingY: { xs: "1.47rem", md: "1rem" } }
		  };

	return (
		<CheckReportDialogTemplate
			sendMail={sendMail}
			closeDialog={closeDialog}
			buttonLocalizationKey={
				"send_check_button_placeholder" + localizationKeyPluralAddition
			}
			contentProps={contentProps}
			dialogOpen={dialogOpen}
			emailBarProps={emailBarProps}
			isLoading={isLoading}
		/>
	);
};
