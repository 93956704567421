import { Box, Stack } from "@mui/material";
import React from "react";
import { SEOMetaContentInput, SEOMetaNameInput, SEOTitleInput } from "../inputs";
import { GenericBody } from "../../elements";
import { MetaTagNumberBox } from "../../../util/theme/styled-components";
import { AdminFormSEOButton } from "../buttons";

export const SEOForm: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
	return (
		<Box padding="1rem">
			<Stack spacing="0.75rem">
				<SEOTitleInput />
				{[1, 2, 3, 4].map(itemIndex => (
					<Stack spacing="0.25rem" key={itemIndex}>
						<Box sx={{ position: "relative" }}>
							<MetaTagNumberBox>
								<GenericBody
									variant="body4-primary-semi-bold"
									text={itemIndex.toString()}
								/>
							</MetaTagNumberBox>
							<SEOMetaNameInput index={itemIndex} />
						</Box>
						<SEOMetaContentInput index={itemIndex} />
					</Stack>
				))}
				<AdminFormSEOButton handleClick={handleClose} />
			</Stack>
		</Box>
	);
};
