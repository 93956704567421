import { TableProvider } from "../../util/contexts/table.context";
import { TableEnum } from "../../util/types/dashboard-table.types";
import { ClientTable } from "../components";
import { CheckReportContainer } from "./check-report.container";

interface Props {
	table: TableEnum;
}

export const ClientTableContainer: React.FC<Props> = ({ table }) => (
	<TableProvider table={table}>
		<ClientTable />
		<CheckReportContainer />
	</TableProvider>
);
