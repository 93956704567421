import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { ThreeCardIconContainer } from "../../../util/theme/styled-components";
import { GenericImageContainer } from "../../elements";

type IconEnum = "threeCardIcon1" | "threeCardIcon2" | "threeCardIcon3";

type IconFactory = (
	iconKey: IconEnum,
	width: string,
	height: string,
	style?: Record<string, string>
) => React.FC;

export const ThreeCardIconFactory: IconFactory =
	(iconKey, width, height, style) => () => {
		const { [iconKey]: imageSrc } = ImageDictionary.ThreeCardIcons;

		return (
			<GenericImageContainer
				imageAlt={iconKey}
				imageSrc={imageSrc}
				width={width}
				height={height}
				component={ThreeCardIconContainer}
				style={style}
			/>
		);
	};

export const ThreeCardIcon1 = ThreeCardIconFactory(
	"threeCardIcon1",
	"11.3rem",
	"11.5rem",
	{
		marginBottom: "-1.88rem"
	}
);

export const ThreeCardIcon2 = ThreeCardIconFactory(
	"threeCardIcon2",
	"14.8rem",
	"8.44rem"
);
export const ThreeCardIcon3 = ThreeCardIconFactory(
	"threeCardIcon3",
	"11.8rem",
	"8.00rem"
);
