import React from "react";
import { TableBody } from "@mui/material";
import { DashboardTableBodyRow } from "../rows";
import { useTableContext } from "../../../util/contexts/table.context";

export const DashboardTableBody: React.FC = () => {
	const { tableElements } = useTableContext();

	const bodyRows: React.ReactElement[] = tableElements.map(element => (
		<DashboardTableBodyRow key={element.id} element={element} />
	));

	return <TableBody>{bodyRows}</TableBody>;
};
