import React from "react";
import { SwipeableHandlers } from "react-swipeable";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import { HomePagination } from "../../../util/theme/styled-components";
import { EditableGenericButton } from "../../elements";
import { PageIdProps } from "../../types";
import { HomeTextField } from "../types";
import { Stack } from "@mui/material";
import { HomepageSlider } from "../sliders";

interface Props extends PageIdProps {
	handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
	textFields: HomeTextField[];
	pageIndex: number;
	swipeHandlers: SwipeableHandlers;
}

export const HomepageTextContent: React.FC<Props> = ({
	handlePageChange,
	textFields,
	pageIndex,
	pageId,
	swipeHandlers
}) => {
	const { navigate } = useLocalizedNavigate();

	const { buttonText, navigationPath } = textFields[pageIndex];

	const buttonPropertyKey = [
		{ body: "body_3", navigationPath: "navigationPath_1" },
		{ body: "body_4", navigationPath: "navigationPath_2" }
	];

	return (
		<Stack overflow="hidden" spacing={{ xs: "0.75rem", md: "2rem" }} {...swipeHandlers}>
			<HomepageSlider pageId={pageId} pageIndex={pageIndex} textFields={textFields} />
			<Stack
				alignItems="flex-start"
				spacing={{ xs: "1rem", md: "2rem" }}
				width={{ xs: "100%", lg: "13.5rem" }}
			>
				<EditableGenericButton
					text={buttonText}
					handleClick={() => navigate(`/${navigationPath}`)}
					text1={buttonText}
					propertyKey1={buttonPropertyKey[pageIndex].body}
					text2={navigationPath}
					propertyKey2={buttonPropertyKey[pageIndex].navigationPath}
					pageId={pageId}
					variant="primary"
					sx={{ width: "100%" }}
				/>
				<HomePagination
					hideNextButton
					hidePrevButton
					page={pageIndex + 1}
					count={2}
					size="small"
					onChange={handlePageChange}
				/>
			</Stack>
		</Stack>
	);
};
