import { Stack } from "@mui/material";
import React from "react";
import { LocalizedGenericBody, LocalizedGenericButton } from "../../elements";
import { UserCreateResponseDialogContentProps } from "../types";

export const UserCreateResponseDialogContent: React.FC<
	UserCreateResponseDialogContentProps
> = ({ handleDialogClose, Image, variant }) => (
	<Stack p="2rem 1rem 1rem 1rem" spacing="1.5rem">
		<Stack spacing="1.5rem" alignItems={"center"} sx={{ textAlign: "center" }}>
			<Image />
			<LocalizedGenericBody
				variant={`body1-${variant}-semi-bold`}
				localizationKey={`user_create_${variant}_message`}
			/>
			<LocalizedGenericBody
				variant="body2-primary"
				localizationKey={`user_create_${variant}_info`}
				sx={{ maxWidth: "23rem" }}
			/>
		</Stack>
		<LocalizedGenericButton
			handleClick={handleDialogClose}
			variant="primary"
			localizationKey={`user_create_${variant}_button_text`}
			sx={{ width: "100%" }}
		/>
	</Stack>
);
