import { Theme } from "@mui/material";

declare module "@mui/material/Paper" {
	interface PaperPropsVariantOverrides {
		"bg-primary": true;
		"bg-secondary": true;
		"bg-grey": true;
		"bg-white": true;
		"bg-home-1": true;
		"bg-home-2": true;
		"bg-home-3": true;
	}
}

export const getMuiPaperStyles = (theme: Theme) => {
	return {
		variants: [
			{
				props: { variant: "bg-primary" },
				style: {
					background: theme.palette.primary.main
				}
			},
			{
				props: { variant: "bg-secondary" },
				style: {
					background: theme.palette.secondary.main
				}
			},
			{
				props: { variant: "bg-tertiary" },
				style: {
					background: theme.palette.tertiary.main
				}
			},
			{
				props: { variant: "bg-grey" },
				style: {
					background: theme.palette.grey[300]
				}
			},
			{
				props: { variant: "bg-announcement" },
				style: {
					background: `linear-gradient(180deg, ${theme.palette.primary.light}cc 0%, ${theme.palette.tertiary.light}cc 100%)`
				}
			},
			{
				props: { variant: "bg-white" },
				style: {
					background: theme.palette.common.white
				}
			},
			{
				props: { variant: "bg-home-1" },
				style: {
					background: `linear-gradient(0deg, ${theme.palette.tertiary.main} -82.79%, #625696 -12.07%, ${theme.palette.primary.main} 143.76%)`,
					borderRadius: "0"
				}
			},
			{
				props: { variant: "bg-home-2" },
				style: {
					background: `linear-gradient(0deg, ${theme.palette.tertiary.main} 5.48%, #625696 87.3%, ${theme.palette.primary.main} 267.62%)`,
					borderRadius: "0"
				}
			},
			{
				props: { variant: "bg-home-3" },
				style: {
					background:
						"linear-gradient(180deg, #6F6E88 8.35%, #82927E 56.56%, #94B375 100%)",
					borderRadius: "0"
				}
			}
		]
	};
};
