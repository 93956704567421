import React from "react";
import { Box } from "@mui/material";
import { useFormContext } from "../../../../util/contexts/form.context";
import { DndUploadPreview } from "./dnd-upload-preview";
import { DragAndDropInputBase } from "./dnd-input-base";

export const DragAndDropInput: React.FC = props => {
	const { setValues } = useFormContext();
	const [files, setFiles] = React.useState<File[]>([]);

	const handleClear = () => {
		setFiles([]);
		setValues((prev: Record<string, any>) => ({ ...prev, document: null }));
	};

	const preview = files.map((file: File) => (
		<DndUploadPreview
			key={file.name}
			fileName={file.name}
			fileSize={file.size}
			handleClear={handleClear}
		/>
	));

	return (
		<>
			<DragAndDropInputBase uploadFiles={setFiles} {...props} />
			<Box>{preview}</Box>
		</>
	);
};
