import React from "react";
import { NewsDAO } from "../../../util/generators/types";
import { Stack } from "@mui/material";
import { NewsInspiredPageImage } from "../../../util/theme/styled-components";
import { GenericBody } from "../../elements";

interface Props {
	convertDate: (date: string) => string;
	news: NewsDAO;
}

export const NewsInspiredPageContent: React.FC<Props> = ({
	convertDate,
	news: { imageLink, date, title, description }
}) => (
	<Stack spacing={{ xs: "1rem", md: "2rem" }}>
		<NewsInspiredPageImage imagelink={imageLink} />
		<Stack spacing={{ xs: "0.25rem", md: "0.5rem" }}>
			<GenericBody
				text={convertDate(date)}
				variants={{ desktop: "h4-secondary", mobile: "h7-secondary" }}
			/>
			<Stack spacing="1rem">
				<GenericBody
					text={title}
					variants={{ desktop: "h2-primary", mobile: "h4-primary" }}
				/>
				<GenericBody
					text={description}
					variants={{ desktop: "body1-primary", mobile: "body2-primary" }}
				/>
			</Stack>
		</Stack>
	</Stack>
);
