import {
	AwardsPublicCommunicator,
	AnnouncementsPublicCommunicator,
	BranchesPublicCommunicator,
	DocumentsPublicCommunicator,
	FaqsPublicCommunicator,
	LanguagePublicCommunicator,
	LiderPublicCommunicator,
	NewsPublicCommunicator,
	PagePublicCommunicator,
	SectionPublicCommunicator,
	JobOpeningsPublicCommunicator,
	LocalizationsPublicCommunicator
} from "../../communicators/lider.http.communicator";
import {
	branchesAdaptor,
	documentsByIdAdaptor,
	sectionsByLanguageIdAdaptor,
	baseAdaptor,
	localizationsAdaptor
} from "../adaptors/lider.adaptors";
import { LanguageEnum } from "../dictionaries/types";
import { FromManyAdaptor } from "../adaptors/types";
import { HttpRequestArgs } from "../../communicators/types";
import { API_DB_CONNECTION_NAMES, API_URL_CONFIG } from "../../communicators/config";

interface IPaginationOptions {
	pageSize: number;
	pageNumber?: number;
}
interface IGeneratorOptions {
	pagination?: IPaginationOptions;
}

export const PublicGeneratorFactory = <DAO, AdaptorResultType>(
	Communicator: { new (args?: Partial<HttpRequestArgs>): LiderPublicCommunicator<DAO> },
	adaptor: FromManyAdaptor<DAO, AdaptorResultType>,
	options: Record<string, any> = {}
) => {
	const usePagination = !!options.pagination;
	const pageSize = options.pagination ? options.pagination.pageSize : 1;
	const initialPage = options.pagination ? options.pagination.pageNumber : 0;
	const [communicatorConstructorArgs, communicatorUrlConfig] =
		localStorage.getItem("isFastEditMode") === "true"
			? [
					{
						headers: {
							authorization: `Bearer ${localStorage.getItem("token")}`,
							"connection-name": API_DB_CONNECTION_NAMES.draft
						}
					},
					{
						accessLevel: API_URL_CONFIG.accessLevels.private,
						userRole: API_URL_CONFIG.userRoles.admin
					}
			  ]
			: [{}, {}];

	return class PublicGenerator extends Communicator {
		hasMore = false;
		pageSize: number;
		currentPage: number;
		response: any;
		url: URL = new URL("", "https://_");

		constructor(options: IGeneratorOptions = {}) {
			super(communicatorConstructorArgs);
			Object.assign(this, communicatorUrlConfig);
			this.pageSize = options.pagination ? options.pagination.pageSize : pageSize;
			this.currentPage =
				options.pagination && options.pagination.pageNumber
					? options.pagination.pageNumber
					: initialPage;
		}

		all = async () => this._callAndAdapt("findAll");
		many = async (query: string) => this._callAndAdapt("findMany", query);
		manyForLanguage = async (language: LanguageEnum, query?: string) =>
			this.many(`language=${language}${query ? "&" + query : ""}`);

		_callAndAdapt = async (
			method: "findAll" | "findMany",
			query?: string
		): Promise<AdaptorResultType> => {
			this.url = new URL(query ?? "", "https://_");

			if (usePagination) this._createUpdatePaginationParameters();
			await this._createResponse(method);

			return this.response;
		};

		_createUpdatePaginationParameters = () => {
			[
				{
					key: "page[size]",
					value: this.pageSize
				},
				{ key: "page[number]", value: this.currentPage }
			].forEach(({ key, value }) => this.url.searchParams.set(key, String(value)));

			this.currentPage += 1;
		};

		_createResponse = async (method: "findAll" | "findMany") => {
			const result = await this[method](
				this.url.pathname.substring(1) + "&" + this.url?.search.substring(1)
			);

			if (!result) return;
			const { data, links } = result;

			this.hasMore = !!links?.next;
			this.response = adaptor(data);
		};
	};
};

export const PublicBranchesGenerator = PublicGeneratorFactory(
	BranchesPublicCommunicator,
	branchesAdaptor
);

export const PublicBranchesBaseGenerator = PublicGeneratorFactory(
	BranchesPublicCommunicator,
	baseAdaptor
);

export const PublicDocumentsByIdGenerator = PublicGeneratorFactory(
	DocumentsPublicCommunicator,
	documentsByIdAdaptor
);

export const PublicSectionsByLanguageIdGenerator = PublicGeneratorFactory(
	SectionPublicCommunicator,
	sectionsByLanguageIdAdaptor
);

export const PublicAnnouncementGenerator = PublicGeneratorFactory(
	AnnouncementsPublicCommunicator,
	baseAdaptor
);

export const PublicAwardsGenerator = PublicGeneratorFactory(
	AwardsPublicCommunicator,
	baseAdaptor
);

export const PublicDocumentsGenerator = PublicGeneratorFactory(
	DocumentsPublicCommunicator,
	baseAdaptor,
	{
		pagination: {
			pageSize: 10,
			pageNumber: 0
		}
	}
);

export const PublicFaqGenerator = PublicGeneratorFactory(
	FaqsPublicCommunicator,
	baseAdaptor
);

export const PublicJobOpeningsGenerator = PublicGeneratorFactory(
	JobOpeningsPublicCommunicator,
	baseAdaptor
);

export const PublicLanguageGenerator = PublicGeneratorFactory(
	LanguagePublicCommunicator,
	baseAdaptor
);

export const PublicLocalizationGenerator = PublicGeneratorFactory(
	LocalizationsPublicCommunicator,
	localizationsAdaptor
);

export const PublicNewsGenerator = PublicGeneratorFactory(
	NewsPublicCommunicator,
	baseAdaptor,
	{
		pagination: {
			pageSize: 5,
			pageNumber: 0
		}
	}
);

export const PublicPageGenerator = PublicGeneratorFactory(
	PagePublicCommunicator,
	baseAdaptor
);
