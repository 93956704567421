import { Box, Tooltip } from "@mui/material";
import React from "react";
import { GenericImageContainer } from "../../elements";
import { SyncClientsTooltipContent } from "../contents";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";

import infoIcon from "../../../assets/images/dashboard/sync-clients-info-icon.svg";

export const SyncClientsTooltip: React.FC = () => (
	<Tooltip
		title={<SyncClientsTooltipContent />}
		arrow
		placement="right"
		slotProps={{
			tooltip: {
				sx: {
					padding: "1rem",
					borderRadius: "0.75rem",
					border: `1px solid ${ColorDictionary["#D4D4D4"]}`,
					background: ColorDictionary["#f3f3f3"]
				}
			},
			arrow: {
				sx: {
					":before": {
						border: `1px solid ${ColorDictionary["#D4D4D4"]}`,
						background: ColorDictionary["#f3f3f3"]
					}
				}
			}
		}}
	>
		<Box>
			<GenericImageContainer
				imageSrc={infoIcon}
				imageAlt="sync-clients-info"
				width="1rem"
			/>
		</Box>
	</Tooltip>
);
