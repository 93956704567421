import { Stack, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import {
	HeaderSectionTypography,
	SectionDropdownItem
} from "../../../util/theme/styled-components";
import { useSectionsContext } from "../../../util/contexts/sections.context";
import { HeaderSectionChevron } from "../images";

interface Props {
	collapsed?: boolean;
	openMenu: (
		event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		sectionId: string
	) => void;
	closeMenu: () => void;
	hoveredSectionId: string;
	route: string;
	sectionId: string;
	title: string;
}

export const HeaderSection: React.FC<Props> = ({
	collapsed = false,
	hoveredSectionId,
	route,
	sectionId,
	title,
	openMenu,
	closeMenu
}) => {
	const { prefix } = useLocalizedNavigate();
	const theme = useTheme();
	const { selectedSection, pageCounts } = useSectionsContext();

	const isSelected = selectedSection === sectionId;

	const isHovered = hoveredSectionId === sectionId;

	const isMultiplePage = pageCounts[sectionId] && pageCounts[sectionId] > 1;

	const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
		isMultiplePage ? openMenu(event, sectionId) : closeMenu();

	return (
		<NavLink
			to={route[0] === "/" ? `${prefix()}${route}` : `${prefix()}/${route}`}
			style={{
				color:
					isSelected || isHovered
						? theme.palette.tertiary.main
						: theme.palette.primary.main,
				textDecoration: "none"
			}}
			onClick={closeMenu}
		>
			{collapsed ? (
				<SectionDropdownItem>
					<HeaderSectionTypography variant="button">{title}</HeaderSectionTypography>
				</SectionDropdownItem>
			) : (
				<Stack
					direction="row"
					spacing="0.625rem"
					alignItems="center"
					onMouseEnter={handleMouseEnter}
				>
					<HeaderSectionTypography variant="button">{title}</HeaderSectionTypography>
					{isMultiplePage ? (
						<HeaderSectionChevron isHovered={isSelected || isHovered} />
					) : null}
				</Stack>
			)}
		</NavLink>
	);
};
