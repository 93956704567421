import { MenuItem, Stack } from "@mui/material";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { DashboardFooterSelectInput } from "../inputs";
import { GenericBody, GenericButton } from "../../elements";
import { DashboardTablePagination } from "../../../util/theme/styled-components";
import { useTableContext } from "../../../util/contexts/table.context";
import { BottomNavigationChevronLeft, BottomNavigationChevronRight } from "../images";

export const DashboardTableFooterPagination: React.FC = ({ ...props }) => {
	const { isMobile, isTablet } = useResponsive();
	const {
		pageNumber,
		pageCount,
		paginateToPreviousPage,
		paginateToPage,
		paginateToNextPage
	} = useTableContext();

	return isMobile ? (
		<DashboardFooterSelectInput value={pageNumber.toString()}>
			{Array.from(Array(pageCount).keys()).map(pageNumber => (
				<MenuItem value={pageNumber} key={pageNumber}>
					<GenericBody
						text={(pageNumber + 1).toString()}
						variant="body3-primary-medium"
						sx={{ display: "flex" }}
					/>
				</MenuItem>
			))}
		</DashboardFooterSelectInput>
	) : (
		<Stack
			{...props}
			direction="row"
			height="2rem"
			alignItems="center"
			gap={{ xs: "0.5rem", sm: "unset" }}
		>
			{PreviousNextButtonGetter(
				paginateToPreviousPage,
				<BottomNavigationChevronLeft />,
				null,
				pageNumber === 0
			)}
			<DashboardTablePagination
				hidePrevButton
				hideNextButton
				boundaryCount={1}
				siblingCount={isTablet ? 0 : 1}
				count={pageCount}
				onChange={(event: React.ChangeEvent<unknown> | null, page: number) => {
					paginateToPage(page - 1);
				}}
				page={pageNumber + 1}
			/>
			{PreviousNextButtonGetter(
				paginateToNextPage,
				null,
				<BottomNavigationChevronRight />,
				pageNumber === pageCount - 1
			)}
		</Stack>
	);
};

const PreviousNextButtonGetter = (
	handleClick: (event: React.MouseEvent) => void,
	startIcon: JSX.Element | null,
	endIcon: JSX.Element | null,
	disabled: boolean
) => (
	<GenericButton
		mini
		startIcon={startIcon ?? startIcon}
		endIcon={endIcon ?? endIcon}
		handleClick={handleClick}
		disabled={disabled}
		variant="tablePagination"
		textVariant="body3-primary-medium"
	/>
);
