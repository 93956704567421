export const LanguageCheckmark = ({ fill }: { fill: string } = { fill: "#222447" }) => {
	return (
		<svg
			width="10"
			height="8"
			viewBox="0 0 10 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.55496 0.216334C9.81336 0.442439 9.83955 0.835213 9.61344 1.09362L3.81081 7.7252C3.6975 7.85469 3.53559 7.93144 3.36362 7.93716C3.19164 7.94289 3.02498 7.87709 2.90331 7.75541L0.41647 5.26857C0.173677 5.02578 0.173677 4.63213 0.41647 4.38934C0.659263 4.14655 1.05291 4.14655 1.2957 4.38934L3.31264 6.40628L8.67767 0.27482C8.90378 0.0164141 9.29655 -0.00977077 9.55496 0.216334Z"
				fill={fill}
			/>
		</svg>
	);
};
