import React from "react";
import { PageDAO } from "../generators/types";

interface IPagesContext {
	pages: PageDAO[];
	selectedPageId: string | null;
	setSelectedPageId: (pageId: string) => void;
	setPages: (pages: PageDAO[]) => void;
}
export const PagesContext = React.createContext<IPagesContext>({
	selectedPageId: null,
	pages: [],
	setSelectedPageId: () => null,
	setPages: () => null
});

export const usePagesContext = () => React.useContext(PagesContext);

export const PagesProvider: React.FC = ({ children }) => {
	const [pages, setPages] = React.useState<PageDAO[]>([]);
	const [selectedPageId, setSelectedPageId] = React.useState<string | null>(null);

	const contextValue = { pages, setPages, selectedPageId, setSelectedPageId };

	return <PagesContext.Provider value={contextValue}>{children}</PagesContext.Provider>;
};
