import { Stack } from "@mui/material";
import { LocalizedGenericBody } from "../../elements";
import { AdminPopupIconButton } from "../../../util/theme/styled-components";
import { AdminSEODialogCloseArrow, DashboardDialogClose } from "../images";

interface Props {
	localizationKey: string;
	handleClose: () => void;
	enableGoBack?: boolean;
}

export const AdminDialogHeader: React.FC<Props> = ({
	handleClose,
	localizationKey,
	enableGoBack = false
}) => {
	return (
		<Stack>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				sx={{
					padding: "0.75rem 1rem",
					borderBottom: "1px solid",
					borderColor: "grey.300"
				}}
			>
				{enableGoBack ? (
					<AdminPopupIconButton onClick={handleClose}>
						<AdminSEODialogCloseArrow />
					</AdminPopupIconButton>
				) : null}
				<LocalizedGenericBody
					variant="body3-primary-medium"
					localizationKey={localizationKey}
				/>
				<AdminPopupIconButton onClick={handleClose}>
					<DashboardDialogClose />
				</AdminPopupIconButton>
			</Stack>
		</Stack>
	);
};
