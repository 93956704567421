import React from "react";
import { GenericBody } from "../../elements";
import { useLanguageContext } from "../../../util/contexts/language.context";

interface Props {
	value: any;
}

export const DateRangeCellContent: React.FC<Props> = ({ value }) => {
	const { language } = useLanguageContext();

	return (
		<GenericBody
			lineClamp={1}
			variant="body3-primary"
			text={value.toLocaleDateStringFormat(language)}
		/>
	);
};
