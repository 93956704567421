import { CircularProgress, styled } from "@mui/material";

export const GradientSpinner = styled(CircularProgress)(() => ({
	circle: {
		stroke: "url(#linearColors) !important"
	}
}));

export const StockPricesProgress = styled(CircularProgress)(({ theme }) => ({
	circle: {
		stroke: theme.palette.secondary.main
	}
}));
