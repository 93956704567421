import React from "react";
import { CheckReportEmailUserAvatar } from "../images";
import { ClearButton } from "../buttons";
import { EmailChip as Chip } from "../../../util/theme/styled-components";

interface Props {
	handleEmailRemove: (email: string) => void;
	email: string;
}

export const EmailChip: React.FC<Props> = ({ handleEmailRemove, email }) => (
	<Chip
		avatar={<CheckReportEmailUserAvatar />}
		label={email}
		onDelete={() => handleEmailRemove(email)}
		deleteIcon={<ClearButton handleClear={() => handleEmailRemove(email)} />}
		sx={{ paddingX: "0.25rem", backgroundColor: "common.white" }}
	/>
);
