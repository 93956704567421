export enum TableEnum {
	adminActivities = "adminActivities",
	announcements = "announcements",
	awards = "awards",
	branches = "branches",
	checks = "checks",
	homepageChecks = "homepageChecks",
	documents = "documents",
	faqs = "faqs",
	invoices = "invoices",
	jobOpenings = "jobOpenings",
	localizations = "localizations",
	news = "news",
	pages = "pages",
	sections = "sections",
	users = "users",
	userRoleSubscriptions = "userRoleSubscriptions"
}

export interface FilteredColumn {
	filter: string;
	value: unknown[];
	columnName: string;
}
