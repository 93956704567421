import { Box } from "@mui/material";
import { FilterArrowDown } from "../../../../util/theme/styled-components";

interface Props {
	styles: {
		box: Record<string, string>;
		arrow: Record<string, string | number>;
	};
	handleClick: () => void;
}

export const DashboardSelectEndAdornment: React.FC<Props> = ({
	styles,
	handleClick,
	...props
}) => (
	<Box onClick={handleClick} sx={styles.box}>
		<FilterArrowDown {...props} sx={styles.arrow} />
	</Box>
);
