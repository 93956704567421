import { Box, Stack } from "@mui/material";
import { NewsDAO } from "../../../util/generators/types";
import { LocalizedGenericBody } from "../../elements";
import { LoadMoreButton } from "../buttons";
import { NewsList } from "../lists";

interface NewsListProps {
	convertDate: (timeString: string) => string | null;
	passNews: (news: NewsDAO) => void;
}

interface Props {
	news: NewsDAO[];
	highlightedNews?: NewsDAO[];
	shouldLoadMore: boolean;
	handleNextClick: ({ highlighted }: { highlighted: boolean }) => void;
	newsListProps: NewsListProps;
}

export const NewsListPageContent: React.FC<Props> = ({
	news,
	highlightedNews,
	shouldLoadMore,
	handleNextClick,
	newsListProps
}) => (
	<Stack py={{ xs: "1rem", md: "2.63rem" }} spacing={{ xs: "0", md: "2.75rem" }}>
		{highlightedNews ? (
			<Box>
				<LocalizedGenericBody
					variant="h3-secondary"
					localizationKey="highlighted_news_heading"
					sx={{ marginBottom: "1rem" }}
				/>
				<NewsList highlighted={true} news={highlightedNews} {...newsListProps} />
			</Box>
		) : null}
		<Box>
			<LocalizedGenericBody
				variant="h3-secondary"
				localizationKey="news_list_heading"
				sx={{ marginBottom: "1rem" }}
			/>
			<NewsList highlighted={false} news={news} {...newsListProps} />
		</Box>
		{shouldLoadMore ? (
			<LoadMoreButton handleNextClick={() => handleNextClick({ highlighted: false })} />
		) : (
			""
		)}
	</Stack>
);
