import { Box, Grid } from "@mui/material";
import React from "react";
import { ContentFourSubcontentIcon } from "../components";
import { PageWithSEO, PlainContentSubcontentContainer } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	body_5: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	subtitle_4: string;
	title_1: string;
}

export const ContentFourSubcontentPage: React.FC<Props> = ({
	body_1,
	body_2,
	body_3,
	body_4,
	body_5,
	subtitle_1,
	subtitle_2,
	subtitle_3,
	subtitle_4,
	title_1,
	pageId,
	...seoProps
}) => {
	const content = { title: title_1, body: body_1 };
	const subcontents = [
		{
			title: subtitle_1,
			titleProperty: "subtitle_1",
			body: body_2,
			bodyProperty: "body_2"
		},
		{
			title: subtitle_2,
			titleProperty: "subtitle_2",
			body: body_3,
			bodyProperty: "body_3"
		},
		{
			title: subtitle_3,
			titleProperty: "subtitle_3",
			body: body_4,
			bodyProperty: "body_4"
		},
		{
			title: subtitle_4,
			titleProperty: "subtitle_4",
			body: body_5,
			bodyProperty: "body_5"
		}
	];
	const props = { content, subcontents, pageId };

	return (
		<PageWithSEO withSidebar {...seoProps}>
			<Grid container sx={{ marginBottom: { xs: "2.00rem", md: "unset" } }}>
				<Grid item xs={12}>
					<PlainContentSubcontentContainer {...props} />
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "flex-end" }}>
						<ContentFourSubcontentIcon variant="right" />
					</Box>
				</Grid>
			</Grid>
		</PageWithSEO>
	);
};
