import React from "react";
import { Box, Grid } from "@mui/material";
import { CityItem, HighlightedBranchItem } from "../items";
import { BranchDAO } from "../../../util/generators/types";
import { City } from "../../containers/types";

interface Props {
	handleClick: (city: string) => void;
	cities: City[];
	highlightedBranches: BranchDAO[];
}

export const CityList: React.FC<Props> = ({
	handleClick,
	cities,
	highlightedBranches
}) => {
	const cityCount = cities.length;

	const getExtraBlockCount = () => {
		const getComplement = (mod: number) => (mod - (cityCount % mod)) % mod;

		return Math.max(getComplement(4), getComplement(3));
	};

	const extraBlocks = getExtraBlockCount();

	return (
		<Grid
			container
			rowSpacing="1.00rem"
			columnSpacing={{ xs: "0.938rem", md: "1.13rem" }}
		>
			{highlightedBranches.map(branch => (
				<Grid
					item
					xs={12}
					key={`${branch.name}-highlighted`}
					sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
				>
					<HighlightedBranchItem {...branch} />
				</Grid>
			))}
			{cities.map(({ city, route, imageLink }) => (
				<Grid item key={city} xs>
					<Box
						sx={{
							transitionProperty: "all",
							transitionDuration: "1s",
							"&:hover": {
								transform: "translateX(-0.625rem)"
							}
						}}
					>
						<CityItem
							handleClick={handleClick}
							city={city}
							imageLink={imageLink}
							route={route}
						/>
					</Box>
				</Grid>
			))}
			{[...new Array(extraBlocks)].map((item, index) => (
				<Grid item key={"extra-" + (index + 1)} xs>
					<Box sx={{ minWidth: { xs: "10.0rem", md: "13.1rem" } }} />
				</Grid>
			))}
		</Grid>
	);
};
