import React from "react";
import { PublicGeneratorFactory } from "../generators/public.generator";

export const usePublicGeneratorRef = <DAO>(
	Generator: ReturnType<typeof PublicGeneratorFactory<DAO, DAO[]>>,
	options: Record<string, any> = {}
) => {
	const generatorRef = React.useRef(new Generator(options));
	const refreshGenerator = (refreshOptions?: Record<string, any>) => {
		generatorRef.current = new Generator(refreshOptions ?? options);
	};

	return { generatorRef, refreshGenerator };
};
