import { Grid, Stack } from "@mui/material";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminFormCreateUpdateButton } from "../buttons";
import {
	AdminPanelSectionsVisibilityCheck,
	IndexInput,
	LanguageSelect,
	AdminNameInput
} from "../inputs";

export const AdminPanelSectionsForm: React.FC<FormProps> = ({ handleSubmit }) => (
	<Stack spacing="0.75rem">
		<AdminNameInput />
		<Grid container gap="0.75rem">
			<Grid item xs>
				<LanguageSelect />
			</Grid>
			<Grid item xs>
				<IndexInput />
			</Grid>
		</Grid>
		<AdminPanelSectionsVisibilityCheck />
		<AdminFormCreateUpdateButton handleClick={handleSubmit} />
	</Stack>
);
