import { Grid, Stack } from "@mui/material";
import { BasicTextCard } from "../components";
import { GenericMedia } from "../elements";
import { PageIdProps } from "../types";
import { AdminTextEditContainer } from "./admin-text-edit.container";
import { TitleAndDescriptionWrapper } from "./wrappers";

interface Props extends PageIdProps {
	title_1: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	imageLink_1?: string;
}

export const ThreePointContentWithMediaContainer: React.FC<Props> = ({
	title_1,
	subtitle_1,
	subtitle_2,
	subtitle_3,
	body_1,
	body_2,
	body_3,
	body_4,
	pageId,
	imageLink_1
}) => {
	const cards = [
		{
			title: subtitle_1,
			titleProperty: "subtitle_1",
			body: body_2,
			bodyProperty: "body_2"
		},
		{
			title: subtitle_2,
			titleProperty: "subtitle_2",
			body: body_3,
			bodyProperty: "body_3"
		},
		{
			title: subtitle_3,
			titleProperty: "subtitle_3",
			body: body_4,
			bodyProperty: "body_4"
		}
	].map(({ title, titleProperty, body, bodyProperty }, index) => (
		<BasicTextCard
			key={index}
			title={title}
			titleProperty={titleProperty}
			body={body}
			bodyProperty={bodyProperty}
			titleVariant="h5-tertiary"
			bodyVariant="body1"
			pageId={pageId}
		/>
	));

	const titleAndDescriptionProps = {
		headingProps: { variant: "h3-secondary", text: title_1 },
		bodyProps: { variant: "body1", text: body_1 },
		pageId
	};

	return (
		<TitleAndDescriptionWrapper {...titleAndDescriptionProps}>
			{imageLink_1 ? (
				<Grid item xs={12}>
					<AdminTextEditContainer
						text1={imageLink_1}
						propertyKey1="imageLink_1"
						pageId={pageId}
					>
						<GenericMedia
							mediaSrc={imageLink_1}
							styles={{
								position: "relative",
								paddingBottom: "40%", // 1 / 2.5, change for different aspect ratios
								paddingTop: "1.56rem",
								height: 0,
								border: "none",
								boxShadow: "none",
								width: "100%"
							}}
						/>
					</AdminTextEditContainer>
				</Grid>
			) : null}
			<Grid item>
				<Stack direction="row" justifyContent="start" sx={{ flexWrap: "wrap" }}>
					{cards}
				</Stack>
			</Grid>
		</TitleAndDescriptionWrapper>
	);
};
