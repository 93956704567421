import { TableEnum } from "../types/dashboard-table.types";

export const DashboardPlaceholderLocalizations = {
	[TableEnum.adminActivities]: "dashboard_admin_activities_search_placeholder",
	[TableEnum.announcements]: "dashboard_announcements_search_placeholder",
	assets: "dashboard_assets_search_placeholder",
	[TableEnum.awards]: "dashboard_awards_search_placeholder",
	[TableEnum.branches]: "dashboard_branches_search_placeholder",
	[TableEnum.checks]: "dashboard_checks_search_placeholder",
	[TableEnum.homepageChecks]: "dashboard_checks_search_placeholder",
	[TableEnum.documents]: "dashboard_documents_search_placeholder",
	[TableEnum.faqs]: "dashboard_faqs_search_placeholder",
	[TableEnum.invoices]: "dashboard_invoices_search_placeholder",
	[TableEnum.jobOpenings]: "dashboard_job_openings_search_placeholder",
	[TableEnum.localizations]: "dashboard_localizations_search_placeholder",
	[TableEnum.news]: "dashboard_news_search_placeholder",
	[TableEnum.pages]: "dashboard_pages_search_placeholder",
	[TableEnum.sections]: "dashboard_sections_search_placeholder",
	[TableEnum.users]: "dashboard_users_search_placeholder",
	[TableEnum.userRoleSubscriptions]:
		"dashboard_user_role_subscriptions_search_placeholder"
};
