import { IconButton, Slide } from "@mui/material";
import React from "react";
import { FormSubmissionSnackbar } from "../../../util/theme/styled-components";
import { SubmissionStatus, SubmissionStatusEnum } from "../../containers/types";
import {
	PublishErrorAlert,
	PublishErrorIcon,
	PublishSuccessAlert,
	PublishSuccessIcon
} from "../images";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";
import { FormSnackbarContent } from "../contents";
import { FormVariant } from "../types";

interface Props {
	handleClose: () => void;
	autoHideDuration?: number;
	submissionStatus: SubmissionStatus;
	formVariant: FormVariant;
	offset?: string;
}

interface SnackbarConfig {
	variant: SubmissionStatusEnum;
	color: string;
	Icon: React.FC;
	AlertIcon: React.FC;
}

type SnackbarConfigGetter = (submissionStatus: SubmissionStatus) => SnackbarConfig;

const snackbarConfigGetter: SnackbarConfigGetter = submissionStatus =>
	submissionStatus === SubmissionStatusEnum.success ||
	submissionStatus === SubmissionStatusEnum.inProgress
		? {
				variant: SubmissionStatusEnum.success,
				color: ColorDictionary.green800,
				Icon: PublishSuccessIcon,
				AlertIcon: PublishSuccessAlert
		  }
		: {
				variant: SubmissionStatusEnum.error,
				color: ColorDictionary.red800,
				Icon: PublishErrorIcon,
				AlertIcon: PublishErrorAlert
		  };

export const FormSnackbar: React.FC<Props> = ({
	handleClose,
	autoHideDuration,
	submissionStatus,
	formVariant,
	offset = "0"
}) => {
	const [snackbarConfig, setSnackbarConfig] = React.useState(
		snackbarConfigGetter(submissionStatus)
	);

	React.useEffect(() => {
		if (submissionStatus) setSnackbarConfig(snackbarConfigGetter(submissionStatus));
	}, [submissionStatus]);

	const { variant, color, Icon, AlertIcon } = snackbarConfig;
	return (
		<FormSubmissionSnackbar
			data-cy="submission-snackbar"
			open={!!submissionStatus}
			offset={offset}
			onClose={handleClose}
			TransitionComponent={Slide}
			variant={variant}
			autoHideDuration={autoHideDuration}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			transitionDuration={{ enter: 250, exit: 250 }}
			message={
				<FormSnackbarContent
					color={color}
					formVariant={formVariant}
					submissionStatus={submissionStatus}
					Icon={Icon}
				/>
			}
			action={
				<IconButton
					data-cy="submission-snackbar-close"
					onClick={handleClose}
					sx={{ padding: "0 !important" }}
				>
					<AlertIcon />
				</IconButton>
			}
		/>
	);
};
