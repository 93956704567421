import { Stack } from "@mui/material";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminFormCreateUpdateButton } from "../buttons";
import { KeyInput, LanguageSelect, TextInput } from "../inputs";

export const AdminPanelLocalizationsForm: React.FC<FormProps> = ({ handleSubmit }) => (
	<Stack spacing="0.75rem">
		<KeyInput />
		<TextInput />
		<LanguageSelect />
		<AdminFormCreateUpdateButton handleClick={handleSubmit} />
	</Stack>
);
