import { ThreeTitledContentPageList } from "../components";
import { PageWithSEO } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	body_1: string;
	body_2: string;
	body_3: string;
	title_1: string;
	title_2: string;
	title_3: string;
}

export const ThreeTitledContentPage: React.FC<Props> = ({
	body_1,
	body_2,
	body_3,
	title_1,
	title_2,
	title_3,
	pageId,
	...seoProps
}) => {
	const contents = [
		{
			title: title_1,
			titleProperty: "title_1",
			body: body_1,
			bodyProperty: "body_1",
			headingVariants: { desktop: "h2-secondary", mobile: "h3-secondary" }
		},
		{
			title: title_2,
			titleProperty: "title_2",
			body: body_2,
			bodyProperty: "body_2",
			headingVariants: { desktop: "h2-tertiary", mobile: "h3-tertiary" }
		},
		{
			title: title_3,
			titleProperty: "title_3",
			body: body_3,
			bodyProperty: "body_3",
			headingVariants: { desktop: "h2-primary", mobile: "h3-primary" }
		}
	];

	return (
		<PageWithSEO withSidebar {...seoProps}>
			<ThreeTitledContentPageList contents={contents} pageId={pageId} />
		</PageWithSEO>
	);
};
