import { Stack } from "@mui/material";
import { useTableContext } from "../../../util/contexts/table.context";
import { GenericBody, LocalizedGenericBody } from "../../elements";

export const CheckReportMultipleItemsContent: React.FC = () => {
	const { selectedElements } = useTableContext();
	const totalAmount = selectedElements.reduce(
		(amount, check) => amount + Number(check.checkAmount),
		0
	);

	return (
		<Stack direction="column" spacing="1rem">
			<Stack>
				<LocalizedGenericBody
					variant="body4-primary"
					localizationKey="check_report_total_check_count"
				/>
				<GenericBody
					variant="body2-primary-bold"
					text={selectedElements.length.toString()}
				/>
			</Stack>
			<Stack>
				<LocalizedGenericBody
					variant="body4-primary"
					localizationKey="check_report_total_check_amount"
				/>
				<GenericBody
					variant="body2-primary-bold"
					text={"₺" + totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				/>
			</Stack>
		</Stack>
	);
};
