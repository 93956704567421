import React from "react";
import {
	CityBottomContainer,
	CityHeadingContainer,
	CityHeadingFullContainer,
	CityTopContainer
} from "../../../util/theme/styled-components";
import { GenericBody } from "../../elements";

interface Props {
	city: string;
	style?: Record<string, string>;
}

export const CityHeadingFull: React.FC<Props> = ({ city, style }) => (
	<CityHeadingFullContainer sx={style}>
		<GenericBody
			variants={{ desktop: "h2-white", mobile: "h4-white" }}
			text={city}
			sx={{ textAlign: "center" }}
		/>
	</CityHeadingFullContainer>
);

export const CityHeading: React.FC<Props> = ({ city, style }) => {
	return (
		<CityHeadingContainer sx={{ ...style }}>
			<CityTopContainer marginBottom={{ xs: 1, md: 4 }} />
			<GenericBody
				sx={{ textAlign: "center" }}
				variants={{ desktop: "h2-white", mobile: "h4-white" }}
				text={city}
			/>
			<CityBottomContainer marginTop={{ xs: 1, md: 4 }} />
		</CityHeadingContainer>
	);
};
