import { Skeleton } from "@mui/material";
import React from "react";
import { StockPricesPublicCommunicator } from "../../communicators/lider.http.communicator";
import { StockPricesEventDAO } from "../../util/generators/types";
import { StockPricesBar } from "../components";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import { useSocketEvent } from "../../util/hooks/useSocketEvent.hook";

export const StockPricesContainer: React.FC = () => {
	const [priceEvent, setPriceEvent] = React.useState<StockPricesEventDAO>();
	const [priceError, setPriceError] = React.useState<boolean>(false);

	const { isMobile } = useResponsive();

	const communicator = React.useRef(new StockPricesPublicCommunicator());

	React.useEffect(() => {
		communicator.current
			.getStockPrices()
			.then(setPriceEvent)
			.catch(() => setPriceError(true));

		return () => {
			communicator.current.abort();
		};
	}, []);

	useSocketEvent("price", setPriceEvent);

	const barProps = { event: priceEvent, priceError };
	return Object.values(barProps).some(value => !!value) ? (
		<StockPricesBar {...barProps} />
	) : (
		<Skeleton variant="rectangular" height={isMobile ? "2rem" : "3.5rem"} width="100%" />
	);
};
