import { ClickAwayListener, Popper } from "@mui/material";
import React from "react";

interface Props {
	closeCalendar: () => void;
	open: boolean;
	anchorEl: HTMLElement | null;
}

export const DateRangePopper: React.FC<Props> = ({
	closeCalendar,
	open,
	anchorEl,
	children
}) => (
	<ClickAwayListener
		onClickAway={closeCalendar}
		mouseEvent="onMouseDown"
		touchEvent="onTouchStart"
	>
		<Popper
			open={open}
			anchorEl={anchorEl}
			placement="bottom-start"
			sx={{ zIndex: 1300 }}
		>
			{children}
		</Popper>
	</ClickAwayListener>
);
