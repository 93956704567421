import { Grid, Box, Typography, Stack } from "@mui/material";
import React from "react";
import { NewsDAO } from "../../../util/generators/types";
import {
	NewsDetailBackground,
	NewsDetailBox
} from "../../../util/theme/styled-components";
import { GenericBody } from "../../elements";

interface Props {
	highlighted?: boolean;
	newsItem: NewsDAO;
	passNews: (news: NewsDAO) => void;
	convertDate: (date: string) => string | null;
}

interface NewsDetailProps {
	imageLink: string;
	height: string;
}

export const AnimationBox: React.FC = ({ children }) => (
	<Box
		sx={{
			transitionProperty: "all",
			transitionDuration: "0.6s",
			":hover": { transform: "translateX(-0.250rem)" }
		}}
	>
		{children}
	</Box>
);

export const NewsDetail: React.FC<NewsDetailProps> = ({ imageLink, height }) => (
	<NewsDetailBox imagelink={imageLink} height={height}>
		<NewsDetailBackground />
	</NewsDetailBox>
);
export const HighlightedNewsItem: React.FC<Props> = ({
	newsItem,
	newsItem: { imageLink, description, date, title },
	passNews,
	convertDate
}) => {
	return (
		<AnimationBox>
			<Grid container spacing={3} onClick={() => passNews(newsItem)}>
				<Grid item xs={12} md={8}>
					<NewsDetail imageLink={imageLink} height="20.2rem" />
				</Grid>
				<Grid item xs height={{ xs: "auto", md: "20.2rem" }} sx={{ overflowY: "hidden" }}>
					<Stack height="100%" gap={1}>
						<Box>
							<Box sx={{ marginBottom: { xs: "0.5rem", md: "1rem" } }}>
								<GenericBody variant="h4-primary" text={title} />
							</Box>
							<GenericBody
								variant="body2-primary"
								text={description}
								sx={{
									display: "-webkit-box",
									WebkitLineClamp: { xs: "unset", md: "8" },
									WebkitBoxOrient: "vertical",
									overflow: "hidden",
									textOverflow: "ellipsis"
								}}
							/>
						</Box>
						<Box
							sx={{
								marginTop: "auto"
							}}
						>
							<GenericBody
								variants={{
									desktop: "body2-grey-semi-bold",
									mobile: "body3-grey-semi-bold"
								}}
								sx={{ textAlign: { xs: "left", md: "right" } }}
								text={convertDate(date) ?? ""}
							/>
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</AnimationBox>
	);
};

export const NewsItem: React.FC<Props> = ({
	newsItem,
	newsItem: { imageLink, date, title },
	passNews,
	convertDate
}) => {
	return (
		<AnimationBox>
			<Stack height="100%" onClick={() => passNews(newsItem)}>
				<NewsDetail imageLink={imageLink} height="12.5rem" />
				<Typography variant="body2-primary-semi-bold" mt="0.750rem" mb="0.250rem">
					{title}
				</Typography>
				<Typography variant="body3-grey-semi-bold" mt="auto" sx={{ textAlign: "left" }}>
					{convertDate(date)}
				</Typography>
			</Stack>
		</AnimationBox>
	);
};

export const NewsSidebarItem: React.FC<Props> = ({
	newsItem,
	newsItem: { date, title },
	passNews,
	highlighted,
	convertDate
}) => (
	<Box onClick={() => passNews(newsItem)} sx={{ cursor: "pointer" }}>
		<GenericBody
			variants={{ desktop: "body3-primary", mobile: "body3-primary-semi-bold" }}
			text={convertDate(date) || ""}
		/>
		<Typography
			variant={highlighted ? "h4-tertiary" : "h4-secondary"}
			pt="0.313rem"
			maxWidth={{ xs: "unset", md: "13.8rem" }}
		>
			{title}
		</Typography>
	</Box>
);
