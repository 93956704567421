import { DashboardTableCellProps } from "../types";
import { DashboardTableCell } from "./dashboard-table.cell";

interface Props extends DashboardTableCellProps {
	sortable: boolean;
}

export const DashboardTableHeaderCell: React.FC<Props> = ({
	sortable,
	...tableCellProps
}) => (
	<DashboardTableCell
		variant="head"
		sx={{ cursor: sortable ? "pointer" : "default" }}
		{...tableCellProps}
	/>
);

export const DashboardTableHeaderRowEndCell: React.FC<Props> = ({
	sortable,
	...tableCellProps
}) => (
	<DashboardTableCell
		variant="head"
		sx={{ cursor: sortable ? "pointer" : "default", width: "0" }}
		{...tableCellProps}
	/>
);
