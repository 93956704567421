import React from "react";
import { useTemplatesContext } from "../contexts/templates.context";
import { usePagesContext } from "../contexts/pages.context";
import { useSectionsContext } from "../contexts/sections.context";
import { TemplateDictionary } from "../dictionaries/template.dictionary";
import { PageTemplateMatcherProps } from "./types";

interface TemplateContainerFactoryProps {
	Template: any;
	templateName: string;
	id: string;
	sectionId: string;
	isAdmin?: boolean;
	[key: string]: any;
}

type TComponentFactory = (args: TemplateContainerFactoryProps) => React.FC;
type TPageTemplateMatcher = (args: PageTemplateMatcherProps) => React.FC;

const TemplateContainerFactory: TComponentFactory = ({
	Template,
	templateName,
	id,
	sectionId,
	isAdmin,
	...props
}) => {
	const TemplateContainer: React.FC = () => {
		const { setSelectedSection } = useSectionsContext();
		const { setSelectedPageId } = usePagesContext();
		const { setActiveTemplateName } = useTemplatesContext();

		React.useEffect(() => {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

			setActiveTemplateName(templateName);

			if (!isAdmin) {
				setSelectedPageId(id);
				setSelectedSection(sectionId);
			}
		}, []);

		return <Template {...props} pageId={id} isAdmin={isAdmin} />;
	};
	return TemplateContainer as React.FC;
};

export const PageTemplateMatcher: TPageTemplateMatcher = ({ templateName, ...props }) => {
	const Template = TemplateDictionary[templateName];
	return TemplateContainerFactory({ Template, templateName, ...props });
};
