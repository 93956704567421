import { Box, Stack, useTheme } from "@mui/material";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import { BranchDAO } from "../../../util/generators/types";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

export const BranchDetailsItemContent: React.FC<BranchDAO> = props => {
	const theme = useTheme();

	const { isMobile } = useResponsive();
	const whatsappDomain = isMobile ? "api" : "web";

	const countryCode = "+9";

	return (
		<Stack
			sx={{ padding: { xs: "1.5rem", md: "1.56rem 2rem" } }}
			spacing={{ xs: 3, md: 5 }}
		>
			<Stack spacing="0.25rem">
				<GenericBody
					variants={{ desktop: "h4-primary", mobile: "h5-primary" }}
					text={props.name}
				/>
				<GenericBody variant="body2-secondary-medium" text={props.address} />
			</Stack>
			<Stack
				direction="row"
				justifyContent="space-between"
				flexWrap="wrap"
				rowGap={2}
				columnGap={1}
				sx={{
					borderTop: `1px solid ${theme.palette.grey[300]}`,
					paddingTop: "1rem"
				}}
			>
				<Box>
					<LocalizedGenericBody
						variant="body3-primary-semi-bold"
						localizationKey="phone_number_text"
					/>
					<GenericBody
						variant="body2-secondary-medium"
						innerHTML={`<a style="color:${
							theme.palette.secondary.main
						};text-decoration:none;" href="tel:${
							props.phoneNumber
						}" target="_blank" rel="external">${props.phoneNumber ?? ""}</a>`}
					/>
				</Box>
				{props.whatsapp && (
					<Box>
						<LocalizedGenericBody
							variant="body3-primary-semi-bold"
							localizationKey="whatsapp_text"
						/>
						<GenericBody
							variant="body2-secondary-medium"
							innerHTML={`<a style="color:${
								theme.palette.secondary.main
							};text-decoration:none;" target="_blank" rel="external" href="https://${whatsappDomain}.whatsapp.com/send?phone=${countryCode}${props.whatsapp
								.split(" ")
								.join("")}&;text=">${props.whatsapp}</a>`}
						/>
					</Box>
				)}
				{props.fax && (
					<Box>
						<LocalizedGenericBody
							variant="body3-primary-semi-bold"
							localizationKey="fax_text"
						/>
						<GenericBody variant="body2-secondary-medium" text={props.fax as string} />
					</Box>
				)}
			</Stack>
		</Stack>
	);
};
