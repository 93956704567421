import { Route } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import {
	AdminLoginContainer,
	ForgotPasswordContainer,
	ResetNoticeContainer
} from "./views";
import React from "react";

const AdminDashboard = () => {
	React.useEffect(() => {
		localStorage.removeItem("isFastEditMode");
	}, []);

	return (
		<Dashboard userRole="admin">
			<Route path="/login" element={<AdminLoginContainer />} />
			<Route path="/forgot-password" element={<ForgotPasswordContainer />} />
			<Route path="/password-reset-success" element={<ResetNoticeContainer />} />
		</Dashboard>
	);
};

export default AdminDashboard;
