import React from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";
import { focused, accept, reject } from "../../../../util/theme/components/dnd.theme";
import { LocalizedButton } from "../../../elements";
import { Add } from "@mui/icons-material";

interface Props {
	onCreate: (formData: FormData) => void;
	setImages: (files: File[]) => void;
}

export const DragAndDropImageButton: React.FC<Props> = ({ onCreate, setImages }) => {
	const onDrop = React.useCallback(
		(acceptedFiles: File[]) => {
			if (!acceptedFiles || acceptedFiles.length === 0) return;
			const formData = new FormData();
			formData.set("asset", acceptedFiles[0]);
			onCreate(formData);
			setImages(acceptedFiles);
		},
		[onCreate]
	);

	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
		useDropzone({
			onDrop,
			maxFiles: 1
		});

	const style = React.useMemo(
		() => ({
			...(isFocused ? focused : {}),
			...(isDragAccept ? accept : {}),
			...(isDragReject ? reject : {})
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	return (
		<Box {...getRootProps({ style })}>
			<input {...getInputProps()} />
			<LocalizedButton
				localizationKey="create_asset_button_placeholder"
				variant="newPrimary-mini"
				startIcon={<Add />}
			/>
		</Box>
	);
};
