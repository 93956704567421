import dndImage from "../../../assets/images/dnd-input-image.svg";
import { GenericImageContainer } from "../../elements";

export const DndImage: React.FC = () => (
	<GenericImageContainer
		imageSrc={dndImage}
		imageAlt="dnd input image"
		width="7.03rem"
		height="4rem"
		style={{ img: { objectFit: "none" } }}
	/>
);
