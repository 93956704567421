import { useNavigate } from "react-router";
import { useLanguageContext } from "../contexts/language.context";

export const useLocalizedNavigate = () => {
	const mainNavigate = useNavigate();
	const { getLanguage } = useLanguageContext();

	const prefix = () => {
		const language = getLanguage();
		return language === process.env.REACT_APP_DEFAULT_LANGUAGE ? "" : `/${language}`;
	};

	const navigate = (route: string) => mainNavigate(`${prefix()}${route}`);

	return {
		prefix,
		navigate
	};
};
