import React from "react";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { useFormContext } from "../../../util/contexts/form.context";
import { LocalizedGenericButton } from "../../elements";
import { BasicSpinner } from "../spinners";
import { Add } from "@mui/icons-material";

interface Props {
	handleClick?: (event: React.MouseEvent) => void;
	disabled?: boolean;
	localizationKey?: string;
}

type FactoryOptions = {
	localizationKey: string;
	textVariant?: any;
	variant?:
		| "primary"
		| "secondary"
		| "contained"
		| "outlined"
		| "text"
		| "header"
		| "location"
		| "submit";
	fullWidth?: boolean;
	mini?: boolean;
	startIcon?: any;
	type?: "button" | "submit" | "reset";
	sx?: CSSProperties;
	containerStyles?: CSSProperties;
};

type Factory = (factoryOption: FactoryOptions) => React.FC<Props>;

const ButtonFactory: Factory = ({
	localizationKey,
	textVariant,
	variant,
	fullWidth,
	mini,
	startIcon,
	type,
	sx,
	containerStyles
}) => {
	const defaultKey = localizationKey;
	return ({ handleClick, disabled, localizationKey, ...props }) => (
		<LocalizedGenericButton
			handleClick={handleClick}
			localizationKey={localizationKey ?? defaultKey}
			variant={variant}
			textVariant={textVariant}
			fullWidth={fullWidth}
			startIcon={startIcon}
			sx={sx}
			containerStyles={containerStyles}
			disabled={disabled}
			mini={mini}
			type={type}
			{...props}
		/>
	);
};

interface AdminButtonWithLoaderProps {
	handleClick?: (event: React.MouseEvent) => void;
	localizationKey: string;
	textVariant?: any;
	mini?: boolean;
	startIcon?: any;
	sx?: CSSProperties;
}

export const AdminButtonWithLoader: React.FC<AdminButtonWithLoaderProps> = ({
	handleClick,
	localizationKey,
	textVariant,
	mini,
	startIcon,
	sx,
	...props
}) => {
	const { isLoading } = useFormContext();

	return isLoading ? (
		<BasicSpinner />
	) : (
		ButtonFactory({
			localizationKey,
			textVariant,
			variant: "primary",
			fullWidth: true,
			startIcon,
			mini,
			sx
		})({ handleClick, ...props })
	);
};

export const AdminPanelCreateButton: React.FC<Props> = ({ handleClick, ...props }) => (
	<AdminButtonWithLoader
		{...props}
		localizationKey="create_button_placeholder"
		textVariant="body4-white-medium"
		handleClick={handleClick}
		mini={true}
		startIcon={<Add />}
	/>
);

export const LocalizableAdminButton: React.FC<{
	localizationKey: string;
	handleClick?: (event: React.MouseEvent) => void;
}> = props => <AdminButtonWithLoader {...props} sx={{ width: "100%" }} />;

export const AdminFormCreateUpdateButton: React.FC<Props> = ({
	handleClick,
	...props
}) => (
	<LocalizableAdminButton
		{...props}
		localizationKey="create_or_update_button_placeholder"
		handleClick={handleClick}
	/>
);

export const AdminFormSaveButton: React.FC<Props> = ({ handleClick }) => (
	<LocalizableAdminButton
		localizationKey="save_button_placeholder"
		handleClick={handleClick}
	/>
);

export const AdminFormUpdateButton: React.FC<Props> = ({ handleClick }) => (
	<LocalizableAdminButton
		localizationKey="update_button_placeholder"
		handleClick={handleClick}
	/>
);

export const AdminFormSEOButton: React.FC<Props> = ({ handleClick }) => (
	<LocalizableAdminButton
		localizationKey="admin_seo_button_text"
		handleClick={handleClick}
	/>
);

export const LogoutButton = ButtonFactory({
	localizationKey: "logout_button_placeholder",
	variant: "header"
});

export const LoginButton = ButtonFactory({
	localizationKey: "login_button_placeholder",
	variant: "primary",
	fullWidth: true,
	type: "submit",
	containerStyles: { width: "100%" },
	sx: { width: "100%" }
});

export const ForgotPasswordButton = ButtonFactory({
	localizationKey: "admin_forgot_password_button",
	variant: "primary",
	fullWidth: true,
	sx: { width: "100%" }
});

export const VerificationButton = ButtonFactory({
	localizationKey: "verification_button_placeholder",
	variant: "primary",
	fullWidth: true,
	type: "submit",
	containerStyles: { width: "100%" },
	sx: { width: "100%" }
});
