import { Stack } from "@mui/material";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import { currencyFormatter } from "../../../util/currency.formatter";
import { useChartContext } from "../../../util/contexts/chart.context";

export const RiskAnalysisContent: React.FC = () => {
	const { chartItems } = useChartContext();
	return (
		<Stack
			width="100%"
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			px={{ xs: "0.62rem", md: "1.5rem" }}
		>
			<Stack spacing="0.25rem">
				<LocalizedGenericBody
					localizationKey="risk_analysis_content_heading"
					variant="body3-primary"
					sx={{ fontWeight: "600" }}
				/>
				<LocalizedGenericBody
					localizationKey="risk_analysis_content_description"
					variant="body4-primary"
					sx={{ opacity: "0.65" }}
				/>
			</Stack>
			<GenericBody
				text={currencyFormatter(chartItems[0].value)}
				variant="body2-secondary-bold"
			/>
		</Stack>
	);
};
