import { Wrapper } from "@googlemaps/react-wrapper";
import { Stack } from "@mui/material";
import { BranchDAO } from "../../util/generators/types";
import {
	BasicSpinner,
	BranchDetailsList,
	BranchDetailsNavigationItem,
	MetaWithTitle
} from "../components";
import { City } from "./types";

interface Props {
	handleClick: (city: string) => void;
	city: string;
	cities: City[];
	indexOfCity: number;
	branches: {
		[city: string]: BranchDAO[];
	};
}

export const BranchDetailsContainer: React.FC<Props> = ({
	handleClick,
	city,
	cities,
	indexOfCity,
	branches
}) => {
	const branchesInCity = branches[city];
	const previousCity = cities[(indexOfCity - 1 + cities.length) % cities.length].city;
	const nextCity = cities[(indexOfCity + 1) % cities.length].city;

	const { imageLink, seoTitle, seoMetaName_1, seoMetaContent_1 } = branchesInCity[0];

	return (
		<>
			<MetaWithTitle
				title={seoTitle}
				meta={{ name: seoMetaName_1, content: seoMetaContent_1 }}
			/>
			<Wrapper
				apiKey={process.env.REACT_APP_MAPS_API_KEY as string}
				render={() => <BasicSpinner size={80} />}
			>
				<Stack spacing={{ xs: 3, md: 5 }} sx={{ minHeight: "51.6rem" }}>
					<BranchDetailsNavigationItem
						city={city}
						imageLink={imageLink}
						previousCity={previousCity}
						nextCity={nextCity}
						handleClick={handleClick}
					/>
					<BranchDetailsList branches={branchesInCity} />
				</Stack>
			</Wrapper>
		</>
	);
};
