import { GenericImageContainer } from "../../elements";
import phone from "../../../assets/images/stockbar-phone.svg";
import React from "react";

export const StockbarPhone: React.FC = () => {
	return (
		<GenericImageContainer
			imageSrc={phone}
			imageAlt="phone"
			width="0.938rem"
			height="0.838rem"
		/>
	);
};
