import { DateTime } from "luxon";
import React from "react";
import { Route, useNavigate } from "react-router-dom";
import { NewsDAO } from "../../util/generators/types";
import { PublicNewsGenerator } from "../../util/generators/public.generator";
import { useLanguageContext } from "../../util/contexts/language.context";
import { NewsListPageContent } from "../components";
import { NewsInspiredContainer } from "./news-inspired.container";
import { NotFoundPage } from "../pages";
import { SentryRoutes } from "../../util/dictionaries/routes.dictionary";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";

export const NewsListPageContainer: React.FC = () => {
	const { language } = useLanguageContext();

	const generatorRef = React.useRef(
		new PublicNewsGenerator({ pagination: { pageSize: 10 } })
	);
	const highlightedGeneratorRef = React.useRef(new PublicNewsGenerator());

	const generator = generatorRef.current;
	const highlightedGenerator = highlightedGeneratorRef.current;

	const [regularNews, setRegularNews] = React.useState<NewsDAO[]>([]);
	const [highlightedNews, setHighlightedNews] = React.useState<NewsDAO[]>([]);
	const [shouldLoadMore, setShouldLoadMore] = React.useState<boolean>(true);

	const setPaginationButtonVisibility = () => setShouldLoadMore(generator.hasMore);

	const navigate = useNavigate();

	const fetcHighlightedNews = () =>
		highlightedGenerator.manyForLanguage(language, "highlighted=true&sort=-date");

	const fetchHighlightedNewsSuccess = (response: NewsDAO[]) => {
		setHighlightedNews(response);
		setPaginationButtonVisibility();
	};

	const fetchRegularNews = () =>
		generator.manyForLanguage(language, "highlighted=false&sort=-date");

	const fetchRegularNewsSuccess = (response: NewsDAO[]) => {
		setRegularNews(previous => [...previous, ...response]);
		setPaginationButtonVisibility();
	};

	useGeneratorEffect(
		{
			effect: fetcHighlightedNews,
			onSuccess: fetchHighlightedNewsSuccess,
			callback: highlightedGenerator.abort
		},
		[language]
	);

	useGeneratorEffect(
		{
			effect: fetchRegularNews,
			onSuccess: fetchRegularNewsSuccess,
			callback: generator.abort
		},
		[language]
	);

	const convertDate = (timeString: string): string | null =>
		DateTime.fromISO(timeString).toFormat("dd/MM/yyyy");

	const navigateToNews = ({ date, title }: NewsDAO) => {
		navigate(`${date}/${title}`);
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};

	const handleNextClick = () => fetchRegularNews().then(fetchRegularNewsSuccess);

	const newsListProps = {
		passNews: navigateToNews,
		convertDate
	};

	const pageContentProps = {
		news: regularNews,
		highlightedNews,
		shouldLoadMore,
		handleNextClick,
		newsListProps
	};

	return (
		<SentryRoutes>
			<Route index element={<NewsListPageContent {...pageContentProps} />} />
			<Route
				path=":date/:title"
				element={<NewsInspiredContainer passNews={navigateToNews} />}
			/>
			<Route key={"catch"} path="*" element={<NotFoundPage showSidebar={true} />} />
		</SentryRoutes>
	);
};
