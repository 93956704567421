import { flags } from "./flags-dictionary";

// TODO: use LanguageEnum here
export const languageDetails: Record<string, { nativeName: string; flag: JSX.Element }> =
	{
		tr: {
			nativeName: "Türkçe",
			flag: flags.tr
		},
		en: {
			nativeName: "English",
			flag: flags.en
		}
	};
