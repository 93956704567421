import { Box, Stack, useTheme } from "@mui/material";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import {
	CashImageDesktop,
	CollectionImageDesktop,
	CashImageMobile,
	CollectionImageMobile,
	ServicesLogo
} from "../components";
import { LocalizedGenericBody } from "../elements";

export const ServiceItem: React.FC<{
	localizationKeys: { title: string; text: string };
	image: JSX.Element;
}> = ({ localizationKeys, image }) => {
	const theme = useTheme();
	const { isMobile, isTablet } = useResponsive();

	const textPaddings = isMobile
		? { paddingTop: "0.750rem" }
		: { paddingY: "1.00rem", paddingLeft: "1.44rem", paddingRight: "0.500rem" };

	return (
		<Stack
			direction={{ xs: "column", md: "row" }}
			alignItems="center"
			justifyContent="flex-start"
			sx={{
				maxWidth: "37.7rem",
				width: { xs: "10.4rem", md: "100%" },
				padding: { xs: "0.750rem", md: 0 },
				background: theme.palette.grey[200],
				borderRadius: { xs: "0.500rem", md: "1.00rem" },
				overflow: "hidden"
			}}
		>
			<Box sx={{ ...textPaddings, order: { xs: 2, md: 1 } }}>
				<LocalizedGenericBody
					variants={{
						mobile: "body3-primary-semi-bold",
						desktop: "body1-primary-semi-bold"
					}}
					localizationKey={localizationKeys.title}
					sx={{ paddingBottom: "0.250rem", textAlign: { xs: "center", md: "left" } }}
				/>
				<LocalizedGenericBody
					variants={{ mobile: "body4-primary", desktop: "body3-primary" }}
					localizationKey={localizationKeys.text}
					sx={{ textAlign: { xs: "center", md: "left" } }}
				/>
			</Box>
			{isTablet && !isMobile ? null : <Box sx={{ order: { xs: 1, md: 2 } }}>{image}</Box>}
		</Stack>
	);
};

export const ServicesContainer: React.FC = () => {
	const { isMobile } = useResponsive();

	const [CashImage, CollectionImage] = isMobile
		? [CashImageMobile, CollectionImageMobile]
		: [CashImageDesktop, CollectionImageDesktop];

	return (
		<Stack spacing={2} paddingBottom="3.50rem">
			<LocalizedGenericBody
				variants={{ mobile: "h7-primary", desktop: "h4-primary" }}
				localizationKey="services_title_text"
				sx={{ textAlign: { xs: "center", md: "left" } }}
			/>
			<Stack
				direction="row"
				justifyContent={{ xs: "center", lg: "space-between" }}
				gap="0.563rem"
			>
				<ServiceItem
					localizationKeys={{
						title: "services_cash_management_title",
						text: "services_cash_management_text"
					}}
					image={<CashImage />}
				/>
				{isMobile ? null : (
					<Box sx={{ alignSelf: "center" }}>
						<ServicesLogo />
					</Box>
				)}
				<ServiceItem
					localizationKeys={{
						title: "services_collection_management_title",
						text: "services_collection_management_text"
					}}
					image={<CollectionImage />}
				/>
			</Stack>
		</Stack>
	);
};
