import { Box, Paper, Stack } from "@mui/material";
import React from "react";
import { StockPricesEventDAO } from "../../../util/generators/types";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { StockPricesProgress } from "../../../util/theme/styled-components";
import { LayoutWrapper } from "../../containers";
import { LocalizedGenericBody } from "../../elements";
import { HeaderPhoneNumber } from "../items";
import { MakePaymentButton } from "../buttons";
import { StockPricesEventContent } from "../contents";

interface Props {
	event?: StockPricesEventDAO;
	priceError: boolean;
}
export const StockPricesBar: React.FC<Props> = ({ event, priceError }) => {
	const { isMobile } = useResponsive();

	return (
		<Paper variant="bg-grey">
			<LayoutWrapper>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					gap="0.375rem"
					sx={{ paddingY: { xs: "0.5rem", md: "0.625rem" } }}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						gap="0.375rem"
						sx={{ minWidth: { xs: "unset", sm: "18.3rem" } }}
					>
						<HeaderPhoneNumber />
						{isMobile ? null : <MakePaymentButton />}
					</Stack>
					{event ? (
						<Box
							component="a"
							target="_blank"
							href="https://finance.yahoo.com/quote/LIDFA.IS/"
							sx={{
								textDecoration: "none",
								height: "100%"
							}}
						>
							<StockPricesEventContent event={event} />
						</Box>
					) : priceError ? (
						<LocalizedGenericBody
							variant="body3-error-bold"
							localizationKey="stock_prices_error"
						/>
					) : (
						<StockPricesProgress size={"1.25rem"} />
					)}
				</Stack>
			</LayoutWrapper>
		</Paper>
	);
};
