import {
	GenericBody,
	LocalizedGenericBody,
	AdminPutIcon,
	AdminPostIcon,
	AdminDeleteIcon,
	DateWithTimeCellContent,
	ActivityResourceImage,
	DateRangeCellContent
} from "../../views";
import {
	DashboardTableCheckbox,
	DashboardTableChip,
	DashboardTableIndexBox,
	UserInitialsAvatar
} from "../theme/styled-components";
import { Box, IconButton, Stack } from "@mui/material";
import { Check, Close, Delete } from "@mui/icons-material";
import { useAuthContext } from "../contexts/auth.context";
import { useLanguageContext } from "../contexts/language.context";
import { FilterOption } from "../../views/types";
import { ColorDictionary } from "../dictionaries/color.dictionary";
import { AdminActivitiesIconDictionary } from "../dictionaries/admin-activities-icon.dictionary";
import { UserRoleEnum } from "../dictionaries/types";
import { Identifiable } from "./types";
import { TableEnum } from "../types/dashboard-table.types";

import CalendarIcon from "../../assets/images/document-select-icon.svg";
import ClockIcon from "../../assets/images/clock-icon.svg";
import { useTableContext } from "../contexts/table.context";
import { useResponsive } from "../hooks/useResponsive.hook";

export const chipVariants = [
	"primary",
	"secondary",
	"tertiary",
	"quaternary",
	"quinary",
	"senary",
	"septenary"
] as const;

export const userRoleChipLookup = {
	client: "primary",
	admin: "secondary",
	employee: "tertiary"
} as const;

const nonRedeemableStatusCheckKey = ["4", "5", "7", "P", "S", "T"].join(",");
const inCollectionStatusCheckKey = ["0", "1", "A", "B"].join(",");
const collectedStatusCheckKey = ["2", "3"].join(",");
const returnedStatusCheckKey = ["6", "D", "E"].join(",");

export const checkStatusChipLookup = {
	[nonRedeemableStatusCheckKey]: "primary",
	[inCollectionStatusCheckKey]: "secondary",
	[collectedStatusCheckKey]: "tertiary",
	[returnedStatusCheckKey]: "quaternary"
} as const;

type ContentGetter = (
	value: any,
	element: Identifiable,
	possibleValues: FilterOption[]
) => JSX.Element;

const actions: Record<
	string,
	{ localizationKey: string; Icon?: () => JSX.Element; color: string }
> = {
	PUT: {
		localizationKey: "admin_dashboard_table_action_put",
		Icon: () => <AdminPutIcon />,
		color: "tertiary.main"
	},
	POST: {
		localizationKey: "admin_dashboard_table_action_post",
		Icon: () => <AdminPostIcon />,
		color: "secondary.main"
	},
	DELETE: {
		localizationKey: "admin_dashboard_table_action_delete",
		Icon: () => <AdminDeleteIcon />,
		color: "error.main"
	},
	PUBLISH: {
		localizationKey: "admin_dashboard_table_action_publish",
		color: "success.main"
	},
	DEFAULT: {
		localizationKey: "admin_dashboard_table_action_default",
		color: "primary.main"
	}
};

export const DashboardBodyCellContentAdaptor: Record<string, ContentGetter> = {
	adminActivity: value => {
		const selectedValue = actions[value] ?? actions.DEFAULT;
		const Icon = selectedValue.Icon ? (
			selectedValue.Icon()
		) : (
			<Box sx={{ height: "0.8125rem", width: "0.8125rem" }} />
		);

		return (
			<Stack direction="row" alignItems="center" spacing="0.5rem">
				{Icon}
				<LocalizedGenericBody
					variant="body3-primary"
					localizationKey={selectedValue.localizationKey}
					sx={{ color: selectedValue.color }}
				/>
			</Stack>
		);
	},
	adminActivityUserInformation: (value = "--- ---") => {
		const [firstName, lastName = "---"] = value.split(" ");

		return (
			<Stack direction="row" spacing="0.75rem" alignItems="center">
				<UserInitialsAvatar sx={{ width: "2rem", height: "2rem" }}>
					<GenericBody
						variant="body4-secondary-semi-bold"
						text={firstName[0] + lastName[0]}
					/>
				</UserInitialsAvatar>
				<GenericBody variant="body2-primary-medium" text={value} />
			</Stack>
		);
	},
	boolean: value => {
		const Image = !!value ? Check : Close;
		const imageProps = { fontSize: "small", color: "primary" } as const;

		return <Image {...imageProps} />;
	},
	chip: value => {
		const variant =
			Object.entries(checkStatusChipLookup).find(([key]) =>
				key.split(",").includes(value)
			)?.[1] || "primary";

		return (
			<DashboardTableChip variant={variant}>
				<LocalizedGenericBody
					localizationKey={`table_${TableEnum.checks}_checkStatus_${value}`}
					lineClamp={1}
				/>
			</DashboardTableChip>
		);
	},
	currency: value => (
		<GenericBody
			variant="body3-primary"
			text={"₺" + value.replace(/\./g, ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
		/>
	),
	dateRange: value => <DateRangeCellContent value={value} />,
	oneYearDateRange: value => <DateRangeCellContent value={value} />,
	dateRangeWithTime: value => {
		const { language } = useLanguageContext();

		return (
			<Box>
				<DateWithTimeCellContent
					text={value.toLocaleDateStringFormat(language)}
					imageSrc={CalendarIcon}
				/>
				<DateWithTimeCellContent text={value.toISOTimeFormat()} imageSrc={ClockIcon} />
			</Box>
		);
	},
	delete: () => (
		<IconButton>
			<Delete />
		</IconButton>
	),
	index: value => (
		<DashboardTableIndexBox>
			<GenericBody variant="body4-primary" text={value} />
		</DashboardTableIndexBox>
	),
	resourceName: value => (
		<Stack direction="row" spacing="0.75rem">
			<ActivityResourceImage imageSrc={AdminActivitiesIconDictionary[value]} />
			<LocalizedGenericBody
				localizationKey={`table_${TableEnum.adminActivities}_resourceName_${value}`}
				variant="body2-primary-medium"
			/>
		</Stack>
	),
	select: (_, { id }) => {
		const { selectedElements } = useTableContext();
		const elementIsSelected = selectedElements.some(
			({ id: selectedId }) => selectedId === id
		);

		return <DashboardTableCheckbox checked={elementIsSelected} />;
	},
	text: value => (
		<GenericBody
			variant="body3-primary"
			text={value}
			sx={{
				maxWidth: "18rem",
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap"
			}}
		/>
	),
	userInformation: (_, { firstName, lastName, id, active }) => {
		const { isMobile } = useResponsive();
		const { user } = useAuthContext();

		firstName = firstName ?? "---";
		lastName = lastName ?? "---";

		const isActiveUser = user?.id === id;

		const isDeactivated = !active;

		const [avatarStyles, avatarTextStyles] = isDeactivated
			? [{ background: ColorDictionary["#e2e2e2"] }, { color: "chip.deactivated" }]
			: [undefined, undefined];

		return (
			<Stack direction="row" spacing="1rem" alignItems="center">
				{isMobile ? null : (
					<UserInitialsAvatar sx={avatarStyles}>
						<GenericBody
							variant="body3-secondary-semi-bold"
							text={firstName[0] + lastName[0]}
							sx={avatarTextStyles}
						/>
					</UserInitialsAvatar>
				)}
				<Stack
					direction={{ xs: "column", md: "row" }}
					spacing="0.5rem"
					alignItems={{ xs: "left", md: "center" }}
				>
					<Stack direction="row" alignItems="center">
						<GenericBody
							variant="body2-primary-medium"
							text={`${firstName} ${lastName}`}
						/>
						{isActiveUser ? (
							<>
								<GenericBody variant="body2-primary-medium" text="&nbsp;" />
								<LocalizedGenericBody
									variant="body2-primary-medium"
									localizationKey="user_table_active_user_indicator"
								/>
							</>
						) : null}
					</Stack>
					{isDeactivated ? (
						<DashboardTableChip variant="deactivated" sx={{ width: "5rem" }}>
							<LocalizedGenericBody localizationKey="user_table_deactivated_user_indicator" />
						</DashboardTableChip>
					) : null}
				</Stack>
			</Stack>
		);
	},
	userRoles: (value = [], { active }) => (
		<Stack direction="row" spacing="0.38rem">
			{[...value].map((userRole: UserRoleEnum, index) => (
				<DashboardTableChip
					key={index}
					variant={!active ? "deactivated" : userRoleChipLookup[userRole]}
					sx={{ width: "4rem" }}
				>
					<LocalizedGenericBody
						localizationKey={`table_${TableEnum.users}_userRoles_${userRole}`}
					/>
				</DashboardTableChip>
			))}
		</Stack>
	)
};
