import { Route } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { UsernameLoginContainer, VerificationCodeLoginContainer } from "./views";

const ClientDashboard = () => (
	<Dashboard userRole="client">
		<Route path="/login">
			<Route index element={<UsernameLoginContainer />} />
			<Route path=":loginId" element={<VerificationCodeLoginContainer />} />
		</Route>
	</Dashboard>
);
export default ClientDashboard;
