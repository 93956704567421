import { Box, Container } from "@mui/material";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { useScreenContext } from "../../../util/contexts/screen.context";
import { pxToRem } from "../../../util/converters/px-to-rem.converter";

interface Props {
	withSidebar?: boolean;
	sx?: Record<string, any>;
	leftXXLIndent?: number | string;
	id?: string;
}

const WIDTH = {
	SCREEN_WIDTH: 1440,
	XL: 1296,
	LG: 1296
};

const SIDEBAR_WIDTH_LARGE = 306;
const SIDEBAR_WIDTH_SMALL = 240;
const LAYOUT_PADDING = 72;

const PAGE_MARGIN = {
	MD: SIDEBAR_WIDTH_SMALL + LAYOUT_PADDING,
	LG: SIDEBAR_WIDTH_SMALL + LAYOUT_PADDING,
	XL: SIDEBAR_WIDTH_LARGE + LAYOUT_PADDING,
	XXL: SIDEBAR_WIDTH_LARGE
};

const getPaddings = (withSidebar: boolean, leftXXLIndent?: number | string) => ({
	pl: {
		xs: "1rem",
		md: "2rem",
		lg: withSidebar ? "6rem" : "4.5rem",
		xxl: leftXXLIndent ? leftXXLIndent : withSidebar ? "6rem" : "4.5rem"
	},
	pr: {
		xs: "1rem",
		md: "3.125rem",
		lg: pxToRem((WIDTH.SCREEN_WIDTH - WIDTH.LG) / 2),
		xl: pxToRem((WIDTH.SCREEN_WIDTH - WIDTH.XL) / 2),
		xxl: 0
	}
});

export const MainLayoutPaddingBox: React.FC<{
	styles: Record<string, any>;
	withSidebar: boolean;
	leftXXLIndent?: string | number;
}> = ({ withSidebar, leftXXLIndent, styles, children }) => {
	const paddings = getPaddings(withSidebar, leftXXLIndent);
	return (
		<Box {...paddings} {...styles}>
			{children}
		</Box>
	);
};

export const LayoutWrapper: React.FC<Props> = ({
	withSidebar = false,
	sx = {},
	children,
	leftXXLIndent
}) => {
	const { isMobile } = useResponsive();
	const marginLeft = isMobile
		? 0
		: {
				md: pxToRem(PAGE_MARGIN.MD),
				lg: pxToRem(PAGE_MARGIN.LG),
				xl: pxToRem(PAGE_MARGIN.XL),
				xxl: pxToRem(PAGE_MARGIN.XXL)
		  };

	const ml = withSidebar ? marginLeft : 0;

	return (
		<Container
			maxWidth="xl"
			disableGutters
			sx={{
				position: "relative",
				...sx
			}}
		>
			<MainLayoutPaddingBox
				styles={{ ml }}
				withSidebar={withSidebar}
				leftXXLIndent={leftXXLIndent}
			>
				{children}
			</MainLayoutPaddingBox>
		</Container>
	);
};

export const OverflowBox: React.FC<Props> = ({
	withSidebar = false,
	sx = {},
	children,
	leftXXLIndent,
	id
}) => {
	const { screenWidth } = useScreenContext();

	const styles = {
		ml:
			screenWidth > WIDTH.SCREEN_WIDTH
				? pxToRem((screenWidth - WIDTH.SCREEN_WIDTH) / 2)
				: 0,
		maxWidth: pxToRem(WIDTH.SCREEN_WIDTH)
	};

	return (
		<Box
			id={id}
			sx={{
				width: "100%",
				overflow: "scroll",
				msOverflowStyle: "none",
				scrollbarWidth: "none",
				scrollBehavior: "smooth",
				"&::-webkit-scrollbar": { display: "none" },
				...sx
			}}
		>
			<MainLayoutPaddingBox
				styles={styles}
				withSidebar={withSidebar}
				leftXXLIndent={leftXXLIndent}
			>
				{children}
			</MainLayoutPaddingBox>
		</Box>
	);
};
