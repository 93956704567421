import { Close } from "@mui/icons-material";
import { Dialog, IconButton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { AnnouncementsDAO } from "../../../util/generators/types";
import {
	CookieSnackbar,
	PopupAnnouncementBox,
	WhiteClearIcon
} from "../../../util/theme/styled-components";
import { GenericBody } from "../../elements";

interface PopupProps {
	handleDialogClose: () => void;
	announcement: AnnouncementsDAO;
	dialogOpen: boolean;
}

export const PopupAnnouncementDialog: React.FC<PopupProps> = ({
	handleDialogClose,
	announcement: { title, date, content },
	dialogOpen
}) => {
	const { language } = useLanguageContext();

	const displayDate = DateTime.fromISO(date)
		.setLocale(language)
		.toLocaleString(DateTime.DATE_FULL);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleDialogClose}
			fullWidth={true}
			maxWidth="md"
			transitionDuration={750}
		>
			<PopupAnnouncementBox>
				<Typography variant="h5-white">{title}</Typography>
				<Typography variant="h6-white" mb={2}>
					{displayDate}
				</Typography>
				<Typography variant="body1-white">{content}</Typography>
				<IconButton
					onClick={handleDialogClose}
					sx={{ position: "absolute", right: "1.88rem", top: "1.25rem" }}
				>
					<WhiteClearIcon />
				</IconButton>
			</PopupAnnouncementBox>
		</Dialog>
	);
};

interface StickyProps {
	handleDialogClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
	announcement: AnnouncementsDAO;
	dialogOpen: boolean;
}

export const StickyAnnouncement: React.FC<StickyProps> = ({
	handleDialogClose,
	announcement: { title, content },
	dialogOpen
}) => (
	<CookieSnackbar
		open={dialogOpen}
		onClose={handleDialogClose}
		message={
			<>
				<GenericBody variant="subtitle1" text={title} />
				<GenericBody variant="body2" text={content} />
			</>
		}
		action={
			<IconButton onClick={handleDialogClose}>
				<Close />
			</IconButton>
		}
	/>
);
