import { List, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { LanguageCheckmark } from "../../../assets/images/localizations/language-checkmark";
import { languageDetails } from "../../../assets/images/localizations/language-details";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { LanguageEnum } from "../../../util/dictionaries/types";
import { PageDAO } from "../../../util/generators/types";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import {
	MobileLanguageSelect,
	MobileCheckbox,
	MobileFlag,
	MobileFlagAndLanguage,
	SideMenuItemText
} from "../../../util/theme/styled-components";
interface Props {
	closeDrawer: () => void;
	pages: PageDAO[];
}

export const SideMenuSectionList: React.FC<Props> = ({ pages, closeDrawer }) => {
	return (
		<List>
			{pages.map(({ id, name, route }) => (
				<SideMenuPageItem
					key={id}
					id={id}
					name={name}
					route={route}
					closeDrawer={closeDrawer}
				/>
			))}
		</List>
	);
};

interface SideMenuPageItemProps {
	id: string;
	name: string;
	route: string;
	closeDrawer: () => void;
}

export const SideMenuPageItem: React.FC<SideMenuPageItemProps> = ({
	id,
	name,
	route,
	closeDrawer
}) => {
	const { navigate } = useLocalizedNavigate();
	const handleClick = () => {
		navigate(route[0] === "/" ? route : `/${route}`);
		closeDrawer();
	};

	return (
		<ListItemButton key={id} onClick={handleClick}>
			<SideMenuItemText id={id} primary={name} />
		</ListItemButton>
	);
};

interface SideMenuLanguageItemProps {
	language: LanguageEnum;
	handleClick: (language: LanguageEnum) => void;
}

export const SideMenuLanguageItem: React.FC<SideMenuLanguageItemProps> = ({
	language,
	handleClick
}) => {
	const { language: selectedLanguage } = useLanguageContext();

	return (
		<MobileLanguageSelect key={language} onClick={() => handleClick(language)}>
			<MobileFlagAndLanguage>
				<MobileFlag>{languageDetails[language].flag}</MobileFlag>
				<Typography variant="body2-primary-bold">
					{languageDetails[language].nativeName}
				</Typography>
			</MobileFlagAndLanguage>
			{language === selectedLanguage ? (
				<MobileCheckbox>{LanguageCheckmark({ fill: "#9CBE73" })}</MobileCheckbox>
			) : null}
		</MobileLanguageSelect>
	);
};
