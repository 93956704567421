import { GenericImageContainer } from "../../elements";
import emptyStateImageSrc from "../../../assets/images/assets-empty-state.svg";

export const AssetsEmptyViewImage: React.FC = () => (
	<GenericImageContainer
		imageSrc={emptyStateImageSrc}
		imageAlt="no-assets"
		width="10.3rem"
		height="8.3rem"
	/>
);
