import { AccordionDetails, Stack } from "@mui/material";
import { ArrowDown } from "../images";
import {
	DashboardSidebarUserItemAccordion,
	DashboardSidebarUserItemAccordionSummary,
	UserInitialsAvatar
} from "../../../util/theme/styled-components";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import { UserRoleEnum } from "../../../util/dictionaries/types";
import { DashboardSidebarLogoutButton } from "../buttons";
import { DialogKey } from "../../containers/types";
import { DashboardSidebarUserMenuItem } from "./menu.item";

import logoutIcon from "../../../assets/images/dashboard/dashboard_user_logout_icon.svg";
import userPageIcon from "../../../assets/images/dashboard/dashboard_user_page_icon.svg";
import resetPasswordIcon from "../../../assets/images/dashboard/dashboard_reset_password_icon.svg";
import adminActivitiesIcon from "../../../assets/images/dashboard/dashboard-admin-activities.icon.svg";
import syncClientsIcon from "../../../assets/images/dashboard/dashboard-sync-clients.icon.svg";
import syncClientsSyncedIcon from "../../../assets/images/dashboard/dashboard-sync-clients-synced.icon.svg";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { truncateString } from "../../../util/converters/string.converter";
import React from "react";
import { SyncClientsTooltip } from "../tooltips";

interface Props {
	setActiveRoute: (route: string) => void;
	handleDialogToggle: (dialogKey: DialogKey) => void;
	setExpanded: (expanded: boolean) => void;
	areClientsSynced: boolean;
	syncInProgress: boolean;
	expanded: boolean;
	userRole: UserRoleEnum;
	name: string | undefined;
}

export const DashboardSidebarUserItem: React.FC<Props> = ({
	setActiveRoute,
	handleDialogToggle,
	setExpanded,
	areClientsSynced,
	syncInProgress,
	expanded,
	userRole,
	name = "--- ---"
}) => {
	const { isOpen } = useSidebarContext();

	const expandDisabled = userRole === "client";

	const expandIcon = expandDisabled ? (
		<DashboardSidebarLogoutButton handleClick={() => handleDialogToggle("logout")} />
	) : (
		<ArrowDown />
	);

	const isUserInitialsButton = expandDisabled && !isOpen;

	const [firstName, ...rest] = name.split(" ");
	const lastName = rest.pop();
	const truncatedName = truncateString(name, 20);

	const buttonUserInitialsProps = isUserInitialsButton
		? { onClick: () => handleDialogToggle("logout") }
		: {};

	const syncClientsProps = areClientsSynced
		? {
				imageSrc: syncClientsSyncedIcon,
				localizationKey: "dashboard_sidebar_syncClients_synced_text",
				endIcon: undefined
		  }
		: syncInProgress
		? {
				imageSrc: syncClientsIcon,
				localizationKey: "dashboard_sidebar_syncClients_inProgress_text",
				endIcon: undefined
		  }
		: {
				imageSrc: syncClientsIcon,
				localizationKey: "dashboard_sidebar_syncClients_text",
				endIcon: <SyncClientsTooltip />
		  };

	return (
		<DashboardSidebarUserItemAccordion
			disableGutters
			expanded={expandDisabled ? false : expanded}
			onChange={(_, expanded) => setExpanded(expanded)}
			onBlur={() => setTimeout(() => setExpanded(false), 300)}
		>
			<DashboardSidebarUserItemAccordionSummary
				data-cy="admin-actions-menu"
				expandIcon={isOpen ? expandIcon : undefined}
				$expanddisabled={expandDisabled}
			>
				<Stack direction="row" spacing={"1rem"} alignItems="center">
					<UserInitialsAvatar $button={isUserInitialsButton} {...buttonUserInitialsProps}>
						<GenericBody
							variant="body1-secondary-semi-bold"
							text={firstName[0] + (lastName ? lastName[0] : firstName[1])}
						/>
					</UserInitialsAvatar>
					{isOpen ? (
						<Stack
							direction="column"
							sx={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis"
							}}
						>
							<GenericBody variant="body1-primary-semi-bold" text={truncatedName} />
							<LocalizedGenericBody
								localizationKey={`dashboard_sidebar_${userRole}_userRole_text`}
								variant="body3-primary-medium"
								sx={{ opacity: "0.5" }}
							/>
						</Stack>
					) : null}
				</Stack>
			</DashboardSidebarUserItemAccordionSummary>
			<AccordionDetails
				sx={{ padding: isOpen ? "0.75rem 1.5rem" : "0.75rem 0 0.75rem 1.5rem" }}
			>
				<DashboardSidebarUserMenuItem
					data-cy="admin-activities-item"
					handleClick={() => handleDialogToggle("activities")}
					imageSrc={adminActivitiesIcon}
					localizationKey="dashboard_sidebar_activities_text"
				/>
				<DashboardSidebarUserMenuItem
					data-cy="user-page-item"
					handleClick={() =>
						setActiveRoute("/users?userRoleSubscriptions.userRoleId=admin")
					}
					imageSrc={userPageIcon}
					localizationKey="dashboard_user_page"
				/>
				<DashboardSidebarUserMenuItem
					data-cy="sync-clients-item"
					handleClick={() => handleDialogToggle("syncClients")}
					{...syncClientsProps}
					disabled={areClientsSynced || syncInProgress}
				/>
				<DashboardSidebarUserMenuItem
					data-cy="user-reset-password-item"
					handleClick={() => handleDialogToggle("resetPassword")}
					imageSrc={resetPasswordIcon}
					localizationKey="dashboard_reset_password_page"
				/>
				<DashboardSidebarUserMenuItem
					data-cy="user-logout-item"
					handleClick={() => handleDialogToggle("logout")}
					imageSrc={logoutIcon}
					localizationKey="dashboard_user_logout"
				/>
			</AccordionDetails>
		</DashboardSidebarUserItemAccordion>
	);
};
