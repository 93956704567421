import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../util/contexts/auth.context";
import { LiderClientAuthCommunicator } from "../../../communicators/lider.http.communicator";
import { LoginContainerStack } from "../../../util/theme/styled-components";
import {
	ClientLoginForm,
	FormSnackbar,
	LoginBreadLinks,
	UsernameForm
} from "../../components";
import loginIcon from "../../../assets/images/login-icon.svg";
import { useAuthFormStatus } from "../../../util/hooks/useAuthFormStatus.hook";
import { useQueryString } from "../../../util/hooks/useQueryString.hook";

export const UsernameLoginContainer: React.FC = () => {
	const navigate = useNavigate();
	const { isLoggedIn } = useAuthContext();

	const communicator = new LiderClientAuthCommunicator();

	const [userCode, setUserCode] = React.useState("");
	const { submissionStatus, handleSubmissionError, resetSubmissionStatus } =
		useAuthFormStatus();

	React.useEffect(() => {
		if (isLoggedIn()) navigate("/");
	}, []);

	const { createQueryString } = useQueryString();

	const handleChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => setUserCode(event?.target.value);

	const handleSubmit = async (event?: React.FormEvent) => {
		event?.preventDefault();
		try {
			const response = await communicator.startLogin({ userCode });
			const { phoneNumber, smsVerificationCreatedAt, loginId } = response.data;
			const qs = createQueryString({
				phoneNumber,
				smsVerificationCreatedAt,
				userCode
			});
			const navigateUrl = `${encodeURIComponent(loginId)}?${qs}`;
			navigate(navigateUrl);
		} catch (error: any) {
			handleSubmissionError(error);
		}
	};

	return (
		<LoginContainerStack backgroundimage={loginIcon} spacing="2rem">
			<ClientLoginForm
				element={
					<>
						<UsernameForm
							handleChange={handleChange}
							handleSubmit={handleSubmit}
							submissionStatus={submissionStatus}
							value={userCode}
						/>
						<FormSnackbar
							autoHideDuration={5000}
							handleClose={resetSubmissionStatus}
							submissionStatus={submissionStatus}
							formVariant="username-login"
						/>
					</>
				}
			/>
			<LoginBreadLinks />
		</LoginContainerStack>
	);
};
