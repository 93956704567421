import { Checkbox, styled } from "@mui/material";

export const DashboardTableCheckbox = styled(Checkbox)<{ variant?: "white" }>(
	({ theme, variant }) => ({
		path: { fill: variant ? theme.palette.common.white : theme.palette.primary.main },
		width: "1rem",
		height: "1rem",
		svg: {
			width: "1rem",
			height: "1rem"
		}
	})
);
