import { Snackbar, styled } from "@mui/material";
import { ColorDictionary } from "../../dictionaries/color.dictionary";
import { SubmissionStatusEnum } from "../../../views/containers/types";

export const CookieSnackbar = styled(Snackbar)(({ theme }) => ({
	".MuiPaper-root": {
		background: `linear-gradient(180deg, ${theme.palette.primary.light}cc 0%, ${theme.palette.tertiary.light}cc 100%) !important`
	}
}));

export const FormSubmissionSnackbar = styled(Snackbar)<{
	variant: SubmissionStatusEnum;
	offset: string;
}>(({ variant, offset, theme }) => ({
	left: `calc(50% + ${offset}) !important`,
	transform: `translateX(calc(-50% - calc(${offset}/2))) !important`,
	width: `calc(100% - calc(${offset} * 2))`,

	[theme.breakpoints.down("md")]: {
		width: "90%",
		left: "50% !important",
		transform: "translateX(-50%) !important",

		".MuiSnackbarContent-message": {
			padding: 0,
			".MuiStack-root": {
				paddingRight: 0,
				paddingLeft: "0.5rem"
			}
		},

		".MuiSnackbarContent-action": {
			paddingRight: "0.5rem !important"
		}
	},

	"> .MuiPaper-root": {
		width: "100%",
		maxWidth: "64rem",
		padding: 0,
		flexWrap: "nowrap",

		background:
			variant === SubmissionStatusEnum.success ||
			variant === SubmissionStatusEnum.inProgress
				? ColorDictionary.green50
				: ColorDictionary.red50,
		".MuiSnackbarContent-message": {
			padding: 0
		},

		".MuiSnackbarContent-action": {
			marginRight: 0,
			paddingRight: "1rem"
		}
	}
}));
