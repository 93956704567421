import React from "react";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { LiderImageContainer } from "../../../util/theme/styled-components";
import { GenericImageContainer } from "../../elements";
import { Box } from "@mui/material";

export const LiderLogo: React.FC = () => {
	const { isMobile } = useResponsive();
	const { logo, logoMobile } = ImageDictionary.Home;
	const { imageAlt, imageSrc } = isMobile ? logoMobile : logo;
	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			component={LiderImageContainer}
			width={{ xs: "21.4rem", md: "37.5rem" }}
			height={{ xs: "14.9rem", md: "26.1rem" }}
		/>
	);
};

export const LiderLoginLogo: React.FC = () => {
	const { isMobile } = useResponsive();

	const {
		logo: { imageSrc }
	} = ImageDictionary.Header;

	const [height, width] = isMobile ? ["5rem", "10.5rem"] : ["6.25rem", "13.125rem"];

	return (
		<Box height={height} width={width}>
			<img
				style={{ height: "100%", width: "auto" }}
				src={imageSrc}
				alt="lider-login-logo"
			/>
		</Box>
	);
};
