import { Theme } from "@mui/material";

declare module "@mui/material/Fab" {
	interface FabPropsVariantOverrides {
		"admin-drawer": true;
	}
}

export const getMuiFabStyles = (theme: Theme) => {
	return {
		variants: [
			{
				props: { variant: "admin-drawer" },
				style: {
					background: theme.palette.grey[400],
					position: "fixed",
					left: "0.938rem",
					top: "5.94rem",
					opacity: "0.8",
					zIndex: theme.zIndex.drawer + 1,
					display: "flex"
				}
			}
		]
	};
};
