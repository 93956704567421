import { Grid, Stack } from "@mui/material";
import { AdminFormCreateUpdateButton } from "../buttons";
import {
	AdminNameInput,
	DescriptionInput,
	LanguageSelect,
	DateInput,
	LinkInput
} from "../inputs";
import { FormProps } from "../../../util/types/admin-form.types";

export const AdminPanelAwardsForm: React.FC<FormProps> = ({ handleSubmit }) => (
	<Stack spacing="0.75rem">
		<AdminNameInput />
		<DescriptionInput />
		<Grid container gap="0.75rem">
			<Grid item xs>
				<LanguageSelect />
			</Grid>
			<Grid item xs>
				<DateInput />
			</Grid>
		</Grid>
		<LinkInput />
		<AdminFormCreateUpdateButton handleClick={handleSubmit} />
	</Stack>
);
