import { MenuItem } from "@mui/material";
import { useFormContext } from "../../../util/contexts/form.context";
import { useLanguageContext } from "../../../util/contexts/language.context";
import React from "react";
import { inputs } from "./input-list";
import { DateTime } from "luxon";
import {
	AdminPanelTextField,
	AdminPanelTextarea
} from "../../../util/theme/styled-components";

interface Props {
	index?: number;
	items?: Record<string, any>;
}

type InputFactory = (inputName: string, textArea?: boolean) => React.FC<Props>;

export const InputFactory: InputFactory =
	(inputName, textArea) =>
	({ index, items, ...inputProps }) => {
		const { infoField, localizationKey, type = "text", ...props } = inputs[inputName];

		const { getLocalizedText } = useLanguageContext();
		const text = getLocalizedText(localizationKey);

		const { values, handleChange } = useFormContext();

		const [label, infoFieldProp] = index
			? [`${text} ${index}`, `${infoField}_${index}`]
			: [text, infoField];

		if (items)
			Object.assign(props, {
				children: Object.entries(items).map(entry => (
					<MenuItem key={entry[0]} value={entry[0]}>
						{entry[1]}
					</MenuItem>
				))
			});

		return textArea ? (
			<AdminPanelTextarea
				{...inputProps}
				placeholder={label}
				autoComplete="false"
				onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
					handleChange(infoFieldProp, event)
				}
				value={adjustedValue(values[infoFieldProp], type)}
				minRows={3}
			/>
		) : (
			<AdminPanelTextField
				{...inputProps}
				autoFocus={false}
				autoComplete="false"
				fullWidth={true}
				label={label}
				onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
					handleChange(infoFieldProp, event)
				}
				value={adjustedValue(values[infoFieldProp], type)}
				type={type}
				InputProps={{ disableUnderline: true }}
				variant="filled"
				{...props}
			/>
		);
	};

export const adjustedValue = (value: any, type: string) => {
	if (!value) return "";
	if (type !== "date" && type !== "datetime-local") return value;
	const format = type === "date" ? "yyyy-MM-dd" : "yyyy-MM-ddThh:mm";
	return DateTime.fromISO(value).toFormat(format);
};
