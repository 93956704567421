import headerDots from "../../../assets/images/header-collapse-icon.svg";
import { GenericImageContainer } from "../../elements";

export const HeaderCollapse: React.FC = () => (
	<GenericImageContainer
		imageSrc={headerDots}
		imageAlt="view more sections"
		width="0.75rem"
		height="0.25rem"
	/>
);
