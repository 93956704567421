import React from "react";
import { BarChart } from "./bar.chart";
import { Box, Stack } from "@mui/material";
import { LocalizedGenericBody } from "../../elements";

export const ClientHomepageBarChart: React.FC = () => {
	const containerRef = React.useRef<null | HTMLDivElement>(null);

	return (
		<Box width="100%" px={{ md: "1.5rem" }}>
			<Stack ref={containerRef} spacing="1rem" width="100%" sx={{ overflow: "hidden" }}>
				<LocalizedGenericBody
					variant="body4-primary-semi-bold"
					localizationKey="client_pie-chart_y-label"
				/>
				<BarChart containerRef={containerRef} />
			</Stack>
		</Box>
	);
};
