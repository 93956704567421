import React from "react";
import { ClientInformationDAO } from "../../util/generators/types";
import { CompanyInformationDisclaimerRow, CompanyInformationTable } from "../components";
import { useAuthContext } from "../../util/contexts/auth.context";
import { ClientInformationClientCommunicator } from "../../communicators/lider.http.communicator";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { Stack } from "@mui/material";

export const CompanyInformationContainer: React.FC = () => {
	const { user } = useAuthContext();

	const communicator = new ClientInformationClientCommunicator();

	const [companyInformation, setCompanyInformation] =
		React.useState<ClientInformationDAO | null>(null);

	const extractDataAndSetInformation = (result: { data?: ClientInformationDAO }) => {
		if (!result || !result.data) return;
		setCompanyInformation(result.data);
	};

	const generatorMethods = user
		? {
				effect: () => communicator.findOne(),
				onSuccess: extractDataAndSetInformation,
				callback: communicator.abort
		  }
		: {
				effect: async () => {
					return;
				},
				onSuccess: () => {
					return;
				},
				callback: () => {
					return;
				}
		  };

	useGeneratorEffect(generatorMethods, []);

	return companyInformation ? (
		<Stack spacing="1.5rem" pt="0.25rem">
			<CompanyInformationTable companyInformation={companyInformation} />
			<CompanyInformationDisclaimerRow />
		</Stack>
	) : null;
};
