import { useTheme } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { useLinkProps } from "../../../util/hooks/useLinkProps.hook";
import { LeftAlignedMenuItem } from "../../../util/theme/styled-components";
import { GenericBody } from "../../elements";

interface Props {
	name: string;
	route: string;
	variant?: "dark" | "light";
}

export const FooterSectionItem: React.FC<Props> = ({ name, route, variant }) => {
	const theme = useTheme();

	const { getLinkProps } = useLinkProps();
	const linkProps = getLinkProps(route);

	return (
		<NavLink {...linkProps} reloadDocument={true} style={{ textDecoration: "none" }}>
			<LeftAlignedMenuItem>
				<GenericBody
					variant={variant === "dark" ? "body3-white" : "body3-primary"}
					text={name}
					sx={{
						"&:hover": { color: theme.palette.tertiary.main }
					}}
				/>
			</LeftAlignedMenuItem>
		</NavLink>
	);
};
