import { Stack } from "@mui/material";
import { GenericBody } from "../../elements";
import { SearchItemHeadingProps } from "../types";

export const SearchItemHeading: React.FC<SearchItemHeadingProps> = ({ text, Icon }) => {
	return (
		<Stack direction="row" spacing="0.5rem" alignItems="center">
			<Icon />
			<GenericBody text={text} variant="body3-primary-medium" />
		</Stack>
	);
};
