import React from "react";
import { DashboardBodyCellContentAdaptor } from "../../../util/adaptors/dashboard-body-cell-content.adaptor";
import { DashboardTableBodyRow as BodyRow } from "../../../util/theme/styled-components";
import { Identifiable } from "../../../util/adaptors/types";
import { DashboardTableBodyCell, DashboardTableBodyRowEndCell } from "../cells";
import { useTableContext } from "../../../util/contexts/table.context";
import { DashboardTableConfig } from "../../../util/configs/dashboard-table";

interface BodyProps {
	element: Identifiable;
}

export const DashboardTableBodyRow: React.FC<BodyProps> = ({ element }) => {
	const { handleSelectElement, selectedElements, table } = useTableContext();

	const columns = DashboardTableConfig[table].visible;

	const cells = columns.map(({ action, displayBy, type, filterOptions }, index) => {
		const cellValue = element[displayBy] ?? "---";
		const rowEnd = index === columns.length - 1;
		const Cell = rowEnd ? DashboardTableBodyRowEndCell : DashboardTableBodyCell;
		return (
			<Cell
				handleClick={() => (action ? handleSelectElement(element, action) : null)}
				Content={DashboardBodyCellContentAdaptor[type](cellValue, element, filterOptions)}
				key={index}
			/>
		);
	});

	const isDeactivated = Object.keys(element).includes("active") && !element.active;
	const elementIsSelected = selectedElements.some(
		({ id: selectedId }) => selectedId === element.id
	);

	return (
		<BodyRow hover $isDeactivated={isDeactivated} $isselected={elementIsSelected}>
			{cells}
		</BodyRow>
	);
};
