import { Column } from "./column";
import { FilterableColumnArgs } from "./types";
import { FilterOption } from "../../../views/types";
import { LocalizationObject } from "../../contexts/types";
import { DateTime } from "luxon";

export class FilterableColumn extends Column {
	allowMultipleSelection = true;

	selectedFilterValues: string[] = [];
	filterable = true;

	constructor({ filterableColumnArgs = {}, ...columnArgs }: FilterableColumnArgs) {
		super(columnArgs);
		Object.assign(this, filterableColumnArgs);
	}

	_createFilterOptions: () => FilterOption[] = () =>
		this.possibleValues.map(option => ({
			displayValue: option,
			requestValues: [option]
		}));

	_createLocalizedFilterOptions: (
		localizationObject: LocalizationObject,
		variableLocalizationKey: string
	) => FilterOption[] = (localizationObject, variableLocalizationKey) => {
		const optionsLocalizedTextMap = this.possibleValues.reduce(
			this._createOptionGroupsByLocalizedTexts(
				localizationObject,
				variableLocalizationKey
			),
			{}
		);
		return Object.entries(optionsLocalizedTextMap).map(
			([displayValue, requestValues]: any) => ({
				displayValue,
				requestValues
			})
		);
	};

	createQuery = () => {
		switch (this.type) {
			case "oneYearDateRange":
				return this._createOneYearDateRangeQuery();
			case "dateRangeWithTime":
			case "dateRange":
				return this._createBetweenQuery();
			default:
				return this._createDefaultQuery();
		}
	};

	createFilterOptions = (localizationObject: LocalizationObject) =>
		(this.filterOptions = this.variableLocalizationKey
			? this._createLocalizedFilterOptions(
					localizationObject,
					this.variableLocalizationKey
			  )
			: this._createFilterOptions());

	_createOptionGroupsByLocalizedTexts =
		(localizationObject: LocalizationObject, variableLocalizationKey: string) =>
		(accumulator: any, option: any) => {
			const localizedText = this._extractLocalizedTextFromOption(
				localizationObject,
				variableLocalizationKey,
				option
			);
			return {
				...accumulator,
				[localizedText]: [...(accumulator[localizedText] ?? []), option]
			};
		};

	_createDefaultQuery = () =>
		this.selectedFilterValues
			.map(value => value.split(","))
			.flat(1)
			.map(value => `${this.requestBy}[$in]=${value}`)
			.join("&");

	_createOneYearDateRangeQuery = () => {
		const selectedValue = this.selectedFilterValues[0];
		const dateTime = DateTime.utc(Number(selectedValue));
		const [start, end] = [
			dateTime.startOf("year").toISO(),
			dateTime.endOf("year").toISO()
		];
		return `${this.requestBy}[$between]=${start}&${this.requestBy}[$between]=${end}`;
	};

	_createBetweenQuery = () =>
		`${this.requestBy}[$between]=${this.selectedFilterValues[0]}&${this.requestBy}[$between]=${this.selectedFilterValues[1]}`;

	_extractLocalizedTextFromOption = (
		localizationObject: LocalizationObject,
		variableLocalizationKey: string,
		option: any
	) => {
		const localizationKey = variableLocalizationKey.replace("<VARIABLE>", option);
		const { [localizationKey]: { text } = { text: "" } } = localizationObject;
		return text;
	};
}
