import { Box, Fab } from "@mui/material";
import { LocalizedButton } from "../../elements";
import { ArrowBack } from "../images";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { ChevronLeft } from "@mui/icons-material";

export const BackToLoginButton: React.FC<{ handleClick: () => void }> = ({
	handleClick
}) => {
	const { isMobile } = useResponsive();

	return (
		<Box
			sx={{
				position: "absolute",
				left: "0.75rem",
				top: "0.5rem"
			}}
		>
			{isMobile ? (
				<MobileBackToLoginButton handleClick={handleClick} />
			) : (
				<DesktopBackToLoginButton handleClick={handleClick} />
			)}
		</Box>
	);
};

export const MobileBackToLoginButton: React.FC<{ handleClick: () => void }> = ({
	handleClick
}) => (
	<Fab
		size="small"
		sx={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			backgroundColor: "transparent",
			boxShadow: "none"
		}}
		onClick={handleClick}
	>
		<ChevronLeft sx={{ color: "primary.main" }} />
	</Fab>
);

export const DesktopBackToLoginButton: React.FC<{ handleClick: () => void }> = ({
	handleClick
}) => (
	<LocalizedButton
		localizationKey="login_back_to_login"
		onClick={handleClick}
		variant="newPrimary-dashboard"
		startIcon={<ArrowBack />}
	/>
);
