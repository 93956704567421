import React from "react";
import { useFormContext } from "../../util/contexts/form.context";
import { useTableContext } from "../../util/contexts/table.context";
import { AdminCmsContainer } from "./admin-cms.container";
import { CmsContainerProps, SubmissionStatus, SubmissionStatusEnum } from "./types";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";
import { UserCreateErrorDialog, UserCreateSuccessDialog } from "../components";

export const AdminUsersCmsContainer: React.FC<CmsContainerProps> = props => {
	const { formSubmissionStatus } = useFormContext();
	const { selectedElements } = useTableContext();

	const isUserEditted = !!selectedElements.length;

	const [userCreateStatus, setUserCreateStatus] = React.useState<SubmissionStatus>(null);

	useUpdateEffect(() => {
		if (!isUserEditted && formSubmissionStatus) setUserCreateStatus(formSubmissionStatus);
	}, [formSubmissionStatus]);

	const closeUserCreateResponseDialog = () => setUserCreateStatus(null);

	const userCreateSuccess = userCreateStatus === SubmissionStatusEnum.success;
	const userCreateError = userCreateStatus === SubmissionStatusEnum.error;

	const disabledOpenDialog = () => null;

	const propsWithDisabledOpenDialog = { ...props, openDialog: disabledOpenDialog };

	return (
		<>
			<AdminCmsContainer {...propsWithDisabledOpenDialog} />
			<UserCreateErrorDialog
				handleDialogClose={closeUserCreateResponseDialog}
				dialogOpen={userCreateError}
			/>
			<UserCreateSuccessDialog
				handleDialogClose={closeUserCreateResponseDialog}
				dialogOpen={userCreateSuccess}
			/>
		</>
	);
};
