import React from "react";
import { currencyFormatter } from "../../../util/currency.formatter";
import { Stack } from "@mui/material";
import { GenericBody } from "../../elements";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { useChartContext } from "../../../util/contexts/chart.context";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";

export const PieChartContent: React.FC = () => {
	const { chartItems: items, highlightedIndex, totalAmount } = useChartContext();
	const { getLocalizedText } = useLanguageContext();
	const [description, info] =
		highlightedIndex !== null
			? [items[highlightedIndex].label, currencyFormatter(items[highlightedIndex].value)]
			: [
					getLocalizedText("pie_chart_content_total_text"),
					currencyFormatter(totalAmount)
			  ];
	return (
		<Stack spacing="0.3125rem">
			<GenericBody
				variant="body4"
				text={description}
				sx={{ color: ColorDictionary["#6B7280"], fontSize: "0.625rem" }}
				boxStyles={{ textAlign: "center" }}
			/>
			<GenericBody
				variant="body3-primary"
				text={info}
				sx={{ fontWeight: 600 }}
				boxStyles={{ textAlign: "center" }}
			/>
		</Stack>
	);
};
