export const LanguageOptions = [
	"sr",
	"ro",
	"ii",
	"ty",
	"tl",
	"yi",
	"ak",
	"ms",
	"ar",
	"no",
	"oj",
	"ff",
	"fa",
	"sq",
	"ay",
	"az",
	"zh",
	"cr",
	"et",
	"gn",
	"ik",
	"iu",
	"kr",
	"kv",
	"kg",
	"ku",
	"lv",
	"mg",
	"mn",
	"om",
	"ps",
	"qu",
	"sc",
	"sw",
	"uz",
	"za",
	"bi",
	"nb",
	"nn",
	"id",
	"tw",
	"eo",
	"ia",
	"ie",
	"io",
	"vo",
	"bh",
	"he",
	"sa",
	"cu",
	"pi",
	"ae",
	"la",
	"hy",
	"ss",
	"bo",
	"nr",
	"sl",
	"or",
	"nd",
	"na",
	"mi",
	"mr",
	"lu",
	"rn",
	"km",
	"fy",
	"bn",
	"av",
	"ab",
	"aa",
	"af",
	"am",
	"an",
	"as",
	"bm",
	"ba",
	"eu",
	"be",
	"bs",
	"br",
	"bg",
	"my",
	"ca",
	"ch",
	"ce",
	"ny",
	"cv",
	"kw",
	"co",
	"hr",
	"cs",
	"da",
	"dv",
	"nl",
	"dz",
	"en",
	"ee",
	"fo",
	"fj",
	"fi",
	"fr",
	"gl",
	"ka",
	"de",
	"el",
	"gu",
	"ht",
	"ha",
	"hz",
	"hi",
	"ho",
	"hu",
	"ga",
	"ig",
	"is",
	"it",
	"ja",
	"jv",
	"kl",
	"kn",
	"ks",
	"kk",
	"ki",
	"rw",
	"ky",
	"ko",
	"kj",
	"lb",
	"lg",
	"li",
	"ln",
	"lo",
	"lt",
	"gv",
	"mk",
	"ml",
	"mt",
	"mh",
	"nv",
	"ne",
	"ng",
	"oc",
	"os",
	"pa",
	"pl",
	"pt",
	"rm",
	"ru",
	"sd",
	"se",
	"sm",
	"sg",
	"gd",
	"sn",
	"si",
	"sk",
	"so",
	"st",
	"es",
	"su",
	"sv",
	"ta",
	"te",
	"tg",
	"th",
	"ti",
	"tk",
	"tn",
	"to",
	"tr",
	"ts",
	"tt",
	"ug",
	"uk",
	"ur",
	"ve",
	"vi",
	"wa",
	"cy",
	"wo",
	"xh",
	"yo",
	"zu"
] as const;
