import { ThreeCardsPageCard } from "../../../util/theme/styled-components";
import { EditableGenericBody } from "../../elements";
import { AdminEditPageIdProps } from "../../types";

interface Props extends AdminEditPageIdProps {
	title: string;
	body: string;
	background: string;
}

export const ColoredCard: React.FC<Props> = ({
	title,
	titleProperty,
	body,
	bodyProperty,
	background,
	pageId
}) => {
	return (
		<ThreeCardsPageCard sx={{ background }}>
			<EditableGenericBody
				variants={{ desktop: "h3-white", mobile: "h4-white" }}
				text={title}
				propertyKey={titleProperty}
				pageId={pageId}
				sx={{ marginBottom: { xs: "1.00rem", lg: "0.500rem", xl: "1.00rem" } }}
			/>
			<EditableGenericBody
				variant="body2-white"
				text={body}
				propertyKey={bodyProperty}
				pageId={pageId}
			/>
		</ThreeCardsPageCard>
	);
};
