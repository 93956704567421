import { TableContainer, styled } from "@mui/material";
import { ColorDictionary } from "../../dictionaries/color.dictionary";

export const CompanyInformationTableContainer = styled(TableContainer)(({ theme }) => ({
	borderRadius: "0.75rem",

	border: `1px solid ${theme.palette.grey[300]}`,

	boxShadow: `0prem 0.1875rem 1.1875rem 0rem ${ColorDictionary["#cbcbcb36"]}`
}));
