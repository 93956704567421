import { Stack, useTheme } from "@mui/material";
import React from "react";
import {
	DocumentsSearchItem,
	FaqsSearchItem,
	NewsSearchItem,
	BranchSearchItem
} from "../items";

type SearchListFactory = <T>(
	Component: React.FC<{ item: T; setClose: () => void }>,
	spacing?: number
) => React.FC<{ items: T[]; setClose: () => void }>;

const SearchListFactory: SearchListFactory =
	Component =>
	({ items, setClose }) => {
		const theme = useTheme();
		return (
			<Stack
				spacing={1}
				padding={1}
				sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
			>
				{items.map((item, index) => (
					<Component key={index} item={item} setClose={setClose} />
				))}
			</Stack>
		);
	};

export const DocumentsSearchList = SearchListFactory(DocumentsSearchItem);
export const FaqsSearchList = SearchListFactory(FaqsSearchItem);
export const NewsSearchList = SearchListFactory(NewsSearchItem);
export const BranchSearchList = SearchListFactory(BranchSearchItem);
