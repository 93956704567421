import React from "react";
import { Grid } from "@mui/material";
import { ThreeTitledContentPageContent } from "../contents";
import { PageIdProps } from "../../types";
import { AdminEditProps } from "../../containers/types";

interface Content extends AdminEditProps {
	body: string;
	title: string;
	headingVariant?: string;
	headingVariants?: { desktop: string; mobile: string };
}

interface Props extends PageIdProps {
	contents: Content[];
}

export const ThreeTitledContentPageList: React.FC<Props> = ({ contents, pageId }) => {
	const contentsArray = contents.map(
		(
			{ body, bodyProperty, title, titleProperty, headingVariant, headingVariants },
			index
		) => (
			<ThreeTitledContentPageContent
				key={index}
				title={title}
				titleProperty={titleProperty}
				body={body}
				bodyProperty={bodyProperty}
				headingVariant={headingVariant}
				headingVariants={headingVariants}
				pageId={pageId}
			/>
		)
	);

	return (
		<Grid container rowSpacing={{ xs: 3, md: 4 }}>
			{contentsArray}
		</Grid>
	);
};
