import { Stack } from "@mui/material";
import { RecentChecksTableImage as EmptyViewImage } from "../images";
import { LocalizedGenericBody } from "../../elements";
import { useTableContext } from "../../../util/contexts/table.context";

export const EmptyTableViewContent: React.FC = () => {
	const { table } = useTableContext();

	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			height={{ xs: "12rem", md: "16rem" }}
			spacing="0.75rem"
		>
			<EmptyViewImage />
			<LocalizedGenericBody
				variant="body3-primary"
				sx={{ opacity: "0.65" }}
				localizationKey={`table_${table}_empty_state_message`}
			/>
		</Stack>
	);
};
