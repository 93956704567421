import { Stack } from "@mui/material";
import { LocalizedGenericBody } from "../../../elements";
import { LoginUsernameInput } from "../../inputs";
import { LoginButton } from "../../buttons";
import { UsernameProps } from "./types";

export const UsernameForm: React.FC<UsernameProps> = ({
	handleChange,
	handleSubmit,
	submissionStatus,
	value
}) => (
	<Stack
		px={{ xs: "1rem", md: "2.094rem" }}
		spacing={{ xs: "1.5rem", md: "2rem" }}
		alignItems="center"
	>
		<LocalizedGenericBody
			localizationKey="client_login_heading"
			variants={{ mobile: "h4-primary", desktop: "h3-primary" }}
			sx={{ textAlign: "center" }}
		/>
		<Stack
			component="form"
			spacing={{ xs: "1rem", md: "1.5rem" }}
			onSubmit={handleSubmit}
		>
			<LoginUsernameInput
				handleChange={handleChange}
				value={value}
				submissionStatus={submissionStatus}
			/>
			<Stack spacing="0.75rem">
				<LoginButton disabled={!value} />
				<LocalizedGenericBody
					localizationKey="client_login_helper_text"
					variant="body3-primary-medium"
					sx={{
						textAlign: "center",
						paddingX: "1rem",
						opacity: "0.6"
					}}
				/>
			</Stack>
		</Stack>
	</Stack>
);
