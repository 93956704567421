import React from "react";
import { NewsBar } from "../components";
import { DateTime } from "luxon";
import { useLanguageContext } from "../../util/contexts/language.context";
import { usePagesContext } from "../../util/contexts/pages.context";
import { NewsDAO, PageDAO } from "../../util/generators/types";
import { PublicNewsGenerator } from "../../util/generators/public.generator";
import { useTheme } from "@mui/material";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { useResponsive } from "../../util/hooks/useResponsive.hook";

export const NewsBarContainer: React.FC = () => {
	const { language } = useLanguageContext();
	const { pages } = usePagesContext();
	const { palette, typography } = useTheme();
	const { isMobile } = useResponsive();

	const generatorRef = React.useRef(new PublicNewsGenerator());
	const generator = generatorRef.current;

	const [news, setNews] = React.useState<NewsDAO[]>([]);
	const [newsPage, setNewsPage] = React.useState<PageDAO>();

	useGeneratorEffect(
		{
			effect: () => generator.manyForLanguage(language, "&sort=-date"),
			onSuccess: setNews,
			callback: generator.abort
		},
		[language]
	);

	React.useEffect(() => {
		setNewsPage(
			pages.find(page => page.templateName === "newsList" && page.language === language)
		);
	}, [language]);

	const makeText = (title: string, description: string, date: string) => {
		const displayDate = (date: string) =>
			`(${DateTime.fromISO(date).setLocale("en-gb").toLocaleString()})`;

		const createSpan = (style: string, content: string) =>
			`<span style=${style}>${content}</span> `;

		const fontSize = isMobile ? typography.body4.fontSize : typography.body3.fontSize;
		const lineHeight = isMobile
			? typography.body4.lineHeight
			: typography.body3.lineHeight;

		const elementCss = `font-weight:400;font-size:${fontSize};line-height:${lineHeight}`;

		const separator = "    • • • • • •    ";

		return (
			createSpan(elementCss, displayDate(date)) +
			createSpan(elementCss, `${title}: `) +
			createSpan(elementCss, description) +
			createSpan(elementCss, separator)
		);
	};

	const newsDescriptions = news.map(({ title, description, date }: any) => {
		return newsPage
			? `<a
			style="
				font-size: unset;
				text-decoration: none; 
				color: ${palette.common.white}"
			href="${newsPage.route}/${date}/${title}"
			target="_blank">${makeText(title, description, date)}</a>`
			: makeText(title, description, date);
	});

	const newsText = newsDescriptions.join(" ");
	return <NewsBar html={newsText} />;
};
