import { Stack } from "@mui/material";
import React from "react";
import { BranchDAO } from "../../../util/generators/types";
import { BranchDetailsItem } from "../items";

interface Props {
	branches: BranchDAO[];
}

export const BranchDetailsList: React.FC<Props> = ({ branches }) => (
	<Stack spacing={{ xs: 2, md: 4 }}>
		{branches
			.sort((a, b) => (a.highlighted ? -1 : b.highlighted ? 1 : 0))
			.map((branch, index) => (
				<BranchDetailsItem key={index} {...branch} />
			))}
	</Stack>
);
