import { Container, Toolbar } from "@mui/material";
import React from "react";
import { useLanguageContext } from "../util/contexts/language.context";
import { useTemplatesContext } from "../util/contexts/templates.context";
import { FullHeightStack } from "../util/theme/styled-components";
import { DesktopSidebar, StickyAnnouncement } from "./components";
import { Header, NewsBarContainer, StockPricesContainer } from "./";
import { AnnouncementsDAO } from "../util/generators/types";
import { PublicAnnouncementGenerator } from "../util/generators/public.generator";
import { useGeneratorEffect } from "../util/hooks/useGeneratorEffect.hook";

export const PageView: React.FC = ({ children, ...props }) => {
	const { language } = useLanguageContext();

	const { activeTemplateName } = useTemplatesContext();

	const [stickyAnnouncement, setStickyAnnouncement] = React.useState<AnnouncementsDAO>();
	const [dialogOpen, setDialogOpen] = React.useState(
		!localStorage.getItem("cookiePermissionToken")
	);

	const generator = new PublicAnnouncementGenerator();

	const findAndSetAnnouncements = (response: AnnouncementsDAO[]) =>
		setStickyAnnouncement(response.find(({ type }) => type === "sticky"));

	useGeneratorEffect(
		{
			effect: () => generator.manyForLanguage(language),
			onSuccess: findAndSetAnnouncements,
			callback: generator.abort
		},
		[]
	);

	const showBars = activeTemplateName === "homepage";

	const handleDialogClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		localStorage.setItem("cookiePermissionToken", "true");
		setDialogOpen(false);
	};

	return (
		<>
			<Header />
			<FullHeightStack {...props} sx={{ overflowX: "hidden" }}>
				<Container maxWidth="xl" disableGutters sx={{ position: "relative" }}>
					<DesktopSidebar />
				</Container>
				<Toolbar sx={{ height: { xs: "10.4rem", md: "8.00rem", xl: "9.25rem" } }} />
				{showBars ? <NewsBarContainer /> : null}
				{showBars ? <StockPricesContainer /> : null}
				{children}
			</FullHeightStack>
			{stickyAnnouncement ? (
				<StickyAnnouncement
					handleDialogClose={handleDialogClose}
					announcement={stickyAnnouncement}
					dialogOpen={dialogOpen}
				/>
			) : null}
		</>
	);
};
