import { Stack, useTheme } from "@mui/material";
import { FastEditStack } from "../../../util/theme/styled-components";
import {
	FastEditButtonIcon,
	FastEditButtonImage,
	FastEditReturnButtonIcon
} from "../images";
import { LocalizedGenericButton, LocalizedGenericBody } from "../../elements";
import { useAppModeContext } from "../../../util/contexts/app-mode.context";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

export const FastEditButton: React.FC = () => {
	const [hovered, setHovered] = React.useState(false);
	const { setAppMode } = useAppModeContext();
	const navigate = useNavigate();

	const typographyVariant = hovered ? "body2-white-medium" : "body2-secondary-medium";

	const { isOpen } = useSidebarContext();

	const { isTablet } = useResponsive();

	const displayImage = !(!isOpen || isTablet);

	return (
		<FastEditStack
			data-cy="fast-edit-button"
			alignItems="center"
			onClick={() => {
				setAppMode("fast-edit");
				navigate("/");
			}}
			spacing="0.938rem"
			$issidebaropen={isOpen}
			$isimagedisplayed={displayImage}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<Stack
				direction="row"
				alignItems="center"
				spacing="0.875rem"
				sx={{ maxWidth: "100%", overflow: "hidden" }}
			>
				<FastEditButtonIcon hovered={hovered} />
				{isOpen ? (
					<LocalizedGenericBody
						variant={typographyVariant}
						localizationKey="fast_edit_text"
						lineClamp={1}
					/>
				) : null}
			</Stack>
			{displayImage ? <FastEditButtonImage /> : null}
		</FastEditStack>
	);
};

export const FastEditReturnButton: React.FC = () => {
	const { setAppMode } = useAppModeContext();
	const navigate = useNavigate();
	const theme = useTheme();

	const styles = {
		width: "100%",
		background: `linear-gradient(0deg, ${theme.palette.secondary.main}0d, ${theme.palette.secondary.main}0d), ${theme.palette.grey[50]}`,
		border: `1px solid ${theme.palette.secondary.main}94`,
		boxShadow: `0 0 1.1875rem 0.125rem ${theme.palette.secondary.main}29`,
		"&:hover": {
			background: `linear-gradient(0deg, ${theme.palette.secondary.main}1f, ${theme.palette.secondary.main}1f), ${theme.palette.grey[50]}`
		}
	};

	return (
		<LocalizedGenericButton
			data-cy="fast-edit-return-button"
			localizationKey="fast_edit_return_button_placeholder"
			variant="primary"
			textVariant="body2-secondary-medium"
			handleClick={() => {
				setAppMode("admin");
				navigate("/");
			}}
			disableEdit={true}
			sx={styles}
			startIcon={<FastEditReturnButtonIcon />}
		/>
	);
};
