import React from "react";
import { Typography, TypographyProps } from "@mui/material";

export const FadingText = (props: TypographyProps) => {
	const { sx, ...rest } = props;
	const [opacity, setOpacity] = React.useState<number>(0);

	React.useEffect(() => {
		setOpacity(100);
	}, []);

	return (
		<Typography
			{...rest}
			sx={{ transition: "opacity", transitionDuration: "1s", opacity, ...sx }}
		></Typography>
	);
};
