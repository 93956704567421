import { Box, Grid, Stack, Skeleton } from "@mui/material";
import { DashboardBodyCellContentAdaptor } from "../../../util/adaptors/dashboard-body-cell-content.adaptor";
import { Identifiable } from "../../../util/adaptors/types";
import { DashboardTableConfig } from "../../../util/configs/dashboard-table";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { useTableContext } from "../../../util/contexts/table.context";
import { DashboardHeaderCellContentAdaptor } from "../../../util/adaptors/dashboard-header-cell-content.adaptor";
import React from "react";
import { MobileTableItemStack } from "../../../util/theme/styled-components";

interface Props {
	element: Identifiable;
}

export const MobileTableItem: React.FC<Props> = ({ element }) => {
	const { getLocalizedText } = useLanguageContext();
	const { handleSelectElement, selectedElements, table, tableKey, isLoading } =
		useTableContext();

	const { data, action, tableInteractionDisabled } = DashboardTableConfig[table];

	const cells = data.map(({ displayBy, type, filterOptions }) => {
		const text = getLocalizedText(`table_${tableKey}_${displayBy}`);
		const cellValue = element[displayBy] ?? "---";
		const cellContent = isLoading ? (
			<Skeleton animation="wave" sx={{ borderRadius: "0.4rem" }} />
		) : (
			DashboardBodyCellContentAdaptor[type](cellValue, element, filterOptions)
		);
		return (
			<React.Fragment key={displayBy}>
				<Grid item xs={0} sm={1} />
				<Grid item xs={4} sm={3}>
					<Stack spacing="0.25rem">
						{DashboardHeaderCellContentAdaptor.text(
							text,
							{
								sortable: false,
								isSorted: false,
								sortOrder: ""
							},
							false,
							"light-grey"
						)}
						{cellContent}
					</Stack>
				</Grid>
				<Grid item xs={2} sm={2} />
			</React.Fragment>
		);
	});

	const actionCells = isLoading ? (
		<Skeleton
			animation="wave"
			variant="circular"
			height="1rem"
			width="1rem"
			sx={{ borderRadius: "0.4rem" }}
		/>
	) : (
		action.map(({ type, action }) => (
			<Box
				key={type}
				onClick={() => handleSelectElement(element, action)}
				children={DashboardBodyCellContentAdaptor[type]("", element, [])}
			/>
		))
	);

	const isSelected = selectedElements.some(
		({ id: selectedId }) => selectedId === element.id
	);

	return (
		<MobileTableItemStack direction="row" spacing="1rem" $isSelected={isSelected}>
			<Stack alignItems="center" justifyContent="space-between">
				{action.length > 0 && actionCells}
			</Stack>
			<Grid
				container
				rowGap="1rem"
				onClick={() =>
					tableInteractionDisabled ? null : handleSelectElement(element, "edit")
				}
			>
				{cells}
			</Grid>
		</MobileTableItemStack>
	);
};
