import { Stack } from "@mui/material";
import React from "react";
import { EditableGenericBody } from "../elements";
import { PageIdProps } from "../types";

interface Content {
	body: string;
	title: string;
}

interface Subcontent extends Content {
	titleProperty: string;
	bodyProperty: string;
}

interface Props extends PageIdProps {
	content: Content;
	subcontents: Subcontent[];
}

export const InfoFourPartContainer: React.FC<Props> = ({
	content,
	subcontents,
	pageId
}) => {
	const displaySections = () => {
		const bodyVariants = [
			{ desktop: "body2-primary", mobile: "body3-primary" },
			{ desktop: "body2-primary", mobile: "body3-primary" },
			{ desktop: "body2-primary", mobile: "body2-primary" },
			{ desktop: "body2-primary", mobile: "body2-primary" }
		];

		const titleVariants = [
			"h4-secondary",
			"h4-tertiary",
			"body2-primary-semi-bold",
			"body2-primary-semi-bold"
		];

		return subcontents.map(({ title, titleProperty, body, bodyProperty }, index) => (
			<Stack spacing={2} key={index}>
				<EditableGenericBody
					text={title}
					variant={titleVariants[index]}
					propertyKey={titleProperty}
					pageId={pageId}
				/>
				<EditableGenericBody
					innerHTML={body}
					variants={bodyVariants[index]}
					propertyKey={bodyProperty}
					pageId={pageId}
				/>
			</Stack>
		));
	};

	return (
		<Stack
			spacing={{ xs: 2, md: 7 }}
			marginBottom={{ xs: "1.00rem", md: "4.50rem" }}
			sx={{
				a: { fontWeight: 600 },
				strong: { fontWeight: 600 }
			}}
		>
			<EditableGenericBody
				text={content.title}
				variants={{ desktop: "h2-secondary", mobile: "h3-secondary" }}
				propertyKey="title_1"
				pageId={pageId}
				component="h1"
			/>
			<Stack spacing={{ xs: 4, md: 7 }}>{displaySections()}</Stack>
		</Stack>
	);
};
