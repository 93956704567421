import { Grid } from "@mui/material";
import React from "react";
import { FooterSection } from "../sections";
import { PageDAO } from "../../../util/generators/types";

interface FooterSection {
	name: string;
	pages: PageDAO[];
}
interface FooterSections {
	[key: string]: FooterSection;
}
interface FooterProps {
	sections: FooterSections;
	variant?: "dark" | "light";
}

export const FooterSectionList: React.FC<FooterProps> = ({ sections, variant }) => {
	const footerSections: React.ReactElement[] = Object.entries(sections).map(entry => {
		const [sectionId, section] = entry;
		return (
			<FooterSection
				sectionId={sectionId}
				name={section.name}
				key={sectionId}
				variant={variant}
			/>
		);
	});

	return (
		<Grid container columnGap={4} rowGap={5} justifyContent="flex-end">
			{footerSections}
		</Grid>
	);
};
