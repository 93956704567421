import React from "react";
import { FormProvider } from "../../util/contexts/form.context";
import { AdminActivitiesIconDictionary } from "../../util/dictionaries/admin-activities-icon.dictionary";
import { AdminActivitiesDAO } from "../../util/generators/types";
import { TableEnum } from "../../util/types/dashboard-table.types";
import { AdminHomepageCard } from "../components";
import { AdminCmsContainer } from "./admin-cms.container";
import {
	AdminActivitiesGenerator,
	PrivateEntityByPrimaryKeyGeneratorDictionary
} from "../../util/generators/private.generator";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { useRefreshContext } from "../../util/contexts/refresh.context";
import { AdminDocumentsCmsContainer } from "./admin-documents-cms.container";
import { AdminPagesCmsContainer } from "./admin-pages-cms.container";
import { AdminUsersCmsContainer } from "./admin-users-cms.container";

import buttonIcon from "../../assets/images/dashboard/admin-homepage-form-button-icon.svg";

export const AdminHomepageFormContainer: React.FC<{
	table: TableEnum;
}> = ({ table }) => {
	const { crudRefreshTrigger } = useRefreshContext();

	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [entityCount, setEntityCount] = React.useState(0);
	const [lastActivity, setLastActivity] = React.useState<AdminActivitiesDAO | null>(null);

	const adminActivitiesGenerator = new AdminActivitiesGenerator();
	const entityGenerator = new PrivateEntityByPrimaryKeyGeneratorDictionary[table]();

	const openDialog = () => setDialogOpen(true);
	const closeDialog = () => setDialogOpen(false);

	const contentProps = {
		adminActivity: lastActivity
			? lastActivity
			: ({
					createdAt: new Date(0).toISOString()
			  } as AdminActivitiesDAO),
		entityCount,
		localizationKey: `admin-homepage-${table}-form-container_card_content_resource_text`
	};

	useGeneratorEffect(
		{
			effect: entityGenerator.findAll,
			onSuccess: ({ meta }) => {
				if (meta) setEntityCount(meta.count);
			},
			callback: entityGenerator.abort
		},
		[crudRefreshTrigger]
	);

	useGeneratorEffect(
		{
			effect: () => adminActivitiesGenerator.many(`?resourceName=${table}`),
			onSuccess: response => setLastActivity(response[0] || null),
			callback: adminActivitiesGenerator.abort
		},
		[crudRefreshTrigger]
	);

	const CmsContainer = getCmsContainer(table);

	return (
		<FormProvider table={table}>
			<AdminHomepageCard
				buttonAction={openDialog}
				buttonImageSrc={buttonIcon}
				buttonLocalizationKey="admin-homepage-form-container_card_button_text"
				buttonVariant="newSecondary"
				contentProps={contentProps}
				imageSrc={AdminActivitiesIconDictionary[table]}
				localizationKey={`admin-homepage-${table}-form-container_card_header_text`}
				route={`/${table}`}
			/>
			<CmsContainer
				closeDialog={closeDialog}
				openDialog={openDialog}
				dialogOpen={dialogOpen}
				table={table}
			/>
		</FormProvider>
	);
};

const getCmsContainer = (table: TableEnum) => {
	switch (table) {
		case TableEnum.documents:
			return AdminDocumentsCmsContainer;
		case TableEnum.pages:
			return AdminPagesCmsContainer;
		case TableEnum.users:
			return AdminUsersCmsContainer;
		default:
			return AdminCmsContainer;
	}
};
