import { DashboardPageItem } from "../../types";
import { DashboardSidebarItemStack } from "../../../util/theme/styled-components";
import { GenericImageContainer, LocalizedGenericBody } from "../../elements";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

interface Props {
	setActiveRoute: (route: string) => void;
	activeRoute: string;
	item: DashboardPageItem;
}

export const DashboardSidebarItem: React.FC<Props> = ({
	setActiveRoute,
	activeRoute,
	item: { imageSrc, route, localizationKey }
}) => {
	const { isTablet } = useResponsive();
	const { isOpen, setIsOpen } = useSidebarContext();

	const handleItemClick = () => {
		setActiveRoute(route);
		if (isTablet && isOpen) setIsOpen(false);
	};

	return (
		<DashboardSidebarItemStack
			direction="row"
			spacing="1rem"
			$active={route === activeRoute}
			onClick={handleItemClick}
			$issidebaropen={isOpen}
		>
			<GenericImageContainer
				imageAlt={`dashboard-${route}-icon`}
				imageSrc={imageSrc}
				width="1.25rem"
			/>
			{isOpen ? (
				<LocalizedGenericBody
					localizationKey={localizationKey}
					variant="body2-secondary-medium"
				/>
			) : null}
		</DashboardSidebarItemStack>
	);
};
