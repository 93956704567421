import React from "react";
import { io } from "socket.io-client";

export interface ISocketContext {
	addEvent: (event: string, callback: (data: any) => void) => void;
	clearEvent: (event: string) => void;
}

export const SocketContext = React.createContext<ISocketContext>({
	addEvent: () => null,
	clearEvent: () => null
});

export const useSocketContext = () => React.useContext(SocketContext);

export const SocketProvider: React.FC = ({ children }) => {
	const socket = io(process.env.REACT_APP_LIDER_API_ENDPOINT as string, {
		multiplex: true
	});
	const addEvent = (event: string, callback: (data: any) => void) =>
		socket.on(event, callback);
	const clearEvent = (event: string) => socket.off(event);

	const contextValue = { addEvent, clearEvent };

	return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>;
};
