import { Box, Stack, useTheme } from "@mui/material";
import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { PageDAO } from "../../../util/generators/types";
import { useLinkProps } from "../../../util/hooks/useLinkProps.hook";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import { BottomNavigationChevronLeft, BottomNavigationChevronRight } from "../images";

interface Props {
	nextPage: PageDAO | null;
	previousPage: PageDAO | null;
}

interface NavigationItemProps {
	page?: PageDAO | null;
	alignment: string;
	reverse?: boolean;
	localizationKey: string;
	icon: ReactElement;
}

export const BottomNavigationItem: React.FC<NavigationItemProps> = ({
	page = null,
	alignment,
	reverse = false,
	localizationKey,
	icon
}) => {
	const theme = useTheme();
	const { getLinkProps } = useLinkProps();

	const [hover, setHover] = React.useState(false);

	const handleScroll = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

	return page ? (
		<NavLink
			{...getLinkProps(page.route)}
			style={{ textDecoration: "none" }}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={handleScroll}
		>
			<Stack>
				<Stack
					direction={reverse ? "row-reverse" : "row"}
					alignItems="center"
					justifyContent={alignment}
					spacing="0.500rem"
				>
					{icon}
					<LocalizedGenericBody
						localizationKey={localizationKey}
						variants={{
							desktop: "body3-primary-medium",
							mobile: "body4-primary-medium"
						}}
						sx={{ opacity: 0.5 }}
					/>
				</Stack>
				<GenericBody
					variants={{
						desktop: "body1-secondary-bold",
						mobile: "body2-secondary-bold"
					}}
					text={page.name}
					sx={{
						textAlign: alignment,
						color: hover ? theme.palette.primary.main : theme.palette.secondary.main,
						textDecoration: hover ? "underline" : "none"
					}}
				/>
			</Stack>
		</NavLink>
	) : (
		<Box width="6.25rem" />
	);
};

export const TemplateBottomNavigation: React.FC<Props> = ({ nextPage, previousPage }) => {
	const theme = useTheme();

	return (
		<Stack
			paddingTop={{ xs: "1.00rem", md: "1.50rem" }}
			paddingBottom={{ xs: "1.00rem", md: "3.25rem" }}
			sx={{ borderTop: `1px solid ${theme.palette.primary.main}33` }}
			direction="row"
			justifyContent="space-between"
		>
			<BottomNavigationItem
				page={previousPage}
				alignment="left"
				localizationKey="template_bottom_navigation_previous"
				icon={<BottomNavigationChevronLeft opacity="0.5" />}
			/>
			<BottomNavigationItem
				page={nextPage}
				alignment="right"
				reverse
				localizationKey="template_bottom_navigation_next"
				icon={<BottomNavigationChevronRight opacity="0.5" />}
			/>
		</Stack>
	);
};
