import { Paper, styled } from "@mui/material";
import { transientOptions } from "../options";

export const DashboardTablePaper = styled(
	Paper,
	transientOptions
)<{ $inBlock?: boolean }>(({ $inBlock }) => {
	const borderRadius = "0.75rem";
	const variantStyles = $inBlock
		? { borderRadius: `0 0 ${borderRadius} ${borderRadius}`, boxShadow: "none" }
		: { borderRadius };

	return {
		width: "100%",
		...variantStyles
	};
});
