import Website from "./Website";
import { useAppModeContext } from "./util/contexts/app-mode.context";
import { useAuthContext } from "./util/contexts/auth.context";
import { FastEditSidebar } from "./views";
import { UsersPrivateCommunicator } from "./communicators/lider.http.communicator";
import { useGeneratorEffect } from "./util/hooks/useGeneratorEffect.hook";
import { UserDAO } from "./util/generators/types";

const FastEditWebsite = () => {
	const { setUser } = useAuthContext();
	const { setAppMode } = useAppModeContext();

	const communicator = new UsersPrivateCommunicator();

	const setUserAndAppmode = ({ data }: { data: UserDAO | null }) => {
		setUser(data);
		if (!data) return setAppMode("admin");
		localStorage.setItem("isFastEditMode", "true");
	};

	useGeneratorEffect(
		{
			effect: () => communicator.getUser("admin"),
			onSuccess: setUserAndAppmode,
			callback: communicator.abort
		},
		[]
	);

	return (
		<>
			<FastEditSidebar />
			<Website />
		</>
	);
};

export default FastEditWebsite;
