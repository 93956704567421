import { GenericImageContainer } from "../../elements";

import fastEditButtonIcon from "../../../assets/images/fast-edit/fast-edit-button-icon.svg";
import fastEditButtonIconHovered from "../../../assets/images/fast-edit/fast-edit-button-icon-hovered.svg";
import fastEditReturnButtonIcon from "../../../assets/images/fast-edit/fast-edit-return-button-icon.svg";
import fastEditButtonImage from "../../../assets/images/fast-edit/fast-edit-button-image.png";
import fastEditCollapseOpen from "../../../assets/images/fast-edit/fast-edit-collapse-open.svg";
import fastEditCollapseClose from "../../../assets/images/fast-edit/fast-edit-collapse-close.svg";

interface Props {
	hovered: boolean;
}

export const FastEditButtonIcon: React.FC<Props> = ({ hovered }) => (
	<GenericImageContainer
		secondaryImageRendered={hovered}
		imageSrc={fastEditButtonIcon}
		secondaryImageSrc={fastEditButtonIconHovered}
		imageAlt="fast-edit-button-icon"
		width="1rem"
	/>
);

export const FastEditReturnButtonIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={fastEditReturnButtonIcon}
		imageAlt="fast-edit-return-button-icon"
		width="1.125rem"
	/>
);

export const FastEditButtonImage: React.FC = () => (
	<GenericImageContainer
		imageSrc={fastEditButtonImage}
		imageAlt="fast-edit-button-image"
		width="13.5rem"
		height="4.688rem"
		style={{
			borderRadius: "0.188rem 0",
			maxWidth: "100%"
		}}
	/>
);

export const FastEditCollapseButtonImage: React.FC<{ open: boolean }> = ({ open }) => (
	<GenericImageContainer
		secondaryImageRendered={open}
		imageSrc={fastEditCollapseClose}
		secondaryImageSrc={fastEditCollapseOpen}
		imageAlt="fast-edit-collapse"
		width="1.5rem"
	/>
);
