import { Box, Stack } from "@mui/material";
import React from "react";
import { SwipeEventData, useSwipeable, LEFT, RIGHT } from "react-swipeable";
import {
	HomepageTextContent,
	LiderLogo as LiderHomeImage,
	MakePaymentButton
} from "../components";
import { PageIdProps } from "../types";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import { HomeTextField } from "../components/types";

interface Props extends PageIdProps {
	title_1: string;
	title_2: string;
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	navigationPath_1: string;
	navigationPath_2: string;
}

export const HomepageContainer: React.FC<Props> = ({
	title_1,
	title_2,
	body_1,
	body_2,
	body_3,
	body_4,
	navigationPath_1,
	navigationPath_2,
	pageId
}) => {
	const textFields: HomeTextField[] = [
		{
			title: title_1,
			body: body_1,
			navigationPath: navigationPath_1,
			buttonText: body_3,
			headingVariants: { desktop: "hxl-primary", mobile: "h2-primary" }
		},
		{
			title: title_2,
			body: body_2,
			navigationPath: navigationPath_2,
			buttonText: body_4,
			headingVariants: { desktop: "hxl-primary", mobile: "h2-primary" }
		}
	];

	const { isMobile } = useResponsive();

	const [pageIndex, setPageIndex] = React.useState(0);

	const [stopInterval, setStopInterval] = React.useState(false);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPageIndex(value - 1);
		setStopInterval(true);
	};

	const handleSwipe = (eventData: SwipeEventData) => {
		let nextPage = pageIndex;

		if (eventData.dir === LEFT && pageIndex + 1 < textFields.length)
			nextPage = pageIndex + 1;
		if (eventData.dir === RIGHT && pageIndex - 1 >= 0) nextPage = pageIndex - 1;

		setPageIndex(nextPage);
	};

	const swipeHandlers = useSwipeable({
		onSwiped: handleSwipe
	});

	React.useEffect(() => {
		const interval = setTextfieldChangeInterval(
			setPageIndex,
			pageIndex,
			textFields,
			stopInterval
		);

		return () => clearInterval(interval);
	}, [pageIndex]);

	const textFieldProps = {
		textFields,
		pageIndex,
		handlePageChange,
		pageId,
		swipeHandlers
	};

	return (
		<Stack
			alignItems="center"
			py={{ xs: "3.38rem", md: "6.00rem" }}
			sx={{ gap: "0.500rem", minHeight: "36.3rem" }}
		>
			{isMobile ? (
				<Box
					sx={{
						position: "absolute",
						top: "0.750rem",
						right: 0
					}}
				>
					<MakePaymentButton />
				</Box>
			) : null}

			<Stack
				direction={{ xs: "column", lg: "row-reverse" }}
				alignItems="center"
				justifyContent={{ xs: "center", lg: "space-between" }}
				sx={{ gap: "2.00rem", width: "100%", minHeight: "24.3rem" }}
			>
				<Box sx={{ maxWidth: "37.5rem" }}>
					<LiderHomeImage />
				</Box>
				<HomepageTextContent {...textFieldProps} />
			</Stack>
		</Stack>
	);
};

export const setTextfieldChangeInterval = (
	setPageIndex: React.Dispatch<React.SetStateAction<number>>,
	pageIndex: number,
	textFields: HomeTextField[],
	stopInterval: boolean
): NodeJS.Timer => {
	const nextIndex = textFields[pageIndex + 1] ? pageIndex + 1 : 0;
	const onInterval = () => setPageIndex(stopInterval ? pageIndex : nextIndex);
	const interval = setInterval(onInterval, 10000);
	return interval;
};
