import React from "react";
import { ByLanguageId } from "../adaptors/types";
import { SectionDAO } from "../generators/types";
interface ISectionsContext {
	sections: ByLanguageId<SectionDAO> | null;
	pageCounts: Record<string, number>;
	selectedSection: string | null;
	setSections: (sections: ByLanguageId<SectionDAO> | null) => void;
	setPageCounts: (pageCounts: Record<string, number>) => void;
	setSelectedSection: (sectionId: string) => void;
}

export const SectionsContext = React.createContext<ISectionsContext>({
	sections: null,
	pageCounts: {},
	selectedSection: null,
	setSections: () => null,
	setPageCounts: () => null,
	setSelectedSection: () => null
});

export const useSectionsContext = () => React.useContext(SectionsContext);

export const SectionsProvider: React.FC = ({ children }) => {
	const [sections, setSections] = React.useState<ByLanguageId<SectionDAO> | null>(null);

	const [selectedSection, setSelectedSection] = React.useState<string | null>(null);
	const [pageCounts, setPageCounts] = React.useState<Record<string, number>>({});

	const contextValue = {
		sections,
		pageCounts,
		setSections,
		selectedSection,
		setPageCounts,
		setSelectedSection
	};

	return (
		<SectionsContext.Provider value={contextValue}>{children}</SectionsContext.Provider>
	);
};
