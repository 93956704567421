import { GenericImageContainer } from "../../elements";

import activateUserPrimary from "../../../assets/images/activate-user-primary.svg";
import activateUserWhite from "../../../assets/images/activate-user-white.svg";
import buttonAdd from "../../../assets/images/button-add.svg";
import buttonOpen from "../../../assets/images/button-open.svg";
import buttonDownload from "../../../assets/images/button-download.svg";
import buttonDelete from "../../../assets/images/button-delete.svg";
import deactivateUserPrimary from "../../../assets/images/deactivate-user-primary.svg";
import deactivateUserWhite from "../../../assets/images/deactivate-user-white.svg";
import editUser from "../../../assets/images/edit-user.svg";
import launchIcon from "../../../assets/images/launch-icon.svg";
import launchIconPassive from "../../../assets/images/launch-icon-passive.svg";
import logoutButtonIcon from "../../../assets/images/dashboard/logout-button-icon.svg";
import selectClearIcon from "../../../assets/images/dashboard/dashboard-select-bar-clear-icon.svg";
import sendMailIcon from "../../../assets/images/dashboard/dashboard-select-bar-send-mail-icon.svg";
import printIcon from "../../../assets/images/dashboard/dashboard-select-bar-print-icon.svg";

export const AddIcon: React.FC = () => (
	<GenericImageContainer imageSrc={buttonAdd} imageAlt="add item" width="0.875rem" />
);

export const OpenIcon: React.FC = () => (
	<GenericImageContainer imageSrc={buttonOpen} imageAlt="open item" width="0.875rem" />
);

export const DownloadIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={buttonDownload}
		imageAlt="download item"
		width="0.875rem"
	/>
);

export const DeleteIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={buttonDelete}
		imageAlt="delete item"
		width="0.875rem"
	/>
);

interface PublishProps {
	isPublished?: boolean;
}

export const PublishButtonImage: React.FC<PublishProps> = ({ isPublished }) => (
	<GenericImageContainer
		secondaryImageRendered={isPublished}
		imageSrc={launchIcon}
		secondaryImageSrc={launchIconPassive}
		imageAlt="launch-icon"
		width="1.25rem"
	/>
);

export const DashboardSidebarLogoutButtonImage: React.FC = () => (
	<GenericImageContainer
		imageSrc={logoutButtonIcon}
		imageAlt="logout-button-icon"
		width="1.25rem"
	/>
);

export const TableSelectSnackbarClearIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={selectClearIcon}
		imageAlt="select-clear-icon"
		width="1.5rem"
	/>
);

export const TableSelectSnackbarSendMailIcon: React.FC = () => (
	<GenericImageContainer imageSrc={sendMailIcon} imageAlt="send-mail-icon" width="1rem" />
);

export const TableSelectSnackbarPrintIcon: React.FC = () => (
	<GenericImageContainer imageSrc={printIcon} imageAlt="print-icon" width="1rem" />
);

export const ActivateUserWhiteIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={activateUserWhite}
		imageAlt="activate-user-white"
		width="1.25rem"
	/>
);

export const DeactivateUserWhiteIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={deactivateUserWhite}
		imageAlt="deactivate-user-white"
		width="1.25rem"
	/>
);

export const ActivateUserPrimaryIcon: React.FC<{ active: boolean }> = ({ active }) => (
	<GenericImageContainer
		secondaryImageRendered={active}
		imageSrc={activateUserPrimary}
		secondaryImageSrc={deactivateUserPrimary}
		imageAlt="activate-user-primary"
		width="0.9219rem"
	/>
);

export const EditUserIcon: React.FC = () => (
	<GenericImageContainer imageSrc={editUser} imageAlt="edit-user" width="0.75rem" />
);
