import { TableHead } from "@mui/material";
import { DashboardHeaderCellContentAdaptor } from "../../../util/adaptors/dashboard-header-cell-content.adaptor";
import { DashboardTableConfig } from "../../../util/configs/dashboard-table";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { DashboardTableHeaderRow as HeaderRow } from "../../../util/theme/styled-components";
import { DashboardTableHeaderCell, DashboardTableHeaderRowEndCell } from "../cells";
import { useTableContext } from "../../../util/contexts/table.context";
import { HeaderRowProps } from "../types";

export const DashboardTableHeaderRow: React.FC<HeaderRowProps> = ({
	variant,
	...props
}) => {
	const {
		handleSortClick,
		handleSelectAll,
		sortedField,
		sortOrder,
		table,
		tableKey,
		isLoading
	} = useTableContext();
	const { getLocalizedText } = useLanguageContext();

	const textColor = variant === "secondary" ? "white" : "primary";

	const columns = DashboardTableConfig[table].visible;

	const headerColumnTypeConverter = (type: string) => {
		switch (type) {
			case "select":
			case "delete":
				return type;
			default:
				return "text";
		}
	};

	const eventHandler = (type: string, requestBy: string, sortable: boolean) => {
		switch (type) {
			case "select":
				return handleSelectAll;
			case "delete":
				return () => null;
			default:
				return () => (sortable ? handleSortClick(requestBy) : null);
		}
	};

	const cells = columns.map(({ displayBy, requestBy, type, sortable }, index) => {
		const text = getLocalizedText(`table_${tableKey}_${displayBy}`);

		const rowEnd = index === columns.length - 1;
		const Cell = rowEnd ? DashboardTableHeaderRowEndCell : DashboardTableHeaderCell;

		const shouldDisplaySpinner = index === columns.length - 1 && isLoading;
		return (
			<Cell
				handleClick={eventHandler(type, requestBy, sortable)}
				key={index}
				sortable={sortable}
				Content={DashboardHeaderCellContentAdaptor[headerColumnTypeConverter(type)](
					text,
					{ sortable, isSorted: sortedField === requestBy, sortOrder },
					shouldDisplaySpinner,
					textColor
				)}
			/>
		);
	});

	return (
		<TableHead {...props}>
			<HeaderRow variant={variant}>{cells}</HeaderRow>
		</TableHead>
	);
};
