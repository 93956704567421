import { Box, Stack, useTheme } from "@mui/material";
import { BackToLoginButton, VerificationButton } from "../../buttons";
import {
	LocalizedGenericBody,
	LocalizedTypographyWithVariableSubject
} from "../../../elements";
import { LoginVerificationCodeInput } from "../../inputs";
import { VerificationCodeProps } from "./types";
import { useLocalizedNavigate } from "../../../../util/hooks/useLocalizedNavigate";

export const VerificationCodeForm: React.FC<VerificationCodeProps> = ({
	handleChange,
	handleSubmit,
	resendVerificationCode,
	phoneNumber,
	remainder,
	value,
	warning
}) => {
	const theme = useTheme();

	const { navigate } = useLocalizedNavigate();

	const handleButtonClick = () => navigate("/login");

	return (
		<Stack px="1rem" spacing={{ xs: "1.5rem", md: "2rem" }} alignItems="center">
			<BackToLoginButton handleClick={handleButtonClick} />
			<Stack spacing="0.5rem">
				<LocalizedGenericBody
					localizationKey="client_verification_heading"
					variants={{ mobile: "h4-primary", desktop: "h3-primary" }}
					sx={{ textAlign: "center" }}
				/>
				<LocalizedTypographyWithVariableSubject
					localizationKey="client_verification_information_text"
					variants={{ mobile: "body3-primary-medium", desktop: "body1-primary-medium" }}
					variable={getHiddenPhoneNumber(phoneNumber)}
					sx={{ textAlign: "center" }}
				/>
			</Stack>
			<Stack
				component="form"
				spacing="1.5rem"
				alignItems="center"
				onSubmit={handleSubmit}
			>
				<LoginVerificationCodeInput warning={warning} handleChange={handleChange} />
				{warning ? (
					<LocalizedGenericBody
						localizationKey="error_smsVerification_wrong-code"
						variants={{ mobile: "body3-error-center", desktop: "body2-error-center" }}
						sx={{ maxWidth: "25rem", color: theme.palette.error.main }}
					/>
				) : null}
				<Stack direction="row">
					<LocalizedTypographyWithVariableSubject
						localizationKey="client_verification_countdown_text"
						variant="body2-primary-medium"
						variable={remainder}
						sx={{ opacity: "0.6" }}
					/>
					<Box onClick={resendVerificationCode} sx={{ cursor: "pointer" }}>
						<LocalizedGenericBody
							localizationKey="client_resend_verification_text"
							variant="body2-primary-bold"
							sx={{
								textDecoration: "underline",
								":hover": { color: theme.palette.tertiary.main },
								a: {}
							}}
						/>
					</Box>
				</Stack>
				<VerificationButton disabled={value.length !== 4} />
			</Stack>
		</Stack>
	);
};

const getHiddenPhoneNumber = (phoneNumber: string) => {
	const lastFour = phoneNumber.slice(-4);
	return phoneNumber.slice(0, -4).replace(/./g, "*") + lastFour;
};
