export const flags: Record<string, JSX.Element> = {
	tr: (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_449_20462)">
				<path
					d="M7 13.5625C10.6244 13.5625 13.5625 10.6244 13.5625 7C13.5625 3.37563 10.6244 0.4375 7 0.4375C3.37563 0.4375 0.4375 3.37563 0.4375 7C0.4375 10.6244 3.37563 13.5625 7 13.5625Z"
					fill="#D42B3C"
				/>
				<path
					d="M9.03437 8.53125L9.05625 7.35L7.875 7L9.05625 6.65L9.03437 5.46875L9.75625 6.40937L10.9375 6.05938L10.2156 7L10.9375 7.94062L9.75625 7.59063L9.03437 8.53125Z"
					fill="white"
				/>
				<path
					d="M7.2625 9.625C5.81875 9.625 4.65937 8.44375 4.65937 7C4.65937 5.55625 5.81875 4.375 7.2625 4.375C7.80938 4.375 8.3125 4.55 8.75 4.83438C8.15938 4.15625 7.28438 3.71875 6.3 3.71875C4.50625 3.71875 3.0625 5.18438 3.0625 7C3.0625 8.81563 4.50625 10.2813 6.3 10.2813C7.28438 10.2813 8.15938 9.84375 8.75 9.16563C8.33438 9.45 7.83125 9.625 7.2625 9.625Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_449_20462">
					<rect width="14" height="14" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	en: (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.8127 13.1906V10.1719L2.55957 11.8344C3.19395 12.425 3.95957 12.8844 4.8127 13.1906ZM9.1877 13.1906C10.0408 12.8844 10.8064 12.425 11.4408 11.8344L9.1877 10.15V13.1906ZM0.80957 9.1875C0.875195 9.40625 0.962695 9.60313 1.07207 9.82188L1.9252 9.1875H0.80957ZM12.0752 9.1875L12.9283 9.82188C13.0158 9.625 13.1033 9.40625 13.1908 9.1875H12.0752Z"
				fill="#2A5F9E"
			/>
			<path
				d="M5.14072 8.3125H0.568848C0.634473 8.61875 0.721973 8.90313 0.809473 9.1875H1.9251L1.07197 9.82188C1.24697 10.1938 1.44385 10.5219 1.68447 10.85L3.9376 9.1875H4.8126V9.625L2.25322 11.5062L2.55947 11.8125L4.8126 10.1719V13.1906C5.09697 13.3 5.38135 13.3656 5.6876 13.4313V8.3125H5.14072ZM13.4313 8.3125H8.3126V13.4313C8.61885 13.3656 8.90322 13.2781 9.1876 13.1906V10.1719L11.4407 11.8125C11.747 11.5281 12.0095 11.2219 12.272 10.8937L9.93135 9.1875H11.4188L12.7532 10.1719C12.8188 10.0625 12.8845 9.93125 12.9282 9.82188L12.0751 9.1875H13.1907C13.2782 8.90313 13.3657 8.61875 13.4313 8.3125Z"
				fill="white"
			/>
			<path
				d="M1.68457 10.85C1.85957 11.0906 2.03457 11.3094 2.23145 11.5281L4.8127 9.64687V9.20937H3.9377L1.68457 10.85ZM9.95332 9.1875L12.2939 10.8937C12.3814 10.7844 12.4471 10.675 12.5346 10.5656C12.5564 10.5437 12.5564 10.5219 12.5783 10.5219C12.6439 10.4125 12.7314 10.2812 12.7971 10.1719L11.4189 9.1875H9.95332Z"
				fill="#D52B3C"
			/>
			<path
				d="M9.1877 0.809326V3.82808L11.4408 2.16558C10.8064 1.57495 10.0408 1.11558 9.1877 0.809326ZM4.8127 0.809326C3.95957 1.11558 3.19395 1.57495 2.55957 2.16558L4.8127 3.84995V0.809326ZM13.1908 4.81245C13.1252 4.5937 13.0377 4.39683 12.9283 4.17808L12.0752 4.81245H13.1908ZM1.9252 4.81245L1.07207 4.17808C0.98457 4.39683 0.89707 4.5937 0.80957 4.81245H1.9252Z"
				fill="#2A5F9E"
			/>
			<path
				d="M8.85947 5.6876H13.4095C13.3438 5.38135 13.2563 5.09697 13.1688 4.8126H12.0532L12.9063 4.17822C12.7313 3.80635 12.5345 3.47822 12.2938 3.1501L10.0626 4.8126H9.1876V4.3751L11.747 2.49385L11.4407 2.1876L9.1876 3.82822V0.809473C8.90322 0.700098 8.61885 0.634473 8.3126 0.568848V5.6876H8.85947ZM0.568848 5.6876H5.6876V0.568848C5.38135 0.634473 5.09697 0.721973 4.8126 0.809473V3.82822L2.55947 2.1876C2.25322 2.47197 1.99072 2.77822 1.72822 3.10635L4.06885 4.8126H2.58135L1.24697 3.82822C1.18135 3.9376 1.11572 4.06885 1.07197 4.17822L1.9251 4.8126H0.809473C0.721973 5.09697 0.634473 5.38135 0.568848 5.6876Z"
				fill="white"
			/>
			<path
				d="M12.3157 3.15005C12.1407 2.90942 11.9657 2.69067 11.7688 2.47192L9.1876 4.35317V4.79067H10.0626L12.3157 3.15005M4.04697 4.81255L1.72822 3.1063C1.64072 3.21567 1.5751 3.32505 1.4876 3.43442C1.46572 3.4563 1.46572 3.47817 1.44385 3.47817C1.37822 3.58755 1.29072 3.7188 1.2251 3.82817L2.55947 4.81255H4.04697Z"
				fill="#D52B3C"
			/>
			<path
				d="M13.4313 5.6875H8.3125V0.56875C7.89688 0.48125 7.45938 0.4375 7 0.4375C6.54063 0.4375 6.10313 0.48125 5.6875 0.56875V5.6875H0.56875C0.48125 6.10313 0.4375 6.54063 0.4375 7C0.4375 7.45938 0.48125 7.89688 0.56875 8.3125H5.6875V13.4313C6.10313 13.5188 6.54063 13.5625 7 13.5625C7.45938 13.5625 7.89688 13.5188 8.3125 13.4313V8.3125H13.4313C13.5188 7.89688 13.5625 7.45938 13.5625 7C13.5625 6.54063 13.5188 6.10313 13.4313 5.6875Z"
				fill="#D52B3C"
			/>
		</svg>
	)
};
