import { Chip, styled } from "@mui/material";

export const EmailChip = styled(Chip)(({ theme }) => ({
	paddingLeft: "0.25rem",
	background: theme.palette.common.white,
	fontFamily: "Raleway",
	fontSize: "0.875rem",
	color: theme.palette.primary.main,
	fontWeight: 500,
	lineHeight: "140%",
	span: {
		padding: "0 0.9rem 0 0.5rem"
	}
}));
