import { ListItemButton } from "@mui/material";
import React from "react";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import { SideMenuListItemText } from "../../../util/theme/styled-components";

interface SideMenuSectionItemProps {
	name: string;
	route: string;
	closeDrawer: () => void;
}

export const SideMenuSectionItem: React.FC<SideMenuSectionItemProps> = ({
	name,
	route,
	closeDrawer
}) => {
	const { navigate } = useLocalizedNavigate();
	const handleClick = () => {
		navigate(route[0] === "/" ? route : `/${route}`);
		closeDrawer();
	};
	return (
		<ListItemButton onClick={handleClick}>
			<SideMenuListItemText primary={name} />
		</ListItemButton>
	);
};
