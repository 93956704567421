import { FaqListContainer, PageWithSEO } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	title_1: string;
}

export const FaqsPage: React.FC<Props> = ({ title_1, pageId, ...seoProps }) => {
	return (
		<PageWithSEO withSidebar {...seoProps}>
			<FaqListContainer {...{ title_1, pageId }} />
		</PageWithSEO>
	);
};
