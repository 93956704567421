import { PageWithSEO, ThreePointContentWithMediaContainer } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	title_1: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	imageLink_1: string;
}

export const ThreePointContentWithMediaPage: React.FC<Props> = ({
	title_1,
	subtitle_1,
	subtitle_2,
	subtitle_3,
	body_1,
	body_2,
	body_3,
	body_4,
	imageLink_1,
	pageId,
	...seoProps
}) => (
	<PageWithSEO withSidebar {...seoProps}>
		<ThreePointContentWithMediaContainer
			{...{
				title_1,
				subtitle_1,
				subtitle_2,
				subtitle_3,
				body_1,
				body_2,
				body_3,
				body_4,
				imageLink_1,
				pageId
			}}
		/>
	</PageWithSEO>
);
