import { Grid, Stack } from "@mui/material";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminFormCreateUpdateButton } from "../buttons";
import {
	TitleInput,
	LanguageSelect,
	AnnouncementsTypeSelect,
	DateInput,
	AnnouncementsContentInput
} from "../inputs";

export const AdminPanelAnnouncementsForm: React.FC<FormProps> = ({ handleSubmit }) => (
	<Stack spacing={2}>
		<TitleInput />
		<AnnouncementsContentInput />
		<Grid container>
			<Grid item xs={12} sm={6}>
				<LanguageSelect />
			</Grid>
			<Grid item xs={12} sm={6} pl={{ xs: 0, sm: 2 }} pt={{ xs: 2, sm: 0 }}>
				<AnnouncementsTypeSelect />
			</Grid>
		</Grid>
		<DateInput />
		<AdminFormCreateUpdateButton handleClick={handleSubmit} />
	</Stack>
);
