import React from "react";
import { LayoutWrapper, NewsListPageContainer, PageWithSEO } from "../containers";
import { SeoProps } from "../containers/types";

export const NewsListPage: React.FC<SeoProps> = props => (
	<PageWithSEO footerVariant="dark" {...props}>
		<LayoutWrapper>
			<NewsListPageContainer />
		</LayoutWrapper>
	</PageWithSEO>
);
