import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import {
	TitleButtonThreeSubtitleContent,
	TitleButtonThreeSubtitleImage,
	TitleButtonThreeSubtitleSubcontent
} from "../components";
import { PageIdProps } from "../types";

interface Props extends PageIdProps {
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	body_5: string;
	navigationPath_1: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	title_1: string;
}

export const TitleButtonThreeSubtitleContainer: React.FC<Props> = ({
	body_1,
	body_2,
	body_3,
	body_4,
	body_5,
	navigationPath_1,
	subtitle_1,
	subtitle_2,
	subtitle_3,
	title_1,
	pageId
}) => (
	<Stack>
		<Grid container alignItems="center" columnSpacing={14} rowSpacing={6}>
			<Grid order={{ xs: 2, md: 1 }} item xs={12} md={8}>
				<TitleButtonThreeSubtitleContent
					body={body_1}
					buttonText={body_2}
					navigationPath={navigationPath_1}
					title={title_1}
					pageId={pageId}
				/>
			</Grid>
			<Grid
				order={{ xs: 1, md: 2 }}
				item
				xs={12}
				md={4}
				sx={{ display: "flex", justifyContent: "center" }}
			>
				<Box maxWidth="23.8rem" minWidth="18.8rem">
					<TitleButtonThreeSubtitleImage />
				</Box>
			</Grid>
		</Grid>
		<Grid
			container
			columnSpacing={12}
			rowSpacing={6}
			paddingTop={{ lg: 0, xs: "3.75rem" }}
		>
			<Grid item xs={12} md={4}>
				<TitleButtonThreeSubtitleSubcontent
					body={body_3}
					bodyProperty="body_3"
					title={subtitle_1}
					titleProperty="subtitle_1"
					pageId={pageId}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TitleButtonThreeSubtitleSubcontent
					body={body_4}
					bodyProperty="body_4"
					title={subtitle_2}
					titleProperty="subtitle_2"
					pageId={pageId}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TitleButtonThreeSubtitleSubcontent
					body={body_5}
					bodyProperty="body_5"
					title={subtitle_3}
					titleProperty="subtitle_3"
					pageId={pageId}
				/>
			</Grid>
		</Grid>
	</Stack>
);
