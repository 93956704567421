import React from "react";
import { TableEnum } from "../../util/types/dashboard-table.types";
import { AdminTable } from "../components";
import { TableProvider } from "../../util/contexts/table.context";
import { AdminSelectContainer } from "./admin-select.container";
import { AdminCmsContainer } from "./admin-cms.container";
import { FormProvider } from "../../util/contexts/form.context";
import { CmsContainerProps } from "./types";

interface Props {
	table: TableEnum;
	CmsContainer?: React.FC<CmsContainerProps>;
}

export const AdminTableContainer: React.FC<Props> = ({
	table,
	CmsContainer = AdminCmsContainer
}) => {
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const openDialog = () => setDialogOpen(true);
	const closeDialog = () => setDialogOpen(false);

	return (
		<TableProvider table={table} data-cy="admin-table-container">
			<FormProvider table={table}>
				<AdminTable openDialog={openDialog} data-cy="admin-table" />
				<AdminSelectContainer handleDialogOpen={openDialog} />
				<CmsContainer
					dialogOpen={dialogOpen}
					closeDialog={closeDialog}
					openDialog={openDialog}
					table={table}
				/>
			</FormProvider>
		</TableProvider>
	);
};
