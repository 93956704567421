import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useLanguageContext } from "../../util/contexts/language.context";
import { useLocalizedNavigate } from "../../util/hooks/useLocalizedNavigate";
import { PageWithoutSidebar } from "../containers";
import { GenericButton } from "../elements";

interface Props {
	title_1: string;
	title_2: string;
	body_1: string;
	body_2: string;
	navigationPath_1: string;
	showSidebar: boolean;
}

export const NotFoundPageTemplate: React.FC<Props> = ({
	title_1,
	title_2,
	body_1,
	body_2,
	navigationPath_1,
	showSidebar
}) => {
	const { navigate } = useLocalizedNavigate();

	const ContainerComponent = (
		<Container>
			<Stack
				alignItems="center"
				sx={{ textAlign: "center", paddingTop: "6.25rem", paddingBottom: "6.25rem" }}
			>
				<Typography variant="h1" mb={2}>
					{title_1}
				</Typography>
				<Typography variant="h3-secondary" mb={1}>
					{title_2}
				</Typography>
				<Typography variant="body1" mb={2}>
					{body_1}
				</Typography>
				<Box paddingY="2.50rem">
					<GenericButton
						variant="primary"
						text={body_2}
						handleClick={() => navigate(navigationPath_1)}
					/>
				</Box>
			</Stack>
		</Container>
	);

	return showSidebar ? (
		ContainerComponent
	) : (
		<PageWithoutSidebar footerVariant="dark">{ContainerComponent}</PageWithoutSidebar>
	);
};

export const NotFoundPage: React.FC<{ showSidebar: boolean }> = ({ showSidebar }) => {
	const { getLocalizedTexts } = useLanguageContext();

	const [not_found_page_title, not_found_page_body, not_found_page_button] =
		getLocalizedTexts([
			"not_found_page_title",
			"not_found_page_body",
			"not_found_page_button"
		]);

	const props = {
		title_1: "404",
		title_2: not_found_page_title,
		body_1: not_found_page_body,
		body_2: not_found_page_button,
		navigationPath_1: "/",
		showSidebar
	};

	return <NotFoundPageTemplate {...props} />;
};
