import React from "react";
import { HomepageSectionBreak } from "../../util/theme/styled-components";
import {
	AnnouncementsContainer,
	AwardsContainer,
	GeneralInquiry,
	HomepageContainer,
	LayoutWrapper,
	PageWithSEO,
	ServicesContainer
} from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	title_1: string;
	title_2: string;
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	navigationPath_1: string;
	navigationPath_2: string;
	isAdmin?: boolean;
}

type GradientOrder = 1 | 2 | 3;

export const Homepage: React.FC<Props> = ({
	title_1,
	title_2,
	body_1,
	body_2,
	body_3,
	body_4,
	navigationPath_1,
	navigationPath_2,
	pageId,
	isAdmin,
	...seoProps
}) => (
	<PageWithSEO {...seoProps}>
		<LayoutWrapper>
			<HomepageContainer
				{...{
					title_1,
					title_2,
					body_1,
					body_2,
					body_3,
					body_4,
					navigationPath_1,
					navigationPath_2,
					pageId
				}}
			/>
			<ServicesContainer />
		</LayoutWrapper>
		<AnnouncementsContainer isAdmin={isAdmin} />
		{[AwardsContainer, GeneralInquiry].map((Component, index) =>
			index > 0 ? (
				<React.Fragment key={index}>
					<HomepageSectionBreak />
					<Component index={(index + 1) as GradientOrder} />
				</React.Fragment>
			) : (
				<Component key={index} index={(index + 1) as GradientOrder} />
			)
		)}
	</PageWithSEO>
);
