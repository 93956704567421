import React from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Stack, useTheme } from "@mui/material";
import { BranchDetailsDots } from "../images";
import { CityHeadingFull } from "../headings";
import { BranchNavigation } from "../../../util/theme/styled-components";
import { turkishToEnglish } from "../../../util/converters/string.converter";
import { BranchNavigationButton } from "../buttons";

interface CityProps {
	handleClick: (city: string) => void;
	city: string;
	previousCity: string;
	nextCity: string;
	imageLink: string;
}

export const BranchDetailsNavigationItem: React.FC<CityProps> = ({
	handleClick,
	city,
	imageLink,
	previousCity,
	nextCity
}) => {
	const {
		palette: { primary, secondary, tertiary, common }
	} = useTheme();

	React.useEffect(() => {
		window.scrollTo({ top: 0, left: 0 });
	}, []);

	const createLink = (city: string) => turkishToEnglish(city).toLowerCase();
	const handleNextOrPreviousClick = (city: string) => handleClick(createLink(city));

	return (
		<BranchNavigation
			sx={{
				background: `linear-gradient(170.31deg, ${tertiary.main} 40%, ${secondary.main} 70.2%, ${primary.main} 186.13%), url(${imageLink}), ${primary.main}CC`,
				backgroundBlendMode: "soft-light",
				backgroundSize: "cover",
				backgroundPositionY: "center"
			}}
		>
			<CityHeadingFull city={city} style={{ width: "min-content" }} />
			<Stack flexDirection="row" columnGap={{ xs: 3, md: 2 }}>
				<BranchNavigationButton
					handleNavigation={() => handleNextOrPreviousClick(previousCity)}
					Icon={<ChevronLeft sx={{ color: common.white, fontSize: "2rem" }} />}
				/>
				<BranchNavigationButton
					handleNavigation={() => handleClick(`${location.pathname.split("/")[0]}`)}
					Icon={<BranchDetailsDots />}
				/>
				<BranchNavigationButton
					handleNavigation={() => handleNextOrPreviousClick(nextCity)}
					Icon={<ChevronRight sx={{ color: common.white, fontSize: "2rem" }} />}
				/>
			</Stack>
		</BranchNavigation>
	);
};
