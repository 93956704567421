import { CheckReportDialogProps } from "../types";
import { CheckReportDialogTemplate } from "./check-report.dialog-template";
import { useTableContext } from "../../../util/contexts/table.context";
import { SubmissionStatus } from "../../containers/types";
import { CheckReportResponseContent } from "../contents";
import { useTheme } from "@mui/material";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";

interface Props extends CheckReportDialogProps {
	response: SubmissionStatus;
}
export const CheckReportResponseDialog: React.FC<Props> = ({
	closeDialog,
	sendMail,
	emailBarProps,
	dialogOpen,
	response,
	isLoading
}) => {
	const theme = useTheme();
	const { selectedElements } = useTableContext();

	const multipleElementsSelected = selectedElements.length > 1;

	const localizationKeyPluralAddition = multipleElementsSelected ? "_plural" : "";

	const localizationKey =
		`select_dialog_${response}_message` + localizationKeyPluralAddition;

	const contentProps = {
		PrimaryInfo: (
			<CheckReportResponseContent localizationKey={localizationKey} response={response} />
		),
		infoContainerStyles: {
			width: { xs: "16.69rem", md: "18.44rem" },
			paddingLeft: "1.88rem",
			height: "100%",
			borderRadius: "0.5rem 0 0 0.5rem",
			background: `linear-gradient(90deg, ${ColorDictionary["#786aa8"]}66 0%, ${theme.palette.primary.main}00 100%)`
		}
	};

	return (
		<CheckReportDialogTemplate
			closeDialog={closeDialog}
			sendMail={sendMail}
			buttonLocalizationKey={
				`select_dialog_${response}_button` + localizationKeyPluralAddition
			}
			contentProps={contentProps}
			dialogOpen={dialogOpen}
			emailBarProps={emailBarProps}
			isLoading={isLoading}
		/>
	);
};
