import { StockPricesEventDAO } from "../../../util/generators/types";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { Stack } from "@mui/material";
import { DateTime } from "luxon";
import {
	StockPricesEventArrow,
	StockPricesBarDot
} from "../../../util/theme/styled-components";
import { GenericBody, LocalizedGenericBody } from "../../elements";

interface Props {
	event: StockPricesEventDAO;
}

export const StockPricesEventContent: React.FC<Props> = ({
	event: { currency, price, priceChange, priceChangePercent, date }
}) => {
	const { language } = useLanguageContext();
	const { isMobile } = useResponsive();

	const displayedDate = DateTime.fromISO(date)
		.setLocale(language)
		.toLocaleString(isMobile ? DateTime.DATETIME_SHORT : DateTime.DATETIME_MED);

	const priceChangePercentage = priceChangePercent.toLocaleString(language, {
		style: "percent",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});

	const hasPriceDecreased = priceChange < 0;
	const ChangeArrow = <StockPricesEventArrow $isDecreaseArrow={hasPriceDecreased} />;
	const changeColor = hasPriceDecreased ? "error.main" : "tertiary.main";

	return (
		<Stack alignItems="center" direction="row" spacing={isMobile ? 1 : 2}>
			<GenericBody
				variants={{
					desktop: "body3-primary-medium",
					mobile: "body4-primary-medium"
				}}
				text={displayedDate}
			/>
			<StockPricesBarDot />
			<Stack direction="row" gap="0.375rem" alignItems="center">
				<GenericBody
					variants={{
						desktop: "body3-primary-semi-bold",
						mobile: "body4-primary-semi-bold"
					}}
					text="LIDFA"
				/>
				{ChangeArrow}
				<GenericBody
					variants={{
						desktop: "body3-primary-medium",
						mobile: "body4-primary-medium"
					}}
					text={price.toString()}
					sx={{ color: changeColor }}
				/>
				<GenericBody
					variants={{
						desktop: "body3-primary-medium",
						mobile: "body4-primary-medium"
					}}
					text={currency}
				/>
			</Stack>
			{isMobile ? null : (
				<>
					<StockPricesBarDot />
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
						{ChangeArrow}
						<GenericBody
							variants={{
								desktop: "body3-primary-medium",
								mobile: "body4-primary-medium"
							}}
							sx={{ color: changeColor }}
							text={`${priceChange.toPrecision(2)} ${priceChangePercentage}`}
						/>
						<LocalizedGenericBody
							variants={{
								desktop: "body3-primary-medium",
								mobile: "body4-primary-medium"
							}}
							localizationKey="stock_prices_date_span_text"
						/>
					</Stack>
				</>
			)}
		</Stack>
	);
};
