import React from "react";
import { TableProvider } from "../../util/contexts/table.context";
import { TableEnum } from "../../util/types/dashboard-table.types";
import { AdminActivitiesDialog, AdminHomepageTable } from "../components";

export const AdminHomepageTableContainer: React.FC = () => {
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const closeDialog = () => setDialogOpen(false);
	const openDialog = () => setDialogOpen(true);

	return (
		<>
			<TableProvider table={TableEnum.adminActivities} pageSize={10}>
				<AdminHomepageTable openDialog={openDialog} />
			</TableProvider>
			<AdminActivitiesDialog handleDialogClose={closeDialog} dialogOpen={dialogOpen} />
		</>
	);
};
