import { GenericImageContainer } from "../../elements";
import menuIcon from "../../../assets/images/menu-icon.svg";
import React from "react";

export const MenuIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={menuIcon}
		imageAlt="hamburger menu"
		width="1.26rem"
		height="1rem"
	/>
);
