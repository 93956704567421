import { GenericImageContainer } from "../../elements";
import deleteActionIcon from "../../../assets/images/dashboard/dashboard-activity-delete-icon.svg";
import postActionIcon from "../../../assets/images/dashboard/dashboard-activity-post-icon.svg";
import putActionIcon from "../../../assets/images/dashboard/dashboard-activity-put-icon.svg";
import React from "react";

interface Props {
	imageSrc: string;
	imageAlt: string;
}

export const AdminActivityImage: React.FC<Props> = ({ imageSrc, imageAlt }) => (
	<GenericImageContainer imageSrc={imageSrc} imageAlt={imageAlt} width="0.8125rem" />
);

export const AdminDeleteIcon: React.FC = () => (
	<AdminActivityImage imageSrc={deleteActionIcon} imageAlt="item deleted" />
);

export const AdminPostIcon: React.FC = () => (
	<AdminActivityImage imageSrc={postActionIcon} imageAlt="item posted" />
);

export const AdminPutIcon: React.FC = () => (
	<AdminActivityImage imageSrc={putActionIcon} imageAlt="item modified" />
);
