import {
	Accordion,
	Box,
	ListItemText,
	Step,
	StepLabel,
	styled,
	Typography
} from "@mui/material";

export const AwardsStep = styled(Step)<{ order: number }>(({ order }) => {
	const transform = order % 2 === 0 ? " rotateX(180deg)" : "";
	return {
		transformOrigin: "center 1.13rem",
		transform,
		" .MuiStepConnector-root": {
			top: "1.03rem !important",
			left: "calc(-50% + 1.00rem)",
			right: "calc(50% + 1.13rem)"
		}
	};
});

export const AwardsStepLabel = styled(StepLabel)(() => ({
	position: "relative",
	height: "11.3rem",
	minWidth: "16.3rem"
}));

export const AwardsStepTextfield = styled(Box)<{ order: number }>(({ order }) => {
	const transform = order % 2 === 0 ? " rotateX(180deg)" : "";
	return { transform };
});

export const AwardsDateText = styled(Typography)<{ order: number }>(({ order }) => {
	const transform = order % 2 === 0 ? " rotateX(180deg)" : "";
	return {
		position: "absolute",
		top: "0",
		left: "50%",
		transform: `${transform} translate(-50%)`,
		marginTop: "-2.25rem"
	};
});

export const FaqAccordion = styled(Accordion)<{ index: number; openindex: number }>(
	({ theme, index, openindex }: { theme: any; index: number; openindex: number }) => {
		const background =
			index === openindex ? theme.palette.grey[50] : theme.palette.common.white;
		return {
			boxShadow: "none",
			minWidth: "18.8rem",
			padding: "1.25rem 0",
			background,
			"&.Mui-expanded": {
				borderTop: "1px solid rgba(0, 0, 0, 0.12)"
			},
			[theme.breakpoints.down("md")]: {
				padding: "1.00rem 0",
				background: theme.palette.common.white
			},
			"&:first-of-type": {
				borderTop: "none",
				borderTopLeftRadius: "0.500rem",
				borderTopRightRadius: "0.500rem"
			},
			"&:last-of-type": {
				borderBottomLeftRadius: "0.500rem",
				borderBottomRightRadius: "0.500rem"
			}
		};
	}
);

export const SideMenuItemText = styled(ListItemText)(({ theme }) => ({
	fontSize: "0.875rem",
	color: theme.palette.common.white,
	paddingLeft: "0.875rem",
	paddingBottom: "0.625rem",
	".MuiTypography-root": { fontSize: theme.typography.body2.fontSize, fontWeight: 500 }
}));
