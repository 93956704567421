import { Box } from "@mui/material";
import React from "react";
import { Search } from "../images";
import { AdminSearchField } from "../../../util/theme/styled-components";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { DashboardPlaceholderLocalizations } from "../../../util/dictionaries/dashboard-search-placeholder.dictionary";
import { ClearButton } from "../buttons";
import { useTableContext } from "../../../util/contexts/table.context";
import { SearchBarPopper } from "../poppers";

interface Props {
	inputActive: boolean;
	inputRef: React.RefObject<HTMLInputElement>;
	searchValue: string;
	isTooltipVisible: boolean;
	blurInput: () => void;
	focusInput: () => void;
	mouseOverEvent: () => void;
	mouseOutEvent: () => void;
	setSearchValue: (value: string) => void;
}

export const AdminSearchInput: React.FC<Props> = ({
	inputActive,
	inputRef,
	searchValue,
	isTooltipVisible,
	blurInput,
	focusInput,
	mouseOverEvent,
	mouseOutEvent,
	setSearchValue,
	...props
}) => {
	const { table } = useTableContext();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(String(event.target.value));
	};
	const handleClear = () => {
		setSearchValue("");
		blurInput();
	};

	const localizationKey = DashboardPlaceholderLocalizations[table];
	const { getLocalizedText } = useLanguageContext();
	const placeholder = getLocalizedText(localizationKey);

	const responsiveWidth = { xs: inputActive ? "13rem" : "2.75rem", md: "13rem" };

	return (
		<Box
			sx={{
				transition: "all 1s",
				minWidth: responsiveWidth,
				maxWidth: responsiveWidth
			}}
		>
			<AdminSearchField
				{...props}
				inputRef={inputRef}
				onMouseOver={mouseOverEvent}
				onMouseOut={mouseOutEvent}
				onClick={focusInput}
				onBlur={blurInput}
				startAdornment={
					<Box sx={{ padding: "0.375rem", paddingRight: "0.5rem" }}>
						<Search />
					</Box>
				}
				endAdornment={
					searchValue ? (
						<ClearButton handleClear={handleClear} data-cy="admin-search-clear" />
					) : null
				}
				disableUnderline
				value={searchValue}
				onChange={handleChange}
				placeholder={placeholder}
			/>
			<SearchBarPopper isOpen={isTooltipVisible} anchorEl={inputRef.current} />
		</Box>
	);
};
