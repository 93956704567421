import React from "react";
import { useLanguageContext } from "./util/contexts/language.context";
import { DashboardRouteGenerator } from "./util/generators/dashboard-route.generator";
import { PublicLocalizationGenerator } from "./util/generators/public.generator";
import { Route } from "react-router-dom";
import { DashboardView, FullScreenSpinner } from "./views";
import { useGeneratorEffect } from "./util/hooks/useGeneratorEffect.hook";
import { UserRoleEnum } from "./util/dictionaries/types";
import { DashboardPageItemDictionary } from "./util/dictionaries/dashboard-item.dictionary";
import { SentryRoutes } from "./util/dictionaries/routes.dictionary";

interface Props {
	userRole: UserRoleEnum;
}

export const Dashboard: React.FC<Props> = ({ children, userRole }) => {
	const { setLocalizationObject, localizationObject, language } = useLanguageContext();
	const pageReadyToRender = () => Object.values(localizationObject).length > 0;

	const localizationGenerator = new PublicLocalizationGenerator();

	const [routes, setRoutes] = React.useState<JSX.Element[]>([]);

	const items = DashboardPageItemDictionary[userRole];

	useGeneratorEffect(
		{
			effect: () => localizationGenerator.many(`language=${language}`),
			onSuccess: setLocalizationObject,
			callback: localizationGenerator.abort
		},
		[]
	);

	React.useEffect(() => {
		setRoutes(DashboardRouteGenerator(items));
	}, [localizationObject]);

	return pageReadyToRender() ? (
		<SentryRoutes>
			<Route
				path="/*"
				element={
					<DashboardView
						items={items}
						userRole={userRole}
						children={<SentryRoutes children={routes} />}
					/>
				}
			/>
			{children}
		</SentryRoutes>
	) : (
		<FullScreenSpinner />
	);
};
