import React from "react";
import { ApplicationContainer, PageWithSEO } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	body_1: string;
	title_1: string;
}

export const ApplicationPage: React.FC<Props> = ({
	body_1,
	title_1,
	pageId,
	...seoProps
}) => (
	<PageWithSEO withSidebar {...seoProps}>
		<ApplicationContainer pageId={pageId} body_1={body_1} title_1={title_1} />
	</PageWithSEO>
);
