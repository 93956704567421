import { UserCreateResponseDialogContent } from "../contents";
import { AdminDialogProps, UserCreateResponseDialogContentProps } from "../types";
import { AdminDialogTemplate } from "./admin-dialog.template";

interface Props extends AdminDialogProps, UserCreateResponseDialogContentProps {}

export const UserCreateResponseDialog: React.FC<Props> = ({
	handleDialogClose,
	dialogOpen,
	...contentProps
}) => (
	<AdminDialogTemplate
		handleClose={handleDialogClose}
		dialogOpen={dialogOpen}
		headerLocalizationKey="admin_panel_create_user_text"
	>
		<UserCreateResponseDialogContent
			handleDialogClose={handleDialogClose}
			{...contentProps}
		/>
	</AdminDialogTemplate>
);
