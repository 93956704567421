import { LanguageOptions } from "../dictionaries/localization.dictionary";
import { LanguageEnum } from "../dictionaries/types";

export const LanguageFieldGenerator = () => {
	const generatedObject = {} as Record<LanguageEnum, any>;
	LanguageOptions.forEach(language => {
		Object.assign(generatedObject, { [language]: [] });
	});

	return generatedObject;
};
