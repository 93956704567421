import { Card, CardMedia } from "@mui/material";
import React from "react";

interface Props {
	styles: Record<string, any>;
	mediaSrc: string;
	variant?: "iframe" | "img";
}

export const GenericMedia: React.FC<Props> = ({
	styles,
	mediaSrc,
	variant = "iframe"
}) => (
	<Card sx={styles}>
		<CardMedia
			component={variant}
			scrolling="no"
			sx={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				border: 0
			}}
			src={mediaSrc}
		/>
	</Card>
);
