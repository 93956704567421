import { Grid } from "@mui/material";
import { EditableGenericBody } from "../elements";
import { PageIdProps } from "../types";

interface Props extends PageIdProps {
	title_1: string;
	body_1: string;
	body_2: string;
	subtitle_1: string;
	subtitle_2: string;
}

export const TitleWithListItem: React.FC<{
	subtitle: string;
	body: string;
	pageId: string;
}> = ({ subtitle, body, pageId }) => (
	<>
		<EditableGenericBody
			variants={{ desktop: "h4-primary", mobile: "h4-secondary" }}
			text={subtitle}
			propertyKey="subtitle_1"
			pageId={pageId}
			sx={{ marginBottom: { xs: "0.5rem", md: "1.5rem" } }}
			component="h2"
		/>
		<EditableGenericBody
			variant="body2-primary"
			innerHTML={body}
			propertyKey="body_1"
			pageId={pageId}
		/>
	</>
);

export const TitleWithListContainer: React.FC<Props> = ({
	title_1,
	body_1,
	body_2,
	subtitle_1,
	subtitle_2,
	pageId
}) => {
	return (
		<Grid
			container
			rowSpacing={{ xs: 2, md: 3 }}
			sx={{ marginBottom: { xs: "2rem", md: "unset" } }}
		>
			<Grid item xs={12}>
				<EditableGenericBody
					variants={{ desktop: "h2-secondary", mobile: "h3-secondary" }}
					text={title_1}
					propertyKey="title_1"
					pageId={pageId}
					component="h1"
				/>
			</Grid>
			<Grid item sx={{ mb: "2rem" }}>
				<TitleWithListItem subtitle={subtitle_1} body={body_1} pageId={pageId} />
			</Grid>
			<Grid item>
				<TitleWithListItem subtitle={subtitle_2} body={body_2} pageId={pageId} />
			</Grid>
		</Grid>
	);
};
