import { Stack, Menu } from "@mui/material";
import { DocumentDropdownItem as AssetDropdownItem } from "../../../util/theme/styled-components";
import { LocalizedGenericBody } from "../../elements";
import { DownloadIcon, DeleteIcon } from "../images";

interface Props {
	handleClose: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => void;
	handleDelete: () => void;
	handleDownload: () => void;
	anchorEl: HTMLElement | null;
}

export const AssetDropdown: React.FC<Props> = ({
	handleClose,
	handleDelete,
	handleDownload,
	anchorEl
}) => (
	<Menu
		anchorEl={anchorEl}
		open={!!anchorEl}
		onClose={() => handleClose(null)}
		sx={{
			".MuiList-root": { padding: 0 },
			".MuiMenuItem-root": { width: "4.75rem", padding: "0 0 0 0.5rem" },
			"& .MuiPaper-root": { borderRadius: "0.5rem" }
		}}
	>
		<AssetDropdownItem onClick={handleDownload}>
			<Stack width="100%" direction="row" spacing="0.5rem">
				<DownloadIcon />
				<LocalizedGenericBody
					variant="body3-primary-medium"
					localizationKey="download_asset_button"
				/>
			</Stack>
		</AssetDropdownItem>
		<AssetDropdownItem onClick={handleDelete}>
			<Stack width="100%" direction="row" spacing="0.5rem">
				<DeleteIcon />
				<LocalizedGenericBody
					variant="body3-primary-medium"
					localizationKey="delete_asset_button"
				/>
			</Stack>
		</AssetDropdownItem>
	</Menu>
);
