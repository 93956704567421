import { Stack } from "@mui/material";
import { AssetsEmptyViewImage } from "../images";
import { LocalizedGenericBody } from "../../elements";

export const EmptyAssetViewContent: React.FC = () => (
	<Stack
		alignItems="center"
		justifyContent="center"
		height={{ xs: "12rem", md: "35rem" }}
		spacing="1rem"
	>
		<AssetsEmptyViewImage />
		<LocalizedGenericBody
			variant="body3-primary"
			sx={{ opacity: "0.65" }}
			localizationKey="assets_empty_state_message"
		/>
	</Stack>
);
