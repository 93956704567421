import { TableCell } from "@mui/material";
import React from "react";
import { DashboardTableCellProps } from "../types";

type variantEnum = "body" | "head";

interface IndeterminateCellProps extends DashboardTableCellProps {
	variant: variantEnum;
	sx?: object;
}

export const DashboardTableCell: React.FC<IndeterminateCellProps> = ({
	variant,
	handleClick,
	Content,
	sx
}) => (
	<TableCell
		onClick={handleClick}
		variant={variant}
		sx={{
			px: "2rem",
			maxWidth: "20rem",
			"&.MuiTableCell-stickyHeader": { background: "inherit" },
			border: "none",
			...sx
		}}
	>
		{Content}
	</TableCell>
);
