export class InputValidation {
	errorKey!: string;
	result = true;

	constructor(public value: string) {}

	validation = (value: string) => true; // eslint-disable-line @typescript-eslint/no-unused-vars
	validate = () => {
		this.result = this.validation(this.value);
		return this;
	};
}

export class NotEmpty extends InputValidation {
	errorKey = "input_validation_not_empty";
	validation = (value: string) => !!value && value !== "";
}

export class ValidEmail extends InputValidation {
	errorKey = "input_validation_email";
	validation = (value: string) =>
		!!value.match(
			/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
		);
}

export class Validator<Fields extends string> {
	failedCheck: undefined | InputValidation;
	errorKey: null | string = null;

	constructor(public validations: Record<Fields, (typeof InputValidation)[]>) {}

	validate = (
		fieldName: Fields,
		value: string,
		setErrors?: React.Dispatch<React.SetStateAction<Record<Fields, boolean>>>,
		setErrorKeys?: React.Dispatch<React.SetStateAction<Record<Fields, string | null>>>
	) => {
		const checks = this.validations[fieldName].map(Validation =>
			new Validation(value).validate()
		);

		this.failedCheck = checks.find(({ result }) => !result);
		this.errorKey = !!this.failedCheck ? this.failedCheck.errorKey : null;

		if (setErrors) this.setErrors(fieldName, setErrors);
		if (setErrorKeys) this.setErrorKeys(fieldName, setErrorKeys);
		return this;
	};

	setErrors = (
		fieldName: Fields,
		setErrors: React.Dispatch<React.SetStateAction<Record<Fields, boolean>>>
	) => {
		setErrors(previous => ({ ...previous, [fieldName]: !!this.failedCheck }));
	};

	setErrorKeys = (
		fieldName: Fields,
		setErrorKeys: React.Dispatch<React.SetStateAction<Record<Fields, string | null>>>
	) => {
		setErrorKeys(previous => ({ ...previous, [fieldName]: this.errorKey }));
	};
}
