import { MenuItem, Stack, Box } from "@mui/material";
import React from "react";
import { LocalizedTypographyWithVariableSubject } from "../../elements";
import { DashboardFooterSelectInput } from "../inputs";
import { DashboardTableFooterPagination } from "../paginations/dashboard-table-footer.pagination";
import { useTableContext } from "../../../util/contexts/table.context";

interface Props {
	pageSizeFixed?: boolean;
}

export const DashboardTableFooter: React.FC<Props> = ({ pageSizeFixed = false }) => {
	const { pageSize } = useTableContext();

	return (
		<Box
			sx={{
				display: "flex",
				padding: "0.5rem",
				backgroundColor: "grey.300",
				borderBottomLeftRadius: "inherit",
				borderBottomRightRadius: "inherit"
			}}
		>
			<Stack
				direction={{ xs: "column", sm: "row" }}
				alignItems={{ xs: "left", sm: "center" }}
				gap="0.5rem"
				justifyContent="space-between"
				width="100%"
			>
				{pageSizeFixed ? (
					<Box />
				) : (
					<DashboardFooterSelectInput
						data-cy="page-size-select"
						value={pageSize.toString()}
					>
						{[5, 10, 15, 20, 25, 50, 100].map(pageSizeOption => {
							const selected = pageSizeOption === pageSize;
							return (
								<MenuItem
									value={pageSizeOption}
									key={pageSizeOption}
									sx={{
										":hover": { backgroundColor: "grey.300" },
										backgroundColor: selected ? "grey.300" : "common.white"
									}}
								>
									<LocalizedTypographyWithVariableSubject
										variant={selected ? "body3-primary-bold" : "body3-primary-medium"}
										localizationKey="dashboard_footer_entry_text"
										variable={pageSizeOption.toString()}
									/>
								</MenuItem>
							);
						})}
					</DashboardFooterSelectInput>
				)}
				<DashboardTableFooterPagination data-cy="footer-pagination" />
			</Stack>
		</Box>
	);
};
