import React from "react";
import { AnnouncementsDAO } from "../../util/generators/types";
import { PublicAnnouncementGenerator } from "../../util/generators/public.generator";
import { useLanguageContext } from "../../util/contexts/language.context";
import { BannerAnnouncement, PopupAnnouncementDialog } from "../components";
import { Strip } from "../elements";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";

interface Props {
	isAdmin?: boolean;
}

export const AnnouncementsContainer: React.FC<Props> = ({ isAdmin }) => {
	const { language } = useLanguageContext();

	const [bannerAnnouncement, setBannerAnnouncement] = React.useState<AnnouncementsDAO>();
	const [popupAnnouncement, setPopupAnnouncement] = React.useState<AnnouncementsDAO>();

	const [dialogOpen, setDialogOpen] = React.useState(true);

	const generator = new PublicAnnouncementGenerator();

	const setBannerAndPopupAnnouncements = (announcements: AnnouncementsDAO[]) => {
		setBannerAnnouncement(announcements.find(({ type }) => type === "banner"));
		setPopupAnnouncement(announcements.find(({ type }) => type === "popup"));
	};

	useGeneratorEffect(
		{
			effect: () => generator.manyForLanguage(language),
			onSuccess: setBannerAndPopupAnnouncements,
			callback: generator.abort
		},
		[]
	);

	const handleDialogClose = () => setDialogOpen(false);

	return (
		<>
			{popupAnnouncement && !isAdmin ? (
				<PopupAnnouncementDialog
					handleDialogClose={handleDialogClose}
					announcement={popupAnnouncement}
					dialogOpen={dialogOpen}
				/>
			) : null}
			{bannerAnnouncement ? (
				<Strip variant="bg-announcement">
					<BannerAnnouncement announcement={bannerAnnouncement} />
				</Strip>
			) : null}
		</>
	);
};
