import { LoginEmailInput, LoginPasswordInput } from "../../inputs";
import { AdminLoginFlowFormTemplate } from "./admin-login.template";
import { AdminLoginProps } from "./types";

export const AdminLoginForm: React.FC<AdminLoginProps> = ({
	handleChange,
	handleSubmit,
	fieldValues,
	submissionStatus,
	validate
}) => (
	<AdminLoginFlowFormTemplate
		headerLocalization="admin_login_heading"
		buttonLocalization="login_button_placeholder"
		handleSubmit={handleSubmit}
		validate={validate}
	>
		<LoginEmailInput
			data-cy="admin-login-email-input"
			handleChange={handleChange}
			value={fieldValues.email}
			submissionStatus={submissionStatus}
		/>
		<LoginPasswordInput
			data-cy="admin-login-password-input"
			handleChange={handleChange}
			value={fieldValues.password}
			submissionStatus={submissionStatus}
		/>
	</AdminLoginFlowFormTemplate>
);
