import { containerHeight, containerMargin } from "../charts/bar.chart";
import { useChartContext } from "../../../util/contexts/chart.context";

interface Props {
	height: number;
	ownerState: { dataIndex: number };
	y: number;
	x: number;
	width: number;
}

export const BarChartBarItem: React.FC<Props> = ({
	height,
	ownerState: { dataIndex },
	y,
	x,
	width
}) => {
	const { highlightedIndex } = useChartContext();

	const emptyBarHeight = 5;

	const adjustedHeight = Math.max(height, emptyBarHeight);
	const adjustedY = Math.min(
		y,
		containerHeight - containerMargin.bottom - containerMargin.top + adjustedHeight
	);

	const highlighted = highlightedIndex === dataIndex;

	const gradientId =
		adjustedHeight === emptyBarHeight
			? "greyGradient"
			: highlighted
			? "highlightedGradient"
			: "defaultGradient";

	const clipPathId = `clip-${dataIndex}`;

	return (
		<svg>
			<defs>
				<clipPath id={clipPathId}>
					<path
						d={`M${x},${adjustedY} h${width - 4} a4,4 0 0 1 4,4 v${
							adjustedHeight - 4
						} h-${width} v-${adjustedHeight - 4} a4,4 0 0 1 4,-4 z`}
					/>
				</clipPath>
			</defs>

			<rect
				id={`bar-slot-#${dataIndex}`}
				x={x}
				y={adjustedY}
				width={width}
				height={adjustedHeight}
				fill={`url(#${gradientId})`}
				clipPath={`url(#${clipPathId})`}
			/>
		</svg>
	);
};
