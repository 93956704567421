import { GenericBody } from "../../../elements";
import { FilterInputLabel } from "../../../../util/theme/styled-components";

interface Props {
	isSelected: boolean;
	styles: Record<string, any>;
	localizedText: string;
	labelId: string;
}

export const DashboardFilterLabel: React.FC<Props> = ({
	styles,
	isSelected,
	localizedText,
	labelId
}) => (
	<FilterInputLabel id={labelId} shrink={isSelected} sx={styles}>
		<GenericBody variant="body3-primary-medium" text={localizedText} />
	</FilterInputLabel>
);
