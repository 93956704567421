import { Stack } from "@mui/material";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminFormCreateUpdateButton } from "../buttons";
import {
	CityInput,
	DescriptionInput,
	LanguageSelect,
	OpeningsRequirementsInput,
	OpeningsReferenceIdInput,
	TitleInput
} from "../inputs";

export const AdminPanelJobOpeningsForm: React.FC<FormProps> = ({ handleSubmit }) => (
	<Stack spacing={2}>
		<TitleInput />
		<DescriptionInput />
		<CityInput />
		<OpeningsRequirementsInput />
		<OpeningsReferenceIdInput />
		<LanguageSelect />
		<AdminFormCreateUpdateButton handleClick={handleSubmit} />
	</Stack>
);
