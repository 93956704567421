import { LoginEmailInput } from "../../inputs";
import { AdminLoginFlowFormTemplate } from "./admin-login.template";
import { ForgotPasswordProps } from "./types";

export const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
	handleChange,
	handleSubmit,
	email,
	validate,
	submissionStatus
}) => {
	const handleInputChange = (_: string, value: string) => handleChange(value);

	return (
		<AdminLoginFlowFormTemplate
			headerLocalization="admin_forgot_password_heading"
			buttonLocalization="admin_forgot_password_button"
			handleSubmit={handleSubmit}
			validate={validate}
		>
			<LoginEmailInput
				handleChange={handleInputChange}
				value={email}
				submissionStatus={submissionStatus}
			/>
		</AdminLoginFlowFormTemplate>
	);
};
