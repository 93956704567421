import { Box, Stack } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { DocumentExtension } from "../../../util/dictionaries/types";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { GenericBody, LocalizedGenericButton } from "../../elements";
import { ExtensionIcon, OpenIcon } from "../images";
interface Props {
	name: string;
	date?: string;
	extension: DocumentExtension;
	link: string;
}

export const DocumentHeading: React.FC<Props> = ({ date, name, extension, link }) => {
	const displayDate = date
		? DateTime.fromISO(date).setLocale("en-gb").toLocaleString()
		: "";

	const { isMobile } = useResponsive();

	return (
		<Stack direction="row" justifyContent="space-between" width="100%" gap="0.750rem">
			<Stack direction="row" alignItems="center" width="100%">
				<Box sx={{ alignSelf: { xs: "flex-start", md: "center" } }}>
					<ExtensionIcon variant={extension} />
				</Box>
				<Stack
					direction={{ xs: "column", md: "row" }}
					alignItems={{ xs: "flex-start", md: "center" }}
					width="100%"
				>
					<GenericBody
						variant="body2-primary-medium"
						innerHTML={name}
						sx={{ marginLeft: "0.688rem" }}
					/>
					{date ? (
						<GenericBody
							variants={{ desktop: "body3-grey", mobile: "body4-grey" }}
							sx={{ marginLeft: "0.688rem", marginRight: "0.750rem" }}
							boxStyles={{ marginLeft: { xs: "unset", md: "auto" } }}
							text={displayDate}
						/>
					) : (
						<></>
					)}
				</Stack>
			</Stack>
			{isMobile ? (
				<></>
			) : (
				<LocalizedGenericButton
					variant="secondary"
					mini
					startIcon={<OpenIcon />}
					target="_blank"
					href={link}
					sx={{
						minHeight: "unset",
						height: "1.75rem",
						alignSelf: "center",
						background: "transparent"
					}}
					localizationKey="open_document_button_text"
					textVariant="body4-primary-medium"
				/>
			)}
		</Stack>
	);
};
