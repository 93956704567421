import { Grid, Stack } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { DocumentDAO } from "../../util/generators/types";
import { PublicDocumentsGenerator } from "../../util/generators/public.generator";
import { DocumentsContent, DocumentYearSelect } from "../components";
import { EditableGenericBody } from "../elements";
import { PageIdProps } from "../types";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";

interface Props extends PageIdProps {
	body_1: string;
	description: string;
	hasDate: boolean;
	hasInfo?: boolean;
}

export const allYearsOption = "...";

export const DocumentsContainer: React.FC<Props> = ({
	description,
	body_1,
	pageId,
	hasDate,
	hasInfo
}) => {
	const [selectedYear, setSelectedYear] = React.useState<string>(allYearsOption);
	const [documents, setDocuments] = React.useState<DocumentDAO[]>([]);
	const [documentYears, setDocumentYears] = React.useState<string[]>([]);
	const [shouldLoadMore, setShouldLoadMore] = React.useState<boolean>(true);

	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);

	const handleDropdownClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent> | null
	) => {
		const nextAnchorElement = event?.currentTarget || null;
		setAnchorElement(nextAnchorElement);
	};

	const setPaginationButtonVisibility = () =>
		setShouldLoadMore(generator.hasMore && documents.length > 0);

	const handleSelect = (year: string): void => {
		setSelectedYear(year || allYearsOption);
		setAnchorElement(null);
	};

	const generatorRef = React.useRef(new PublicDocumentsGenerator());
	const generator = generatorRef.current;

	const fetchDocuments = async ({ next, year }: { next: boolean; year?: string }) => {
		const convertToISO = (method: "startOf" | "endOf") =>
			DateTime.utc(Number(year))[method]("year").toISO();

		let dateFilter = "";
		if (hasDate && year)
			dateFilter = `&date[$between]=${convertToISO(
				"startOf"
			)}&date[$between]=${convertToISO("endOf")}`;

		return generator.many(`pageId=${pageId}${dateFilter}&sort=-date`).then(response => {
			const nextDocuments = next ? [...documents, ...response] : response;
			setDocuments(nextDocuments);
		});
	};

	const adjustDateAndSetYears = ({
		data: { options }
	}: {
		data: { options: string[] };
	}) => {
		const years = [
			...new Set(options.map(date => new Date(date).getFullYear().toString()))
		];
		setDocumentYears(years);
	};

	useGeneratorEffect(
		{
			effect: () => generator.findOptions(`date?pageId=${pageId}`),
			onSuccess: adjustDateAndSetYears,
			callback: generator.abort
		},
		[]
	);

	const createFetchOptions = ({ next }: { next: boolean }) =>
		hasDate
			? { next, year: selectedYear === allYearsOption ? "" : selectedYear }
			: { next };

	React.useEffect(() => {
		if (documentYears.length === 0) return;
		if (!selectedYear) {
			setSelectedYear(documentYears[0]);
			return;
		}
		generator.currentPage = 0;
		fetchDocuments(createFetchOptions({ next: false }));
	}, [selectedYear, documentYears]);

	React.useEffect(() => {
		return generator.abort;
	}, []);

	React.useEffect(() => {
		setPaginationButtonVisibility();
	}, [documents]);

	const handleNextClick = () => fetchDocuments(createFetchOptions({ next: true }));

	const contentProps = {
		handleNextClick,
		documents,
		hasInfo,
		shouldLoadMore
	};

	return (
		<Stack spacing={2}>
			<Grid container>
				<Grid item>
					<EditableGenericBody
						variants={{ desktop: "h2-secondary", mobile: "h3-secondary" }}
						text={description}
						propertyKey="description"
						pageId={pageId}
						sx={{ marginBottom: { xs: "0.500rem", md: "1.50rem" } }}
						component="h1"
					/>
				</Grid>
				<Grid xs item order={{ xs: 3, md: 2 }}>
					<Stack
						alignItems="flex-end"
						sx={{ marginBottom: { xs: "0.750rem", md: "1.50rem" } }}
					>
						{hasDate ? (
							<DocumentYearSelect
								anchorEl={anchorElement}
								handleButtonClick={handleDropdownClick}
								selectedYear={selectedYear}
								documentYears={documentYears}
								handleChange={handleSelect}
							/>
						) : (
							<></>
						)}
					</Stack>
				</Grid>
				<Grid item xs={12} order={{ xs: 2, md: 3 }}>
					<EditableGenericBody
						variant="body2-primary"
						text={body_1}
						propertyKey="body_1"
						pageId={pageId}
						sx={{ marginBottom: { xs: "2.25rem", md: "1.50rem" } }}
					/>
				</Grid>
			</Grid>
			<DocumentsContent {...contentProps} />
		</Stack>
	);
};
