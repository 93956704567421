import React from "react";
import AdminDashboard from "./AdminDashboard";
import ClientDashboard from "./CLientDashboard";
import Website from "./Website";
import { useAppModeContext } from "./util/contexts/app-mode.context";
import { AppMode } from "./util/contexts/types";
import FastEditWebsite from "./FastEditWebsite";
import { FullScreenSpinner } from "./views";

const App = () => {
	const ApplicationDictionary: Record<AppMode, JSX.Element> = {
		admin: <AdminDashboard />,
		client: <ClientDashboard />,
		website: <Website />,
		"fast-edit": <FastEditWebsite />
	};

	const { appMode } = useAppModeContext();

	const [AppToRender, setAppToRender] = React.useState<JSX.Element>(
		<FullScreenSpinner />
	);

	React.useEffect(() => {
		setAppToRender(ApplicationDictionary[appMode]);
	}, [appMode]);

	return AppToRender;
};

export default App;
