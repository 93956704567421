import React from "react";
import { LocalizationContainer } from "../../containers";
import { InputValidation } from "../../../util/validations/input.validation";
import { ValidatedTextInput } from "./validated-text.input";
import {
	ContactFormMessageField,
	ContactFormTextField as StyledContactFormTextField
} from "../../../util/theme/styled-components";
import { ContactFormInfoFields } from "../../containers/types";

interface Props {
	handleChange: (
		infoField: ContactFormInfoFields,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	setIsValidForField: (infoField: ContactFormInfoFields, isValid: null | boolean) => void;
	infoField: ContactFormInfoFields;
	placeholderKey: string;
	value: string;
	isValid: null | boolean;
	Validation: typeof InputValidation;
}

interface BaseProps extends Props {
	Component: typeof StyledContactFormTextField | typeof ContactFormMessageField;
	customizations?: {
		multiline?: boolean;
		rows?: number;
	};
}

export const ContactFormInputBase: React.FC<BaseProps> = ({
	Component,
	customizations = { rows: 1, multiline: false },
	handleChange,
	setIsValidForField,
	infoField,
	placeholderKey,
	value,
	isValid,
	Validation
}) => (
	<LocalizationContainer localizationKey={placeholderKey}>
		{({ localizedText }) => (
			<ValidatedTextInput
				InputComponent={Component}
				InputProps={{
					fullWidth: true,
					onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
						handleChange(infoField, event),
					placeholder: localizedText,
					value,
					...customizations
				}}
				Validation={Validation}
				isValid={isValid}
				passValidationResult={isValid => setIsValidForField(infoField, isValid)}
				helperTextVariant="body3-error-semi-bold"
			/>
		)}
	</LocalizationContainer>
);

export const ContactFormTextField: React.FC<Props> = props => (
	<ContactFormInputBase Component={StyledContactFormTextField} {...props} />
);

export const ContactFormTextArea: React.FC<Props> = props => (
	<ContactFormInputBase
		Component={ContactFormMessageField}
		customizations={{ multiline: true, rows: 5 }}
		{...props}
	/>
);
