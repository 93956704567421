import React from "react";
import { AdminSearchInput } from "../components";
import {
	DashboardTableConfig,
	SearchableColumn
} from "../../util/configs/dashboard-table";
import { useTableContext } from "../../util/contexts/table.context";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";

export const DashboardSearchContainer: React.FC = () => {
	const { table, setSearchQueryString } = useTableContext();

	const inputRef = React.useRef<HTMLInputElement>(null);

	const [isTooltipVisible, setIsTooltipVisible] = React.useState<boolean>(false);
	const [inputFocused, setInputFocused] = React.useState<boolean>(false);
	const [searchValue, setSearchValue] = React.useState<string>("");
	const searchableColumns = DashboardTableConfig[table].searchable;

	const hideSearchInput = searchableColumns.length === 0;
	const inputActive = !!searchValue || inputFocused;

	React.useEffect(() => {
		setSearchValue("");
	}, [table]);

	useUpdateEffect(() => {
		updateSearchQueryString();
	}, [searchValue]);

	const updateSearchQueryString = () => {
		const searchQueryString = createSearchQueryString(searchableColumns, searchValue);
		setSearchQueryString(searchQueryString);
	};

	const focusInput = () => {
		if (inputActive) return;
		inputRef.current?.focus();
		setInputFocused(true);
	};

	const blurInput = () => setInputFocused(false);

	const mouseOverEvent = () => {
		if (inputRef.current) {
			setIsTooltipVisible(true);
		}
	};

	const mouseOutEvent = () => {
		setIsTooltipVisible(false);
	};

	return hideSearchInput ? null : (
		<AdminSearchInput
			data-cy="admin-search-input"
			inputActive={inputActive}
			inputRef={inputRef}
			searchValue={searchValue}
			isTooltipVisible={isTooltipVisible}
			blurInput={blurInput}
			focusInput={focusInput}
			mouseOverEvent={mouseOverEvent}
			mouseOutEvent={mouseOutEvent}
			setSearchValue={setSearchValue}
		/>
	);
};

const createSearchQueryString = (
	searchableColumns: SearchableColumn[],
	searchValue: string
) => searchableColumns.map(column => column.createQuery(searchValue)).join("&");
