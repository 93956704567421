import React from "react";
import { NewsDAO } from "../../../util/generators/types";
import { Breadcrumbs } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import { useNavigate } from "react-router";

export const NewsBreadcrumbs: React.FC<{ news: NewsDAO }> = ({ news: { title } }) => {
	const navigate = useNavigate();

	return (
		<Breadcrumbs
			separator={<ChevronRight sx={{ fontSize: "1.25rem" }} />}
			sx={{ marginBottom: "1rem" }}
		>
			<LocalizedGenericBody
				variant="subtitle1-primary"
				localizationKey="news_inspired_heading"
				handleClick={() => navigate(`/${location.pathname.split("/")[1]}`)}
			/>
			<GenericBody variant="subtitle1-primary" text={title} />
		</Breadcrumbs>
	);
};
