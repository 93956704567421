import { Menu, Typography } from "@mui/material";
import { DocumentDropdownItem } from "../../../util/theme/styled-components";
import { allYearsOption } from "../../containers";
import { LocalizedGenericBody } from "../../elements";

interface Props {
	handleClose: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => void;
	handleChange: (year: string) => void;
	anchorEl: HTMLElement | null;
	documentYears: string[];
}

export const DocumentDropdown: React.FC<Props> = ({
	handleClose,
	handleChange,
	anchorEl,
	documentYears
}) => (
	<Menu
		anchorEl={anchorEl}
		open={!!anchorEl}
		onClose={() => handleClose(null)}
		anchorOrigin={{ horizontal: 0, vertical: 40 }}
		sx={{ ".MuiList-root": { padding: 0 } }}
	>
		<DocumentDropdownItem onClick={() => handleChange(allYearsOption)}>
			<LocalizedGenericBody
				variant="body3-primary-medium"
				localizationKey="document_select_all_years"
			/>
		</DocumentDropdownItem>
		{documentYears
			.sort((a, b) => Number(b) - Number(a))
			.map((year, index) => (
				<DocumentDropdownItem key={index} onClick={() => handleChange(year)}>
					<Typography variant="body3-primary-medium">{year}</Typography>
				</DocumentDropdownItem>
			))}
	</Menu>
);
