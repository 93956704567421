import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { LoadMoreButton as StyledButton } from "../../../util/theme/styled-components";
import { LocalizationContainer } from "../../containers";

interface Props {
	styles?: { button: Record<string, any> };
	handleNextClick: () => void;
}

export const LoadMoreButton: React.FC<Props> = ({
	handleNextClick,
	styles = { button: {} }
}) => (
	<LocalizationContainer localizationKey="load_more_button_placeholder">
		{({ localizedText }) => (
			<StyledButton
				disableRipple
				onClick={handleNextClick}
				sx={styles.button}
				endIcon={<ExpandCircleDownOutlined />}
			>
				{localizedText}
			</StyledButton>
		)}
	</LocalizationContainer>
);
