import {
	Clear,
	ExpandCircleDownOutlined,
	ExpandLess,
	ExpandMore,
	FileCopy,
	KeyboardArrowDown,
	KeyboardArrowUp,
	Search
} from "@mui/icons-material";
import { styled } from "@mui/material";

export const SideMenuExpandLess = styled(ExpandLess)(({ theme }) => ({
	color: theme.palette.common.white
}));

export const SideMenuExpandMore = styled(ExpandMore)(({ theme }) => ({
	color: theme.palette.common.white
}));

export const FaqArrow = styled(ExpandCircleDownOutlined)<{
	openindex: number;
	index: number;
}>(({ theme, openindex, index }) => ({
	height: "1.50rem",
	width: "1.50rem",
	color: openindex === index ? theme.palette.primary.main : theme.palette.tertiary.main
}));

export const SearchBarIcon = styled(Search)(({ theme }) => ({
	fill: theme.palette.common.white,
	fontSize: "1.00rem",
	[theme.breakpoints.down("md")]: {
		fontSize: "0.750rem"
	}
}));

export const WhiteClearIcon = styled(Clear)(({ theme }) => ({
	fill: theme.palette.common.white,
	fontSize: "1.88rem"
}));

export const FileIcon = styled(FileCopy)(({ theme }) => ({
	fill: theme.palette.primary.main,
	fontSize: "1.88rem"
}));

export const FilterArrowDown = styled(KeyboardArrowDown)(({ theme }) => ({
	fill: theme.palette.primary.main,
	fontSize: "1rem"
}));

export const PaginationArrowDown = styled(KeyboardArrowDown)(({ theme }) => ({
	fill: theme.palette.primary.main,
	fontSize: "1rem"
}));

export const PaginationArrowUp = styled(KeyboardArrowUp)(({ theme }) => ({
	fill: theme.palette.primary.main,
	fontSize: "1rem"
}));
