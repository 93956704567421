import { useTableContext } from "../../../util/contexts/table.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import {
	AdminHomepageTableBox,
	DashboardTablePaper
} from "../../../util/theme/styled-components";
import { DashboardTableBody } from "../bodies";
import { EmptyTableViewContent } from "../contents";
import { DashboardTableHeaderRow } from "../rows";
import { DashboardTableWrapper } from "./dashboard.table";
import { MobileTable } from "./mobile.table";
import { SkeletonTable } from "./skeleton.table";
import { AdminHomepageTableHeader } from "../headers";

import adminActivitiesIcon from "../../../assets/images/dashboard/dashboard-admin-activities.icon.svg";

interface Props {
	openDialog: () => void;
}

export const AdminHomepageTable: React.FC<Props> = ({ openDialog }) => {
	const { isMobile } = useResponsive();
	const { isLoading, tableElements } = useTableContext();

	const numberOfDisplayableAdminActivities = tableElements.length;

	const [desktopTable, mobileTable] = isLoading
		? [
				<DashboardTablePaper $inBlock>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow variant="grey" />
						<SkeletonTable />
					</DashboardTableWrapper>
				</DashboardTablePaper>,
				<MobileTable loadMoreDisabled />
		  ]
		: numberOfDisplayableAdminActivities > 0
		? [
				<DashboardTablePaper $inBlock>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow variant="grey" />
						<DashboardTableBody />
					</DashboardTableWrapper>
				</DashboardTablePaper>,
				<MobileTable loadMoreDisabled />
		  ]
		: [
				<DashboardTablePaper $inBlock>
					<DashboardTableWrapper>
						<DashboardTableHeaderRow variant="grey" />
					</DashboardTableWrapper>
					<EmptyTableViewContent />
				</DashboardTablePaper>,
				<MobileTable loadMoreDisabled />
		  ];

	const tableContent = isMobile ? mobileTable : desktopTable;

	return (
		<AdminHomepageTableBox>
			<AdminHomepageTableHeader
				handleClick={openDialog}
				imageSrc={adminActivitiesIcon}
				localizationKey="dashboard_sidebar_activities_text"
				padding={{ xs: "1.25rem 0", md: "1.25rem 2rem" }}
			/>
			{tableContent}
		</AdminHomepageTableBox>
	);
};
