import { Grid, Stack } from "@mui/material";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminFormCreateUpdateButton } from "../buttons";
import {
	CategoryInput,
	FaqsQuestionInput,
	FaqsAnswerInput,
	LanguageSelect,
	IndexInput
} from "../inputs";

export const AdminPanelFaqsForm: React.FC<FormProps> = ({ handleSubmit }) => (
	<Stack spacing="0.75rem">
		<FaqsQuestionInput />
		<FaqsAnswerInput />
		<CategoryInput />
		<Grid container gap="0.75rem">
			<Grid item xs>
				<LanguageSelect />
			</Grid>
			<Grid item xs>
				<IndexInput />
			</Grid>
		</Grid>
		<AdminFormCreateUpdateButton handleClick={handleSubmit} />
	</Stack>
);
