import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";
import { ThreeCardsContentPage } from "./three-cards-content.page";

interface Props extends SeoProps, PageIdProps {
	title_1: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
}

export const ThreeCardsWithIconsPage: React.FC<Props> = props => (
	<ThreeCardsContentPage {...props} illustrated />
);
