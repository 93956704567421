import { Paper } from "@mui/material";
import React from "react";
import { LayoutWrapper } from "../containers";

interface Props {
	variant?: any;
}

export const Strip: React.FC<Props> = ({ children, variant }) => {
	return (
		<Paper variant={variant}>
			<LayoutWrapper sx={{ paddingY: "3.13rem" }}>{children}</LayoutWrapper>
		</Paper>
	);
};
