import { Stack, useTheme } from "@mui/material";
import { LoginContainerStack } from "../../../util/theme/styled-components";
import { LiderLoginLogo, LoginBreadLinks } from "../../components";
import loginIcon from "../../../assets/images/login-icon.svg";

export const LoginContainerTemplate: React.FC = ({ children }) => {
	const theme = useTheme();

	return (
		<LoginContainerStack backgroundimage={loginIcon}>
			<Stack alignItems="center" gap={{ xs: 0, md: "2rem" }}>
				<Stack
					alignItems="center"
					gap={{ xs: "2rem", md: "5.0625rem" }}
					width={{ xs: "100vw", md: "35.875rem" }}
					maxWidth="35.875rem"
					padding={{ xs: "3.1875rem 1rem", md: "3.1875rem 3.5rem" }}
					sx={{
						position: "relative",
						borderRadius: { xs: 0, sm: "1rem" },
						background: theme.palette.common.white,
						boxShadow: `0 0.25rem 0.5rem 0 ${theme.palette.grey[500]}33`
					}}
				>
					<LiderLoginLogo />
					{children}
				</Stack>
				<LoginBreadLinks />
			</Stack>
		</LoginContainerStack>
	);
};
