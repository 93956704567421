import { CssBaseline, Divider, IconButton, Stack, Box } from "@mui/material";
import React from "react";
import { HeaderSection } from "../sections";
import { LocalizationSelect } from "../items";
import { PageDAO } from "../../../util/generators/types";
import { LanguageEnum } from "../../../util/dictionaries/types";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import {
	HeaderCollapseButton,
	HeaderCollapseMenu,
	HeaderSectionDrawer
} from "../../../util/theme/styled-components";
import { HeaderCollapse, MenuIcon } from "../images";
import { HeaderSectionPopover } from "../dropdowns";
import { SideMenuCollapsibleList } from "./side-menu-collapsible.list";

interface HeaderSection {
	name: string;
	route: string;
	index: number;
}

interface HeaderSections {
	[key: string]: HeaderSection;
}

interface HeaderProps {
	closeSectionMenu: () => void;
	handleDrawerToggle: () => void;
	handleLocalization: (language: LanguageEnum) => void;
	handleDropdownClick: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent> | null
	) => void;
	openSectionMenu: (
		event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		sectionId: string
	) => void;
	anchorEl: HTMLElement | null;
	drawerOpen: boolean;
	hoveredSectionId: string;
	invisibleSections: HeaderSections;
	pagesInHoveredSection: PageDAO[];
	sectionAnchorEl: HTMLElement | null;
	sections: HeaderSections;
}

export const HeaderSectionList: React.FC<HeaderProps> = ({
	closeSectionMenu,
	handleDrawerToggle,
	handleDropdownClick,
	handleLocalization,
	openSectionMenu,
	anchorEl,
	drawerOpen,
	hoveredSectionId,
	invisibleSections,
	pagesInHoveredSection,
	sectionAnchorEl,
	sections
}) => {
	const { isTablet, isLarge, isLarger, isXLarge } = useResponsive();

	const [open, setOpen] = React.useState(!!anchorEl);
	const [overflowAnchor, setOverflowAnchor] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setOverflowAnchor(event.currentTarget);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setOverflowAnchor(null);
	};

	const allowedSectionCount = isLarge ? 3 : isLarger ? 4 : isXLarge ? 5 : 6;

	const sectionsWithId = Object.entries(sections).map(([id, item]) => ({ ...item, id }));

	const headerSections: React.ReactElement[] = sectionsWithId
		.slice(0, allowedSectionCount)
		.map(({ id, name, route }) => (
			<HeaderSection
				key={id}
				title={name}
				hoveredSectionId={hoveredSectionId}
				route={route}
				sectionId={id}
				openMenu={openSectionMenu}
				closeMenu={closeSectionMenu}
			/>
		));

	const collapsedSections: React.ReactElement[] = sectionsWithId
		.slice(allowedSectionCount)
		.map(({ id, name, route }) => (
			<HeaderSection
				key={id}
				collapsed={true}
				title={name}
				hoveredSectionId={hoveredSectionId}
				route={route}
				sectionId={id}
				openMenu={openSectionMenu}
				closeMenu={closeSectionMenu}
			/>
		));

	return (
		<>
			<CssBaseline />
			{isTablet ? (
				<>
					<IconButton
						size="large"
						onClick={handleDrawerToggle}
						sx={{
							padding: "0.375rem",
							borderRadius: 0,
							".MuiPaper-root": { borderRadius: 0 }
						}}
					>
						<MenuIcon />
					</IconButton>
					<HeaderSectionDrawer
						variant="temporary"
						anchor="right"
						open={drawerOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true
						}}
					>
						<SideMenuCollapsibleList
							sections={{ ...sections, ...invisibleSections }}
							handleDrawerToggle={handleDrawerToggle}
						/>
						<Divider />
						<Box pl={{ xs: "0", md: "0.938rem" }}>
							<LocalizationSelect
								mobile
								anchorEl={anchorEl}
								handleButtonClick={handleDropdownClick}
								handleChange={handleLocalization}
							/>
						</Box>
						<Divider />
					</HeaderSectionDrawer>
				</>
			) : (
				<Stack
					direction="row"
					spacing={{ md: 2, lg: 4, xl: 3 }}
					sx={{
						flexWrap: "wrap",
						justifyContent: "flex-end",
						alignItems: "center"
					}}
				>
					{headerSections}
					{collapsedSections.length > 0 ? (
						<>
							<HeaderCollapseButton
								onClick={handleClick}
								onMouseEnter={closeSectionMenu}
								aria-controls={open ? "hidden-sections-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
							>
								<Box>
									<HeaderCollapse />
								</Box>
							</HeaderCollapseButton>
							<HeaderCollapseMenu
								anchorEl={overflowAnchor}
								id="hidden-sections-menu"
								open={open}
								onClose={handleClose}
								onClick={handleClose}
								transformOrigin={{ horizontal: "center", vertical: "top" }}
								anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
								PaperProps={{ onMouseLeave: handleClose }}
							>
								{collapsedSections}
							</HeaderCollapseMenu>
						</>
					) : null}

					<LocalizationSelect
						anchorEl={anchorEl}
						handleButtonClick={handleDropdownClick}
						handleChange={handleLocalization}
					/>
					<HeaderSectionPopover
						closeMenu={closeSectionMenu}
						anchorEl={sectionAnchorEl}
						pagesInHoveredSection={pagesInHoveredSection}
					/>
				</Stack>
			)}
		</>
	);
};
