import React from "react";
import { ConfirmationDialog } from "./confirmation.dialog";
import { useTableContext } from "../../../util/contexts/table.context";
import { ConfirmationVariant } from "../types";

interface Props {
	handleClose: () => void;
	handleConfirmation: () => void;
	dialogOpen: boolean;
}

export const ActivationConfirmationDialog: React.FC<Props> = props => {
	const { selectedElements } = useTableContext();

	const numberOfSelectedElements = selectedElements.length;

	const multipleElementsSelected = numberOfSelectedElements > 1;

	const localizationKeyPluralAddition = multipleElementsSelected ? "_plural" : "";

	const activateUserVariant = selectedElements[0].active
		? "deactivateUser"
		: "activateUser";

	const confirmationDialogVariant = (activateUserVariant +
		localizationKeyPluralAddition) as ConfirmationVariant;

	return <ConfirmationDialog {...props} variant={confirmationDialogVariant} />;
};
