export const turkishToEnglish = (text: string) =>
	text
		.replace(/Ğ/g, "G")
		.replace(/Ü/g, "U")
		.replace(/Ş/g, "S")
		.replace(/İ/g, "I")
		.replace(/Ö/g, "O")
		.replace(/Ç/g, "C")
		.replace(/ğ/g, "g")
		.replace(/ü/g, "u")
		.replace(/ş/g, "s")
		.replace(/ı/g, "i")
		.replace(/ö/g, "o")
		.replace(/ç/g, "c");

export const sanitizeString = (text: string) => {
	const htmlRegex = /<[^>]*>/g;
	text = text.replace(htmlRegex, "");

	const map: Record<string, string> = {
		"&": "&amp;",
		"<": "&lt;",
		">": "&gt;",
		// eslint-disable-next-line quotes
		'"': "&quot;",
		"'": "&#x27;",
		"/": "&#x2F;"
	};
	const specialRegex = /[&<>"'/]/gi;
	text = text.replace(specialRegex, match => map[match]);
	return text;
};

export const truncateString = (text: string, maxLength: number) => {
	if (text.length <= maxLength) {
		return text;
	}

	return `${text.slice(0, maxLength - 3)}...`;
};
