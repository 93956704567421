import { Stack } from "@mui/material";
import { TitleThreeSubtitleDot } from "../../../util/theme/styled-components";
import { EditableGenericBody } from "../../elements";
import { AdminEditPageIdProps } from "../../types";

interface Props extends AdminEditPageIdProps {
	body: string;
	title: string;
}

export const TitleThreeSubtitleSubcontent: React.FC<Props> = ({
	body,
	bodyProperty,
	title,
	titleProperty,
	pageId
}) => (
	<Stack spacing="0.625rem" alignItems="center">
		<TitleThreeSubtitleDot />
		<EditableGenericBody
			variant="subtitle2-primary-center"
			text={title}
			propertyKey={titleProperty}
			pageId={pageId}
		/>
		<EditableGenericBody
			variant="subtitle1-primary-center"
			text={body}
			propertyKey={bodyProperty}
			pageId={pageId}
		/>
	</Stack>
);
