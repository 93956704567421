import { Accordion, Box, ListItemText, MenuItem, styled } from "@mui/material";

export const DocumentDropdownItem = styled(MenuItem)(({ theme }) => ({
	width: "7.75rem",
	height: "2.25rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	borderWidth: "1px 0px",
	borderStyle: "solid",
	borderColor: theme.palette.grey[300]
}));

export const FaqSearchItemAccordion = styled(Accordion)(({ theme }) => ({
	boxShadow: "none",
	".Mui-expanded": {
		background: theme.palette.grey[300],
		borderRadius: "0.250rem"
	},
	"&:hover": {
		background: theme.palette.grey[300]
	},
	".MuiCollapse-root": { background: theme.palette.grey[300] },
	borderRadius: "0.250rem",
	"&::before": { display: "none" }
}));

export const SearchItemBox = styled(Box)(({ theme }) => ({
	padding: "0.500rem 0.750rem",
	borderRadius: "0.250rem",

	"&:hover": {
		background: theme.palette.grey[300]
	}
}));

export const LeftAlignedMenuItem = styled(MenuItem)(() => ({
	whiteSpace: "break-spaces",
	padding: "0.125rem 0"
}));

export const LanguageSelectItem = styled(MenuItem)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	padding: "0.500rem",
	gap: "0.375rem",

	background: theme.palette.common.white,

	width: "7.75rem",
	height: "2.25rem",

	borderBottom: `1px solid ${theme.palette.grey[300]}`,
	"&:last-child": {
		borderBottom: "none"
	}
}));

export const SectionDropdownItem = styled(MenuItem)(({ theme }) => ({
	height: "2.25rem",
	borderWidth: "1px 0px",
	borderStyle: "solid",
	borderColor: theme.palette.grey[300]
}));

export const SideMenuListItemText = styled(ListItemText)(({ theme }) => ({
	color: theme.palette.common.white,
	".MuiTypography-root": {
		fontWeight: 600,
		fontSize: theme.typography.body1.fontSize
	}
}));
