import { Box, Button, Stack } from "@mui/material";
import { DocumentExtension } from "../../../util/dictionaries/types";
import { DocumentDAO } from "../../../util/generators/types";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { GenericBody } from "../../elements";
import { DocumentHeading } from "../headings";
import { InfoIcon } from "../images";

interface Props {
	document: DocumentDAO;
	hasInfo?: boolean;
}

interface DocumentItemProps {
	name: string;
	date?: string;
	extension: DocumentExtension;
	link: string;
}

interface DocumentItemWithInfoProps extends DocumentItemProps {
	description?: string;
}

export const DocumentWrapper: React.FC<{ documentLink: string }> = ({
	documentLink,
	children
}) => {
	const { isMobile } = useResponsive();

	return isMobile ? (
		<Button
			variant="document"
			href={documentLink}
			target="_blank"
			sx={{
				paddingLeft: "1.56rem",
				paddingRight: "0.938rem",
				paddingY: 0,
				textTransform: "unset"
			}}
		>
			{children}
		</Button>
	) : (
		<Box
			sx={{
				paddingLeft: "1.56rem",
				paddingRight: "0.938rem",
				paddingY: "0",

				display: "flex",
				flexDirection: "row",
				justifyContent: "left",
				alignItems: "center",

				background: "transparent",
				borderRadius: "0",

				width: "100%"
			}}
		>
			{children}
		</Box>
	);
};

export const DocumentWithInfo: React.FC<DocumentItemWithInfoProps> = ({
	name,
	extension,
	description,
	link
}) => {
	return (
		<Box
			sx={{
				width: "100%",
				marginTop: { xs: "0.750rem", md: "1.19rem" },
				marginBottom: { xs: "0.750rem", md: "1.00rem" }
			}}
		>
			<DocumentHeading link={link} name={name} extension={extension} />

			<Stack
				direction="row"
				alignItems="center"
				marginTop={{ xs: "0.750rem", md: "0.938rem" }}
			>
				<Box marginRight="0.813rem">
					<InfoIcon />
				</Box>
				<GenericBody
					variant="body3-primary"
					sx={{ opacity: 0.7 }}
					innerHTML={description}
				/>
			</Stack>
		</Box>
	);
};

export const DocumentWithoutInfo: React.FC<DocumentItemProps> = props => (
	<Box sx={{ my: { xs: "0.750rem", md: "1.19rem" }, width: "100%" }}>
		<DocumentHeading {...props} />
	</Box>
);

export const DocumentItem: React.FC<Props> = ({
	document: { fileExtension, link, name, date, description },
	hasInfo
}) => {
	const { isMobile } = useResponsive();

	const fileName = link.split("/").pop();

	const documentLink = isMobile ? link : `/files/${fileName}`;

	return (
		<DocumentWrapper documentLink={documentLink}>
			{hasInfo ? (
				<DocumentWithInfo
					name={name}
					date={date}
					extension={fileExtension as DocumentExtension}
					description={description}
					link={documentLink}
				/>
			) : (
				<DocumentWithoutInfo
					link={documentLink}
					name={name}
					date={date}
					extension={fileExtension as DocumentExtension}
				/>
			)}
		</DocumentWrapper>
	);
};
