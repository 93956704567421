import { AggregationDAO } from "../generators/types";

export const useEmptyChartData = () => {
	const emptyPieChartData: AggregationDAO[] = checkStatusList.map(generateChartData);
	const emptyBarChartData: AggregationDAO[] =
		getBarChartXAxisLabels().map(generateChartData);

	return { emptyPieChartData, emptyBarChartData };
};

const generateChartData = (groupedBy: string) => ({ count: "0", groupedBy, value: "0" });

const checkStatusList = [
	"0",
	"1",
	"A",
	"B",
	"2",
	"3",
	"4",
	"5",
	"7",
	"P",
	"S",
	"T",
	"6",
	"D",
	"E"
];

const getBarChartXAxisLabels = () => {
	const currentDate = new Date();
	const months = [];

	for (let i = 0; i < 12; i++) {
		const month = currentDate.getMonth() + i;
		const year =
			currentDate.getFullYear() + Math.floor((currentDate.getMonth() + i) / 12);

		const formattedMonth = ((month % 12) + 1).toString().padStart(2, "0");
		const formattedYear = year.toString();

		months.push(`${formattedMonth}/${formattedYear}`);
	}

	return months;
};
