import React from "react";
import { InfoFourPartContainer, PageWithSEO } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	subtitle_4: string;
	title_1: string;
}

export const InfoFourPartPage: React.FC<Props> = ({
	body_1,
	body_2,
	body_3,
	body_4,
	subtitle_1,
	subtitle_2,
	subtitle_3,
	subtitle_4,
	title_1,
	pageId,
	...seoProps
}) => {
	const content = { title: title_1, body: body_1 };
	const subcontents = [
		{
			title: subtitle_1,
			titleProperty: "subtitle_1",
			body: body_1,
			bodyProperty: "body_1"
		},
		{
			title: subtitle_2,
			titleProperty: "subtitle_2",
			body: body_2,
			bodyProperty: "body_2"
		},
		{
			title: subtitle_3,
			titleProperty: "subtitle_3",
			body: body_3,
			bodyProperty: "body_3"
		},
		{
			title: subtitle_4,
			titleProperty: "subtitle_4",
			body: body_4,
			bodyProperty: "body_4"
		}
	];
	const props = { content, subcontents, pageId };

	return (
		<PageWithSEO withSidebar {...seoProps}>
			<InfoFourPartContainer {...props} />
		</PageWithSEO>
	);
};
