import { Popover, Stack } from "@mui/material";

interface Props {
	open: boolean;
	anchorEl: HTMLElement | null;
	onClose: () => void;
}
export const TextEditPopover: React.FC<Props> = ({ children, ...props }) => (
	<Popover
		{...props}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right"
		}}
		transformOrigin={{ horizontal: "right", vertical: "top" }}
	>
		<Stack alignItems="center" sx={{ padding: "1.25rem 1.88rem" }} spacing={3}>
			{children}
		</Stack>
	</Popover>
);
