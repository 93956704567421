import React from "react";
import { AggregationDAO } from "../generators/types";
import { ChartItem } from "../../views/containers/types";
import { ChecksClientCommunicator } from "../../communicators/lider.http.communicator";
import { useGeneratorEffect } from "../hooks/useGeneratorEffect.hook";

interface IChartContext {
	setHighlightedIndex: (index: number | null) => void;
	chartItems: ChartItem[];
	itemCount: number;
	highlightedIndex: number | null;
	totalAmount: number;
}

export const ChartContext = React.createContext<IChartContext>({
	setHighlightedIndex: () => null,
	chartItems: [],
	itemCount: 0,
	highlightedIndex: null,
	totalAmount: 0
});

export const useChartContext = () => React.useContext(ChartContext);

interface Props {
	labelFormatter: (groupedBy: string) => string;
	apiRequestArguments: string[];
	emptyChartData: AggregationDAO[];
}

export const ChartProvider: React.FC<Props> = ({
	labelFormatter,
	apiRequestArguments,
	emptyChartData,
	children
}) => {
	const emptyChartItems = reduceToChartItems(emptyChartData, labelFormatter);

	const [chartItems, setChartItems] = React.useState<ChartItem[]>(emptyChartItems);
	const [highlightedIndex, setHighlightedIndex] = React.useState<number | null>(null);

	const processApiResponse = ({ data }: { data: AggregationDAO[] }) =>
		setChartItems(reduceToChartItems([...emptyChartData, ...data], labelFormatter));

	const communicator = new ChecksClientCommunicator();

	useGeneratorEffect(
		{
			effect: () => communicator.getTotalsBy(...apiRequestArguments),
			onSuccess: processApiResponse,
			callback: communicator.abort
		},
		[]
	);

	const { itemCount, totalAmount } = chartItems.reduce(
		({ itemCount, totalAmount }, { count, value }) => ({
			itemCount: itemCount + count,
			totalAmount: totalAmount + value
		}),
		{ itemCount: 0, totalAmount: 0 }
	);

	const contextValue = {
		setHighlightedIndex,
		chartItems,
		itemCount,
		highlightedIndex: !!itemCount ? highlightedIndex : null,
		totalAmount
	};

	return <ChartContext.Provider value={contextValue}>{children}</ChartContext.Provider>;
};

const reduceToChartItems = (
	totals: AggregationDAO[],
	getItemLabel: (groupedBy: string) => string
) =>
	Object.entries(
		totals
			.map(({ groupedBy, value, count }) => {
				const itemLabel = getItemLabel(groupedBy);

				return { label: itemLabel, value: Number(value), count: Number(count) };
			})
			.reduce(groupRepeatingFields, {})
	).map(([label, { value, count }]) => ({ label, value, count }));

const groupRepeatingFields = (
	accumulator: Record<string, { count: number; value: number }>,
	{ label, value, count }: ChartItem
) => ({
	...accumulator,
	[label]: accumulator[label]
		? { value: accumulator[label].value + value, count: accumulator[label].count + count }
		: { value, count }
});
