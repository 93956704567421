import { Box, Popper, Stack } from "@mui/material";
import React from "react";
import { GenericBody } from "../../elements";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";
import { useChartContext } from "../../../util/contexts/chart.context";
import { currencyFormatter } from "../../../util/currency.formatter";

export const BarChartPopper: React.FC<{
	anchorEl: HTMLElement | null;
}> = ({ anchorEl }) => {
	const { chartItems, highlightedIndex } = useChartContext();

	return highlightedIndex !== null ? (
		<Popper open={true} anchorEl={anchorEl} placement="top">
			<Stack alignItems="center" justifyContent="center" pb="0.25rem">
				<GenericBody
					variant="body4-primary-medium"
					text={currencyFormatter(chartItems[highlightedIndex].value)}
					boxStyles={{
						padding: "0.25rem 0.625rem",
						borderRadius: "0.25rem",
						background: ColorDictionary["#b9ace6"],
						textAlign: "center"
					}}
				/>
				<Box
					width="0"
					borderLeft="0.25rem solid transparent"
					borderRight="0.25rem solid transparent"
					borderTop={`0.25rem solid ${ColorDictionary["#b9ace6"]}`}
				/>
			</Stack>
		</Popper>
	) : null;
};
