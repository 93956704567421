import { AppBar, Box, Container, Grid, Stack, Toolbar } from "@mui/material";
import React from "react";
import { LanguageEnum } from "../../util/dictionaries/types";
import { HeaderSectionList } from "./lists";
import { HeaderLogo } from "./images";
import { PageDAO } from "../../util/generators/types";
import { SearchContainer } from "../containers";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import { HeaderSearchBarSeperator } from "../../util/theme/styled-components";
import { useSidebarContext } from "../../util/contexts/sidebar.context";
import { pxToRem } from "../../util/converters/px-to-rem.converter";

interface Section {
	name: string;
	route: string;
	index: number;
}

interface Sections {
	[key: string]: Section;
}

interface Props {
	closeSectionMenu: () => void;
	handleDrawerToggle: () => void;
	handleDropdownClick: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent> | null
	) => void;
	handleLocalization: (language: LanguageEnum) => void;
	openSectionMenu: (
		event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		sectionId: string
	) => void;
	setSearchBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
	anchorEl: HTMLElement | null;
	drawerOpen: boolean;
	hoveredSectionId: string;
	invisibleSections: Sections;
	pagesInHoveredSection: PageDAO[];
	sectionAnchorEl: HTMLElement | null;
	searchBarOpen: boolean;
	sections: Sections;
}

const WIDTH = {
	SCREEN_WIDTH: 1440,
	XL: 1296,
	LG: 1296
};

export const HeaderView: React.FC<Props> = ({ searchBarOpen, ...props }) => {
	const { isMobile, isTablet } = useResponsive();
	const { isOpen } = useSidebarContext();
	const displaySections = !isTablet && searchBarOpen ? false : true;

	const paddings = {
		lg: pxToRem((WIDTH.SCREEN_WIDTH - WIDTH.LG) / 2),
		xl: pxToRem((WIDTH.SCREEN_WIDTH - WIDTH.XL) / 2)
	};

	return (
		<AppBar position="absolute">
			<Toolbar
				sx={{
					height: { xs: "10.4rem", md: "8.00rem", xl: "9.25rem" },
					px: "0 !important"
				}}
			>
				<Container
					maxWidth="xl"
					disableGutters
					sx={{
						display: "flex",
						alignItems: "center",

						height: "100%",
						paddingRight: {
							md: "3.13rem",
							lg: paddings.lg,
							xl: paddings.xl,
							xxl: 0
						},
						paddingLeft: {
							md: "4.50rem",
							xxl: 0
						}
					}}
				>
					{isMobile ? (
						<MobileHeader searchBarOpen={searchBarOpen} {...props} />
					) : (
						<DesktopHeader
							isOpen={isOpen}
							displaySections={displaySections}
							searchBarOpen={searchBarOpen}
							{...props}
						/>
					)}
				</Container>
			</Toolbar>
		</AppBar>
	);
};
interface DesktopHeaderProps extends Props {
	isOpen: boolean;
	displaySections: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
	isOpen,
	displaySections,
	setSearchBarOpen,
	searchBarOpen,
	...props
}) => {
	return (
		<Grid container alignItems="center" onMouseLeave={props.closeSectionMenu}>
			<Grid item xs="auto" md={4} lg={3} onMouseEnter={props.closeSectionMenu}>
				<Stack
					direction="row"
					alignItems="center"
					spacing={isOpen ? { md: "1.00rem", lg: "3.00rem" } : "2.25rem"}
				>
					<HeaderLogo />
					<HeaderSearchBarSeperator sx={{ opacity: isOpen ? 0 : 1 }} />
					<Box width="12.3rem" sx={{ transition: "margin 1.2s" }}>
						<SearchContainer
							openTrigger={searchBarOpen}
							setOpenTrigger={setSearchBarOpen}
						/>
					</Box>
				</Stack>
			</Grid>
			<Grid
				item
				sx={{
					textAlign: "right",
					transition: "opacity",
					transitionDuration: displaySections ? "1.4s" : "0.4s",
					transitionTimingFunction: "ease-in",
					opacity: displaySections ? 1 : 0
				}}
				xs
			>
				<HeaderSectionList {...props} />
			</Grid>
		</Grid>
	);
};

export const MobileHeader: React.FC<Props> = ({
	setSearchBarOpen,
	searchBarOpen,
	...props
}) => {
	return (
		<Grid container sx={{ height: "100%" }}>
			<Grid item xs={12}>
				<Stack
					direction="row"
					justifyContent="space-between"
					sx={{ paddingX: "1.00rem", paddingTop: "1.00rem" }}
				>
					<HeaderLogo />
					<HeaderSectionList {...props} />
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<Stack alignItems="center" justifyContent="flex-end" height="100%">
					<Box
						sx={{
							width: "100%",
							padding: "0.750rem 1.00rem"
						}}
					>
						<SearchContainer
							openTrigger={searchBarOpen}
							setOpenTrigger={setSearchBarOpen}
						/>
					</Box>
				</Stack>
			</Grid>
		</Grid>
	);
};
