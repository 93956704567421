import { DashboardMultipleSelectDot } from "../../../../util/theme/styled-components";
import { GenericBody } from "../../../elements";

export const DashboardFilterCountBubble: React.FC<{ selectedCount: number }> = ({
	selectedCount
}) =>
	selectedCount > 1 ? (
		<DashboardMultipleSelectDot>
			<GenericBody variant="body4-white-medium" text={selectedCount.toString()} />
		</DashboardMultipleSelectDot>
	) : null;
