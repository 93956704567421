import { AdminLoginFlowFormTemplate } from "./admin-login.template";
import { ResetNoticeProps } from "./types";
import { LocalizedTypographyWithVariableSubject } from "../../../elements";

export const ResetNoticeForm: React.FC<ResetNoticeProps> = ({ handleSubmit, email }) => {
	return (
		<AdminLoginFlowFormTemplate
			headerLocalization="admin_reset_notice_heading"
			buttonLocalization="login_button_placeholder"
			handleSubmit={handleSubmit}
			validate={() => true}
		>
			<LocalizedTypographyWithVariableSubject
				variants={{ mobile: "body2-primary", desktop: "body1-primary" }}
				localizationKey="admin_reset_notice_text"
				variable={email}
				sx={{ textAlign: "center" }}
			/>
		</AdminLoginFlowFormTemplate>
	);
};
