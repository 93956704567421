import { Box } from "@mui/material";
import { DocumentDAO } from "../../../util/generators/types";
import { LoadMoreButton } from "../buttons";
import { DocumentList } from "../lists";

interface Props {
	handleNextClick: () => void;
	documents: DocumentDAO[];
	hasInfo?: boolean;
	shouldLoadMore: boolean;
}

export const DocumentsContent: React.FC<Props> = ({
	handleNextClick,
	documents,
	hasInfo,
	shouldLoadMore
}) => {
	return (
		<Box>
			<DocumentList documents={documents} hasInfo={hasInfo} />
			{shouldLoadMore ? <LoadMoreButton handleNextClick={handleNextClick} /> : ""}
		</Box>
	);
};
