import { StoredBranches } from "../../views/containers/types";
import { LanguageEnum } from "../dictionaries/types";
import {
	BranchDAO,
	DocumentDAO,
	LocalizationDAO,
	SectionDAO,
	TemplateDAO
} from "../generators/types";
import { ById, ByLanguageId, FromManyAdaptor, Identifiable } from "./types";

interface LocalizedIdentifiable extends Identifiable {
	language: LanguageEnum;
}

const byIdReducer = (accumulator: Record<any, any>, element: Identifiable) => ({
	...accumulator,
	[element.id]: element
});

const byLanguageIdReducer = <T extends LocalizedIdentifiable>(
	accumulator: ByLanguageId<T>,
	element: T
) => ({
	...accumulator,
	[element.language]: {
		...accumulator[element.language],
		[element.id]: element
	}
});

export const baseAdaptor = <T>(items: T[]) => items;

export const documentsByIdAdaptor: FromManyAdaptor<DocumentDAO, ById<DocumentDAO>> = (
	documents: DocumentDAO[]
) => documents.reduce(byIdReducer, {});

export const templatesByIdAdaptor: FromManyAdaptor<TemplateDAO, ById<TemplateDAO>> = (
	templates: TemplateDAO[]
) => templates.reduce(byIdReducer, {});
export const sectionsByLanguageIdAdaptor: FromManyAdaptor<
	SectionDAO,
	ByLanguageId<SectionDAO>
> = (sections: SectionDAO[]) =>
	sections.reduce(byLanguageIdReducer, {} as ByLanguageId<SectionDAO>);

export const branchesAdaptor = (branches: BranchDAO[]) =>
	branches.reduce((accumulator: ByLanguageId<BranchDAO[]>, element) => {
		const cities = accumulator[element.language] || {};
		const branches = cities[element.city] || [];
		branches.push(element);

		cities[element.city] = branches;

		return { ...accumulator, [element.language]: cities };
	}, {} as StoredBranches);

export const localizationsAdaptor = (localizations: LocalizationDAO[]) =>
	localizations.reduce(
		(accumulator: Record<string, LocalizationDAO>, element) => ({
			...accumulator,
			[element.key]: element
		}),
		{}
	);
