import { Stack } from "@mui/material";
import { LocalizedGenericBody } from "../../elements";
import React from "react";
import { SubmissionStatus } from "../../containers/types";
import { FormVariant } from "../types";

interface Props {
	color: string;
	formVariant: FormVariant;
	submissionStatus: SubmissionStatus;
	Icon: React.FC;
}

export const FormSnackbarContent: React.FC<Props> = ({
	color,
	formVariant,
	submissionStatus,
	Icon
}) => (
	<Stack direction="row" alignItems="center" spacing="0.75rem" padding="1rem">
		<Icon />
		<LocalizedGenericBody
			variant="body3-primary-medium"
			sx={{ color }}
			localizationKey={`${formVariant}_form_submission_${submissionStatus}_text`}
			disableEdit
		/>
	</Stack>
);
