declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		sxl: true;
		xxl: true;
	}
}

export const getMuiBreakpoints = () => {
	return {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			sxl: 1340,
			xl: 1440,
			xxl: 1600
		}
	};
};
