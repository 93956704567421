import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { TitleThreeSubtitleImage, TitleThreeSubtitleSubcontent } from "../components";
import { EditableGenericBody } from "../elements";
import { PageIdProps } from "../types";

interface Props extends PageIdProps {
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	title_1: string;
}

export const TitleThreeSubtitleContainer: React.FC<Props> = ({
	body_1,
	body_2,
	body_3,
	body_4,
	subtitle_1,
	subtitle_2,
	subtitle_3,
	title_1,
	pageId
}) => {
	return (
		<Stack>
			<Grid container alignItems="center" columnSpacing={12}>
				<Grid item xs={12} md={5} sx={{ display: "flex", justifyContent: "center" }}>
					<Box maxWidth="34.4rem" minWidth="21.3rem">
						<TitleThreeSubtitleImage />
					</Box>
				</Grid>
				<Grid item xs={12} md={7}>
					<Stack spacing="1.25rem">
						<EditableGenericBody
							variant="h3-primary"
							text={title_1}
							propertyKey="title_1"
							pageId={pageId}
						/>
						<EditableGenericBody
							variant="subtitle1-primary"
							text={body_1}
							propertyKey="body_1"
							pageId={pageId}
						/>
					</Stack>
				</Grid>
			</Grid>
			<Grid
				container
				columnSpacing={14}
				rowSpacing={7}
				paddingTop={{ lg: 0, xs: "1.25rem" }}
			>
				<Grid item xs={12} md={4}>
					<TitleThreeSubtitleSubcontent
						body={body_2}
						bodyProperty="body_2"
						title={subtitle_1}
						titleProperty="subtitle_1"
						pageId={pageId}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TitleThreeSubtitleSubcontent
						body={body_3}
						bodyProperty="body_3"
						title={subtitle_2}
						titleProperty="subtitle_2"
						pageId={pageId}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TitleThreeSubtitleSubcontent
						body={body_4}
						bodyProperty="body_4"
						title={subtitle_3}
						titleProperty="subtitle_3"
						pageId={pageId}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
};
