import React from "react";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { MapItem } from "./map.item";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import {
	HighlightedBranchHeadingLine,
	HighlightedBranchInfoStack,
	HighlightedBranchInnerStack,
	HighlightedBranchMapBox,
	HighlightedBranchOuterStack
} from "../../../util/theme/styled-components";
import { BranchDAO } from "../../../util/generators/types";
import { BranchDetailsItemContent } from "../contents";

export const BranchDetailsItem: React.FC<BranchDAO> = props => {
	const { latitude, longitude } = props;
	const theme = useTheme();

	const center = { lat: latitude, lng: longitude };

	const mapItem = React.useMemo(
		() => <MapItem center={center} branch={props} />,
		[latitude, longitude]
	);

	return (
		<Box sx={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: "0.5rem" }}>
			<Grid container>
				<Grid item xs={12} md="auto">
					<Box
						width={{ xs: "100%", md: "16.3rem" }}
						height={{ xs: "7.88rem", md: "100%" }}
					>
						{mapItem}
					</Box>
				</Grid>
				<Grid item xs={12} md>
					<BranchDetailsItemContent {...props} />
				</Grid>
			</Grid>
		</Box>
	);
};

export const HighlightedBranchItem: React.FC<BranchDAO> = props => {
	const { address, imageLink, latitude, longitude, name, phoneNumber, fax } = props;
	const center = { lat: latitude, lng: longitude };

	return (
		<HighlightedBranchOuterStack
			direction={{ xs: "column", lg: "row" }}
			justifyContent={{ xs: "none", lg: "space-between" }}
			alignItems="center"
			spacing={2}
			padding={{ lg: "1rem 1rem 1rem 3rem", md: "1rem", xs: "0.5rem" }}
			imagelink={imageLink}
		>
			{HeadingStack(name)}
			<HighlightedBranchInnerStack
				direction={{ xs: "column", lg: "row" }}
				alignItems="center"
				width={{ xs: "100%", lg: "70%" }}
				height="100%"
			>
				{MapBox({ center, branch: props })}
				{InfoStack({ name, address, phoneNumber, fax })}
			</HighlightedBranchInnerStack>
		</HighlightedBranchOuterStack>
	);
};

const HeadingStack = (name: string) => (
	<Stack
		width={{ lg: "10.3rem", xs: "12.9rem" }}
		textAlign="center"
		spacing={{ lg: 2, xs: 1 }}
		marginTop={{ xs: 1, md: 0 }}
	>
		<HighlightedBranchHeadingLine />
		<GenericBody
			sx={{ textAlign: "center" }}
			variants={{ mobile: "h4-white", desktop: "h2-white" }}
			text={name}
		/>
		<HighlightedBranchHeadingLine />
	</Stack>
);

const MapBox = ({
	center,
	branch
}: {
	center: { lat: number; lng: number };
	branch: BranchDAO;
}) => (
	<HighlightedBranchMapBox
		width={{ lg: "12.3rem", xs: "100%" }}
		minWidth={{ lg: "12.3rem", xs: "100%" }}
		height={{ lg: "100%", xs: "7.88rem" }}
	>
		<MapItem center={center} branch={branch} />
	</HighlightedBranchMapBox>
);

const InfoStack = ({
	name,
	address,
	phoneNumber,
	fax
}: {
	name: string;
	address: string;
	phoneNumber: string;
	fax?: string;
}) => {
	const theme = useTheme();

	return (
		<HighlightedBranchInfoStack
			spacing={{ xs: "1rem", md: "0.75rem" }}
			px={{ xs: 3, md: 4 }}
			py={{ xs: 3, lg: "1.25rem" }}
			borderRadius={{ lg: 0, xs: "0 0 0.25rem 0.25rem" }}
		>
			<Stack
				spacing="0.25rem"
				paddingBottom={{ xs: "1.5rem", md: "1.75rem" }}
				sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
			>
				<GenericBody
					variants={{ desktop: "h4-primary", mobile: "h5-primary" }}
					text={name}
				/>
				<Typography variant="body2-secondary-medium">{address}</Typography>
			</Stack>
			<Stack direction="row" justifyContent="space-between">
				<Box>
					<LocalizedGenericBody
						variant="body3-primary-semi-bold"
						localizationKey="phone_number_text"
					/>
					<GenericBody
						variant="body2-secondary-medium"
						innerHTML={`<a style="color:${theme.palette.secondary.main};text-decoration:none;" href="tel:${phoneNumber}" target="_blank" rel="external">${phoneNumber}</a>`}
					/>
				</Box>
				<Box>
					<LocalizedGenericBody
						variant="body3-primary-semi-bold"
						localizationKey="fax_text"
					/>
					<GenericBody variant="body2-secondary-medium" text={fax} />
				</Box>
			</Stack>
		</HighlightedBranchInfoStack>
	);
};
