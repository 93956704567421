import { Stack } from "@mui/material";
import { DateWithTimeCellImage } from "../images";
import { GenericBody } from "../../elements";

interface Props {
	text: string;
	imageSrc: string;
}

export const DateWithTimeCellContent: React.FC<Props> = ({ text, imageSrc }) => (
	<Stack direction="row" spacing="0.5rem" alignItems="center">
		<DateWithTimeCellImage imageSrc={imageSrc} />
		<GenericBody variant="body3-primary" text={text} />
	</Stack>
);
