import Axios, { AxiosError, AxiosInstance } from "axios";
import { CommunicatorMethod, CommunicatorMethodEnum, HttpRequestArgs } from "./types";

export abstract class BaseHTTPCommunicator {
	axios: AxiosInstance;
	abortController: AbortController;

	get!: CommunicatorMethod;
	post!: CommunicatorMethod;
	put!: CommunicatorMethod;
	patch!: CommunicatorMethod;
	delete!: CommunicatorMethod;
	getAndCache: CommunicatorMethod;

	constructor(args: HttpRequestArgs) {
		this.abortController = new AbortController();
		this.axios = Axios.create({
			...args
		});

		["get", "post", "put", "patch", "delete"].forEach(
			method =>
				(this[method as CommunicatorMethodEnum] = async (...args) => {
					const result = await this.axios[method as CommunicatorMethodEnum](...args, {
						signal: this.abortController.signal
					})
						.then(({ data }) => {
							return data;
						})
						.catch(this.errorHandler);

					return result;
				})
		);

		this.getAndCache = async (url: string) => {
			const config = {};
			this.#addCacheHeaders(url, config);
			return await this.axios
				.get(url, config)
				.then(response => this.#cacheAndReturn(response, url))
				.catch(error => {
					if (error.response && error.response.status === 304)
						return this.#getCachedData(url);
					this.errorHandler(error);
				});
		};
	}

	abort = () => {
		this.abortController.abort();
	};

	errorHandler(error: AxiosError) {
		throw error;
	}

	#addCacheHeaders = (url: string, config: any) => {
		const { cacheVersion } = this.#getCacheRegister(url);
		const cachedData = this.#getCachedData(url);
		config.headers = !!cachedData
			? { "if-none-match": cacheVersion }
			: { "if-none-match": cacheVersion, "cache-control": "no-cache" };
	};

	#getCacheRecord = () => {
		return JSON.parse(localStorage.getItem("cacheRecord") || "{}");
	};

	#cacheAndReturn = (response: any, url: string) => {
		this.#registerCache(response, url);
		this.#cacheData(url, response.data);
		return response.data;
	};

	#registerCache = (response: any, url: string) => {
		const { headers = { ["last-modified"]: new Date().toISOString() } } = response;
		const cacheVersion = new Date(headers["last-modified"]).getTime();
		this.#updateCacheRegister(url, cacheVersion);
	};

	#updateCacheRegister = (url: string, registerDate: number) => {
		const { cacheRecord } = this.#getCacheRegister(url);
		Object.assign(cacheRecord, { [url]: registerDate });
		localStorage.setItem("cacheRecord", JSON.stringify(cacheRecord));
	};

	#getCacheRegister = (url: string) => {
		const cacheRecord = this.#getCacheRecord();
		let cacheVersion = cacheRecord[url];

		if (cacheVersion === undefined) {
			cacheVersion = 0;
			cacheRecord[url] = cacheVersion;
			localStorage.setItem("cacheRecord", JSON.stringify(cacheRecord));
		}

		return { cacheRecord, cacheVersion };
	};

	#getCachedData = (url: string) => {
		const cacheStorage = JSON.parse(localStorage.getItem("cacheStorage") || "{}");
		return cacheStorage[url];
	};

	#cacheData = (url: string, data: any) => {
		const cacheStorage = JSON.parse(localStorage.getItem("cacheStorage") || "{}");
		cacheStorage[url] = data;
		localStorage.setItem("cacheStorage", JSON.stringify(cacheStorage));
	};
}
