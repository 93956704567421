import React from "react";
import { useFormContext } from "../../util/contexts/form.context";
import { usePagesContext } from "../../util/contexts/pages.context";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { AdminCmsContainer } from "./admin-cms.container";
import { CmsContainerProps } from "./types";
import {
	AdminDocumentsGenerator,
	AdminDocumentPagesGenerator
} from "../../util/generators/private.generator";

export const AdminDocumentsCmsContainer: React.FC<CmsContainerProps> = props => {
	const { setPages } = usePagesContext();
	const { setExtensionList } = useFormContext();

	const documentsGenerator = new AdminDocumentsGenerator();
	const pagesGenerator = new AdminDocumentPagesGenerator();

	const extractOptionsAndSetState = (data: { options: string[] }) =>
		setExtensionList(data.options);

	useGeneratorEffect(
		{
			effect: () => documentsGenerator.findOptions("fileExtension"),
			onSuccess: extractOptionsAndSetState,
			callback: documentsGenerator.abort
		},
		[]
	);

	useGeneratorEffect(
		{
			effect: () => pagesGenerator.many("sort=sectionId,index"),
			onSuccess: setPages,
			callback: pagesGenerator.abort
		},
		[]
	);

	return <AdminCmsContainer {...props} />;
};
