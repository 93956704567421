import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { usePagesContext } from "../../../util/contexts/pages.context";
import { useSectionsContext } from "../../../util/contexts/sections.context";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { PageDAO } from "../../../util/generators/types";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import { SidebarDrawer } from "../../../util/theme/styled-components";
import { HeaderLogoNegative } from "../images";

interface Props {
	handleClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}

const transition = (wasOpen: boolean, index?: number) => {
	const sidebarDelay = wasOpen ? 0 : 1.2;
	const transitionDelay = index ? `${sidebarDelay + index * 0.1}s` : `${sidebarDelay}s`;

	return {
		transition: "visibility 0.5s, opacity 0.5s ease-in-out",
		transitionDelay
	};
};

const transitionAnimation = (isOpen: boolean, wasOpen: boolean, index?: number) => ({
	visibility: isOpen ? "visible" : "hidden",
	opacity: isOpen ? 1 : 0,
	...transition(wasOpen, index)
});

export const DesktopSidebar: React.FC<Props> = ({ handleClose }) => {
	const { isOpen, wasOpen } = useSidebarContext();

	const { language } = useLanguageContext();
	const { sections, selectedSection } = useSectionsContext();
	const { pages: contextPages, selectedPageId, setSelectedPageId } = usePagesContext();

	const [sidebarHeader, setSidebarHeader] = React.useState<string>("");
	const [pages, setPages] = React.useState<PageDAO[]>([]);

	React.useEffect(() => {
		if (sections) {
			const pagesForSection = contextPages.filter(
				({ sectionId }) => sectionId === selectedSection
			);
			if (isOpen) {
				setPages(pagesForSection);

				if (selectedSection && sections[language][selectedSection])
					setSidebarHeader(sections[language][selectedSection].name);
			}
		}
	}, [sections, selectedSection, language]);

	const imageOffsetStyles = {
		marginTop: "1.13rem",
		display: "flex",
		alignItems: "center"
	};

	return (
		<SidebarDrawer
			variant={"permanent"}
			open={true}
			anchor="top"
			onClose={handleClose}
			topoffset={isOpen ? "0" : "-47.9rem"}
		>
			<Box
				sx={{
					...imageOffsetStyles,
					...transitionAnimation(isOpen, wasOpen)
				}}
			>
				<HeaderLogoNegative />
			</Box>
			<Typography
				component="a"
				variant="body1-tertiary-semi-bold"
				sx={{
					marginTop: "2.50rem",
					paddingLeft: "3.75rem",
					paddingY: 0,
					...transitionAnimation(isOpen, wasOpen)
				}}
			>
				{sidebarHeader}
			</Typography>
			<List sx={{ paddingLeft: "3.75rem", paddingY: 0 }}>
				{pages.map(({ id, name, route }, index) => (
					<ListPageItem
						key={id}
						id={id}
						name={name}
						route={route}
						index={index}
						isOpen={isOpen}
						wasOpen={wasOpen}
						selectedPageId={selectedPageId}
						setSelectedPageId={setSelectedPageId}
					/>
				))}
			</List>
		</SidebarDrawer>
	);
};

interface ListPageItemProps {
	id: string;
	isOpen: boolean;
	wasOpen: boolean;
	index: number;
	route: string;
	selectedPageId: string | null;
	setSelectedPageId: (id: string) => void;
	name: string;
}

export const ListPageItem: React.FC<ListPageItemProps> = ({
	id,
	isOpen,
	wasOpen,
	index,
	route,
	selectedPageId,
	setSelectedPageId,
	name
}) => {
	const [transitionBool, setTransitionBool] = React.useState(false);

	React.useEffect(() => {
		const transitionDelay = wasOpen ? 50 : 500;
		const transitionTimeout = setTimeout(
			setTransitionBool.bind(this, isOpen),
			transitionDelay
		);
		return () => clearTimeout(transitionTimeout);
	}, [isOpen]);

	const { prefix } = useLocalizedNavigate();

	const removeHTTPS = (route: string) => route.split("https:")[1];

	const linkProps = route.includes("https")
		? {
				to: `//${removeHTTPS(route)}`,
				target: "_blank",
				rel: "nofollow noreferrer"
		  }
		: {
				to: route[0] === "/" ? `${route}` : `${prefix()}/${route}`
		  };

	return (
		<ListItem
			key={id}
			sx={{
				cursor: "pointer",
				paddingLeft: 0,
				marginTop: "2.19rem",
				paddingY: 0,
				...transitionAnimation(transitionBool, wasOpen, index)
			}}
			onClick={() => setSelectedPageId(id)}
		>
			<NavLink {...linkProps} style={{ textDecoration: "none" }}>
				<Typography
					variant="body2-white-semi-bold"
					sx={{
						textDecoration: () => (selectedPageId === id ? "underline" : "none"),
						textUnderlineOffset: "0.375rem",
						"&:hover": {
							textDecoration: "underline",
							textUnderlineOffset: "0.375rem"
						}
					}}
				>
					{name}
				</Typography>
			</NavLink>
		</ListItem>
	);
};
