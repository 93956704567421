import { Box, Grid, Paper, Stack, useTheme } from "@mui/material";
import React from "react";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import { PageDAO } from "../../util/generators/types";
import { FooterSectionList, InstitutionLogoList, SocialMediaLogoList } from "./lists";
import { LayoutWrapper } from "../containers";
import { LocalizedGenericBody } from "../elements";
import { FooterLogo, FooterLogoNegative } from "./images";

interface Section {
	name: string;
	pages: PageDAO[];
}
interface Sections {
	[key: string]: Section;
}

interface Props {
	sections: Sections;
	variant?: "dark" | "light";
}

export const FooterView: React.FC<Props> = ({ sections, variant }) => {
	const { isMobile } = useResponsive();
	const theme = useTheme();

	const [paperVariant, Logo, copyrightBorderColor, typographyVariant] =
		variant === "dark"
			? [
					"bg-primary" as const,
					FooterLogoNegative,
					theme.palette.grey[300],
					"body3-white-medium"
			  ]
			: [
					"bg-white" as const,
					FooterLogo,
					theme.palette.primary.main,
					"body3-primary-medium"
			  ];

	return (
		<Paper variant={paperVariant} sx={{ borderRadius: "0" }}>
			<LayoutWrapper leftXXLIndent={0}>
				<Grid
					container
					gap={{ xs: 4, md: 6 }}
					paddingTop={{ xs: "2.00rem", md: "3.00rem" }}
				>
					<Grid item xs="auto">
						<Stack spacing={{ xs: 6, md: 8 }} width={{ xs: "100%", md: "13.1rem" }}>
							{isMobile ? <></> : <Logo />}
							<SocialMediaLogoList variant={variant} />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",

									padding: 0,

									width: "13.5rem"
								}}
							>
								<InstitutionLogoList />
							</Box>
						</Stack>
					</Grid>
					<Grid item xs>
						{isMobile ? (
							<></>
						) : (
							<Box sx={{ maxWidth: "58.5rem", marginLeft: "auto" }}>
								<FooterSectionList sections={sections} variant={variant} />
							</Box>
						)}
					</Grid>
					<Grid item xs={12}>
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							minHeight="3.25rem"
							sx={{
								position: "relative"
							}}
						>
							<Box
								sx={{
									position: "absolute",
									top: 0,
									left: "50%",
									transform: "translateX(-50%)",

									width: "100vw",
									maxWidth: "81.0rem",
									height: "1px",

									background: copyrightBorderColor,
									opacity: 0.1
								}}
							/>

							<LocalizedGenericBody
								variant={typographyVariant}
								localizationKey="footer_copyright_text"
								sx={{ opacity: 0.4 }}
							/>
						</Stack>
					</Grid>
				</Grid>
			</LayoutWrapper>
		</Paper>
	);
};
