import React from "react";
import { useLanguageContext } from "../../util/contexts/language.context";
import { useSectionsContext } from "../../util/contexts/sections.context";
import { FooterView } from "../components";
import { FooterProps } from "./types";

export const Footer: React.FC<FooterProps> = ({ variant }) => {
	const { language } = useLanguageContext();
	const storedSections = useSectionsContext().sections;

	const sections = {};

	if (storedSections && storedSections[language])
		Object.entries(storedSections[language]).forEach(entry => {
			const [sectionId, { name }] = entry;
			Object.assign(sections, { [sectionId]: { name } });
		});

	const footerProps = { sections, variant };
	return <FooterView {...footerProps} />;
};
