import { Box, Button, Typography } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import React from "react";
import { AdminTextEditContainer, LocalizationContainer } from "../containers";
import { PageIdProps } from "../types";

interface BaseProps {
	handleClick?: (event: React.MouseEvent) => void;
	disabled?: boolean;
	variant?:
		| "primary"
		| "secondary"
		| "contained"
		| "outlined"
		| "text"
		| "header"
		| "location"
		| "submit"
		| "publish"
		| "selectBar"
		| "tablePagination"
		| "cancel";
	textVariant?: any;
	mini?: boolean;
	fullWidth?: boolean;
	startIcon?: any;
	endIcon?: any;
	target?: string;
	href?: string;
	sx?: CSSProperties;
	type?: "button" | "submit" | "reset";
	component?: any;
}
interface Props extends BaseProps {
	text?: string;
}

export const GenericButton: React.FC<Props> = ({
	handleClick,
	disabled,
	text,
	mini = false,
	variant = "secondary",
	textVariant,
	fullWidth = false,
	startIcon,
	endIcon,
	sx,
	component,
	target,
	href,
	children,
	...buttonProps
}) => {
	const getTextVariant = () =>
		textVariant ?? (variant === "primary" ? "body2-white" : "body2-primary");

	const buttonStyles = mini
		? {
				minWidth: "max-content",
				justifyContent: "unset",
				px: "0.500rem",
				minHeight: "unset",
				height: "2rem",
				...sx
		  }
		: sx;

	const getIcon = (icon: any) =>
		mini ? (
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				{icon}
			</Box>
		) : (
			<Box
				sx={{
					width: "0.875rem",
					height: "0.875rem",

					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				{icon}
			</Box>
		);

	return (
		<Button
			disabled={disabled}
			variant={variant}
			onClick={handleClick}
			fullWidth={fullWidth}
			sx={buttonStyles}
			startIcon={getIcon(startIcon)}
			endIcon={getIcon(endIcon)}
			component={component}
			target={target}
			href={href}
			{...buttonProps}
		>
			<Box
				sx={
					mini
						? {
								marginLeft: startIcon ? 0 : "0.250rem",
								marginRight: endIcon ? 0 : "0.250rem"
						  }
						: { mx: "auto" }
				}
			>
				<Typography variant={getTextVariant()}>
					{text}
					{children}
				</Typography>
			</Box>
		</Button>
	);
};

interface LocalizedProps extends BaseProps {
	localizationKey: string;
	disableEdit?: boolean;
	containerStyles?: CSSProperties;
}

export const LocalizedGenericButton: React.FC<LocalizedProps> = ({
	localizationKey,
	disableEdit,
	disabled,
	variant,
	textVariant,
	handleClick,
	fullWidth,
	sx,
	containerStyles,
	...buttonProps
}) => (
	<LocalizationContainer
		disableEdit={disableEdit}
		localizationKey={localizationKey}
		sx={containerStyles}
	>
		{({ localizedText }) => (
			<GenericButton
				disabled={disabled}
				variant={variant}
				textVariant={textVariant}
				handleClick={handleClick}
				fullWidth={fullWidth}
				sx={sx}
				text={localizedText}
				{...buttonProps}
			/>
		)}
	</LocalizationContainer>
);

interface AdminProps extends Props, PageIdProps {
	propertyKey1: string;
	propertyKey2: string;
	text1: string;
	text2: string;
}

export const EditableGenericButton: React.FC<AdminProps> = ({
	pageId,
	propertyKey1,
	propertyKey2,
	text1,
	text2,
	...buttonProps
}) => (
	<AdminTextEditContainer
		pageId={pageId}
		propertyKey1={propertyKey1}
		propertyKey2={propertyKey2}
		text1={text1}
		text2={text2}
	>
		<GenericButton {...buttonProps} />
	</AdminTextEditContainer>
);
