import React from "react";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { GenericImageContainer } from "../../elements";

export const FooterLogo: React.FC = () => {
	const { imageAlt, imageSrc } = ImageDictionary.Footer.logo;

	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			width={{ xs: "9.19rem", md: "13.1rem" }}
			height={{ xs: "4.38rem", md: "6.25rem" }}
			style={{
				padding: "0",
				margin: "0"
			}}
		/>
	);
};

export const FooterLogoNegative: React.FC = () => {
	const { imageAlt, imageSrc } = ImageDictionary.Footer.logoNegative;

	return (
		<GenericImageContainer imageAlt={imageAlt} imageSrc={imageSrc} width="fit-content" />
	);
};
