import { GenericImageContainer } from "../../elements";

import cashManagement from "../../../assets/images/services-cash.svg";
import collectionManagement from "../../../assets/images/services-collection.svg";
import cashManagementMobile from "../../../assets/images/services-cash-mobile.svg";
import collectionManagementMobile from "../../../assets/images/services-collection-mobile.svg";
import servicesLogo from "../../../assets/images/services-logo.svg";

export const CashImageDesktop: React.FC = () => (
	<GenericImageContainer
		imageSrc={cashManagement}
		imageAlt="money in hands"
		width="14.3rem"
		height="8.38rem"
	/>
);

export const CollectionImageDesktop: React.FC = () => (
	<GenericImageContainer
		imageSrc={collectionManagement}
		imageAlt="people shaking hands"
		width="14.3rem"
		height="8.38rem"
	/>
);

export const CashImageMobile: React.FC = () => (
	<GenericImageContainer
		imageSrc={cashManagementMobile}
		imageAlt="money in hands"
		width="6.88rem"
		height="3.38rem"
	/>
);

export const CollectionImageMobile: React.FC = () => (
	<GenericImageContainer
		imageSrc={collectionManagementMobile}
		imageAlt="people shaking hands"
		width="6.88rem"
		height="3.38rem"
	/>
);

export const ServicesLogo: React.FC = () => (
	<GenericImageContainer
		imageSrc={servicesLogo}
		imageAlt="lider logo"
		width="2.75rem"
		height="4rem"
	/>
);
