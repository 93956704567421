import { FormControl, FormHelperText, TextField } from "@mui/material";
import React from "react";
import { LocalizedGenericBody } from "../../elements";
import { InputValidation } from "../../../util/validations/input.validation";
import { ValidatedInputProps } from "../types";

interface Props {
	passValidationResult: (isValid: null | boolean, errorKey: string) => void;
	InputComponent?: React.FC<any>;
	InputProps: ValidatedInputProps;
	isValid: null | boolean;
	Validation: typeof InputValidation;
	helperTextVariant?: any;
	displayHelperText?: boolean;
}

export const ValidatedTextInput: React.FC<Props> = ({
	passValidationResult,
	InputComponent,
	InputProps: { onChange, onBlur, ...InputProps },
	isValid,
	helperTextVariant = "body4-error",
	Validation,
	displayHelperText = true
}) => {
	const Input = InputComponent ? InputComponent : TextField;

	const { errorKey, validate } = new Validation(InputProps.value);

	const isInvalid = isValid === false;

	const onBlurHandler = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		passValidationResult(validate().result, errorKey);
		if (onBlur) onBlur(event);
	};
	const onChangeHandler = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (isInvalid) passValidationResult(null, errorKey);
		return onChange(event);
	};

	return (
		<FormControl sx={{ width: "100%" }}>
			<Input onBlur={onBlurHandler} onChange={onChangeHandler} {...InputProps} />
			{isInvalid && displayHelperText ? (
				<FormHelperText>
					<LocalizedGenericBody localizationKey={errorKey} variant={helperTextVariant} />
				</FormHelperText>
			) : null}
		</FormControl>
	);
};
