import { Box, Grid } from "@mui/material";
import { AdminTextEditContainer } from "../../containers";
import { EditableGenericBody, GenericMedia } from "../../elements";
import { PageIdProps } from "../../types";

interface Props extends PageIdProps {
	title_1: string;
	subtitle_1: string;
	subtitle_2: string;
	body_1: string;
	body_2: string;
	imageLink_1?: string;
}

export const ListAndMediaPageItem: React.FC<{
	title: string;
	body: string;
	titleVariant: string;
	keys: { title: string; body: string };
	pageId: string;
}> = ({ title, body, titleVariant, keys, pageId }) => (
	<>
		<EditableGenericBody
			text={title}
			variant={titleVariant}
			propertyKey={keys.title}
			pageId={pageId}
		/>
		<EditableGenericBody
			text={body}
			variant="body1"
			propertyKey={keys.body}
			pageId={pageId}
		/>
	</>
);

export const ListAndMediaPageContent: React.FC<Props> = ({
	title_1,
	subtitle_1,
	subtitle_2,
	body_1,
	body_2,
	pageId,
	imageLink_1
}) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<EditableGenericBody
					text={title_1}
					variant="h3-secondary"
					propertyKey="title_1"
					pageId={pageId}
				/>
			</Grid>
			<Grid item xs={12}>
				<ListAndMediaPageItem
					title={subtitle_1}
					body={body_1}
					titleVariant="h5-secondary"
					keys={{ title: "subtitle_1", body: "body_1" }}
					pageId={pageId}
				/>
			</Grid>
			{imageLink_1 ? (
				<Grid item xs={12}>
					<Box mb={2}>
						<AdminTextEditContainer
							text1={imageLink_1}
							propertyKey1="imageLink_1"
							pageId={pageId}
						>
							<GenericMedia
								variant="img"
								mediaSrc={imageLink_1}
								styles={{
									position: "relative",
									paddingBottom: "35%", // 3.5 / 10, change for different aspect ratios.
									paddingTop: "1.56rem",
									height: 0,
									border: "none",
									boxShadow: "none",
									width: "100%"
								}}
							/>
						</AdminTextEditContainer>
					</Box>
				</Grid>
			) : null}
			<Grid item xs={12}>
				<ListAndMediaPageItem
					title={subtitle_2}
					body={body_2}
					titleVariant="h5-secondary"
					keys={{ title: "subtitle_2", body: "body_2" }}
					pageId={pageId}
				/>
			</Grid>
		</Grid>
	);
};
