import React from "react";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { FullWidthImageContainer } from "../../../util/theme/styled-components";
import { GenericImageContainer } from "../../elements";

export const TitleThreeSubtitleImage: React.FC = () => {
	const { imageAlt, imageSrc } = ImageDictionary.TitleThreeSubtitle;

	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			component={FullWidthImageContainer}
			width={{ xs: "21.3rem", md: "25.0rem", lg: "27.5rem", xl: "34.3rem" }}
		/>
	);
};
