import React from "react";
import { LanguageEnum } from "../dictionaries/types";
import { LocalizationObject } from "./types";

export interface ILanguageContext {
	getLanguage: () => LanguageEnum;
	language: LanguageEnum;
	languages: LanguageEnum[];
	localizationObject: LocalizationObject;
	triggerLanguageRender: boolean;
	getLocalizedText: (key: string, defaultText?: string) => string;
	getLocalizedTexts: (keys: string[], defaultText?: string) => string[];
	setLanguage: (language: LanguageEnum) => void;
	setLanguages: (languages: LanguageEnum[]) => void;
	setLocalizationObject: (localizations: LocalizationObject) => void;
	refreshTriggerLanguageRender: () => void;
}

export const LanguageContext = React.createContext<ILanguageContext>({
	language: process.env.REACT_APP_DEFAULT_LANGUAGE as LanguageEnum,
	languages: [],
	localizationObject: {},
	triggerLanguageRender: false,
	getLocalizedText: () => "",
	getLocalizedTexts: () => [],
	getLanguage: () => process.env.REACT_APP_DEFAULT_LANGUAGE as LanguageEnum,
	setLanguage: () => null,
	setLanguages: () => null,
	setLocalizationObject: () => null,
	refreshTriggerLanguageRender: () => null
});

export const useLanguageContext = () => React.useContext(LanguageContext);

const setDocumentLanguage = (language: LanguageEnum) => {
	document.documentElement.setAttribute("lang", language);
	const languageTag = document.getElementById("content-language");
	languageTag?.setAttribute("content", language);
};

export const LanguageProvider: React.FC = ({ children }) => {
	const storedLanguage = localStorage.getItem("language") as LanguageEnum;
	const [language, setLanguageState] = React.useState<LanguageEnum>(
		storedLanguage || (process.env.REACT_APP_DEFAULT_LANGUAGE as LanguageEnum)
	);
	const [languages, setLanguages] = React.useState<LanguageEnum[]>([]);
	const [triggerLanguageRender, setTriggerLanguageRender] = React.useState(false);

	const triggerGTMLanguageChange = () => {
		if (!window.dataLayer) window.dataLayer = [];

		window.dataLayer.push({
			currentLanguage: localStorage.getItem("language")
		});
		window.dataLayer.push({ event: "languageChange" });
	};

	React.useEffect(() => {
		localStorage.setItem("language", language);

		setDocumentLanguage(language);
		triggerGTMLanguageChange();
	}, [language]);

	const [localizationObject, setLocalizationObject] = React.useState<LocalizationObject>(
		{}
	);

	const setLanguage = (lang: LanguageEnum) => {
		setLanguageState(lang);
		localStorage.setItem("language", lang);
	};

	const getLanguage = () => language;

	const refreshTriggerLanguageRender = () =>
		setTriggerLanguageRender(previous => !previous);

	const getLocalizedText = (key: string, defaultText = "") => {
		const { text } = localizationObject[key] ?? {
			text: defaultText
		};
		return text;
	};

	const getLocalizedTexts = (keys: string[], defaultText = "") =>
		keys.map(key => getLocalizedText(key, defaultText));

	const contextValue = {
		language,
		languages,
		triggerLanguageRender,
		localizationObject,
		getLocalizedText,
		getLocalizedTexts,
		setLanguage,
		getLanguage,
		setLanguages,
		setLocalizationObject,
		refreshTriggerLanguageRender
	};

	return (
		<LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>
	);
};
