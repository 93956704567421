import { DocumentsContainer, PageWithSEO } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	body_1: string;
	description: string;
	pageId: string;
	hasDate?: boolean;
	hasInfo?: boolean;
}

export const DocumentsPage: React.FC<Props> = ({
	description,
	body_1,
	pageId,
	hasDate = true,
	hasInfo = false,
	...seoProps
}) => (
	<PageWithSEO withSidebar {...seoProps}>
		<DocumentsContainer
			description={description}
			body_1={body_1}
			pageId={pageId}
			hasDate={hasDate}
			hasInfo={hasInfo}
		/>
	</PageWithSEO>
);
