import { Grid, Stack } from "@mui/material";
import { AdminFormCreateUpdateButton } from "../buttons";
import {
	TitleInput,
	DateInput,
	DescriptionInput,
	ImageLinkInput,
	LanguageSelect,
	AdminPanelHighlightedCheck
} from "../inputs";
import { FormProps } from "../../../util/types/admin-form.types";
import React from "react";

import { AdminSEODialog } from "../dialogs";

export const AdminPanelNewsForm: React.FC<FormProps> = ({ handleSubmit }) => {
	return (
		<Stack spacing="0.75rem">
			<TitleInput />
			<ImageLinkInput />
			<DescriptionInput />
			<Grid container gap="0.75rem">
				<Grid item xs>
					<LanguageSelect />
				</Grid>
				<Grid item xs>
					<DateInput />
				</Grid>
			</Grid>
			<AdminSEODialog />
			<AdminPanelHighlightedCheck />
			<AdminFormCreateUpdateButton handleClick={handleSubmit} />
		</Stack>
	);
};
