import { Card } from "@mui/material";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { GenericImageContainer } from "../../elements";

interface Props {
	variant: "whiteClockIcon" | "greyClockIcon";
}

export const ClockIcon: React.FC<Props> = ({ variant }) => {
	const {
		[variant]: { imageAlt, imageSrc }
	} = ImageDictionary.Career;

	return (
		<GenericImageContainer
			imageAlt={imageAlt}
			imageSrc={imageSrc}
			width="0.875rem"
			component={Card}
		/>
	);
};
