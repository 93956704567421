import { useLocalizedNavigate } from "./useLocalizedNavigate";

export const useLinkProps = () => {
	const { prefix } = useLocalizedNavigate();

	const getLinkProps = (route: string) =>
		route.includes("https")
			? {
					to: `//${route.split("https:")[1]}`,
					target: "_blank",
					rel: "nofollow noreferrer"
			  }
			: { to: route[0] === "/" ? `${route}` : `${prefix()}/${route}` };

	return { getLinkProps };
};
