import React from "react";

export const useMuiPieArcHighlightedIndex = (
	setHighlightedIndex: (index: number | null) => void
) => {
	React.useEffect(() => {
		const arcLabels = Object.values(document.getElementsByClassName("MuiPieArc-root"));
		arcLabels.forEach((arcLabel, index) => {
			arcLabel.addEventListener("mouseover", () => setHighlightedIndex(index));
			arcLabel.addEventListener("mouseout", () => setHighlightedIndex(null));
		});

		return () => {
			arcLabels.forEach((arcLabel, index) => {
				arcLabel.removeEventListener("mouseover", () => setHighlightedIndex(index));
				arcLabel.removeEventListener("mouseout", () => setHighlightedIndex(null));
			});
		};
	});
};
