import React from "react";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import { LoginContainerTemplate } from "./login-container.template";
import { Navigate, useLocation } from "react-router";
import { ResetNoticeForm } from "../../components";

export const ResetNoticeContainer: React.FC = () => {
	const { email } = useLocation().state as { email: string };

	const { navigate } = useLocalizedNavigate();

	const handleSubmit = () => navigate("/login");

	const props = { handleSubmit, email };

	return email ? (
		<LoginContainerTemplate>
			<ResetNoticeForm {...props} />
		</LoginContainerTemplate>
	) : (
		<Navigate to="/login" replace />
	);
};
