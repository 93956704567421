import { Box, Typography } from "@mui/material";
import React from "react";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { usePagesContext } from "../../../util/contexts/pages.context";
import { useSectionsContext } from "../../../util/contexts/sections.context";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { SectionDAO } from "../../../util/generators/types";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { MobileBreadCrumbs } from "../../../util/theme/styled-components";
import { MobileBreadCrumbsChevron, TemplateBottomNavigation } from "../../components";
import { Footer } from "../footer.container";
import { LayoutWrapper } from "./layout.wrapper";
import { Page } from "./page.container";
import { FooterProps } from "../types";
interface Props {
	footerVariant?: FooterProps["variant"];
}

export const PageWithSidebar: React.FC<Props> = ({ children, footerVariant }) => {
	const { isMobile } = useResponsive();
	const { setIsOpen } = useSidebarContext();
	const { language } = useLanguageContext();
	const { selectedSection: selectedSectionId, sections } = useSectionsContext();
	const { pages, selectedPageId } = usePagesContext();

	const [breadcrumbState, setBreadcrumbState] = React.useState({
		sectionName: "",
		pageName: ""
	});

	const pagesInSection = pages
		.filter(({ sectionId }) => sectionId === selectedSectionId)
		.sort((a, b) => a.index - b.index);

	const showBottomNavigation = pagesInSection.length > 1;

	const selectedPageArrayIndex = pagesInSection.findIndex(
		page => page.id === selectedPageId
	);

	const previousPage = selectedPageArrayIndex
		? pagesInSection[selectedPageArrayIndex - 1]
		: null;

	const nextPage =
		selectedPageArrayIndex !== pagesInSection.length - 1
			? pagesInSection[selectedPageArrayIndex + 1]
			: null;

	const isAdmin = location.pathname.includes("/admin/");

	let selectedSection = {} as SectionDAO;

	if (sections) selectedSection = sections[language][selectedSectionId as string];
	const selectedPage = pagesInSection[selectedPageArrayIndex] ?? {};

	const mobileBreadCrumbs = [
		<Typography key="0" variant="body4-secondary-semi-bold">
			{breadcrumbState.sectionName}
		</Typography>,
		<Typography key="1" variant="body4-primary-medium" sx={{ opacity: "0.4" }}>
			{breadcrumbState.pageName}
		</Typography>
	];

	React.useEffect(() => {
		if (!selectedSectionId || !selectedPageId) return;
		setBreadcrumbState({
			sectionName: selectedSection.name,
			pageName: selectedPage.name
		});
	}, [selectedSection, selectedPage]);

	React.useEffect(() => {
		setIsOpen(!isMobile && !isAdmin);
	}, [isMobile]);

	return (
		<>
			<LayoutWrapper withSidebar>
				{isMobile ? (
					<Box paddingY="1.00rem">
						<MobileBreadCrumbs separator={<MobileBreadCrumbsChevron />}>
							{mobileBreadCrumbs}
						</MobileBreadCrumbs>
					</Box>
				) : null}
				<Page>{children}</Page>
				{showBottomNavigation ? (
					<TemplateBottomNavigation nextPage={nextPage} previousPage={previousPage} />
				) : null}
			</LayoutWrapper>
			<Box sx={{ marginTop: "auto" }}>
				<Footer variant={footerVariant ?? "dark"} />
			</Box>
		</>
	);
};
