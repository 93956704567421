import React from "react";
import { Box } from "@mui/material";
import { DocumentYearSelectButton, LocalizationSelectButton } from "../buttons";
import { DocumentDropdown, LocalizationDropdown } from "../dropdowns";
import { LanguageEnum } from "../../../util/dictionaries/types";

interface LocalizationProps {
	handleChange: (language: LanguageEnum) => void;
	handleButtonClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => void;
	anchorEl: HTMLElement | null;
	mobile?: boolean;
}

export const LocalizationSelect: React.FC<LocalizationProps> = ({
	handleButtonClick,
	handleChange,
	anchorEl,
	mobile
}) => {
	const dropdownProps = { anchorEl, handleClose: handleButtonClick };

	return (
		<Box>
			<LocalizationSelectButton anchorEl={anchorEl} handleClick={handleButtonClick} />
			<LocalizationDropdown
				mobile={mobile}
				dropdownProps={dropdownProps}
				handleChange={handleChange}
			/>
		</Box>
	);
};

interface DocumentProps {
	handleButtonClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => void;
	handleChange: (year: string) => void;
	anchorEl: HTMLElement | null;
	documentYears: string[];
	selectedYear: string;
}

export const DocumentYearSelect: React.FC<DocumentProps> = ({
	handleButtonClick,
	handleChange,
	anchorEl,
	documentYears,
	selectedYear
}) => (
	<Box>
		<DocumentYearSelectButton
			anchorEl={anchorEl}
			handleClick={handleButtonClick}
			selectedYear={selectedYear}
		/>
		<DocumentDropdown
			anchorEl={anchorEl}
			documentYears={documentYears}
			handleClose={handleButtonClick}
			handleChange={handleChange}
		/>
	</Box>
);
