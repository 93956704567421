import { InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React from "react";
import { NavLink } from "react-router-dom";
import { Stack } from "@mui/material";
import { LoginInput } from "./login.input";
import { PasswordInputProps } from "./types";
import { LocalizedGenericBody } from "../../../elements";

export const LoginPasswordInput: React.FC<PasswordInputProps> = ({
	passwordReset = true,
	...props
}) => {
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => {
		const nextShowPassword = !showPassword;
		setShowPassword(nextShowPassword);
	};

	const type = showPassword ? "text" : "password";

	const endAdornment = (
		<InputAdornment
			data-cy="login-password-input-toggle"
			position="end"
			onClick={handleClickShowPassword}
			sx={{ cursor: "pointer", position: "absolute", right: "1rem" }}
		>
			{showPassword ? <VisibilityOff /> : <Visibility />}
		</InputAdornment>
	);

	return (
		<Stack alignItems="flex-end" gap="0.5rem" alignSelf="stretch" width="100%">
			<LoginInput
				{...props}
				type={type}
				modifiedField="password"
				localizationKey="password_input_placeholder"
				endAdornment={endAdornment}
			/>
			{passwordReset ? (
				<NavLink to="/forgot-password">
					<LocalizedGenericBody
						localizationKey="login_forgot_password"
						variant="body3-primary-medium"
					/>
				</NavLink>
			) : null}
		</Stack>
	);
};
