import { DateTime } from "luxon";

declare global {
	interface String {
		/**
		 * Convert date string to locale date string
		 */
		toLocaleDateStringFormat: (locale: string) => string;
		/**
		 * Convert date string to ISO time string
		 */
		toISOTimeFormat: () => string;
	}
}

Object.defineProperties(String.prototype, {
	toLocaleDateStringFormat: {
		value: function (locale: string) {
			const getDateFormat = (locale: string) => {
				switch (locale) {
					case "en":
						return "MM/dd/yyyy";
					default:
						return "dd/MM/yyyy";
				}
			};
			return DateTime.fromISO(this).toFormat(getDateFormat(locale)).toLocaleString();
		}
	},
	toISOTimeFormat: {
		value: function () {
			return DateTime.fromISO(this).toFormat("HH:mm");
		}
	}
});

export default String.prototype;
