import { SearchItemHeading } from "../headings";
import { GenericBody } from "../../elements";
import { SearchItemHeadingProps } from "../types";
import { Stack } from "@mui/material";

interface SearchItemContentProps extends SearchItemHeadingProps {
	date: string;
}

export const SearchItemContent: React.FC<SearchItemContentProps> = ({
	text,
	Icon,
	date
}) => (
	<Stack sx={{ spacing: "0.75rem" }}>
		<SearchItemHeading text={text} Icon={Icon} />
		<GenericBody
			text={date}
			variant="body4-primary"
			sx={{ opacity: "0.7", pl: "1.5rem" }}
		/>
	</Stack>
);
