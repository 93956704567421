import React from "react";
import { AdminDialogProps } from "../types";
import { UserCreateResponseDialog } from "./user-create-response.dialog";
import { UserCreateSuccessImage } from "../images";
import { SubmissionStatusEnum } from "../../containers/types";

export const UserCreateSuccessDialog: React.FC<AdminDialogProps> = props => (
	<UserCreateResponseDialog
		Image={UserCreateSuccessImage}
		variant={SubmissionStatusEnum.success}
		{...props}
	/>
);
