import React from "react";
import { UserDAO } from "../generators/types";
import { UserRoleEnum } from "../dictionaries/types";

export interface IAuthContext {
	user: UserDAO | null;
	isLoggedIn: () => boolean;
	login: (token: string) => void;
	logout: () => void;
	setUser: (user: UserDAO | null) => void;
	userHasRole: (role: UserRoleEnum, user: UserDAO | null) => boolean;
}

export const AuthContext = React.createContext<IAuthContext>({
	user: null,
	isLoggedIn: () => false,
	login: () => null,
	logout: () => null,
	setUser: () => null,
	userHasRole: () => false
});

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
	const originalRemoveItem = localStorage.removeItem;
	Object.defineProperty(Storage.prototype, "removeItem", {
		value: function (key: string) {
			if (key === "token") {
				setUser(null);
				clearPrivateCache();
			}

			originalRemoveItem.apply(this, [key]);
		}
	});

	const [user, setUser] = React.useState<UserDAO | null>(null);

	const setToken = (token: string) => localStorage.setItem("token", token);

	const removeToken = () => localStorage.removeItem("token");

	const login = (token: string) => setToken(token);

	const isLoggedIn = () => !!localStorage.getItem("token");

	const logout = () => removeToken();

	const userHasRole = (role: UserRoleEnum, user: UserDAO | null) =>
		!!user && user.userRoles.some(userRole => userRole === role);

	const contextValue = {
		user,
		isLoggedIn,
		login,
		logout,
		setUser,
		userHasRole
	};

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const clearPrivateCache = () => {
	const cacheRecordObject: Record<string, string> = JSON.parse(
		localStorage.getItem("cacheRecord") || "{}"
	);
	const cacheStorageObject: Record<string, string> = JSON.parse(
		localStorage.getItem("cacheStorage") || "{}"
	);
	Object.keys(cacheRecordObject).forEach(url => {
		if (url.includes("private")) {
			delete cacheRecordObject[url];
			delete cacheStorageObject[url];
		}
	});
	localStorage.setItem("cacheRecord", JSON.stringify(cacheRecordObject));
	localStorage.setItem("cacheStorage", JSON.stringify(cacheStorageObject));
};
