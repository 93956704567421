import { SubmissionStatus } from "../../containers/types";
import { CheckReportEmailBarProps } from "../types";
import { CheckReportResponseDialog } from "./check-report-response.dialog";
import { CheckReportEmailDialog } from "./check-report-email.dialog";

interface Props {
	closeDialog: () => void;
	sendMail: () => void;
	dialogOpen: boolean;
	sendMailStatus: SubmissionStatus;
	emailBarProps: CheckReportEmailBarProps;
	isLoading: boolean;
}

export const CheckReportDialog: React.FC<Props> = ({
	closeDialog,
	sendMail,
	dialogOpen,
	sendMailStatus,
	emailBarProps,
	isLoading
}) => (
	<>
		<CheckReportEmailDialog
			closeDialog={closeDialog}
			sendMail={sendMail}
			dialogOpen={dialogOpen && !sendMailStatus}
			emailBarProps={emailBarProps}
			isLoading={isLoading}
		/>
		<CheckReportResponseDialog
			closeDialog={closeDialog}
			sendMail={sendMail}
			dialogOpen={dialogOpen && !!sendMailStatus}
			emailBarProps={emailBarProps}
			response={sendMailStatus}
			isLoading={isLoading}
		/>
	</>
);
