import { CSSProperties } from "@mui/styled-engine";
import React from "react";

declare module "@mui/material/styles" {
	interface Variant {
		body3: true;
		body4: true;
	}
	interface TypographyVariants {
		hxl: React.CSSProperties;
		h7: React.CSSProperties;
		body3: React.CSSProperties;
		body4: React.CSSProperties;
	}
	interface TypographyVariantsOptions {
		hxl?: React.CSSProperties;
		h7?: React.CSSProperties;
		body3?: React.CSSProperties;
		body4?: React.CSSProperties;
	}
}

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		hxl: true;
		h7: true;
		body3: true;
		body4: true;
	}
}

export const getMuiTypography = () => {
	const commonStyles: CSSProperties = {
		display: "block",
		lineHeight: "140%",
		fontWeight: 400
	};

	const commonHeadingStyles: CSSProperties = {
		display: "block",
		fontWeight: "600",
		lineHeight: "130%"
	};

	return {
		fontWeightRegular: 400,
		htmlFontSize: 16,
		hxl: {
			...commonHeadingStyles,
			fontSize: "4rem",
			lineHeight: "120%"
		},
		h1: {
			...commonHeadingStyles,
			fontSize: "2.5rem"
		},
		h2: { ...commonHeadingStyles, fontSize: "2.25rem" },
		h3: { ...commonHeadingStyles, fontSize: "2rem" },
		h4: { ...commonHeadingStyles, fontSize: "1.25rem" },
		h5: { ...commonHeadingStyles, fontSize: "1.13rem" },
		h6: { ...commonHeadingStyles, fontSize: "1rem" },
		h7: { ...commonHeadingStyles, fontSize: "0.875rem" },
		subtitle1: { ...commonStyles, fontSize: "1rem", fontWeight: "500" },
		subtitle2: {
			...commonStyles,
			fontSize: "1.75rem",
			fontWeight: "600",
			lineHeight: "2.75rem"
		},
		body1: { ...commonStyles, fontSize: "1.13rem" },
		body2: { ...commonStyles, fontSize: "1rem" },
		body3: { ...commonStyles, fontSize: "0.875rem" },
		body4: { ...commonStyles, fontSize: "0.75rem", lineHeight: "130%" },

		button: {
			...commonStyles,
			fontSize: "0.875rem",
			textTransform: "unset",
			fontWeight: "600"
		}
	};
};
