import { GenericImageContainer } from "../../elements";
import arrowDown from "../../../assets/images/arrow-down.svg";
import arrowBack from "../../../assets/images/arrow-back.svg";
import {
	ChevronContainer,
	PaginationArrowDown,
	PaginationArrowUp,
	PaginationUpAndDownArrowContainer
} from "../../../util/theme/styled-components";
import faqArrowDown from "../../../assets/images/faq-arrow-down.svg";
import faqArrowUp from "../../../assets/images/faq-arrow-up.svg";
import faqSearchArrow from "../../../assets/images/search/faq-search-arrow.svg";
import dashboardSortArrow from "../../../assets/images/dashboard-sort-arrow.svg";
import dashboardSortUpArrow from "../../../assets/images/dashboard-sort-up-arrow.svg";
import dashboardSortArrowPrimary from "../../../assets/images/dashboard-sort-arrow-primary.svg";
import dashboardSortUpArrowPrimary from "../../../assets/images/dashboard-sort-up-arrow-primary.svg";
import seoBackArrow from "../../../assets/images/dashboard/dashboard-dialog-back-arrow.svg";
import React from "react";
import ChevronLeft from "../../../assets/images/bottom-navigation-chevron-left.svg";
import ChevronRight from "../../../assets/images/bottom-navigation-chevron-right.svg";
import MobileBreadCrumbsChevronImage from "../../../assets/images/mobile-breadcrumbs-chevron.svg";
import LanguageChevron from "../../../assets/images/localizations/language-chevron.svg";
import CityItemChevronImage from "../../../assets/images/city-item-chevron.svg";
import DocumentChevron from "../../../assets/images/document-chevron.svg";
import HeaderSectionChevronUp from "../../../assets/images/header-section-up-chevron.svg";

export const ArrowDown: React.FC = () => (
	<GenericImageContainer imageSrc={arrowDown} imageAlt="arrow down" width="1.5rem" />
);

export const ArrowBack: React.FC = () => (
	<GenericImageContainer imageSrc={arrowBack} imageAlt="arrow back" width="0.5rem" />
);

export const CityItemChevron: React.FC = () => (
	<GenericImageContainer
		imageSrc={CityItemChevronImage}
		imageAlt="city item chevron"
		width="fit-content"
	/>
);

export const BottomNavigationChevronLeft: React.FC<{ opacity?: string }> = ({
	opacity = "1"
}) => (
	<GenericImageContainer
		imageSrc={ChevronLeft}
		imageAlt="navigation-chevron-left"
		width="fit-content"
		style={{ opacity }}
	/>
);

export const BottomNavigationChevronRight: React.FC<{
	width?: string;
	height?: string;
	opacity?: string;
}> = ({ width = "0.375rem", height = "0.625rem", opacity = "1" }) => (
	<GenericImageContainer
		imageSrc={ChevronRight}
		imageAlt="navigation-chevron-right"
		width={width}
		height={height}
		style={{ opacity }}
	/>
);

export const MobileBreadCrumbsChevron: React.FC = () => (
	<GenericImageContainer
		imageSrc={MobileBreadCrumbsChevronImage}
		imageAlt="mobile-breadcrumbs-chevron"
		width="fit-content"
		style={{ opacity: "0.4", marginBottom: "1px" }}
	/>
);

interface ExtendedProps {
	anchorEl: HTMLElement | null;
	imageSrc: string;
	imageAlt: string;
}

interface Props {
	anchorEl: HTMLElement | null;
}

export const SelectChevron: React.FC<ExtendedProps> = ({
	anchorEl,
	imageSrc,
	imageAlt
}) => (
	<ChevronContainer sx={{ transform: !!anchorEl ? "" : "rotate(180deg)" }}>
		<img src={imageSrc} alt={imageAlt} />
	</ChevronContainer>
);

export const DocumentSelectChevron: React.FC<Props> = ({ anchorEl }) => (
	<SelectChevron
		anchorEl={anchorEl}
		imageSrc={DocumentChevron}
		imageAlt="document select arrow"
	/>
);

export const LanguageSelectChevron: React.FC<Props> = ({ anchorEl }) => (
	<SelectChevron
		anchorEl={anchorEl}
		imageSrc={LanguageChevron}
		imageAlt="language select arrow"
	/>
);

export const HeaderSectionChevron: React.FC<{ isHovered: boolean }> = ({ isHovered }) =>
	isHovered ? (
		<ChevronContainer>
			<img src={HeaderSectionChevronUp} alt="header-section-chevron-up" />
		</ChevronContainer>
	) : (
		<ChevronContainer sx={{ transform: "rotate(180deg)" }}>
			<img src={LanguageChevron} alt="header-section-chevron-down" />
		</ChevronContainer>
	);

export const FaqArrow: React.FC<{ index: number; openIndex: number }> = ({
	index,
	openIndex
}) => (
	<GenericImageContainer
		secondaryImageRendered={index === openIndex}
		imageSrc={faqArrowDown}
		secondaryImageSrc={faqArrowUp}
		imageAlt="faq expand collapse"
		rotate={index === openIndex ? "180deg" : "0deg"}
		width={{ xs: "1.04rem", md: "1.56rem" }}
		height={{ xs: "1.00rem", md: "1.50rem" }}
	/>
);

export const FaqSearchArrow: React.FC = () => (
	<GenericImageContainer
		imageSrc={faqSearchArrow}
		imageAlt="faq-search-arrow"
		width="0.875rem"
	/>
);

interface SortArrowProps {
	color: string;
	rotate?: string;
}

export const DashboardSortArrow: React.FC<SortArrowProps> = ({ color }) => (
	<GenericImageContainer
		secondaryImageRendered={color === "white"}
		imageSrc={dashboardSortArrowPrimary}
		secondaryImageSrc={dashboardSortArrow}
		imageAlt="sort arrow"
		width="1rem"
	/>
);

export const DashboardSortUpArrow: React.FC<SortArrowProps> = ({ color, rotate }) => (
	<GenericImageContainer
		secondaryImageRendered={color === "white"}
		imageSrc={dashboardSortUpArrowPrimary}
		secondaryImageSrc={dashboardSortUpArrow}
		imageAlt="sort up arrow"
		rotate={rotate}
		width="1rem"
	/>
);

export const DashboardSortDownArrow: React.FC<SortArrowProps> = ({ color }) => (
	<DashboardSortUpArrow color={color} rotate="180deg" />
);

export const AdminSEODialogCloseArrow: React.FC = () => (
	<GenericImageContainer imageSrc={seoBackArrow} imageAlt="go back" width="1rem" />
);

export const PaginationUpAndDownArrow: React.FC = () => (
	<PaginationUpAndDownArrowContainer>
		<PaginationArrowUp />
		<PaginationArrowDown />
	</PaginationUpAndDownArrowContainer>
);
