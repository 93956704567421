import payment from "../../../assets/images/make-payment.svg";
import { GenericImageContainer } from "../../elements";

export const MakePayment: React.FC = () => (
	<GenericImageContainer
		imageSrc={payment}
		imageAlt="make payment"
		width="2.13rem"
		height="1.69rem"
	/>
);
