import { Box, Stack } from "@mui/material";
import React from "react";
import { LocalizedGenericBody } from "../../elements";
import { StockbarPhone } from "../images";

export const HeaderPhoneNumber: React.FC = () => {
	const commonStyles = {
		lineHeight: "100%"
	};

	return (
		<Stack direction="row" alignItems="center" gap="0.500rem">
			<StockbarPhone />
			<Stack direction="row" alignItems="center" gap="0.438rem">
				<LocalizedGenericBody
					localizationKey="header_phone_number_1"
					variant="body1-primary-bold"
					sx={{
						...commonStyles,
						fontSize: { xs: "1.25rem", md: "1.50rem" }
					}}
				/>
				<Box>
					<LocalizedGenericBody
						localizationKey="header_phone_number_2"
						variant="body1-primary-bold"
						sx={{
							...commonStyles,
							width: "1.31rem",

							fontSize: { xs: "0.625rem", md: "0.750rem" }
						}}
					/>
					<LocalizedGenericBody
						localizationKey="header_phone_number_characters"
						variant="body1-primary-bold"
						sx={{
							...commonStyles,
							width: "1.25rem",

							fontSize: { xs: "0.500rem", md: "0.625rem" }
						}}
					/>
				</Box>
			</Stack>
		</Stack>
	);
};
