import { Stack } from "@mui/material";
import {
	GenericBody,
	GenericButton,
	LocalizedGenericButton,
	LocalizedGenericBody
} from "../../elements";
import { PublishButtonImage } from "../images";
import { PublishButtonStack } from "../../../util/theme/styled-components";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";
import { DateTime } from "luxon";
import { useLanguageContext } from "../../../util/contexts/language.context";

interface Props {
	handleClick: () => void;
	isCollapsed?: boolean;
	isPublished: boolean;
	lastPublishDate: Date;
}

export const PublishButton: React.FC<Props> = ({
	handleClick,
	isCollapsed,
	isPublished,
	lastPublishDate
}) => {
	const { language } = useLanguageContext();

	const conditionalTextColor = isPublished ? { color: ColorDictionary["#838383"] } : {};
	const publishedButtonStyles = isPublished
		? { background: ColorDictionary["#c7c7c7"], borderColor: ColorDictionary["#c7c7c7"] }
		: {};

	const isTesting = process.env.REACT_APP_NODE_ENV === "e2e";

	const weekInMilliseconds = 604800000;

	const isLastPublishedInPreviousWeek = !(
		new Date().getTime() - lastPublishDate.getTime() >
		weekInMilliseconds
	);

	const dateTime = DateTime.fromJSDate(lastPublishDate).setLocale(language);

	const relativeText = dateTime.toRelativeCalendar();

	const formattedDate =
		isLastPublishedInPreviousWeek && !!relativeText
			? relativeText
			: dateTime.toLocaleString();

	return isCollapsed ? (
		<GenericButton
			handleClick={handleClick}
			variant="publish"
			mini={true}
			disabled={isPublished}
			sx={{ minHeight: "3rem", ...publishedButtonStyles }}
		>
			<PublishButtonImage isPublished={isPublished} />
		</GenericButton>
	) : (
		<PublishButtonStack
			direction="column"
			spacing="0.5rem"
			padding="0.5rem 0.25rem 0.25rem 0.25rem"
			sx={{ maxWidth: "100%", overflow: "hidden" }}
		>
			<Stack alignItems="center">
				<LocalizedGenericBody
					localizationKey={
						isPublished ? "all_changes_published" : "there_are_unpublished_changes"
					}
					variant="body4-primary-medium"
					disableEdit={true}
					sx={conditionalTextColor}
					lineClamp={1}
				/>
				<Stack direction="row" justifyContent="center" spacing="0.25rem">
					<LocalizedGenericBody
						localizationKey="last_published_at"
						variant="body4-primary-medium"
						disableEdit={true}
						sx={{ color: ColorDictionary["#838383"] }}
						lineClamp={1}
					/>
					<GenericBody
						text={formattedDate}
						variant="body4-primary-medium"
						sx={{ color: ColorDictionary["#838383"] }}
					/>
				</Stack>
			</Stack>
			<LocalizedGenericButton
				data-cy="publish-button"
				localizationKey="publish_button_placeholder"
				variant="publish"
				textVariant="body2-white-medium"
				handleClick={handleClick}
				disableEdit={true}
				startIcon={<PublishButtonImage isPublished={isPublished} />}
				disabled={!isTesting && isPublished}
				sx={{
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					...publishedButtonStyles,
					span: conditionalTextColor
				}}
			/>
		</PublishButtonStack>
	);
};
