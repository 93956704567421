import { IPageWithSEO } from "../types";
import { PageWithSidebar } from "./page-with-sidebar.container";
import { PageWithoutSidebar } from "./page-without-sidebar.container";
import { SEOWrapper } from "./seo.wrapper";

export const PageWithSEO: React.FC<IPageWithSEO> = ({
	withSidebar = false,
	footerVariant,
	children,
	...seoProps
}) => {
	const PageWrapper = withSidebar ? PageWithSidebar : PageWithoutSidebar;

	return (
		<SEOWrapper {...seoProps}>
			<PageWrapper footerVariant={footerVariant}>{children}</PageWrapper>
		</SEOWrapper>
	);
};
