import React from "react";
import { SEOOpenButton } from "../buttons";
import { SEOForm } from "../forms";
import { AdminDialogTemplate } from "./admin-dialog.template";

export const AdminSEODialog: React.FC = () => {
	const [seoDialogOpen, setSeoDialogOpen] = React.useState(false);
	const handleSeoOpen = () => setSeoDialogOpen(true);
	const handleSeoClose = () => setSeoDialogOpen(false);

	return (
		<>
			<SEOOpenButton handleClick={handleSeoOpen} />
			<AdminDialogTemplate
				enableGoBack
				dialogOpen={seoDialogOpen}
				handleClose={handleSeoClose}
				headerLocalizationKey="admin_seo_dialog_header_text"
			>
				<SEOForm handleClose={handleSeoClose} />
			</AdminDialogTemplate>
		</>
	);
};
