export class ActionWithDelayedCallback {
	timeout: NodeJS.Timeout | undefined;

	isCanceled = false;
	hasResponded = false;

	constructor(
		private action: () => void,
		private response: () => void,
		private delay?: number
	) {}

	start = (customDelay?: number) => {
		this.executeWithDelay(customDelay);
		this.hasResponded = false;
		this.isCanceled = false;
	};

	private executeWithDelay = (delay = this.delay) => {
		this.clearTimeout();
		this.action();
		this.timeout = setTimeout(this.respond, delay);
	};

	private respond = () => {
		this.response();
		this.hasResponded = true;
	};

	cancel = () => {
		this.clearTimeout();
		this.isCanceled = true;
	};

	private clearTimeout = () => clearTimeout(this.timeout);
}

export class RegularActionWithDelayedCallback extends ActionWithDelayedCallback {
	constructor(action: () => void, response: () => void) {
		super(action, response, 5000);
	}
}
