import { Grid } from "@mui/material";
import { ContentFourSubcontentIcon, ListAndMediaPageContent } from "../components";
import { PageIdProps } from "../types";

interface Props extends PageIdProps {
	title_1: string;
	subtitle_1: string;
	subtitle_2: string;
	body_1: string;
	body_2: string;
	imageLink_1: string;
}

export const ListAndMediaContainer: React.FC<Props> = props => (
	<Grid container sx={{ maxWidth: "54.4rem", px: "0.625rem" }}>
		<Grid
			item
			xs={1}
			justifyContent="right"
			alignItems="flex-start"
			pr="0.625rem"
			sx={{ display: "flex" }}
		>
			<ContentFourSubcontentIcon variant="left" />
		</Grid>
		<Grid item xs={11}>
			<ListAndMediaPageContent {...props} />
		</Grid>
	</Grid>
);
