import React from "react";
import { Stack } from "@mui/material";
import { AssetDAO } from "../../util/generators/types";
import { AssetsAdminCommunicator } from "../../communicators/lider.http.communicator";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { AssetsSearchContainer } from "../containers";
import { SearchAndFiltersStack } from "../../util/theme/styled-components";
import { DragAndDropImageButton } from "../components/inputs/dnd/dnd.image";
import { useAuthFormStatus } from "../../util/hooks/useAuthFormStatus.hook";
import { AssetsList, EmptyAssetViewContent, FormSnackbar } from "../components";
import { FormVariant } from "../components/types";
import { useRefreshContext } from "../../util/contexts/refresh.context";

const downloadFile = (file: Blob, filename?: string) => {
	const downloadUrl = window.URL.createObjectURL(file);
	const element = document.createElement("a");
	element.href = downloadUrl;
	element.download = filename || "download";
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
	window.URL.revokeObjectURL(downloadUrl);
};

export const AssetsContainer: React.FC = () => {
	const communicator = new AssetsAdminCommunicator();

	const { triggerCRUDRefresh } = useRefreshContext();

	const [assets, setAssets] = React.useState<AssetDAO[] | []>([]);
	const [imagesToUpload, setImagesToUpload] = React.useState<File[]>([]);
	const [assetToDelete, setAssetToDelete] = React.useState<AssetDAO>();
	const [filteredAssets, setFilteredAssets] = React.useState<AssetDAO[]>(assets);
	const [formVariant, setFormVariant] = React.useState<FormVariant>("assetCreate");

	const {
		submissionStatus,
		handleSubmissionError,
		setSubmissionSuccess,
		resetSubmissionStatus
	} = useAuthFormStatus();

	const extractDataAndSetAssets = (result: any) => {
		if (!result) return;
		setAssets(result.data);
	};

	useGeneratorEffect(
		{
			effect: () => communicator.findAll(),
			onSuccess: extractDataAndSetAssets,
			callback: () => communicator.abort()
		},
		[imagesToUpload, assetToDelete]
	);

	const setSubmissionSuccessAndRefresh = () => {
		setSubmissionSuccess();
		triggerCRUDRefresh();
	};

	const onCreate = async (formData: FormData) => {
		setFormVariant("assetCreate");
		communicator
			.createOne(formData)
			.then(setSubmissionSuccessAndRefresh)
			.catch(handleSubmissionError);
	};

	const onDelete = (asset: AssetDAO) => {
		setFormVariant("assetDelete");
		communicator
			.delete(asset.filename)
			.then(setSubmissionSuccessAndRefresh)
			.catch(handleSubmissionError);
		setAssetToDelete(asset);
	};

	const onDownload = async (asset: AssetDAO) => {
		setFormVariant("assetDownload");
		await communicator
			.download(asset.filename)
			.then(response => {
				downloadFile(response, asset.filename);
			})
			.catch(handleSubmissionError);
	};

	const tableContent =
		!filteredAssets || filteredAssets.length == 0 ? (
			<EmptyAssetViewContent />
		) : (
			<AssetsList assets={filteredAssets} onDelete={onDelete} onDownload={onDownload} />
		);

	return (
		<Stack spacing={{ xs: "1rem", md: "1.5rem" }} mt="-0.5rem">
			<Stack
				spacing="1rem"
				direction={{ xs: "column", md: "row" }}
				justifyContent="space-between"
				useFlexGap
				flexWrap="wrap"
				alignItems={{ xs: "unset", md: "end" }}
			>
				<SearchAndFiltersStack>
					<AssetsSearchContainer assets={assets} setFilteredAssets={setFilteredAssets} />
				</SearchAndFiltersStack>
				<DragAndDropImageButton
					onCreate={onCreate}
					setImages={setImagesToUpload}
					data-cy="admin-dnd-image"
				/>
			</Stack>
			{tableContent}
			<FormSnackbar
				autoHideDuration={5000}
				handleClose={resetSubmissionStatus}
				submissionStatus={submissionStatus}
				formVariant={formVariant}
			/>
		</Stack>
	);
};
