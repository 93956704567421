import { Box } from "@mui/material";
import React from "react";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { Footer } from "../footer.container";
import { Page } from "./page.container";
import { FooterProps } from "../types";
interface Props {
	footerVariant?: FooterProps["variant"];
}

export const PageWithoutSidebar: React.FC<Props> = ({ children, footerVariant }) => {
	const { setIsOpen } = useSidebarContext();

	React.useEffect(() => {
		setIsOpen(false);
	}, []);

	return (
		<>
			<Box>
				<Page>{children}</Page>
			</Box>
			<Box sx={{ marginTop: "auto" }}>
				<Footer variant={footerVariant ?? "light"} />
			</Box>
		</>
	);
};
