import { Typography } from "@mui/material";
import React from "react";
import { NewsBarBox } from "../../../util/theme/styled-components";

interface Props {
	html: string;
}

export const NewsBar: React.FC<Props> = ({ html }) => (
	<NewsBarBox>
		<Typography
			sx={{
				height: { xs: "1.00rem", md: "1.25rem" },
				display: "flex",
				alignItems: "center",
				animation: `flowing ${0.1 * html.length}s linear infinite`,
				cursor: "default",
				":hover": {
					animationPlayState: "paused"
				}
			}}
			variant="white-animated"
			dangerouslySetInnerHTML={{ __html: html }}
		></Typography>
	</NewsBarBox>
);
