import React from "react";
import { useLanguageContext } from "../../util/contexts/language.context";
import { usePagesContext } from "../../util/contexts/pages.context";
import { useSectionsContext } from "../../util/contexts/sections.context";
import { LanguageEnum } from "../../util/dictionaries/types";
import { PageDAO } from "../../util/generators/types";
import { HeaderView } from "../components";

export const Header: React.FC = () => {
	const { language, setLanguage, refreshTriggerLanguageRender } = useLanguageContext();
	const storedSections = useSectionsContext().sections;
	const { pages: contextPages } = usePagesContext();

	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);

	const [searchBarOpen, setSearchBarOpen] = React.useState(false);

	const [pagesInHoveredSection, setPagesInHoveredSection] = React.useState<PageDAO[]>([]);
	const [sectionAnchorEl, setSectionAnchorEl] = React.useState<HTMLElement | null>(null);
	const [hoveredSectionId, setHoveredSectionId] = React.useState<string>("");

	const openSectionMenu = (
		event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		sectionId: string
	) => {
		setSectionAnchorEl(event.currentTarget);
		setPagesInHoveredSection(
			contextPages.filter(({ sectionId: pageSectionId }) => pageSectionId === sectionId)
		);
		setHoveredSectionId(sectionId);
	};

	const closeSectionMenu = () => {
		setSectionAnchorEl(null);
		setHoveredSectionId("");
	};

	const handleDropdownClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent> | null
	) => {
		const currentTarget = event?.currentTarget || null;
		const nextAnchorElement = anchorElement ? null : currentTarget;
		setAnchorElement(nextAnchorElement);
	};

	const handleDrawerToggle = () => {
		setDrawerOpen(prev => !prev);
		handleDropdownClick(null);
	};

	const handleLocalization = (language: LanguageEnum) => {
		setLanguage(language);
		refreshTriggerLanguageRender();
		handleDropdownClick(null);
	};

	const sections = {};
	const invisibleSections = {};

	if (storedSections && storedSections[language])
		Object.entries(storedSections[language]).forEach(([id, { name, visible, index }]) => {
			const pages = contextPages.filter(({ sectionId }) => sectionId === id);
			const route = pages.length ? pages[0].route : "";
			if (visible) Object.assign(sections, { [id]: { name, route, index } });
			else Object.assign(invisibleSections, { [id]: { name, route, index } });
		});

	const headerProps = {
		closeSectionMenu,
		handleDrawerToggle,
		handleLocalization,
		handleDropdownClick,
		setSearchBarOpen,
		openSectionMenu,
		anchorEl: anchorElement,
		drawerOpen,
		searchBarOpen,
		hoveredSectionId,
		invisibleSections,
		pagesInHoveredSection,
		sectionAnchorEl,
		sections
	};

	return <HeaderView {...headerProps} />;
};
