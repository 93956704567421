import React from "react";
import { useSocketContext } from "../contexts/socket.context";

export const useSocketEvent = (event: string, callback: (data: any) => void) => {
	const { addEvent, clearEvent } = useSocketContext();
	React.useEffect(() => {
		addEvent(event, callback);

		return () => {
			clearEvent(event);
		};
	}, []);
};
