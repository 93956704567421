import { Stack } from "@mui/material";
import React from "react";
import {
	LocalizedGenericBody,
	LocalizedTypographyWithVariableSubject
} from "../../elements";
import { SearchDropdownContentCard } from "../../../util/theme/styled-components";

import faqBackground from "../../../assets/images/search/faq-background.svg";
import documentBackground from "../../../assets/images/search/document-background.svg";
import newsBackground from "../../../assets/images/search/news-background.svg";
import branchBackground from "../../../assets/images/search/branch-background.svg";
import { SearchEmptyResultsLogo } from "../images";
import { sanitizeString } from "../../../util/converters/string.converter";

interface Props {
	filter?: string;
}

const ContentCardFactory = (
	key: "faq" | "document" | "news" | "branch",
	imageLink: string
) => (
	<SearchDropdownContentCard
		py="1rem"
		px={{ xs: "1rem", md: "1.38rem" }}
		spacing="0.5rem"
		minHeight={{ xs: "5.88rem", md: "6.63rem" }}
		width={{ xs: "unset", md: "50%" }}
		sx={{
			position: "relative",
			"&::before": {
				content: "''",
				position: "absolute",
				left: 0,
				top: 0,
				height: "100%",
				width: "100%",
				opacity: 0.1,
				backgroundImage: `url(${imageLink})`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "90% 0"
			}
		}}
	>
		<LocalizedGenericBody
			localizationKey={`initial_search_${key}_title`}
			variant="body2-primary-medium"
		/>
		<LocalizedGenericBody
			localizationKey={`initial_search_${key}_helper_text`}
			variant="body4-primary-medium"
			sx={{ opacity: "0.8" }}
		/>
	</SearchDropdownContentCard>
);

export const SearchDropdownInitialContent: React.FC = () => (
	<Stack
		direction="column"
		alignItems="center"
		padding={{ xs: "0.75rem", md: "1.25rem" }}
		spacing={{ xs: "0.75rem", md: "1rem" }}
	>
		<LocalizedGenericBody
			localizationKey="initial_search_helper_text"
			variant="body3-primary-medium"
			sx={{ opacity: "0.39", textAlign: "center" }}
		/>
		<Stack
			direction={{ xs: "column", md: "row" }}
			spacing={{ xs: "0.75rem", md: "1rem" }}
		>
			{ContentCardFactory("faq", faqBackground)}
			{ContentCardFactory("document", documentBackground)}
		</Stack>
		<Stack
			direction={{ xs: "column", md: "row" }}
			spacing={{ xs: "0.75rem", md: "1rem" }}
		>
			{ContentCardFactory("news", newsBackground)}
			{ContentCardFactory("branch", branchBackground)}
		</Stack>
	</Stack>
);

export const SearchDropdownEmptyResults: React.FC<{ filter: string }> = ({ filter }) => (
	<Stack
		spacing="0.688rem"
		alignItems="center"
		py={{ xs: "1.75rem", md: "2rem" }}
		px={{ xs: "0.75rem", md: "0" }}
	>
		<SearchEmptyResultsLogo />
		<Stack spacing="0.375rem" alignItems="center" px={{ xs: "3.5rem", md: "0" }}>
			<LocalizedGenericBody
				localizationKey="empty_search_results_title"
				variant="body3-primary-bold"
				sx={{ textAlign: "center" }}
			/>
			<LocalizedTypographyWithVariableSubject
				localizationKey="empty_search_results_helper_text"
				variant="body4-primary-medium"
				variable={`<em>"${sanitizeString(filter)}"</em>`}
				sx={{ opacity: "0.39", textAlign: "center", maxWidth: "20.9rem" }}
			/>
		</Stack>
	</Stack>
);

export const SearchDropdownEmptyContent: React.FC<Props> = ({ filter }) =>
	!!filter ? (
		<SearchDropdownEmptyResults filter={filter} />
	) : (
		<SearchDropdownInitialContent />
	);
