import { Stack } from "@mui/material";
import { LoginButton } from "../../buttons";
import { LocalizedGenericBody } from "../../../elements";
import { TemplateProps } from "./types";

export const AdminLoginFlowFormTemplate: React.FC<TemplateProps> = ({
	headerLocalization,
	buttonLocalization,
	handleSubmit,
	validate,
	children
}) => (
	<Stack
		data-cy="admin-login-form"
		component="form"
		alignItems="center"
		gap={{ xs: "1.5rem", md: "2.5rem" }}
		width="100%"
		onSubmit={handleSubmit}
	>
		<LocalizedGenericBody
			localizationKey={headerLocalization}
			variants={{ mobile: "h4-primary", desktop: "h3-primary" }}
		/>
		<Stack width="100%" alignItems="flex-start" justifyContent="center" gap="1rem">
			{children}
		</Stack>
		<LoginButton
			data-cy="login-button"
			localizationKey={buttonLocalization}
			disabled={!validate()}
		/>
	</Stack>
);
