import { Stack } from "@mui/material";
import { AdminHomepageTableBox } from "../../../util/theme/styled-components";
import { AdminHomepageTableHeader } from "../headers";
import { AdminHomepageCardContent } from "../contents";
import { GenericImageContainer, LocalizedButton } from "../../elements";
import { AdminHomepageCardContentProps } from "../types";
import { useNavigate } from "react-router";

interface Props {
	buttonAction: () => void;
	buttonDisabled?: boolean;
	buttonLocalizationKey: string;
	buttonImageSrc: string;
	buttonVariant: "newPrimary" | "newSecondary";
	contentProps: AdminHomepageCardContentProps;
	endIcon?: React.ReactNode;
	imageSrc: string;
	localizationKey: string;
	route: string;
}

export const AdminHomepageCard: React.FC<Props> = ({
	buttonAction,
	buttonDisabled = false,
	buttonImageSrc,
	buttonLocalizationKey,
	buttonVariant,
	contentProps,
	endIcon,
	imageSrc,
	localizationKey,
	route
}) => {
	const navigate = useNavigate();

	return (
		<AdminHomepageTableBox width="100%">
			<AdminHomepageTableHeader
				handleClick={() => navigate(route)}
				endIcon={endIcon}
				imageSrc={imageSrc}
				localizationKey={localizationKey}
				padding="1rem 1.5rem"
			/>
			<Stack padding="0.75rem 1.5rem" spacing="1.5rem" width="100%">
				<AdminHomepageCardContent {...contentProps} />
				<LocalizedButton
					onClick={buttonAction}
					disabled={buttonDisabled}
					variant={`${buttonVariant}-mini`}
					startIcon={
						<GenericImageContainer
							imageAlt="card-button-icon"
							imageSrc={buttonImageSrc}
							width="0.875rem"
						/>
					}
					localizationKey={buttonLocalizationKey}
					sx={{
						justifyContent: "center",
						width: "100%",
						height: "1.75rem",
						minWidth: "unset",
						".MuiTypography-root": { margin: "unset" }
					}}
				/>
			</Stack>
		</AdminHomepageTableBox>
	);
};
