import { Collapse } from "@mui/material";
import React from "react";
import { LanguageEnum } from "../../../util/dictionaries/types";
import { LocalizationItem } from "../items";
import { LocalizationMenu, MobileDropdown } from "../../../util/theme/styled-components";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { SideMenuLanguageItem } from "../sections";

interface Props {
	dropdownProps: {
		anchorEl: HTMLElement | null;
		handleClose: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => void;
	};
	handleChange: (language: LanguageEnum) => void;
	mobile?: boolean;
}

export const LocalizationDropdown: React.FC<Props> = ({
	dropdownProps,
	handleChange,
	mobile = false
}) => {
	const { anchorEl, handleClose } = dropdownProps;
	const { languages } = useLanguageContext();

	return (
		<>
			{mobile ? (
				<Collapse in={!!anchorEl} timeout="auto" unmountOnExit>
					<MobileDropdown disablePadding>
						{languages.map((language, index) => (
							<SideMenuLanguageItem
								handleClick={handleChange}
								key={index}
								language={language}
							/>
						))}
					</MobileDropdown>
				</Collapse>
			) : (
				<LocalizationMenu
					anchorEl={anchorEl}
					open={!!anchorEl}
					onClose={() => handleClose(null)}
					anchorOrigin={{ horizontal: 0, vertical: 25 }}
				>
					{languages.map((language, index) => (
						<LocalizationItem
							handleChange={handleChange}
							key={index}
							language={language}
						/>
					))}
				</LocalizationMenu>
			)}
		</>
	);
};
