import { AxiosError } from "axios";
import { adminHandler } from "./admin.handler";

export const userHandler = (error: AxiosError) => {
	if (!error.response) throw error;

	switch (error.response.status) {
		case 401:
			localStorage.removeItem("token");
			return { data: null };
		default:
			return adminHandler(error);
	}
};
