import { ButtonProps } from "@mui/material";
import { LocalizationContainer } from "../containers";
import { Button } from "./simplified-button.element";
import { CSSProperties } from "@mui/material/styles/createTypography";

interface LocalizedProps extends ButtonProps {
	localizationKey: string;
	disableEdit?: boolean;
	containerStyles?: CSSProperties;
}

export const LocalizedButton: React.FC<LocalizedProps> = ({
	localizationKey,
	disableEdit,
	containerStyles,
	...buttonProps
}) => (
	<LocalizationContainer
		disableEdit={disableEdit}
		localizationKey={localizationKey}
		sx={containerStyles}
	>
		{({ localizedText }) => <Button {...buttonProps} text={localizedText} />}
	</LocalizationContainer>
);
