import React from "react";
import { LoginContainerTemplate } from "./login-container.template";
import { BackToLoginButton, ForgotPasswordForm, FormSnackbar } from "../../components";
import { useNavigate } from "react-router";
import { UsersAdminCommunicator } from "../../../communicators/lider.http.communicator";
import { useAuthFormStatus } from "../../../util/hooks/useAuthFormStatus.hook";

export const ForgotPasswordContainer: React.FC = () => {
	const [email, setEmail] = React.useState("");

	const { submissionStatus, resetSubmissionStatus, handleSubmissionError } =
		useAuthFormStatus();
	const navigate = useNavigate();

	const handleClick = () => navigate("/login");

	const handleChange = (value: string) => {
		setEmail(value);
	};
	const handleSubmit = async (event?: React.FormEvent) => {
		if (event) event.preventDefault();
		try {
			await new UsersAdminCommunicator().resetPassword({ email });
			navigate("/password-reset-success", { state: { email } });
		} catch (error: any) {
			handleSubmissionError(error);
		}
	};

	const validate = () => email.length > 0;
	const props = { handleChange, handleSubmit, email, validate, submissionStatus };

	return (
		<LoginContainerTemplate>
			<BackToLoginButton handleClick={handleClick} />
			<ForgotPasswordForm {...props} />
			<FormSnackbar
				autoHideDuration={5000}
				handleClose={resetSubmissionStatus}
				submissionStatus={submissionStatus}
				formVariant="username-login"
			/>
		</LoginContainerTemplate>
	);
};
