import React from "react";
import { useAuthContext } from "../../../util/contexts/auth.context";
import { LiderClientAuthCommunicator } from "../../../communicators/lider.http.communicator";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import loginIcon from "../../../assets/images/login-icon.svg";
import { LoginContainerStack } from "../../../util/theme/styled-components";
import {
	ClientLoginForm,
	FormSnackbar,
	LoginBreadLinks,
	VerificationCodeForm
} from "../../components";
import { useAuthFormStatus } from "../../../util/hooks/useAuthFormStatus.hook";
import { useQueryString } from "../../../util/hooks/useQueryString.hook";

export const VerificationCodeLoginContainer: React.FC = () => {
	const { isLoggedIn, login } = useAuthContext();

	const communicator = new LiderClientAuthCommunicator();

	const navigate = useNavigate();
	const { loginId } = useParams();
	const [searchParams] = useSearchParams();

	const [phoneNumber, userCode, smsVerificationCreatedAt] = [
		"phoneNumber",
		"userCode",
		"smsVerificationCreatedAt"
	].map(key => searchParams.get(key) as string);

	const { createQueryString } = useQueryString();

	if (!(loginId && phoneNumber && userCode && smsVerificationCreatedAt))
		navigate("/login");

	const [remainingSeconds, setRemainingSeconds] = React.useState(0);
	const [warning, setWarning] = React.useState(false);
	const [verificationCode, setVerificationCode] = React.useState("");

	const {
		submissionStatus,
		handleSubmissionError,
		resetSubmissionStatus,
		setSubmissionSuccess
	} = useAuthFormStatus();

	React.useEffect(() => {
		const deadline = new Date(
			new Date(smsVerificationCreatedAt).getTime() + 2 * 60 * 1000
		);
		const seconds = (deadline.getTime() - new Date().getTime()) / 1000;

		setRemainingSeconds(seconds > 0 ? Math.floor(seconds) : 0);

		const timer = setInterval(() => {
			setRemainingSeconds(prevCountdown => (prevCountdown > 0 ? prevCountdown - 1 : 0));
		}, 1000);

		return () => clearInterval(timer);
	}, [smsVerificationCreatedAt]);

	React.useEffect(() => {
		if (isLoggedIn()) navigate("/");
	}, []);

	const handleChange = (verificationCode: string) => {
		setVerificationCode(verificationCode);
		setWarning(false);
	};

	const handleSubmit = async (event?: React.FormEvent) => {
		event?.preventDefault();

		try {
			const response = await communicator.completeLogin(loginId as string, {
				userCode,
				verificationCode
			});
			login(response.data.token);
			navigate("/");
		} catch (error: any) {
			setWarning(true);
		}
	};

	const resendVerificationCode = async () => {
		try {
			const response = await communicator.startLogin({ userCode });
			setSubmissionSuccess();
			const { phoneNumber, smsVerificationCreatedAt, loginId } = response.data;
			const qs = createQueryString({
				phoneNumber,
				smsVerificationCreatedAt,
				userCode
			});
			const navigateUrl = `/login/${encodeURIComponent(loginId)}?${qs}`;
			navigate(navigateUrl);
		} catch (error) {
			handleSubmissionError(error);
		}
	};

	const handleSnackbarClose = () => {
		setWarning(false);
		resetSubmissionStatus();
	};

	const minutes = Math.floor(remainingSeconds / 60);
	const seconds = remainingSeconds % 60;
	const secondsString = seconds < 10 ? `0${seconds}` : seconds;
	const timeRemaining = `0${minutes}:${secondsString}`;

	return (
		<LoginContainerStack backgroundimage={loginIcon} spacing="2rem">
			<ClientLoginForm
				element={
					<>
						<VerificationCodeForm
							handleChange={handleChange}
							handleSubmit={handleSubmit}
							resendVerificationCode={resendVerificationCode}
							phoneNumber={phoneNumber}
							remainder={timeRemaining}
							value={verificationCode}
							warning={warning}
						/>
						<FormSnackbar
							autoHideDuration={5000}
							handleClose={handleSnackbarClose}
							submissionStatus={submissionStatus}
							formVariant={"resend-code"}
						/>
					</>
				}
			/>
			<LoginBreadLinks />
		</LoginContainerStack>
	);
};
