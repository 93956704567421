import { GenericImageContainer } from "../../elements";

interface Props {
	imageSrc: string;
}

export const ActivityResourceImage: React.FC<Props> = ({ imageSrc }) => (
	<GenericImageContainer
		imageAlt={"activity-resource"}
		imageSrc={imageSrc}
		width="1.25rem"
	/>
);
