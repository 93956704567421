import { Box, Link } from "@mui/material";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { GenericImageContainer } from "../../elements";
import { InstitutionEnum } from "../types";

interface Props {
	imageKey: InstitutionEnum;
	imageWidth: string;
	imageHeight?: string;
	style?: Record<string, string>;
	containerStyles?: Record<string, string>;
}

export const InstitutionImage: React.FC<Props> = ({
	imageKey,
	imageWidth,
	imageHeight,
	style = {},
	containerStyles
}) => {
	const {
		[imageKey]: { imageAlt, imageSrc, path }
	} = ImageDictionary.Institution;

	return (
		<Link href={`${path}`} target="_blank" rel="external">
			<Box sx={containerStyles}>
				<GenericImageContainer
					width={imageWidth}
					height={imageHeight}
					style={style}
					imageAlt={imageAlt}
					imageSrc={imageSrc}
				/>
			</Box>
		</Link>
	);
};
