import { Breadcrumbs, styled } from "@mui/material";

export const MobileBreadCrumbs = styled(Breadcrumbs)(({ theme }) => ({
	background: theme.palette.grey[300],
	height: "2.25rem",
	borderRadius: "0.500rem",
	padding: "0.625rem 1.00rem",
	ol: { display: "flex", gap: "0.750rem" },
	li: { margin: 0 }
}));
