import { Stack } from "@mui/material";
import { GenericBody, GenericImageContainer, LocalizedGenericBody } from "../../elements";
import { DateTime } from "luxon";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { AdminHomepageCardContentProps } from "../types";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";

import clockIcon from "../../../assets/images/clock-icon.svg";

export const AdminHomepageCardContent: React.FC<AdminHomepageCardContentProps> = ({
	adminActivity,
	entityCount,
	localizationKey
}) => {
	const { language } = useLanguageContext();

	const weekInMilliseconds = 604800000;

	const isLastPublishedInPreviousWeek = !(
		new Date().getTime() - new Date(adminActivity.createdAt).getTime() >
		weekInMilliseconds
	);

	const dateTime = DateTime.fromISO(adminActivity.createdAt).setLocale(language);

	const relativeText = dateTime.toRelativeCalendar();

	const dateText =
		isLastPublishedInPreviousWeek && !!relativeText
			? relativeText
			: dateTime.toLocaleString();

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<Stack spacing="0.25rem" alignItems="center">
				<GenericBody variant="h3-primary" text={entityCount.toString()} />
				<LocalizedGenericBody variant="body4-grey" localizationKey={localizationKey} />
			</Stack>
			<Stack spacing="0.25rem" alignItems="center">
				<Stack
					direction="row"
					alignItems="center"
					spacing="0.5rem"
					padding="0.5rem"
					pr="0.75rem"
					borderRadius="0.5rem"
					sx={{ background: ColorDictionary["#60528f1f"] }}
				>
					<GenericImageContainer
						imageAlt="calendar-icon"
						imageSrc={clockIcon}
						width="0.875rem"
					/>
					<GenericBody variant="body3-primary-medium" text={dateText} />
				</Stack>
				<LocalizedGenericBody
					variant="body4-grey"
					localizationKey="admin-homepage-card_content_last-activity_text"
				/>
			</Stack>
		</Stack>
	);
};
