import { Box, Grid, Popover, Stack } from "@mui/material";
import React from "react";
import {
	ApplicationFormInfoFields,
	ApplicationFormTextValidations,
	ApplicationFormValues,
	SubmissionStatus,
	TermsAgreementFields
} from "../../containers/types";
import { LocalizedGenericButton, LocalizedGenericBody } from "../../elements";
import {
	ApplicationFormCheckboxInput,
	ApplicationFormFileInput,
	ApplicationFormTextArea,
	ApplicationFormTextField
} from "../inputs";
import { ApplicationFileItem } from "../items";
import { FormSnackbar } from "../snackbars";
import { ApplicationFormGrid } from "../../../util/theme/styled-components";
import {
	InputValidation,
	NotEmpty,
	ValidEmail
} from "../../../util/validations/input.validation";

interface Props {
	removeFileFromField: (infoField: "invoices" | "checks", fileKey: string) => void;
	handleCheckboxChange: (
		infoField: TermsAgreementFields,
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	closeSnackbar: () => void;
	handleFileChange: (
		infoField: "invoices" | "checks",
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	handleTextChange: (
		infoField: ApplicationFormInfoFields,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	handleSubmit: () => void;
	setIsValidForField: (
		infoField: ApplicationFormInfoFields,
		isValid: null | boolean
	) => void;
	toggleAgreementFormOpen: () => void;
	agreementFormOpen: boolean;
	filesInvalid: boolean;
	values: ApplicationFormValues;
	submissionStatus: SubmissionStatus;
	textValidations: ApplicationFormTextValidations;
	termsAgreementState: Record<TermsAgreementFields, boolean>;
	termsAgreementWarning: boolean;
	fileValidationWarning: string;
}

type FileInputInfoFields = "invoices" | "checks";
type ValidatedTextInputInfoFields = "first_name" | "last_name" | "email" | "phone_number";
type TextInputInfoFields = ValidatedTextInputInfoFields | "notes";

export const ApplicationForm: React.FC<Props> = ({
	removeFileFromField,
	handleCheckboxChange,
	closeSnackbar,
	handleFileChange,
	handleTextChange,
	handleSubmit,
	setIsValidForField,
	toggleAgreementFormOpen,
	agreementFormOpen,
	filesInvalid,
	values,
	submissionStatus,
	textValidations,
	termsAgreementState: { kvkk, agreementForm },
	termsAgreementWarning,
	fileValidationWarning
}) => {
	const fileListDisplay = (infoField: FileInputInfoFields) => {
		const fileList = values[infoField];
		return (
			<Stack key={infoField} spacing={2}>
				{fileList.map((file, index) => (
					<ApplicationFileItem
						key={index}
						file={file}
						handleDelete={() => removeFileFromField(infoField, file.name)}
					/>
				))}
			</Stack>
		);
	};

	const anchorEl = document.getElementById("agreement_form_checkbox");

	const getFileInputProps = (infoField: FileInputInfoFields) => ({
		handleChange: handleFileChange,
		infoField: infoField,
		labelLocalizationKey: `${infoField}_input_label`,
		placeholderLocalizationKey: `${infoField}_input_placeholder`
	});

	const getTextInputProps = (infoField: TextInputInfoFields) => ({
		handleChange: handleTextChange,
		infoField: infoField,
		localizationKey: `${infoField}_input_placeholder`,
		value: values[infoField]
	});

	const getValidatedInputProps = (
		infoField: ValidatedTextInputInfoFields,
		Validation: typeof InputValidation
	) => ({
		...getTextInputProps(infoField),
		Validation,
		isValid: textValidations[infoField],
		setIsValidForField
	});

	const getValidatedInput = (
		infoField: ValidatedTextInputInfoFields,
		Validation: typeof InputValidation
	) => <ApplicationFormTextField {...getValidatedInputProps(infoField, Validation)} />;

	const getFileInput = (infoField: FileInputInfoFields) => (
		<ApplicationFormFileInput key={infoField} {...getFileInputProps(infoField)} />
	);

	const textAndFileInputGridItemProps = [
		{
			children: getValidatedInput("first_name", NotEmpty)
		},
		{
			children: getValidatedInput("last_name", NotEmpty)
		},
		{
			children: getValidatedInput("email", ValidEmail)
		},
		{
			children: getValidatedInput("phone_number", NotEmpty)
		},
		{
			children: <ApplicationFormTextArea {...getTextInputProps("notes")} />,
			md: undefined
		},
		{
			children: [getFileInput("invoices"), fileListDisplay("invoices")]
		},
		{
			children: [getFileInput("checks"), fileListDisplay("checks")]
		}
	];

	const textAndFileInputGridItems = textAndFileInputGridItemProps.map(
		({ children, ...props }, index) => (
			<ApplicationFormGrid key={index} item xs={12} md={6} {...props}>
				{children}
			</ApplicationFormGrid>
		)
	);

	return (
		<Grid container maxWidth="37.5rem" spacing={2}>
			{textAndFileInputGridItems}
			{filesInvalid ? (
				<ApplicationFormGrid
					item
					xs={12}
					justifyContent="center"
					sx={{ display: "flex" }}
				>
					<LocalizedGenericBody
						localizationKey={`application_form_${fileValidationWarning}_text`}
						variant="body2-error"
					/>
				</ApplicationFormGrid>
			) : null}
			<ApplicationFormGrid item xs={12}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						a: { fontWeight: "700", textDecoration: "underline" }
					}}
				>
					<ApplicationFormCheckboxInput
						handleChange={handleCheckboxChange}
						checked={kvkk}
						infoField="kvkk"
						localizationKey="application_form_kvkk_text"
					/>
					<ApplicationFormCheckboxInput
						id="agreement_form_checkbox"
						handleChange={handleCheckboxChange}
						checked={agreementForm}
						infoField="agreementForm"
						localizationKey="application_form_agreementForm_text"
						onAnchorClick={toggleAgreementFormOpen}
					/>
				</Box>
			</ApplicationFormGrid>
			{termsAgreementWarning ? (
				<ApplicationFormGrid
					item
					xs={12}
					justifyContent="center"
					sx={{ display: "flex" }}
				>
					<LocalizedGenericBody
						localizationKey={"application_form_terms_agreement_warning_text"}
						variant="body2-error"
					/>
				</ApplicationFormGrid>
			) : null}
			<ApplicationFormGrid item xs={12}>
				<LocalizedGenericButton
					localizationKey="submit_button_placeholder"
					variant="primary"
					handleClick={handleSubmit}
					sx={{ width: "100%" }}
				/>
			</ApplicationFormGrid>
			<FormSnackbar
				handleClose={closeSnackbar}
				submissionStatus={submissionStatus}
				formVariant="application"
			/>
			<Popover
				open={agreementFormOpen}
				anchorEl={anchorEl}
				onClose={toggleAgreementFormOpen}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				<LocalizedGenericBody
					localizationKey="agreement_form_text"
					variant="body2-primary"
					sx={{ padding: "1.00rem", width: "18.8rem" }}
				/>
			</Popover>
		</Grid>
	);
};
