import { BranchNavigationButton as StyledButton } from "../../../util/theme/styled-components";

interface Props {
	handleNavigation: () => void;
	Icon: JSX.Element;
}

export const BranchNavigationButton: React.FC<Props> = ({ handleNavigation, Icon }) => (
	<StyledButton onClick={handleNavigation}>{Icon}</StyledButton>
);
