import React from "react";
import AuthCode from "react-auth-code-input";
import { VerificationProps } from "./types";
import "./styles.input.css";

export const LoginVerificationCodeInput: React.FC<VerificationProps> = ({
	handleChange,
	warning
}) => (
	<AuthCode
		allowedCharacters="numeric"
		onChange={handleChange}
		length={4}
		containerClassName="verificationCodeContainer"
		inputClassName={
			warning
				? "verificationCodeInput verificationCodeInputWarning"
				: "verificationCodeInput"
		}
		placeholder="0"
	/>
);
