import { Button, IconButton, ListItemButton, Pagination, styled } from "@mui/material";
import { transientOptions } from "../options";
import { ColorDictionary } from "../../dictionaries/color.dictionary";

export const HomePagination = styled(Pagination)(({ theme }) => ({
	display: "flex",
	alignItems: "flex-start",
	padding: 0,
	gap: "0.500rem",
	width: "4.38rem",

	".MuiPaginationItem-root.Mui-selected": {
		background: theme.palette.primary.main,

		"&:hover": {
			background: theme.palette.secondary.main
		}
	},
	".MuiPaginationItem-root": {
		minWidth: "unset",

		height: "0.750rem",
		width: "0.750rem",

		[theme.breakpoints.up("md")]: {
			height: "1.00rem",
			width: "1.00rem"
		},

		fontSize: 0,
		background: theme.palette.common.white,
		border: `1px solid ${theme.palette.primary.main}`,
		marginRight: "0.500rem",
		"&:hover": {
			background: `${theme.palette.tertiary.main}ab`
		}
	}
}));

export const BranchNavigationButton = styled(IconButton)(() => ({
	borderRadius: 0,
	padding: 0
}));

export const LoadMoreButton = styled(Button)(({ theme }) => ({
	color: theme.palette.tertiary.main,
	background: "transparent",
	border: "none",
	margin: "auto",

	flexDirection: "column",

	fontWeight: 600,
	fontSize: theme.typography.body2.fontSize,

	[theme.breakpoints.down("md")]: {
		fontSize: theme.typography.body3.fontSize
	},

	"&:hover": {
		background: "transparent",
		border: "none"
	},
	"&:active": {
		background: "transparent",
		border: "none"
	},
	"&:focus": {
		background: "transparent",
		border: "none"
	},
	".MuiButton-endIcon": {
		margin: 0
	}
}));

export const HeaderCollapseButton = styled(IconButton)(({ theme }) => ({
	padding: "0.500rem",
	gap: "0.500rem",

	width: "1.50rem",
	height: "1.50rem",

	background: theme.palette.common.white,
	border: `1px solid ${theme.palette.grey[300]}`,
	borderRadius: "1.25rem"
}));

export const FastEditCollapseButton = styled(ListItemButton)(({ theme }) => ({
	padding: "0.25rem",
	background: theme.palette.primary.main,
	borderRadius: "0 1rem 1rem 0",
	":hover": { background: theme.palette.primary.main }
}));

export const AdminPopupIconButton = styled(IconButton)(({ theme }) => ({
	margin: "0 !important",
	padding: "0 !important",
	height: "1.56rem",
	width: "1.56rem",
	background: `${theme.palette.secondary.main}12`
}));

export const TextEditIconButton = styled(IconButton)(({ theme }) => ({
	width: "1.75rem",
	height: "1.75rem",
	borderRadius: "0.25rem",
	background: `linear-gradient(0deg, ${theme.palette.secondary.main}0d 0%, ${theme.palette.secondary.main}0d 100%), ${theme.palette.grey[50]}`,
	border: `1px solid ${theme.palette.secondary.main}94`,
	boxShadow: `0 0 0.9rem 0.1rem ${theme.palette.secondary.main}29, 0 0 0.9rem 0.1rem ${theme.palette.secondary.main}29`
}));

export const UserMenuButton = styled(
	Button,
	transientOptions
)<{ $isOpen: boolean }>(({ theme, $isOpen }) => {
	const borderRadius = "0.5rem";
	return {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		gap: "1rem",

		padding: "0.5rem 0.75rem",

		border: "none",
		borderRadius: $isOpen ? borderRadius : `${borderRadius} 0 0 ${borderRadius}`,

		minWidth: "3.875rem",
		minHeight: "1.75rem",
		width: "100%",

		".MuiButton-startIcon": { marginRight: "0", marginLeft: "0" },
		".MuiButton-endIcon": {
			marginRight: "0",
			marginLeft: "auto",
			display: $isOpen ? "default" : "none"
		},

		background: theme.palette.common.white,
		"&:hover": {
			background: theme.palette.primary.main + "1a"
		},
		"&:disabled": {
			".MuiTypography-root": {
				color: ColorDictionary["#6B7280"]
			}
		}
	};
});
