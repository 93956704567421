import React from "react";
import { DashboardTableConfig } from "../../util/configs/dashboard-table";
import { useTableContext } from "../../util/contexts/table.context";
import { UsersAdminCommunicator } from "../../communicators/lider.http.communicator";
import { Stack } from "@mui/material";
import { ActivationConfirmationDialog, AdminTableSelectBar } from "../components";
import { useRefreshContext } from "../../util/contexts/refresh.context";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";

interface Props {
	handleDialogOpen: () => void;
}

export const AdminSelectContainer: React.FC<Props> = ({ handleDialogOpen }) => {
	const { triggerCRUDRefresh, crudRefreshTrigger } = useRefreshContext();
	const { refreshTable, selectedElements, table } = useTableContext();
	const selectedElementIds = selectedElements.map(element => element.id);
	const selectEnabled = DashboardTableConfig[table].action.some(
		({ type }) => type === "select"
	);
	const containerDisplayed = selectEnabled && selectedElements.length > 0;

	const [selectDialogOpen, setSelectDialogOpen] = React.useState(false);

	const communicator = new UsersAdminCommunicator();

	const handleActivationSuccess = () => {
		setSelectDialogOpen(false);
		triggerCRUDRefresh();
	};
	const handleActivation = () => {
		const promises = selectedElements[0].active
			? selectedElementIds.map(id => communicator.delete(id))
			: selectedElementIds.map(id => communicator.updateOne(id, { active: true }));

		return Promise.all(promises).then(handleActivationSuccess);
	};

	const toggleSelectDialogOpen = () => setSelectDialogOpen(previous => !previous);

	useUpdateEffect(() => {
		refreshTable();
	}, [crudRefreshTrigger]);

	return containerDisplayed ? (
		<Stack alignItems="center">
			<AdminTableSelectBar
				handleDialogOpen={handleDialogOpen}
				toggleSelectDialogOpen={toggleSelectDialogOpen}
			/>
			<ActivationConfirmationDialog
				handleClose={toggleSelectDialogOpen}
				handleConfirmation={handleActivation}
				dialogOpen={selectDialogOpen}
			/>
		</Stack>
	) : null;
};
