import { Box, Popper, Stack, useTheme } from "@mui/material";
import React from "react";
import { LocalizedTypographyWithVariableSubject } from "../../elements";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { useTableContext } from "../../../util/contexts/table.context";
import {
	DashboardTableConfig,
	SearchableColumn
} from "../../../util/configs/dashboard-table";
import { TableEnum } from "../../../util/types/dashboard-table.types";

const getSearchableColumnTexts = (
	searchableColumns: SearchableColumn[],
	getLocalizedText: (key: string, defaultText?: string) => string,
	table: TableEnum
) =>
	searchableColumns.map(column => getLocalizedText(`table_${table}_${column.displayBy}`));

const getTooltipText = (
	searchableColumnTexts: string[],
	getLocalizedText: (key: string, defaultText?: string) => string
) => {
	if (searchableColumnTexts.length === 0) return "";

	const conjunctionLocalizedText = getLocalizedText("conjunction_text");

	return searchableColumnTexts.length > 2
		? `${searchableColumnTexts
				.slice(0, -1)
				.join(", ")} ${conjunctionLocalizedText} ${searchableColumnTexts.slice(-1)}`
		: searchableColumnTexts.length === 2
		? searchableColumnTexts.join(` ${conjunctionLocalizedText} `)
		: searchableColumnTexts[0];
};

export const SearchBarPopper: React.FC<{
	isOpen: boolean;
	anchorEl: HTMLElement | null;
}> = ({ isOpen, anchorEl }) => {
	const theme = useTheme();
	const { getLocalizedText } = useLanguageContext();
	const { table } = useTableContext();
	const searchableColumns = DashboardTableConfig[table].searchable;
	const texts = getSearchableColumnTexts(searchableColumns, getLocalizedText, table);
	const tooltipText = getTooltipText(texts, getLocalizedText);

	return (
		<Popper
			open={isOpen && !!tooltipText}
			anchorEl={anchorEl}
			placement="bottom-start"
			sx={{ pt: "0.5rem" }}
		>
			<Stack
				alignItems="center"
				justifyContent="center"
				sx={{ transform: "translateX(-2rem)" }}
			>
				<Box
					width="0"
					alignSelf="flex-start"
					marginLeft="0.75rem"
					borderLeft="0.25rem solid transparent"
					borderRight="0.25rem solid transparent"
					borderBottom={`0.25rem solid ${theme.palette.primary.main}`}
				/>
				<LocalizedTypographyWithVariableSubject
					variant="body4-white-medium"
					sx={{ lineHeight: "1.25rem" }}
					localizationKey="search_bar_tooltip_text"
					variable={tooltipText}
					boxStyles={{
						maxWidth: "13.5rem",
						padding: "0.75rem 0.75rem",
						borderRadius: "0.5rem",
						background: theme.palette.primary.main,
						textAlign: "left"
					}}
				/>
			</Stack>
		</Popper>
	);
};
