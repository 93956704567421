import { DashboardPageItem } from "./types";
import { DashboardContainer } from "./containers";
import { UserRoleEnum } from "../util/dictionaries/types";

interface Props {
	items: DashboardPageItem[];
	userRole: UserRoleEnum;
}

export const DashboardView: React.FC<Props> = ({ items, children, userRole }) => (
	<DashboardContainer items={items} children={children} userRole={userRole} />
);
