import { GenericImageContainer } from "../../elements";

import userCreateErrorImage from "../../../assets/images/user-create-error-image.svg";
import userCreateSuccessImage from "../../../assets/images/user-create-success-image.svg";

interface Props {
	imageSrc: string;
}

export const UserCreateResponseImage: React.FC<Props> = ({ imageSrc }) => (
	<GenericImageContainer
		imageSrc={imageSrc}
		imageAlt="user-create-response"
		width="8.8121rem"
		height="5rem"
	/>
);

export const UserCreateErrorImage: React.FC = () => (
	<UserCreateResponseImage imageSrc={userCreateErrorImage} />
);

export const UserCreateSuccessImage: React.FC = () => (
	<UserCreateResponseImage imageSrc={userCreateSuccessImage} />
);
