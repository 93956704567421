import { Stack } from "@mui/material";
import { SubmissionStatus } from "../../containers/types";
import { CheckReportResponseImage } from "../images";
import { LocalizedGenericBody } from "../../elements";

interface Props {
	localizationKey: string;
	response: SubmissionStatus;
}

export const CheckReportResponseContent: React.FC<Props> = ({
	localizationKey,
	response
}) => (
	<Stack direction="row" spacing="0.625rem" alignItems="center" height="100%">
		<CheckReportResponseImage variant={response} />
		<LocalizedGenericBody
			variant="body3-primary"
			localizationKey={localizationKey}
			sx={{ fontWeight: "600" }}
		/>
	</Stack>
);
