import { Grid, Link, Stack, useTheme } from "@mui/material";
import React from "react";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";
import { LocalizedGenericBody } from "../../elements";
import { ContentMediaPageImage, InstitutionImage, SocialMediaLogo } from "../images";

interface ImageListImage {
	imageSrc: string;
}

interface Props {
	images: ImageListImage[];
}

export const ContentMediaPageImageList: React.FC<Props> = ({ images }) => {
	const imageArray: React.ReactElement[] = images.map((image, index) => (
		<Grid item xs={12} key={index}>
			<ContentMediaPageImage {...image} imageAlt={`content-media-image-${index}`} />
		</Grid>
	));
	return (
		<Grid container rowSpacing={4}>
			{imageArray}
		</Grid>
	);
};

export const SocialMediaLogoList: React.FC<{ variant?: "dark" | "light" }> = ({
	variant
}) => {
	const socialLinks = ImageDictionary.SocialMedia[variant ?? "dark"];

	const footerSocialLinks: React.ReactElement[] = Object.values(socialLinks).map(
		({ path, imageSrc, imageAlt }) => (
			<Grid item key={path}>
				<Link href={`${path}`} target="_blank" rel="external" underline="none">
					<SocialMediaLogo
						imageSrc={imageSrc}
						imageAlt={imageAlt}
						style={{ height: "3rem", width: "3rem" }}
					/>
				</Link>
			</Grid>
		)
	);

	return (
		<Stack gap="0.75rem">
			<LocalizedGenericBody
				localizationKey="footer_social_media_heading"
				sx={{ textAlign: "unset" }}
				variant={variant === "dark" ? "body2-white-medium" : "body2-primary-medium"}
			/>
			<Grid container spacing={2}>
				{footerSocialLinks}
			</Grid>
		</Stack>
	);
};

export const InstitutionLogoList: React.FC = () => {
	const theme = useTheme();

	return (
		<Grid container>
			<Grid item paddingRight="0.75rem" paddingBottom="0.75rem">
				<InstitutionImage
					imageKey="bddk"
					imageWidth="2.04rem"
					imageHeight="1.93rem"
					containerStyles={{
						background: theme.palette.common.white,
						paddingX: "0.163rem",
						paddingY: "0.161rem",
						borderRadius: "0.188rem"
					}}
				/>
			</Grid>
			<Grid item paddingRight="0.75rem" paddingBottom="0.75rem">
				<InstitutionImage
					imageKey="kap"
					imageWidth="2.43rem"
					imageHeight="1.84rem"
					containerStyles={{
						background: theme.palette.common.white,
						padding: "0.204rem",
						borderRadius: "0.188rem"
					}}
				/>
			</Grid>
			<Grid item paddingRight="0.75rem" paddingBottom="0.75rem">
				<InstitutionImage
					imageKey="finkurbir"
					imageWidth="2.68rem"
					imageHeight="1.84rem"
					containerStyles={{
						background: theme.palette.common.white,
						padding: "0.205rem",
						borderRadius: "0.188rem"
					}}
				/>
			</Grid>
			<Grid item paddingRight="0.75rem" paddingBottom="0.75rem">
				<InstitutionImage
					imageKey="borsaIstanbul"
					imageWidth="1.97rem"
					imageHeight="2.03rem"
					containerStyles={{
						background: theme.palette.common.white,
						padding: "0.112rem",
						borderRadius: "0.188rem"
					}}
				/>
			</Grid>
			<Grid item paddingRight="0.75rem" paddingBottom="0.75rem">
				<InstitutionImage imageKey="masak" imageWidth="2.25rem" />
			</Grid>
			<Grid item paddingRight="0.75rem" paddingBottom="0.75rem">
				<InstitutionImage imageKey="fci" imageWidth="6.66rem" imageHeight="2.25rem" />
			</Grid>
			<Grid item paddingBottom="0.75rem">
				<InstitutionImage imageKey="kkb" imageWidth="2.34rem" imageHeight="2.25rem" />
			</Grid>
		</Grid>
	);
};
