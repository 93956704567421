import React from "react";
import { Box, Theme, useTheme } from "@mui/material";
import { GradientSpinner } from "../../../util/theme/styled-components";
interface Props {
	sx?: any;
	size?: number;
	variant?: "primary" | "secondary" | "tertiary";
}

const CustomLinearGradient = (theme: Theme) => (
	<linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
		<stop offset="20%" stopColor={theme.palette.secondary.main} />
		<stop offset="90%" stopColor={theme.palette.tertiary.main} />
	</linearGradient>
);

export const GradientCircularProgressSpinner: React.FC<Props> = ({ sx, size }) => {
	const theme = useTheme();
	return (
		<>
			<svg width="300" height="300">
				{CustomLinearGradient(theme)}
			</svg>
			<GradientSpinner thickness={4} size={size || 40} sx={sx || {}} />
		</>
	);
};

export const FullScreenSpinner = () => (
	<GradientCircularProgressSpinner
		sx={{
			position: "fixed",
			top: "50%",
			left: "50%",
			marginTop: "-50px",
			marginLeft: "-50px"
		}}
		size={100}
	/>
);

export const BasicSpinner: React.FC<Props> = ({ size, variant = "primary" }) => {
	const theme = useTheme();

	return (
		<>
			<svg width="0" height="0">
				{CustomLinearGradient(theme)}
			</svg>
			<Box width={"100%"} sx={{ textAlign: "center" }}>
				<GradientSpinner
					thickness={4}
					size={size || 40}
					sx={{ circle: { stroke: `${theme.palette[variant].main} !important` } }}
				/>
			</Box>
		</>
	);
};
