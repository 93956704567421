import React from "react";
import { UsernameProps } from "./types";
import { LocalizationContainer } from "../../../containers";
import { LoginTextField } from "../../../../util/theme/styled-components";

export const LoginUsernameInput: React.FC<UsernameProps> = ({
	handleChange,
	value,
	submissionStatus
}) => {
	return (
		<LocalizationContainer localizationKey="login_username_input_placeholder">
			{({ localizedText }) => (
				<LoginTextField
					type="number"
					label={localizedText}
					variant="filled"
					$submissionStatus={submissionStatus}
					value={value}
					onChange={handleChange}
					InputProps={{
						disableUnderline: true
					}}
					autoFocus={false}
				/>
			)}
		</LocalizationContainer>
	);
};
