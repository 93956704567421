import { GenericImageContainer } from "../../elements";

import liderImage from "../../../assets/images/header-logo.svg";
import errorImage from "../../../assets/images/dashboard/dashboard-select-dialog-error-image.svg";
import userAvatar from "../../../assets/images/dashboard/dashboard-select-dialog-mail-user-avatar.svg";
import successImage from "../../../assets/images/dashboard/dashboard-select-dialog-success-image.svg";
import singleItemImage from "../../../assets/images/dashboard/dashboard-select-dialog-single-item-image.svg";
import multipleItemsImage from "../../../assets/images/dashboard/dashboard-select-dialog-multiple-items-image.svg";
import React from "react";
import { SubmissionStatus, SubmissionStatusEnum } from "../../containers/types";

export const CheckReportResponseImage: React.FC<{
	variant: SubmissionStatus;
}> = ({ variant }) => (
	<GenericImageContainer
		secondaryImageRendered={variant === SubmissionStatusEnum.success}
		imageSrc={errorImage}
		secondaryImageSrc={successImage}
		imageAlt="select-response-image"
		width="2.25rem !important"
	/>
);

export const CheckReportImage: React.FC<{ multiple: boolean }> = ({ multiple }) => {
	const { width, height } = multiple
		? { width: "12.859rem", height: "8.742rem" }
		: { width: "11.736rem", height: "6.089rem" };

	return (
		<GenericImageContainer
			secondaryImageRendered={multiple}
			imageSrc={singleItemImage}
			secondaryImageSrc={multipleItemsImage}
			imageAlt="select-item-image"
			width={width}
			height={height}
		/>
	);
};

export const CheckReportEmailUserAvatar: React.FC = () => (
	<GenericImageContainer
		imageSrc={userAvatar}
		imageAlt="user-avatar"
		width="1.5rem !important"
	/>
);

export const CheckReportLiderLogo: React.FC = () => (
	<GenericImageContainer
		imageSrc={liderImage}
		imageAlt="lider-logo"
		width="7.26rem"
		height="3.46rem"
	/>
);
