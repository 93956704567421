import { Box } from "@mui/material";
import React from "react";
import { BranchDAO } from "../../../util/generators/types";

interface Props {
	center: google.maps.LatLngLiteral;
	branch: BranchDAO;
}

export const MapItem: React.FC<Props> = ({ center, branch }) => {
	const mapRef: any = React.useRef();

	React.useEffect(() => {
		if (!window.google) return;
		const map = new window.google.maps.Map(mapRef.current, {
			center,
			zoom: 17,
			disableDefaultUI: true,
			keyboardShortcuts: false
		});
		new window.google.maps.Marker({
			position: center,
			map
		});
	}, [center]);

	const searchQuery = `Lider Faktoring - ${branch.name}`;

	return (
		<Box
			width="100%"
			height="100%"
			ref={mapRef}
			sx={{
				borderRadius: "0.250rem 0 0 0.250rem"
			}}
			id="map"
			onClick={() =>
				window.open(
					`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
						searchQuery
					)}`,
					"_blank"
				)
			}
		/>
	);
};
