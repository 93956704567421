import { Box } from "@mui/material";
import { useTemplatesContext } from "../../../util/contexts/templates.context";

export const Page: React.FC = ({ children }) => {
	const { activeTemplateName } = useTemplatesContext();

	const paddingBottom =
		activeTemplateName === "homepage"
			? { xs: 0, md: 0 }
			: { xs: "0.625rem", md: "3.75rem" };

	return (
		<Box component="main" minHeight="43.8rem" pb={paddingBottom} flexGrow={1}>
			{children}
		</Box>
	);
};
