import { Grid, Stack } from "@mui/material";
import React from "react";
import { FormProps } from "../../../util/types/admin-form.types";
import { AdminFormSaveButton, AdminFormUpdateButton } from "../buttons";
import {
	EmailInput,
	FirstNameInput,
	LastNameInput,
	MessageInput,
	UserRolesSelectInput,
	UsernameInput
} from "../inputs";
import { useFormContext } from "../../../util/contexts/form.context";
import { GenericBody } from "../../elements";
import { UserInitialsAvatar } from "../../../util/theme/styled-components";
import { useTableContext } from "../../../util/contexts/table.context";

export const AdminPanelUsersForm: React.FC<FormProps> = ({ handleSubmit }) => {
	const { selectedElements } = useTableContext();
	const { values } = useFormContext();

	const updateUser = !!selectedElements.length;

	const { email } = values;
	let { firstName, lastName } = values;

	firstName = firstName ?? "---";
	lastName = lastName ?? "---";

	const Button = updateUser ? AdminFormUpdateButton : AdminFormSaveButton;

	return (
		<Stack spacing="0.75rem">
			{updateUser ? (
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					height="6.875rem"
					pl={{ xs: "0.5rem", sm: "1.75rem" }}
					pr={{ xs: "0.5rem", sm: "2.38rem" }}
					sx={{ backgroundColor: "grey.300", borderRadius: "0.5rem" }}
				>
					<UserInitialsAvatar sx={{ width: "3.875rem", height: "3.875rem" }}>
						<GenericBody
							variant="h4-secondary-semi-bold"
							text={firstName[0] + lastName[0]}
						/>
					</UserInitialsAvatar>
					<Stack alignItems="center">
						<GenericBody
							text={`${firstName} ${lastName}`}
							variant="body1-primary-medium"
						/>
						<GenericBody
							text={email}
							variant="body3-primary-medium"
							sx={{ opacity: "0.5" }}
						/>
					</Stack>
				</Stack>
			) : null}
			<Grid container gap="0.75rem">
				<Grid item xs={12} md>
					<UsernameInput />
				</Grid>
				<Grid item xs={12} md>
					<UserRolesSelectInput />
				</Grid>
			</Grid>
			<Grid container gap="0.75rem">
				<Grid item xs={12} md>
					<FirstNameInput />
				</Grid>
				<Grid item xs={12} md>
					<LastNameInput />
				</Grid>
			</Grid>
			<Grid container gap="0.75rem">
				<Grid item xs>
					<EmailInput />
				</Grid>
			</Grid>
			{updateUser ? null : <MessageInput />}
			<Button handleClick={handleSubmit} />
		</Stack>
	);
};
