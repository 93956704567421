import { Box, Stack } from "@mui/material";
import React from "react";
import { ContactPublicCommunicator } from "../../communicators/lider.http.communicator";
import { ContactForm, ContactImage, InquiryImage } from "../components";
import { Strip } from "../elements";
import {
	ContactFormInfoFields,
	ContactFormValidations,
	SubmissionStatus,
	SubmissionStatusEnum
} from "./types";
import { SocialMediaEnum } from "../components/types";

interface Props {
	index: 1 | 2 | 3;
}

const initialValidations: ContactFormValidations = {
	name: null,
	email: null,
	subject: null,
	message: null
};

export const GeneralInquiry: React.FC<Props> = ({ index }) => {
	const initialValues: Record<ContactFormInfoFields, string> = {
		name: "",
		email: "",
		subject: "",
		message: ""
	};

	const [values, setValues] =
		React.useState<Record<ContactFormInfoFields, string>>(initialValues);

	const [submissionStatus, setSubmissionStatus] = React.useState<SubmissionStatus>(null);

	const [validations, setValidations] =
		React.useState<ContactFormValidations>(initialValidations);

	const communicator = new ContactPublicCommunicator();

	const handleChange = (
		infoField: ContactFormInfoFields,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		event.preventDefault();
		const { value } = event.target;
		const previousState = values;
		setValues({ ...previousState, [infoField]: value });
	};

	const handleClose = () => setSubmissionStatus(null);

	const handleSubmit = async () => {
		const thereAreNotValidFields = Object.values(validations).some(v => !v);
		if (thereAreNotValidFields) return validateFields();

		await communicator
			.sendMail(values)
			.then(() => setSubmissionStatus(SubmissionStatusEnum.success))
			.catch(() => setSubmissionStatus(SubmissionStatusEnum.error));
		setValues(initialValues);
	};

	const validateFields = () => {
		const fieldValidations = Object.entries(validations).reduce(
			(accumulator, [infoField, value]) => ({ ...accumulator, [infoField]: !!value }),
			{} as ContactFormValidations
		);

		setValidations(fieldValidations);
	};

	const setIsValidForField = (
		infoField: ContactFormInfoFields,
		isValid: null | boolean
	) => setValidations(previous => ({ ...previous, [infoField]: isValid }));

	const formProps = {
		handleChange,
		handleClose,
		handleSubmit,
		setIsValidForField,
		submissionStatus,
		validations,
		...values
	};

	const imageKeys: SocialMediaEnum[] = [
		"phone",
		"email",
		"linkedIn",
		"facebook",
		"twitter"
	];

	return (
		<Strip variant={`bg-home-${index}`}>
			<Stack
				id="home-general-inquiry"
				direction={{ xs: "column", lg: "row" }}
				justifyContent="space-between"
				alignItems="center"
				sx={{ gap: "0.625rem", width: "100%", minHeight: "23.7rem" }}
			>
				<Box
					sx={{
						display: { xs: "none", lg: "flex" },
						width: "27.6rem",
						height: "16.0rem"
					}}
				>
					<ContactImage variant="contactImage" />
				</Box>
				<Stack
					direction={{ xs: "column", lg: "row" }}
					alignItems="center"
					sx={{
						height: "100%",
						gap: { xs: "1.50rem", lg: "4.50rem" },
						padding: 0
					}}
				>
					<Stack
						alignItems="center"
						direction={{ xs: "row", lg: "column" }}
						sx={{
							padding: 0,
							gap: "1.25rem"
						}}
					>
						{imageKeys.map(imageKey => (
							<InquiryImage key={imageKey} imageKey={imageKey} />
						))}
					</Stack>
					<ContactForm {...formProps} />
				</Stack>
			</Stack>
		</Strip>
	);
};
