import { Stack } from "@mui/material";
import { LocalizedGenericBody } from "../../elements";

export const SyncClientsTooltipContent: React.FC = () => (
	<Stack direction="column" spacing="0.75rem" maxWidth="12rem">
		<LocalizedGenericBody
			variant="h7-primary-bold"
			localizationKey="sync_clients_tooltip_header"
		/>
		<LocalizedGenericBody
			variant="body4-primary"
			localizationKey="sync_clients_tooltip_description"
		/>
	</Stack>
);
