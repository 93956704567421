import React from "react";
import { LoginInput } from "./login.input";
import { Props } from "./types";

export const LoginEmailInput: React.FC<Props> = props => (
	<LoginInput
		{...props}
		type="email"
		modifiedField="email"
		localizationKey="email_input_placeholder"
	/>
);
