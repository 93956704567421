import React from "react";
import { GenericBody, LocalizedGenericBody } from "../../elements";
import {
	CompanyInformationRow,
	CompanyInformationTableCell
} from "../../../util/theme/styled-components";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { Stack } from "@mui/material";

interface Props {
	property: string;
	value: string;
}

export const CompanyInformationTableRow: React.FC<Props> = ({ property, value }) => {
	const { isMobile } = useResponsive();

	const rowContent = isMobile ? (
		<CompanyInformationTableCell sx={{ padding: "1rem" }}>
			<Stack spacing="0.25rem">
				<LocalizedGenericBody
					localizationKey={`table_companyInformation_${property}`}
					variant="body2-primary-semi-bold"
				/>
				<GenericBody variant="body2-primary" text={value} />
			</Stack>
		</CompanyInformationTableCell>
	) : (
		<>
			<CompanyInformationTableCell>
				<LocalizedGenericBody
					localizationKey={`table_companyInformation_${property}`}
					variant="body2-primary-semi-bold"
				/>
			</CompanyInformationTableCell>
			<CompanyInformationTableCell>
				<GenericBody variant="body2-primary" text={value} />
			</CompanyInformationTableCell>
		</>
	);

	return <CompanyInformationRow>{rowContent}</CompanyInformationRow>;
};
