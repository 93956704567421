import { DashboardTableCellProps } from "../types";
import { DashboardTableCell } from "./dashboard-table.cell";

export const DashboardTableBodyCell: React.FC<DashboardTableCellProps> = props => (
	<DashboardTableCell variant="body" sx={{ cursor: "default" }} {...props} />
);

export const DashboardTableBodyRowEndCell: React.FC<DashboardTableCellProps> = props => (
	<DashboardTableCell variant="body" sx={{ cursor: "default", width: "0" }} {...props} />
);
