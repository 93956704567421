import { Box, Dialog } from "@mui/material";
import { AdminDialogHeader } from "../headers";
import { LocalizedGenericBody } from "../../elements";

interface Props {
	dialogOpen: boolean;
	handleClose: () => void;
	headerLocalizationKey: string;
	enableGoBack?: boolean;
	cancelButtonEnabled?: boolean;
}

export const AdminDialogTemplate: React.FC<Props> = ({
	dialogOpen,
	handleClose,
	headerLocalizationKey,
	enableGoBack,
	cancelButtonEnabled,
	children
}) => (
	<Dialog
		fullWidth={true}
		maxWidth="sm"
		open={dialogOpen}
		onClose={handleClose}
		sx={{
			"& .MuiDialog-paper": {
				width: "38.125rem",
				maxWidth: "90%",
				borderRadius: "0.5rem"
			}
		}}
	>
		<AdminDialogHeader
			localizationKey={headerLocalizationKey}
			handleClose={handleClose}
			enableGoBack={enableGoBack}
		/>
		{children}
		{cancelButtonEnabled ? (
			<Box onClick={handleClose} pb="0.875rem" sx={{ alignSelf: "center" }}>
				<LocalizedGenericBody
					localizationKey="confirmation_cancel_placeholder"
					variant="body2-primary-medium"
					disableEdit={true}
					sx={{ cursor: "pointer" }}
				/>
			</Box>
		) : null}
	</Dialog>
);
