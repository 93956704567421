import React from "react";
import { Box } from "@mui/material";
import { ClearButton } from "../components";
import { Search } from "../components/images";
import { AdminSearchField } from "../../util/theme/styled-components";
import { useLanguageContext } from "../../util/contexts/language.context";
import { DashboardPlaceholderLocalizations } from "../../util/dictionaries/dashboard-search-placeholder.dictionary";
import { AssetDAO } from "../../util/generators/types";

interface Props {
	assets: AssetDAO[] | [];
	setFilteredAssets: (assets: AssetDAO[]) => void;
}

export const AssetsSearchContainer: React.FC<Props> = ({ assets, setFilteredAssets }) => {
	const [searchValue, setSearchValue] = React.useState<string>("");
	const [inputFocused, setInputFocused] = React.useState<boolean>(false);

	const inputActive = !!searchValue || inputFocused;

	React.useEffect(() => {
		setFilteredAssets(assets);
	}, [assets]);

	const blurInput = () => setInputFocused(false);

	const handleClear = () => {
		setSearchValue("");
		blurInput();
		setFilteredAssets(assets);
	};

	const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setSearchValue(e.target.value);
		const filteredAssets = assets.filter(asset =>
			asset.filename.toLowerCase().includes(e.target.value.toLowerCase())
		);
		setFilteredAssets(filteredAssets);
	};

	const localizationKey = DashboardPlaceholderLocalizations.assets;
	const { getLocalizedText } = useLanguageContext();
	const placeholder = getLocalizedText(localizationKey);

	const responsiveWidth = { xs: inputActive ? "13rem" : "2.75rem", md: "13rem" };

	const focusInput = () => {
		if (inputActive) return;
		setInputFocused(true);
	};

	return (
		<Box
			sx={{
				transition: "all 1s",
				minWidth: responsiveWidth,
				maxWidth: responsiveWidth
			}}
		>
			<AdminSearchField
				startAdornment={
					<Box sx={{ padding: "0.375rem", paddingRight: "0.5rem" }}>
						<Search />
					</Box>
				}
				endAdornment={
					searchValue ? (
						<ClearButton handleClear={handleClear} data-cy="asset-search-clear" />
					) : null
				}
				disableUnderline
				value={searchValue}
				onClick={focusInput}
				onBlur={blurInput}
				onChange={onSearch}
				placeholder={placeholder}
			/>
		</Box>
	);
};
