import { Theme } from "@mui/material";

export const getMuiAppBarStyles = (theme: Theme) => {
	const { white } = theme.palette.common;
	return {
		styleOverrides: {
			root: {
				padding: "0 !important",
				background: white,

				borderBottom: "none",
				boxShadow: "none",
				zIndex: theme.zIndex.drawer - 1
			}
		}
	};
};
