import { Stack } from "@mui/material";
import { UserRoleEnum } from "../../../util/dictionaries/types";
import { DashboardTableCheckbox } from "../../../util/theme/styled-components";
import { LocalizedGenericBody } from "../../elements";
import { TableEnum } from "../../../util/types/dashboard-table.types";

interface Props {
	isSelected: boolean;
	userRole: UserRoleEnum;
}

export const UserRoleSelectItem: React.FC<Props> = ({ userRole, isSelected }) => (
	<Stack direction="row" spacing="1rem">
		<DashboardTableCheckbox checked={isSelected} />
		<LocalizedGenericBody
			localizationKey={`table_${TableEnum.users}_userRoles_${userRole}`}
			variant={isSelected ? "body3-primary-bold" : "body3-primary-medium"}
		/>
	</Stack>
);
