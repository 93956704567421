import { AccordionDetails, AccordionSummary, Box, Stack, useTheme } from "@mui/material";
import React from "react";
import { useLanguageContext } from "../../util/contexts/language.context";
import { FaqsDAO } from "../../util/generators/types";
import { PublicFaqGenerator } from "../../util/generators/public.generator";
import { FaqAccordion, FaqBox, FaqDot } from "../../util/theme/styled-components";
import { FaqArrow } from "../components";
import { EditableGenericBody, GenericBody } from "../elements";
import { PageIdProps } from "../types";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";

interface Props extends PageIdProps {
	title_1: string;
}

export const FaqListContainer: React.FC<Props> = ({ title_1, pageId }) => {
	const { language } = useLanguageContext();
	const theme = useTheme();

	const [faqs, setFaqs] = React.useState<FaqsDAO[]>([]);
	const [openIndex, setOpenIndex] = React.useState<number>(-1);

	const generator = new PublicFaqGenerator();

	const sortAndSetFaqs = (response: FaqsDAO[]) =>
		setFaqs(response.sort((faq1, faq2) => faq1.index - faq2.index));

	useGeneratorEffect(
		{
			effect: () => generator.manyForLanguage(language),
			onSuccess: sortAndSetFaqs,
			callback: generator.abort
		},
		[]
	);

	const toggleFaq = (index: number): void => {
		openIndex === index ? setOpenIndex(-1) : setOpenIndex(index);
	};
	const faqList = faqs.map(({ id, question, answer }, index) => (
		<FaqAccordion
			key={id}
			index={index}
			openindex={openIndex}
			square
			disableGutters
			expanded={openIndex === index}
			onChange={() => toggleFaq(index)}
		>
			<AccordionSummary
				sx={{
					minHeight: "unset",
					paddingX: { xs: "1.00rem", md: "1.25rem" },
					".MuiAccordionSummary-content": {
						marginRight: "auto",
						marginY: 0,
						maxWidth: { xs: "17.2rem", md: "unset" }
					}
				}}
				expandIcon={<FaqArrow index={index} openIndex={openIndex} />}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<FaqDot index={index} openindex={openIndex} />
				</Box>
				<GenericBody
					variants={{ desktop: "h4-primary", mobile: "h5-primary" }}
					sx={{ paddingLeft: "1.25rem", textTransform: "unset" }}
					innerHTML={question}
				/>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					paddingBottom: 0,
					paddingLeft: { xs: "1.00rem", md: "4.00rem" },
					paddingRight: { md: "4.00rem" },
					background: { xs: theme.palette.common.white, md: theme.palette.grey[50] }
				}}
			>
				<GenericBody
					variants={{ desktop: "body2-primary", mobile: "body3-primary" }}
					innerHTML={answer}
				/>
			</AccordionDetails>
		</FaqAccordion>
	));

	return (
		<Stack
			alignItems={{ xs: "center", md: "start" }}
			spacing={{ xs: 1, md: 3 }}
			sx={{ marginBottom: "2.00rem" }}
		>
			<EditableGenericBody
				variants={{ desktop: "h2-secondary", mobile: "h3-secondary" }}
				text={title_1}
				propertyKey="title_1"
				pageId={pageId}
				component="h1"
			/>
			<FaqBox>{faqList}</FaqBox>
		</Stack>
	);
};
