import React from "react";
import { PiePlot, ResponsiveChartContainer } from "@mui/x-charts";
import { ChartItem } from "../../containers/types";
import { useTheme } from "@mui/material";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { useChartContext } from "../../../util/contexts/chart.context";
import { useMuiPieArcHighlightedIndex } from "../../../util/hooks/useMuiPieArcHighlightedIndex";

export const pieArcHighlightExpansion = 12;
export const outerRadius = 100;
export const innerRadius = 70;

export const chartHeight = outerRadius;
export const chartWidth = 2 * outerRadius;

const arcLabelTextStyles = {
	fontSize: "0.75rem",
	fontFamily: "Raleway",
	fontWeight: 600
};

const getItemAmountPercentageLocaleString =
	(totalAmount: number, language: string) => (item: ChartItem) => {
		if (!item.value) return "";
		const percentage = item.value / totalAmount;
		return percentage.toLocaleString(language, { style: "percent" });
	};

export const PieChart: React.FC = () => {
	const {
		setHighlightedIndex,
		chartItems: items,
		itemCount,
		totalAmount
	} = useChartContext();
	const theme = useTheme();
	const { language } = useLanguageContext();
	useMuiPieArcHighlightedIndex(setHighlightedIndex);

	const chipColors = Object.values(theme.palette.chip);

	const isEmpty = itemCount === 0;

	return isEmpty ? (
		<EmptyPieChart />
	) : (
		<ResponsiveChartContainer
			height={chartHeight + 2 * pieArcHighlightExpansion}
			width={chartWidth + 2 * pieArcHighlightExpansion}
			series={[
				{
					id: "pieChart",
					type: "pie",
					data: items,
					innerRadius,
					outerRadius,
					highlightScope: { highlighted: "item" },
					highlighted: { additionalRadius: pieArcHighlightExpansion },
					startAngle: -90,
					endAngle: 90,
					arcLabel: getItemAmountPercentageLocaleString(totalAmount, language),
					arcLabelMinAngle: 22
				}
			]}
			margin={{ top: chartHeight + pieArcHighlightExpansion }}
			colors={chipColors}
			sx={{
				".MuiPieArc-root": {
					transition: "all 0.25s"
				},
				".MuiPieArcLabel-root": {
					fill: theme.palette.common.white,
					...arcLabelTextStyles,
					cursor: "default",
					pointerEvents: "none",
					transition: "font-size 0.15s"
				},
				".MuiPieArcLabel-highlighted": {
					fontSize: "0.85rem",
					transform: "scale(1.05,1.05)"
				}
			}}
		>
			<PiePlot slotProps={{ pieArcLabel: { outerRadius: outerRadius - 5 } }} />
		</ResponsiveChartContainer>
	);
};

export const EmptyPieChart: React.FC = () => {
	const theme = useTheme();

	return (
		<ResponsiveChartContainer
			height={chartHeight + 2 * pieArcHighlightExpansion}
			width={chartWidth + 2 * pieArcHighlightExpansion}
			series={[
				{
					id: "pieChart",
					type: "pie",
					data: [{ label: "empty", value: 1 }],
					innerRadius,
					outerRadius,
					startAngle: -90,
					endAngle: 90,
					arcLabel: () => ""
				}
			]}
			margin={{ top: chartHeight + pieArcHighlightExpansion }}
			colors={[theme.palette.grey[300]]}
		>
			<PiePlot />
		</ResponsiveChartContainer>
	);
};
