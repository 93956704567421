import { Grid, Stack } from "@mui/material";
import { HighlightedNewsItem, NewsItem, NewsSidebarItem } from "../items";
import { NewsGridItem } from "../../../util/theme/styled-components";
import { NewsDAO } from "../../../util/generators/types";
import { LoadMoreButton } from "../buttons";
import { LocalizedGenericBody } from "../../elements";

interface Props {
	news: NewsDAO[];
	passNews: (news: NewsDAO) => void;
	convertDate: (date: string) => string | null;
	highlighted?: boolean;
}

export const NewsList: React.FC<Props> = ({
	news,
	passNews,
	convertDate,
	highlighted = false
}) => {
	const NewsItemComponent = highlighted ? HighlightedNewsItem : NewsItem;
	const gridItemProps = highlighted ? {} : { xs: 12, sm: 4, md: 3 };

	return (
		<Grid container pb="1.25rem" spacing={3}>
			{news
				.filter(newsItem => newsItem.highlighted === highlighted)
				.map(newsItem => (
					<NewsGridItem item key={newsItem.id} {...gridItemProps}>
						<NewsItemComponent
							newsItem={newsItem}
							passNews={passNews}
							convertDate={convertDate}
						/>
					</NewsGridItem>
				))}
		</Grid>
	);
};

interface SideListProps {
	passNews: (news: NewsDAO) => void;
	convertDate: (date: string) => string | null;
	news: NewsDAO[];
	handleNextClick: () => void;
	selectedNews: NewsDAO;
	shouldLoadMore: boolean;
}

export const NewsSideList: React.FC<SideListProps> = ({
	selectedNews,
	passNews,
	convertDate,
	news,
	handleNextClick,
	shouldLoadMore
}) => {
	const styles = { button: { alignSelf: "center" } };

	return (
		<Stack
			spacing={{ xs: 2, md: 4 }}
			paddingX={{ xs: "unset", md: "1.50rem" }}
			overflow="auto"
			sx={{
				marginTop: { xs: "1.00rem", md: "0" },
				maxHeight: "100vh"
			}}
		>
			<LocalizedGenericBody
				localizationKey="news_side_list_heading"
				variants={{ desktop: "h3-primary", mobile: "h4-primary" }}
			/>
			<Stack spacing={3} sx={{ overflowY: "auto" }}>
				{news.map(item => (
					<NewsSidebarItem
						highlighted={item.id === selectedNews.id}
						key={item.id}
						newsItem={item}
						passNews={passNews}
						convertDate={() => convertDate(item.date)}
					/>
				))}
				{shouldLoadMore ? (
					<LoadMoreButton handleNextClick={handleNextClick} styles={styles} />
				) : null}
			</Stack>
		</Stack>
	);
};
