import { Box, Grid, Stack, styled } from "@mui/material";
import { transientOptions } from "../options";
import { ColorDictionary } from "../../dictionaries/color.dictionary";

export const AwardItemStack = styled(Stack)<{ selected: boolean }>(
	({ selected, theme }) => ({
		borderRadius: "0.5rem",
		background: selected ? theme.palette.common.white : `${theme.palette.common.white}1A`,
		border: selected ? "unset" : `1px solid ${theme.palette.common.white}36`,

		marginRight: "1.5rem",
		padding: "1.5rem",
		width: "20.4rem",
		height: "16.9rem",

		[theme.breakpoints.down("md")]: {
			marginRight: "1rem",
			padding: "1rem",
			width: "16.3rem",
			height: "14.6rem"
		},

		"&:hover": {
			background: selected
				? theme.palette.common.white
				: `${theme.palette.common.white}4C`
		}
	})
);

export const FullHeightStack = styled(Stack)(() => ({
	minHeight: "100vh"
}));

export const BranchNavigation = styled(Stack)(({ theme }) => ({
	borderRadius: "0.5rem",

	height: "12.1rem",
	padding: "3rem",

	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",

	[theme.breakpoints.down("md")]: {
		height: "8.63rem",
		padding: "1.5rem 1rem",

		flexDirection: "column"
	},

	backgroundSize: "cover",
	backgroundPositionY: "center"
}));

export const CityItemsStack = styled(Stack)<{ imagelink: string }>(
	({ theme, imagelink }) => ({
		borderRadius: "0.5rem",

		alignItems: "center",
		justifyContent: "center",
		padding: 0,

		cursor: "pointer",
		background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.main}), url(${imagelink}), ${theme.palette.primary.main}`,
		backgroundBlendMode: "color, soft-light",

		backgroundSize: "cover",
		backgroundPositionY: "center",
		transitionProperty: "all",
		transitionDuration: "0.6s",

		"&:hover": {
			boxShadow: `0.3125rem 0.3125rem 0.3125rem 0.3125rem ${theme.palette.tertiary.main}`,

			background: `linear-gradient(170.31deg, ${theme.palette.tertiary.main} 40%, ${theme.palette.secondary.main} 70.2%, ${theme.palette.primary.main} 186.13%), url(${imagelink}), ${theme.palette.primary.main}CC`,
			backgroundBlendMode: "soft-light",
			backgroundSize: "cover",
			backgroundPositionY: "center"
		}
	})
);

export const CareerDetailStack = styled(Box)(({ theme }) => ({
	borderRadius: "0.5rem",
	background: `linear-gradient(180deg,
			${theme.palette.tertiary.main} 14.06%,
			${theme.palette.primary.main} 61.98%)`,
	padding: "7.7%",
	overflowY: "auto"
}));

export const DocumentSelectStack = styled(Stack)(({ theme }) => ({
	cursor: "pointer",
	padding: "0.5rem 1rem",
	borderRadius: "1.25rem",
	border: `1px solid ${theme.palette.grey[300]}`
}));

export const NewsDetailBox = styled(Box)<{ imagelink: string }>(({ imagelink }) => ({
	position: "relative",

	"&::before": {
		content: "''",
		borderRadius: "0.5rem",
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundImage: `url(${imagelink})`,
		backgroundSize: "cover",
		backgroundPositionY: "center",
		filter: "grayscale(100%)"
	}
}));

export const NewsDetailBackground = styled(Box)(({ theme }) => ({
	height: "100%",

	borderRadius: "0.5rem",
	position: "relative",

	background: `linear-gradient(to right,
			${theme.palette.primary.light}E5,
			${theme.palette.primary.light}E5)
			`,

	backgroundSize: "cover",
	backgroundPositionY: "center",

	"&:hover": {
		background: `radial-gradient(205.83% 97.37% at 0.02% 2.83%, 
				${theme.palette.tertiary.light}B2 0%, 
				${theme.palette.secondary.light}B2 59.37%, 
				${theme.palette.primary.light}B2 73.99%)`
	}
}));

export const NewsGridItem = styled(Grid)(() => ({
	cursor: "pointer"
}));

export const HighlightedBranchInnerStack = styled(Stack)(({ theme }) => ({
	background: theme.palette.common.white,
	borderRadius: "0.25rem"
}));

export const HighlightedBranchInfoStack = styled(Stack)(({ theme }) => ({
	background: theme.palette.common.white
}));

export const HighlightedBranchOuterStack = styled(Stack)<{ imagelink: string }>(
	({ theme, imagelink }) => ({
		position: "relative",

		borderRadius: "0.5rem",
		width: "100%",

		background: `linear-gradient(157.31deg, ${theme.palette.tertiary.main} 23.41%, ${theme.palette.secondary.main} 74.2%, ${theme.palette.primary.main} 186.13%), linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.main}), url(${imagelink}), ${theme.palette.primary.main}`,
		backgroundBlendMode: "soft-light,	color",

		backgroundSize: "cover",
		backgroundPositionY: "center"
	})
);

export const SearchDropdownContentCard = styled(Stack)(({ theme }) => ({
	borderRadius: "0.5rem",
	background: theme.palette.grey[100]
}));

export const ApplicationFormGrid = styled(Grid)(() => ({
	marginLeft: "-0.5rem"
}));

export const LoginStack = styled(Stack)(({ theme }) => ({
	background: theme.palette.common.white,
	borderRadius: "1rem",
	[theme.breakpoints.down("sm")]: {
		borderRadius: 0
	},
	position: "relative"
}));

export const DashboardSidebarItemStack = styled(
	Stack,
	transientOptions
)<{
	$active: boolean;
	$issidebaropen: boolean;
}>(({ theme, $active, $issidebaropen }) => ({
	padding: "0.5rem 0.75rem",
	borderRadius: $issidebaropen ? "0.5rem" : "0.5rem 0 0 0.5rem",
	minWidth: $issidebaropen ? "16.5rem" : "unset",
	background: $active ? `${theme.palette.secondary.main}1a` : "transparent",
	cursor: "pointer",

	":hover": {
		background: `${theme.palette.secondary.main}1a`
	}
}));

export const FastEditStack = styled(
	Stack,
	transientOptions
)<{ $issidebaropen: boolean; $isimagedisplayed: boolean }>(
	({ theme, $issidebaropen, $isimagedisplayed }) => ({
		cursor: "pointer",
		padding: $isimagedisplayed ? "1rem 1.5rem 0 1.5rem" : "0.8125rem",

		background: `linear-gradient(0deg, ${theme.palette.secondary.main}0d, ${theme.palette.secondary.main}0d), ${theme.palette.grey[300]}`,
		border: `1px solid ${theme.palette.secondary.main}3d`,
		boxShadow: $issidebaropen
			? `0 0.25rem 0.8125rem 1px ${theme.palette.secondary.main}29`
			: `0 0 0.9236rem 0.09272rem ${theme.palette.secondary.main}29, 0 0 0.9236rem 0.09272rem ${theme.palette.secondary.main}29`,
		borderRadius: "0.5rem",

		"&:hover": {
			height: "100%",
			width: "100%",
			background: `linear-gradient(0deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.main}), ${theme.palette.grey[50]}`
		}
	})
);

export const DashboardSidebarLogoutButtonStack = styled(Stack)(({ theme }) => ({
	borderRadius: "0.25rem",
	padding: "0.375rem",
	cursor: "pointer",
	background: theme.palette.grey[100],

	":hover": {
		background: theme.palette.grey[200]
	},

	":active": {
		background: theme.palette.grey[400]
	}
}));

export const DashboardSidebarUserMenuItemStack = styled(Stack)(({ theme }) => ({
	cursor: "pointer",

	":hover": { background: `${theme.palette.primary.main}1a` }
}));

export const PublishButtonStack = styled(Stack)(({ theme }) => ({
	borderRadius: "0.75rem",
	border: `0.0625rem solid ${theme.palette.grey[300]}`,
	background: ColorDictionary["#f4f4f4"]
}));

export const TableSelectSnackbarStack = styled(Stack)(({ theme }) => ({
	position: "absolute",
	bottom: "1rem",

	maxWidth: "51.25rem",
	width: "80%",
	height: "3rem",
	paddingRight: "0.5rem",
	borderRadius: "0.75rem",

	background: theme.palette.common.white,
	boxShadow: `0 0.25rem 1.5rem 0.1875rem ${theme.palette.common.black}1f`
}));

export const EmailBarStack = styled(Stack)(({ theme }) => ({
	width: "100%",
	maxWidth: "36.125rem",
	height: "3rem",
	background: theme.palette.grey[300],
	borderRadius: "0.5rem"
}));

export const CheckReportDialogContentStack = styled(Stack)(({ theme }) => ({
	background: theme.palette.grey[50],
	borderRadius: "0.5rem",
	filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.10))",
	backgroundRepeat: "no-repeat"
}));

export const ClientHomepageStack = styled(Stack)(({ theme }) => ({
	borderRadius: "0.75rem",
	background: theme.palette.common.white,
	border: `1px solid ${theme.palette.grey[300]}`,
	boxShadow: `0 0.1875rem 1.1875rem 0 ${ColorDictionary["#cbcbcb36"]}`
}));

export const ChartTooltipContentStack = styled(Stack)(({ theme }) => ({
	width: "100%",
	background: theme.palette.common.white,
	marginLeft: "1rem",
	borderRadius: "0.25rem",
	padding: "0.5rem 1rem",
	border: `1px solid ${theme.palette.grey[300]}`
}));

export const ClientHomepageTimeToggleStack = styled(Stack)(({ theme }) => ({
	padding: "0.175rem",
	borderRadius: "3rem",
	border: `1px solid ${theme.palette.grey[300]}`
}));

export const ClientHomepageTableHeaderStack = styled(Stack)(() => {
	return {
		flexDirection: "row",
		alignItems: "center",
		padding: "1rem 1.5rem",
		justifyContent: "space-between",
		gap: "1rem",
		"@media (max-width:900px)": {
			flexDirection: "column",
			padding: "1rem 0"
		}
	};
});

export const SearchAndFiltersStack = styled(Stack)(() => {
	return {
		height: "2.5rem",
		overflowX: "scroll",
		overflowY: "hidden",
		"::-webkit-scrollbar": {
			display: "none"
		},
		flexDirection: "row",
		alignItems: "end",
		gap: "1rem",
		"@media (max-width:900px)": {
			width: "100%"
		}
	};
});

export const MobileTableItemStack = styled(
	Stack,
	transientOptions
)<{ $isSelected?: boolean }>(({ theme, $isSelected }) => ({
	overflow: "hidden",
	padding: "1rem",
	borderRadius: "0.5rem",
	border: `1px solid ${theme.palette.grey[300]}`,
	boxShadow: `0 0.125rem 0.5rem 0 ${theme.palette.common.black}0d`,
	"&:nth-of-type(even)": {
		background: $isSelected ? ColorDictionary["#ece8f2"] : theme.palette.grey[300]
	},
	background: $isSelected ? ColorDictionary["#ece8f2"] : "unset"
}));
