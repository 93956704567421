import React from "react";
import {
	AssetsContainer,
	CompanyInformationContainer,
	ClientHomepageContainer
} from "../../views";
import {
	ChecksContainer,
	InvoicesContainer
} from "../../views/containers/client-table-containers";
import { DashboardPageItem } from "../../views/types";
import { UserRoleEnum } from "./types";

import homepageIcon from "../../assets/images/dashboard/homepage-icon.svg";

import documentsIcon from "../../assets/images/dashboard/dashboard-sidebar-documents-icon.svg";
import newsIcon from "../../assets/images/dashboard/dashboard-sidebar-news-icon.svg";
import faqsIcon from "../../assets/images/dashboard/dashboard-sidebar-faqs-icon.svg";
import announcementsIcon from "../../assets/images/dashboard/dashboard-sidebar-announcements-icon.svg";
import assetsIcon from "../../assets/images/dashboard/dashboard-sidebar-assets-icon.svg";
import awardsIcon from "../../assets/images/dashboard/dashboard-sidebar-awards-icon.svg";
import branchesIcon from "../../assets/images/dashboard/dashboard-sidebar-branches-icon.svg";
import jobOpeningsIcon from "../../assets/images/dashboard/dashboard-sidebar-jobOpenings-icon.svg";
import pagesIcon from "../../assets/images/dashboard/dashboard-sidebar-pages-icon.svg";
import sectionsIcon from "../../assets/images/dashboard/dashboard-sidebar-sections-icon.svg";
import localizationsIcon from "../../assets/images/dashboard/dashboard-sidebar-localizations-icon.svg";

import companyInformationIcon from "../../assets/images/dashboard/dashboard-sidebar-company-information-icon.svg";
import checksInquiryIcon from "../../assets/images/dashboard/dashboard-sidebar-checks-inquiry-icon.svg";
import invoicesInquiryIcon from "../../assets/images/dashboard/dashboard-sidebar-invoices-inquiry-icon.svg";

import { TableEnum } from "../types/dashboard-table.types";
import {
	AnnouncementsContainer,
	AwardsContainer,
	BranchesContainer,
	DocumentsContainer,
	FaqsContainer,
	JobOpeningsContainer,
	LocalizationsContainer,
	NewsContainer,
	PagesContainer,
	SectionsContainer,
	UsersContainer
} from "../../views/containers/admin-table-containers";
import { AdminHomepage } from "../../views/pages/admin-home.page";

export const DashboardPageItemDictionary: Record<UserRoleEnum, DashboardPageItem[]> = {
	admin: [
		{
			index: 0,
			pageGetter: () => <AdminHomepage />,
			imageSrc: homepageIcon,
			localizationKey: "admin_homepage_sidebar_item_text",
			route: "/"
		},
		{
			index: 1,
			pageGetter: () => <DocumentsContainer />,
			imageSrc: documentsIcon,
			localizationKey: `table_${TableEnum.documents}`,
			route: "/documents"
		},
		{
			index: 2,
			pageGetter: () => <NewsContainer />,
			imageSrc: newsIcon,
			localizationKey: `table_${TableEnum.news}`,
			route: "/news"
		},
		{
			index: 3,
			pageGetter: () => <FaqsContainer />,
			imageSrc: faqsIcon,
			localizationKey: `table_${TableEnum.faqs}`,
			route: "/faqs"
		},
		{
			index: 4,
			pageGetter: () => <AnnouncementsContainer />,
			imageSrc: announcementsIcon,
			localizationKey: `table_${TableEnum.announcements}`,
			route: "/announcements"
		},
		{
			index: 5,
			pageGetter: () => <AwardsContainer />,
			imageSrc: awardsIcon,
			localizationKey: `table_${TableEnum.awards}`,
			route: "/awards"
		},
		{
			index: 6,
			pageGetter: () => <BranchesContainer />,
			imageSrc: branchesIcon,
			localizationKey: `table_${TableEnum.branches}`,
			route: "/branches"
		},
		{
			index: 7,
			pageGetter: () => <JobOpeningsContainer />,
			imageSrc: jobOpeningsIcon,
			localizationKey: `table_${TableEnum.jobOpenings}`,
			route: "/jobOpenings"
		},
		{
			index: 8,
			pageGetter: () => <PagesContainer />,
			imageSrc: pagesIcon,
			localizationKey: `table_${TableEnum.pages}`,
			route: "/pages"
		},
		{
			index: 9,
			pageGetter: () => <SectionsContainer />,
			imageSrc: sectionsIcon,
			localizationKey: `table_${TableEnum.sections}`,
			route: "/sections"
		},
		{
			index: 10,
			pageGetter: () => <LocalizationsContainer />,
			imageSrc: localizationsIcon,
			localizationKey: `table_${TableEnum.localizations}`,
			route: "/localizations"
		},
		{
			index: 11,
			pageGetter: () => <AssetsContainer />,
			imageSrc: assetsIcon,
			localizationKey: "dashboard_asset_page",
			route: "/assets"
		},
		{
			index: 12,
			pageGetter: () => <UsersContainer />,
			imageSrc: "",
			localizationKey: "dashboard_user_page",
			route: "/users",
			hidden: true
		}
	],
	client: [
		{
			index: 0,
			pageGetter: () => <ClientHomepageContainer />,
			imageSrc: homepageIcon,
			localizationKey: "client_homepage_sidebar_item_text",
			route: "/"
		},
		{
			index: 1,
			pageGetter: () => <ChecksContainer />,
			imageSrc: checksInquiryIcon,
			localizationKey: "checksInquiry_sidebar_item_text",
			route: "/checks-inquiry"
		},
		{
			index: 2,
			pageGetter: () => <InvoicesContainer />,
			imageSrc: invoicesInquiryIcon,
			localizationKey: "invoicesInquiry_sidebar_item_text",
			route: "/invoices-inquiry"
		},
		{
			index: 3,
			pageGetter: () => <CompanyInformationContainer />,
			imageSrc: companyInformationIcon,
			localizationKey: "companyInformation_sidebar_item_text",
			route: "/company-information"
		}
	],
	employee: []
};
