import { Edit } from "@mui/icons-material";
import { Box, Card, styled } from "@mui/material";

const TransparentCard = styled(Card)(() => ({
	background: "transparent",
	boxShadow: "none"
}));

const CircularImageContainer = styled(TransparentCard)(() => ({
	borderRadius: "50%"
}));

export const RoundCornerCard = styled(TransparentCard)(({ theme }) => ({
	borderRadius: "0.750rem",
	img: {
		minHeight: "15.6rem",
		width: "100%",
		maxWidth: "100vw",
		height: "auto",
		[theme.breakpoints.up("lg")]: {
			maxHeight: "20vw"
		},
		maxHeight: "30vw"
	}
}));

export const NewsInspiredPageImage = styled(Box)<{ imagelink: string }>(
	({ imagelink, theme }) => ({
		borderRadius: "0.750rem",

		minHeight: "15.6rem",
		width: "100%",
		maxWidth: "100vw",
		height: "100%",
		[theme.breakpoints.up("lg")]: {
			maxHeight: "20vw"
		},
		maxHeight: "30vw",

		background: `linear-gradient(to right, ${theme.palette.secondary.main}99, ${theme.palette.secondary.main}99), url(${imagelink})`,

		backgroundSize: "cover",
		backgroundPosition: "bottom"
	})
);

export const AuthorCard = styled(CircularImageContainer)(() => ({
	width: "7.50rem",
	height: "7.50rem"
}));

export const ContentMediaImageContainer = styled(TransparentCard)(() => ({
	height: "30.0rem",
	width: "17.5rem"
}));

export const NewsListImageContainer = styled(TransparentCard)(() => ({
	width: "10.2rem",
	height: "13.2rem"
}));

export const SocialMediaImageContainer = styled(CircularImageContainer)(() => ({
	height: "2.50rem",
	width: "2.50rem"
}));

export const LiderImageContainer = styled(TransparentCard)(() => ({
	width: "95%",
	img: {
		width: "100%",
		maxWidth: "100vw",
		height: "auto"
	}
}));

export const BranchNavigationDots = styled(TransparentCard)(() => ({
	height: "1.00rem",
	width: "1.00rem",
	borderRadius: 0
}));

export const ExtensionIconContainer = styled(TransparentCard)(() => ({
	height: "1.25rem",
	width: "0.932rem"
}));

export const ThreeCardIconContainer = styled(TransparentCard)(() => ({
	width: "unset !important",
	marginBottom: "-0.938rem",
	zIndex: "1"
}));

export const FullWidthImageContainer = styled(TransparentCard)(() => ({
	width: "100%",
	height: "auto"
}));

export const ContentFourSubcontentImageContainer = (imageKey: string) =>
	styled(TransparentCard)(() => ({
		width: imageKey === "left" ? "2.25rem" : "10.9rem",
		height: "10.9rem"
	}));

export const TextEditIcon = styled(Edit)(({ theme }) => ({
	".MuiSvgIcon-root": {
		width: "0.8rem",
		height: "0.8rem"
	},
	fill: theme.palette.secondary.main,
	fontSize: "0.8rem"
}));
