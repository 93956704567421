import { InputBase } from "@mui/material";
import { ValidatedTextInput } from "./validated-text.input";
import React from "react";
import { ValidEmail } from "../../../util/validations/input.validation";
import { ActionWithDelayedCallback } from "../../../util/ActionWithDelayedCallback";
import { ValidationResult } from "../types";

interface Props {
	handleEmailChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	handleEmailRemove: (email: string) => void;
	passValidationResult: (validationResult: ValidationResult) => void;
	email: string;
	validEmails: string[];
	isValid: boolean | null;
}

export const CheckReportEmailInput: React.FC<Props> = ({
	handleEmailChange,
	handleEmailRemove,
	validEmails,
	email,
	passValidationResult,
	isValid
}) => {
	const enterEvent = (
		event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { currentTarget } = event;
		new ActionWithDelayedCallback(
			() => currentTarget.blur(),
			() => currentTarget.focus()
		).start();
	};
	const backSpaceEvent = () => {
		if (email.length || !validEmails.length) return;
		handleEmailRemove(validEmails[validEmails.length - 1]);
	};

	return (
		<ValidatedTextInput
			InputComponent={InputBase}
			InputProps={{
				autoFocus: false,
				autoComplete: "false",
				fullWidth: true,
				type: "text",
				onChange: handleEmailChange,
				onKeyDown: (
					event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
				) => {
					switch (event.key) {
						case "Enter":
							return enterEvent(event);
						case "Backspace":
							return backSpaceEvent();
						default:
							return;
					}
				},
				value: email
			}}
			Validation={ValidEmail}
			isValid={isValid}
			passValidationResult={(isValid, errorKey) =>
				passValidationResult({ isValid, errorKey })
			}
			displayHelperText={false}
		/>
	);
};
