import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { useAppModeContext } from "../../util/contexts/app-mode.context";
import { useAuthContext } from "../../util/contexts/auth.context";
import { useLanguageContext } from "../../util/contexts/language.context";
import { TextEditButton, TextEditPopover } from "../components";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { useRefreshContext } from "../../util/contexts/refresh.context";
import { TextEditBox } from "../../util/theme/styled-components";
import { AdminLocalizationsGenerator } from "../../util/generators/private.generator";

interface Props {
	localizationKey: string;
	disableEdit?: boolean;
	sx?: CSSProperties;
	children: ({ localizedText }: { localizedText: string }) => React.ReactElement;
}

export const LocalizationContainer: React.FC<Props> = ({
	localizationKey,
	disableEdit,
	sx,
	children
}) => {
	const { triggerCRUDRefresh } = useRefreshContext();
	const { language, localizationObject, getLocalizedText } = useLanguageContext();

	const localization = localizationObject[localizationKey];

	const buttonText = getLocalizedText("create_or_update_button_placeholder");

	const { user, userHasRole } = useAuthContext();

	const { appMode } = useAppModeContext();
	const isFastEdit = appMode === "fast-edit";

	const edit = userHasRole("admin", user) && isFastEdit && !disableEdit;

	const { text: localizedText, id: localizationId } = localization ?? {
		text: edit ? "CREATE LOCALIZATION" : ""
	};

	const [localizationText, setLocalizationText] = React.useState(localizedText);

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
		setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { value } = event.target;

		setLocalizationText(value);
	};

	const generator = new AdminLocalizationsGenerator();

	const handleSubmit = async () => {
		await generator.createOrUpdate({
			id: localizationId,
			args: { text: localizationText, key: localizationKey, language }
		});

		triggerCRUDRefresh();
		handleClose();
	};

	return (
		<Box sx={{ position: "relative", ...sx }}>
			{children({ localizedText })}
			{edit ? (
				<TextEditBox>
					<TextEditButton handleClick={handleClick} />
					<TextEditPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
						<TextField value={localizationText} onChange={handleChange} />
						<Button onClick={handleSubmit}>{buttonText}</Button>
					</TextEditPopover>
				</TextEditBox>
			) : null}
		</Box>
	);
};
