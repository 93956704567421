import { GenericImageContainer } from "../../elements";
import documentInfo from "../../../assets/images/document-info.svg";

export const InfoIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={documentInfo}
		imageAlt="document information"
		width="0.813rem"
		style={{ opacity: "0.4" }}
	/>
);
