import { IconButton } from "@mui/material";
import React from "react";
import { AwardsNextIcon, AwardsPreviousIcon } from "../images";

interface Props {
	handleClick: () => void;
	disabled: boolean;
}

type Factory = (Component: React.FC<{ disabled: boolean }>) => React.FC<Props>;

const AwardsButton: Factory =
	Component =>
	({ handleClick, disabled }) =>
		(
			<IconButton disabled={disabled} onClick={handleClick} sx={{ padding: "0" }}>
				<Component disabled={disabled} />
			</IconButton>
		);

export const AwardsNextButton = AwardsButton(AwardsNextIcon);
export const AwardsPreviousButton = AwardsButton(AwardsPreviousIcon);
