import { styled, Menu, Box, List, ListItemButton } from "@mui/material";

export const LocalizationMenu = styled(Menu)(({ theme }) => ({
	".MuiPaper-root": {
		borderRadius: "0.500rem",
		marginTop: "0.750rem"
	},
	".MuiList-root": {
		display: "flex",
		flexDirection: "column",

		width: "7.75rem",

		padding: 0,

		border: `1px solid ${theme.palette.grey[300]}`,
		borderRadius: "0.500rem",
		filter: "drop-shadow(0 0.250rem 0.813rem rgba(0, 0, 0, 0.07))"
	}
}));

export const MobileDropdown = styled(List)(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	padding: 0,

	width: "13.0rem",
	height: "5.00rem",

	marginTop: "0.750rem",

	border: "1px solid rgba(96, 82, 143, 0.2)"
}));

export const LocalizationButtonBox = styled(Box)(({ theme }) => ({
	cursor: "pointer",

	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	padding: "0.375rem 0.500rem 0.375rem 0.250rem",
	gap: "0.500rem",
	isolation: "isolate",

	height: "1.50rem",
	width: "4.25rem",

	background: theme.palette.common.white,

	border: `1px solid ${theme.palette.grey[300]}`,
	borderRadius: "1.25rem",

	"&:hover": {
		boxShadow: "0 0.250rem 0.813rem rgba(0, 0, 0, 0.07)"
	}
}));

export const DesktopFlagAndLabel = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",
	padding: 0,
	gap: "0.250rem",

	width: "2.25rem",
	height: "1.00rem"
}));

export const DesktopFlag = styled(Box)(() => ({
	width: "1.00rem",
	height: "1.00rem",
	display: "flex",
	alignItems: "center"
}));

export const ChevronContainer = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.300rem",

	width: "0.750rem",
	height: "0.750rem"
}));

export const DesktopLanguageSelect = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",
	padding: 0,
	gap: "0.500rem",

	minWidth: "4.19rem",
	height: "1.25rem",

	flexGrow: 0
}));

export const FlagContainer = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",

	width: "0.875rem",
	height: "0.875rem"
}));

export const DesktopCheckbox = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	padding: "0.250rem 0.188rem",
	gap: "0.500rem",

	width: "1.00rem",
	height: "1.00rem",

	borderRadius: "0.250rem"
}));

export const MobileLanguageSelect = styled(ListItemButton)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",

	padding: "0.500rem 0.750rem",
	gap: "0.375rem",

	width: "13.0rem",
	height: "2.50rem",

	marginLeft: "0.938rem",

	backgroundColor: theme.palette.common.white,

	"&:hover": {
		background: theme.palette.common.white
	},

	borderBottom: "1px solid rgba(96, 82, 143, 0.2)",
	"&:first-child": {
		borderTopLeftRadius: "0.500rem",
		borderTopRightRadius: "0.500rem"
	},
	"&:last-child": {
		borderBottom: "none",
		borderBottomLeftRadius: "0.500rem",
		borderBottomRightRadius: "0.500rem"
	}
}));

export const MobileFlagAndLanguage = styled(ListItemButton)(() => ({
	display: "flex",
	alignItems: "center",
	padding: 0,
	gap: "0.500rem",
	minWidth: "4.31rem",
	height: "1.25rem"
}));

export const MobileFlag = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",

	width: "1.00rem",
	height: "1.00rem"
}));

export const MobileCheckbox = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	padding: "0.281rem, 0.211rem",
	gap: "0.563rem",

	width: "1.13rem",
	height: "1.13rem",
	borderRadius: "0.281rem"
}));
