import { Theme } from "@mui/material";
import { getMuiButtonStyles } from "./components/buttons.theme";
import { getMuiFabStyles } from "./components/fab.theme";
import { getMuiPaperStyles } from "./components/paper.theme";
import { getMuiTypographyStyles } from "./components/typography.theme";
import { getMuiToolBarStyles } from "./components/toolbar.theme";
import { getMuiAppBarStyles } from "./components/appbar.theme";
import { getMuiDividerStyles } from "./components/divider.theme";

export const getMuiComponentStyles = (theme: Theme) => {
	const components = {
		MuiButton: getMuiButtonStyles(theme),
		MuiDivider: getMuiDividerStyles(theme),
		MuiDrawer: {
			styleOverrides: {
				root: {
					height: 0,
					width: 0
				}
			}
		},
		MuiFab: getMuiFabStyles(theme),
		MuiPaper: getMuiPaperStyles(theme),
		MuiTypography: getMuiTypographyStyles(theme),

		MuiAppBar: getMuiAppBarStyles(theme),
		MuiToolBbar: getMuiToolBarStyles()
	};

	return components;
};
