import { Accordion, AccordionSummary, styled } from "@mui/material";
import { transientOptions } from "../options";

interface ExpandDisableProps {
	$expanddisabled: boolean;
}

export const SearchAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	marginTop: 0,
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)"
	},
	"& .MuiAccordionSummary-content": {
		marginTop: 0,
		marginBottom: 0,
		marginLeft: theme.spacing(2)
	},
	"& .MuiAccordionSummary-content.Mui-expanded": {
		marginLeft: theme.spacing(1)
	}
}));

export const DashboardSidebarUserItemAccordion = styled(Accordion)(({ theme }) => ({
	".Mui-expanded": { background: theme.palette.grey[300], margin: "0" },
	borderTop: `1px solid ${theme.palette.grey[300]}`
}));

export const DashboardSidebarUserItemAccordionSummary = styled(
	AccordionSummary,
	transientOptions
)<ExpandDisableProps>(({ $expanddisabled, theme }) => ({
	":hover": { background: $expanddisabled ? "unset" : theme.palette.grey[300] },
	padding: "0.75rem 1.5rem",
	cursor: $expanddisabled ? "unset !important" : "pointer !important",
	".MuiAccordionSummary-content": { margin: "0" }
}));
