import { Table, TableBody } from "@mui/material";
import { ClientInformationDAO } from "../../../util/generators/types";
import { CompanyInformationTableRow } from "../rows";
import { CompanyInformationTableContainer } from "../../../util/theme/styled-components";
import { useLanguageContext } from "../../../util/contexts/language.context";

interface Props {
	companyInformation: ClientInformationDAO;
}

export const CompanyInformationTable: React.FC<Props> = ({ companyInformation }) => {
	const { language } = useLanguageContext();

	const keysToFilter = ["creditLimit", "ypcreditLimit"];

	const rows = Object.entries(companyInformation)
		.filter(entry => !keysToFilter.includes(entry[0]))
		.map(([key, value], index) => {
			if (key.toLowerCase().includes("date"))
				value = value.toLocaleDateStringFormat(language);
			return <CompanyInformationTableRow property={key} value={value} key={index} />;
		});

	return (
		<CompanyInformationTableContainer>
			<Table>
				<TableBody>{rows}</TableBody>
			</Table>
		</CompanyInformationTableContainer>
	);
};
