import { FormControl, useTheme } from "@mui/material";
import React from "react";
import { FilterableColumn } from "../../util/configs/dashboard-table/filterable-column";
import {
	DashboardFilterCountBubble,
	DashboardFilterInput,
	DashboardFilterLabel
} from "../components";
import { useLanguageContext } from "../../util/contexts/language.context";
import { getFilterStyles } from "../../util/theme/components";
import { TableEnum } from "../../util/types/dashboard-table.types";

interface Props {
	handleChange: (value: string[]) => void;
	column: FilterableColumn;
	table: TableEnum;
	clearEvent: React.MouseEvent | null;
}

export const DashboardFilterInputContainer: React.FC<Props> = ({
	handleChange,
	column,
	table,
	clearEvent
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isHovered, setHovered] = React.useState(false);

	const isSelected = !!column.selectedFilterValues.length;

	const { getLocalizedTexts } = useLanguageContext();
	const labelId = `filter-label-${column.displayBy}`;

	const [selectLocalizedText, filterLocalizedText] = getLocalizedTexts([
		`table_${table}_${column.displayBy}`,
		"admin_panel_filter_all"
	]);
	const theme = useTheme();

	const styles = getFilterStyles({
		theme,
		isHovered,
		isOpen,
		isSelected,
		columnType: column.type
	});

	const handleSelectOpen = () => setIsOpen(true);

	const handleSelectClose = () => {
		setIsOpen(false);
		setHovered(false);
	};

	const handleArrowClick = () => {
		const nextIsOpen = !isOpen;
		setIsOpen(nextIsOpen);
	};

	const filterLabelProps = {
		styles: styles.label,
		isSelected,
		localizedText: selectLocalizedText,
		labelId
	};

	const filterSelectInputProps = {
		styles: styles.select,
		isOpen,
		labelId,
		defaultOptionText: filterLocalizedText,
		allowMultipleSelection: column.allowMultipleSelection,
		selectedFilterValues: column.selectedFilterValues,
		columnType: column.type,
		filterOptionsForColumn: column.filterOptions,
		localizedText: selectLocalizedText,
		clearEvent: clearEvent,
		handleOpen: handleSelectOpen,
		handleClose: handleSelectClose,
		handleArrowClick,
		handleChange: handleChange
	};

	return (
		<FormControl
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{column.type.includes("dateRange") ? null : (
				<DashboardFilterCountBubble selectedCount={column.selectedFilterValues.length} />
			)}
			<DashboardFilterLabel {...filterLabelProps} />
			<DashboardFilterInput {...filterSelectInputProps} />
		</FormControl>
	);
};
