import React from "react";
import { ClientHomepageTimeToggleStack } from "../../util/theme/styled-components";
import { ClientHomepageTimeToggleButton } from "../components";
import { DateTime } from "luxon";
import { useTableContext } from "../../util/contexts/table.context";
import { TimeTogglePeriod } from "./types";

export const TimeToggleContainer: React.FC = () => {
	const { setFilterQueryString } = useTableContext();

	const [selectedPeriod, setSelectedPeriod] = React.useState<TimeTogglePeriod>("month");

	React.useEffect(() => {
		const queryString = createQueryStringForTimePeriod("checkDueDate", selectedPeriod);
		setFilterQueryString(queryString);
	}, [selectedPeriod]);

	const periods = ["day", "week", "month"] as const;

	const timeToggleButtons = periods.map(period => (
		<ClientHomepageTimeToggleButton
			key={period}
			passTimePeriod={setSelectedPeriod}
			period={period}
			selectedPeriod={selectedPeriod}
		/>
	));

	return (
		<ClientHomepageTimeToggleStack
			direction="row"
			justifyContent="center"
			alignItems="center"
			spacing="0.175rem"
			width="100%"
			maxWidth="21.4rem"
		>
			{timeToggleButtons}
		</ClientHomepageTimeToggleStack>
	);
};

const createQueryStringForTimePeriod = (
	dateProperty: string,
	period: "day" | "week" | "month"
) => {
	const from = new Date();
	const to = new Date();

	switch (period) {
		case "week":
			to.setDate(to.getDate() + 7);
			break;
		case "day":
			break;
		default:
			to.setMonth(to.getMonth() + 1);
			break;
	}

	return createQueryStringForRange(dateProperty, from, to);
};

const createQueryStringForRange = (dateProperty: string, from: Date, to: Date) =>
	`${dateProperty}[$between]=${DateTime.fromJSDate(from)
		.startOf("day")
		.toISO()}&${dateProperty}[$between]=${DateTime.fromJSDate(to).endOf("day").toISO()}`;
