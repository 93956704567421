import { SelectChangeEvent, Stack, useTheme } from "@mui/material";
import { DashboardFooterSelect } from "../../../util/theme/styled-components";
import { useTableContext } from "../../../util/contexts/table.context";
import { LocalizedTypographyWithVariableSubject } from "../../elements";
import { PaginationUpAndDownArrow } from "../images";

interface Props {
	value: string;
}

export const DashboardFooterSelectInput: React.FC<Props> = ({
	value,
	children,
	...props
}) => {
	const { handlePageSizeChange } = useTableContext();
	const theme = useTheme();

	const handleChange = (event: SelectChangeEvent<any>) =>
		handlePageSizeChange(parseInt(event.target.value, 10));

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing="0.75rem"
			height="2rem"
			overflow="hidden"
		>
			<DashboardFooterSelect
				{...props}
				value={value}
				renderValue={value => (
					<LocalizedTypographyWithVariableSubject
						variant="body4-primary-medium"
						localizationKey="dashboard_footer_entry_text"
						variable={value as string}
						sx={{ color: theme.palette.chip.quaternary, fontSize: 14 }}
					/>
				)}
				onChange={handleChange}
				MenuProps={{
					sx: { maxHeight: "15rem" }
				}}
				IconComponent={PaginationUpAndDownArrow}
			>
				{children}
			</DashboardFooterSelect>
		</Stack>
	);
};
