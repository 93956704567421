import React from "react";
import { LayoutWrapper, PageWithSEO, TitleThreeSubtitleContainer } from "../containers";
import { PageIdProps } from "../types";
import { SeoProps } from "../containers/types";

interface Props extends SeoProps, PageIdProps {
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	title_1: string;
}

export const TitleThreeSubtitlePage: React.FC<Props> = ({
	body_1,
	body_2,
	body_3,
	body_4,
	subtitle_1,
	subtitle_2,
	subtitle_3,
	title_1,
	pageId,
	...seoProps
}) => (
	<PageWithSEO {...seoProps}>
		<LayoutWrapper>
			<TitleThreeSubtitleContainer
				{...{
					body_1,
					body_2,
					body_3,
					body_4,
					title_1,
					subtitle_1,
					subtitle_2,
					subtitle_3,
					pageId
				}}
			/>
		</LayoutWrapper>
	</PageWithSEO>
);
