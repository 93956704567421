import { getMuiPalette } from "../palette.theme";

const palette = getMuiPalette();

const base = {
	flex: 1,
	display: "flex",
	flexDirection: "column" as const,
	alignItems: "center",
	padding: "1.25rem",
	borderWidth: "0,09375rem",
	borderRadius: "0.5rem",
	borderColor: palette.grey[400],
	borderStyle: "dashed",
	backgroundColor: `${palette.grey[300]}36`,
	color: palette.grey[600],
	outline: "none",
	transition: "border .24s ease-in-out"
};
const focused = {
	borderColor: palette.secondary.main
};

const accept = {
	borderColor: palette.success.main
};

const reject = {
	borderColor: palette.error.main
};

export { base, focused, accept, reject };
