import { Stack } from "@mui/material";
import React from "react";
import {
	ContactFormInfoFields,
	ContactFormValidations,
	SubmissionStatus
} from "../../containers/types";
import { LocalizedGenericButton, LocalizedGenericBody } from "../../elements";
import { FormSnackbar } from "../snackbars";
import {
	InputValidation,
	NotEmpty,
	ValidEmail
} from "../../../util/validations/input.validation";
import { ContactFormTextArea, ContactFormTextField } from "../inputs";

interface Props {
	handleChange: (
		infoField: ContactFormInfoFields,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	handleClose: () => void;
	handleSubmit: () => void;
	setIsValidForField: (infoField: ContactFormInfoFields, isValid: null | boolean) => void;
	name: string;
	email: string;
	subject: string;
	submissionStatus: SubmissionStatus;
	message: string;
	validations: ContactFormValidations;
}

export const ContactForm: React.FC<Props> = ({
	handleChange,
	handleClose,
	handleSubmit,
	setIsValidForField,
	name,
	email,
	subject,
	submissionStatus,
	message,
	validations
}) => {
	const getTextInput = (
		value: string,
		key: "name" | "email" | "subject" | "message",
		Validation: typeof InputValidation,
		Component:
			| typeof ContactFormTextField
			| typeof ContactFormTextArea = ContactFormTextField
	) => (
		<Component
			handleChange={handleChange}
			infoField={key}
			placeholderKey={`${key}_input_placeholder`}
			value={value}
			Validation={Validation}
			isValid={validations[key]}
			setIsValidForField={setIsValidForField}
		/>
	);

	return (
		<Stack
			alignItems="flex-start"
			sx={{
				padding: 0,
				gap: "1.00rem",
				height: "100%",

				width: { lg: "34.0rem" },
				minWidth: "21.4rem"
			}}
		>
			<LocalizedGenericBody
				variant="body3-white"
				localizationKey="contact_form_heading"
			/>
			<Stack
				alignItems="stretch"
				sx={{
					gap: "0.500rem",
					padding: 0,
					width: "100%",
					height: "90%"
				}}
			>
				{getTextInput(name, "name", NotEmpty)}
				{getTextInput(email, "email", ValidEmail)}
				{getTextInput(subject, "subject", NotEmpty)}
				{getTextInput(message, "message", NotEmpty, ContactFormTextArea)}
				<LocalizedGenericButton
					variant="primary"
					handleClick={handleSubmit}
					sx={{ width: "100%" }}
					localizationKey="contact_button_placeholder"
					disabled={Object.values(validations).some(v => v === false)}
				/>
			</Stack>
			<FormSnackbar
				handleClose={handleClose}
				submissionStatus={submissionStatus}
				formVariant="contact"
			/>
		</Stack>
	);
};
