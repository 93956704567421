import { LocalizedGenericBody } from "../../elements";
import { ClientHomepageTimeToggleBox } from "../../../util/theme/styled-components";
import { TimeTogglePeriod } from "../../containers/types";

interface Props {
	passTimePeriod: (period: TimeTogglePeriod) => void;
	period: TimeTogglePeriod;
	selectedPeriod: TimeTogglePeriod;
}

export const ClientHomepageTimeToggleButton: React.FC<Props> = ({
	passTimePeriod,
	period,
	selectedPeriod
}) => {
	const isSelected = selectedPeriod === period;
	const textVariant = isSelected ? "body3-white-medium" : "body3-primary-medium";
	return (
		<ClientHomepageTimeToggleBox
			onClick={() => passTimePeriod(period)}
			$isSelected={isSelected}
		>
			<LocalizedGenericBody
				localizationKey={`client_homepage_time-toggle_button_${period}`}
				variant={textVariant}
				sx={{ width: "100%", textAlign: "center" }}
			/>
		</ClientHomepageTimeToggleBox>
	);
};
