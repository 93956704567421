import { useEmptyChartData } from "../hooks/useEmptyChartData.hook";
import { ChartProvider } from "./chart.context";
import { useLanguageContext } from "./language.context";

export const PieChartProvider: React.FC = ({ children }) => {
	const { getLocalizedText } = useLanguageContext();
	const { emptyPieChartData } = useEmptyChartData();

	const apiRequestArguments = ["checkAmount", "checkStatus"];

	const getLocalizedCheckStatus = (checkStatus: string) =>
		getLocalizedText(`table_checks_checkStatus_${checkStatus}`);

	return (
		<ChartProvider
			labelFormatter={getLocalizedCheckStatus}
			apiRequestArguments={apiRequestArguments}
			emptyChartData={emptyPieChartData}
		>
			{children}
		</ChartProvider>
	);
};
