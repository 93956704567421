import React from "react";
import { GenericImageContainer } from "../../elements";
import { ImageDictionary } from "../../../util/dictionaries/image.dictionary";

import sidebarCollapse from "../../../assets/images/dashboard/dashboard-sidebar-collapse-open.svg";

interface handleClickProps {
	handleClick: () => void;
}

interface CollapseProps extends handleClickProps {
	isSidebarOpen: boolean;
}

export const DashboardSidebarLogo: React.FC<handleClickProps> = ({ handleClick }) => {
	const {
		logo: { imageSrc }
	} = ImageDictionary.Header;
	return (
		<GenericImageContainer
			handleClick={handleClick}
			imageSrc={imageSrc}
			imageAlt="dashboard-sidebar-logo"
			width="7.875rem"
			height="3.75rem"
			style={{ cursor: "pointer" }}
		/>
	);
};

export const DashboardSidebarCollapse: React.FC<CollapseProps> = ({
	handleClick,
	isSidebarOpen
}) => (
	<GenericImageContainer
		data-cy="sidebar-collapse"
		handleClick={handleClick}
		imageSrc={sidebarCollapse}
		imageAlt="dashboard-sidebar-collapse"
		rotate={isSidebarOpen ? "0deg" : "180deg"}
		width="1.5rem"
		style={{ cursor: "pointer" }}
	/>
);
