import ActiveIcon from "../../../assets/images/awards/awards-active.svg";
import PassiveIcon from "../../../assets/images/awards/awards-passive.svg";
import { GenericImageContainer } from "../../elements";

interface Props {
	disabled: boolean;
	rotate?: string;
}

export const AwardsNextIcon: React.FC<Props> = ({ disabled, rotate }) => (
	<GenericImageContainer
		secondaryImageRendered={disabled}
		imageSrc={ActiveIcon}
		secondaryImageSrc={PassiveIcon}
		imageAlt="awards next"
		rotate={rotate}
		width={{ xs: "2.00rem", md: "2.75rem" }}
	/>
);

export const AwardsPreviousIcon: React.FC<Props> = ({ disabled }) => (
	<AwardsNextIcon disabled={disabled} rotate="180deg" />
);
