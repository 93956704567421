import { Paper, Skeleton, Stack } from "@mui/material";
import React from "react";
import { AwardDAO } from "../../util/generators/types";
import { PublicAwardsGenerator } from "../../util/generators/public.generator";
import { useLanguageContext } from "../../util/contexts/language.context";
import { AwardItem, AwardsNextButton, AwardsPreviousButton } from "../components";
import { LocalizedGenericBody } from "../elements";
import { LayoutWrapper, OverflowBox } from "./wrappers";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
interface AwardsContainerProps {
	index: 1 | 2 | 3;
}

export const AwardsContainer: React.FC<AwardsContainerProps> = ({ index }) => {
	const { language } = useLanguageContext();

	const [awards, setAwards] = React.useState<AwardDAO[]>([]);
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const overflowBoxId = "awards-overflow-box";

	const overflowBox = document.getElementById(overflowBoxId);
	const awardItems = document.getElementsByClassName("award-item");

	const handleAutoScroll = (newIndex: number) => {
		if (overflowBox && awardItems && awardItems.length > 0) {
			const overflowBoxWidth = overflowBox.clientWidth;
			const awardItemWidth = awardItems[0].clientWidth;

			if (
				awardItemWidth * (newIndex + 1) > overflowBox.scrollLeft + overflowBoxWidth ||
				awardItemWidth * (newIndex - 1) < overflowBox.scrollLeft
			)
				overflowBox.scrollLeft = newIndex * awardItemWidth;
		}
	};

	React.useEffect(() => {
		handleAutoScroll(selectedIndex);
	}, [selectedIndex]);

	const generator = new PublicAwardsGenerator();

	useGeneratorEffect(
		{
			effect: () => generator.manyForLanguage(language, "sort=date"),
			onSuccess: setAwards,
			callback: generator.abort
		},
		[language]
	);

	const handleItemClick = (index: number) => setSelectedIndex(index);

	const handleNextClick = () => {
		const nextIndex = selectedIndex + 1;
		setSelectedIndex(nextIndex);
	};

	const handlePreviousClick = () => {
		const previousIndex = selectedIndex - 1;
		setSelectedIndex(previousIndex);
	};

	return (
		<Paper
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: { xs: "1rem", md: "2rem" },
				height: { xs: "28rem", md: "35.3rem" },
				overflow: "hidden"
			}}
			variant={`bg-home-${index}`}
		>
			<LayoutWrapper>
				<LocalizedGenericBody variant="h3-white" localizationKey="awards_heading" />
			</LayoutWrapper>
			<OverflowBox id={overflowBoxId}>
				<AwardList
					awards={awards}
					selectedIndex={selectedIndex}
					handleClick={handleItemClick}
				/>
			</OverflowBox>
			<LayoutWrapper>
				<AwardNavigation
					awards={awards}
					selectedIndex={selectedIndex}
					handlePreviousClick={handlePreviousClick}
					handleNextClick={handleNextClick}
				/>
			</LayoutWrapper>
		</Paper>
	);
};

export const AwardList: React.FC<{
	awards?: AwardDAO[];
	selectedIndex: number;
	handleClick: (index: number) => void;
}> = ({ awards, selectedIndex, handleClick }) => {
	const dummyAwards = new Array(10).fill(null);

	return (
		<Stack direction="row" width="max-content" marginTop={{ xs: "0.8rem", md: 0 }}>
			{awards
				? awards.map((award, index) => (
						<AwardItem
							key={index}
							award={award}
							index={index}
							selectedIndex={selectedIndex}
							handleClick={handleClick}
						/>
				  ))
				: dummyAwards.map((_, index) => (
						<Skeleton
							key={index}
							variant="rectangular"
							width="20.375rem"
							height="16.937rem"
							sx={{ marginLeft: "1.5rem", borderRadius: "0.5rem" }}
						/>
				  ))}
		</Stack>
	);
};

export const AwardNavigation: React.FC<{
	awards?: AwardDAO[];
	selectedIndex: number;
	handlePreviousClick: () => void;
	handleNextClick: () => void;
}> = ({ awards, selectedIndex, handlePreviousClick, handleNextClick }) => {
	return (
		<Stack direction="row" spacing={{ xs: "1rem", md: "1.5rem" }}>
			{awards ? (
				<>
					<AwardsPreviousButton
						disabled={selectedIndex === 0}
						handleClick={handlePreviousClick}
					/>
					<AwardsNextButton
						disabled={selectedIndex === awards.length - 1}
						handleClick={handleNextClick}
					/>
				</>
			) : (
				<Skeleton
					variant="rectangular"
					width="7rem"
					height="2.75rem"
					sx={{ borderRadius: "27.5rem" }}
				/>
			)}
		</Stack>
	);
};
