import { Box, Dialog, Stack } from "@mui/material";
import { ConfirmationBox } from "../../../util/theme/styled-components";
import {
	LocalizedGenericButton,
	LocalizedTypographyWithVariableSubject
} from "../../elements";
import { ConfirmationButtonDictionary } from "../../../util/dictionaries/button.dictionary";
import { ConfirmationVariant } from "../types";

interface Props {
	handleClose?: () => void;
	handleConfirmation: () => void;
	dialogOpen: boolean;
	variableSubject?: string;
	variant: ConfirmationVariant;
}

export const ConfirmationDialog: React.FC<Props> = ({
	handleClose,
	handleConfirmation,
	dialogOpen,
	variableSubject = "",
	variant
}) => {
	const ConfirmationButton = ConfirmationButtonDictionary[variant];

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleClose}
			sx={{ ".MuiPaper-root": { borderRadius: "1rem" } }}
		>
			<ConfirmationBox>
				<Box p="1.5rem" sx={{ textAlign: "center" }}>
					<LocalizedTypographyWithVariableSubject
						variable={variableSubject}
						variant="body1-primary"
						localizationKey={`${variant}_confirmation_text`}
						disableEdit={true}
					/>
				</Box>
				<Stack direction="column" px="1rem">
					<ConfirmationButton
						data-cy="confirmation-button"
						handleClick={handleConfirmation}
					/>
					<LocalizedGenericButton
						localizationKey="confirmation_cancel_placeholder"
						disableEdit={true}
						variant="cancel"
						handleClick={handleClose}
					/>
				</Stack>
			</ConfirmationBox>
		</Dialog>
	);
};
