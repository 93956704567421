import React from "react";
import { GenericImageContainer } from "../../elements";

import documentSearchIcon from "../../../assets/images/search/document-search-icon.svg";
import documentSearchButtonIcon from "../../../assets/images/search/document-search-button-icon.svg";
import faqSearchIcon from "../../../assets/images/search/faq-search-icon.svg";
import newsSearchIcon from "../../../assets/images/search/news-search-icon.svg";
import branchSearchIcon from "../../../assets/images/search/search-branch-filter-icon.svg";
import search from "../../../assets/images/search/search.svg";
import searchCancel from "../../../assets/images/search/search-cancel.svg";

import searchEmptyResults from "../../../assets/images/search/empty-results.svg";

import searchNoneFilter from "../../../assets/images/search/search-none-filter-icon.svg";
import searchNoneFilterSelected from "../../../assets/images/search/search-none-filter-icon-selected.svg";

import searchFaqFilter from "../../../assets/images/search/search-faq-filter-icon.svg";
import searchFaqFilterSelected from "../../../assets/images/search/search-faq-filter-icon-selected.svg";

import searchDocumentFilter from "../../../assets/images/search/search-document-filter-icon.svg";
import searchDocumentFilterSelected from "../../../assets/images/search/search-document-filter-icon-selected.svg";

import searchNewsFilter from "../../../assets/images/search/search-news-filter-icon.svg";
import searchNewsFilterSelected from "../../../assets/images/search/search-news-filter-icon-selected.svg";

import searchBranchFilter from "../../../assets/images/search/search-branch-filter-icon.svg";
import searchBranchFilterSelected from "../../../assets/images/search/search-branch-filter-icon-selected.svg";
import branchSearchPhoneIcon from "../../../assets/images/search/search-branch-phone-icon.svg";
import branchSearchFaxIcon from "../../../assets/images/search/search-branch-fax-icon.svg";

export const SearchEmptyResultsLogo: React.FC = () => (
	<GenericImageContainer
		imageSrc={searchEmptyResults}
		imageAlt="search-empty-results"
		width="6.56rem"
		height="6.13rem"
	/>
);

export const Search: React.FC = () => (
	<GenericImageContainer imageSrc={search} imageAlt="search" width="0.75rem" />
);

export const SearchCancel: React.FC = () => (
	<GenericImageContainer
		imageSrc={searchCancel}
		imageAlt="search-cancel"
		width="1.5rem"
	/>
);

export const DocumentSearchIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={documentSearchIcon}
		imageAlt="document-search-icon"
		width={{ xs: "0.875rem", md: "1rem" }}
	/>
);

export const DocumentSearchButtonIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={documentSearchButtonIcon}
		imageAlt="document-search-button-icon"
		width="0.875rem"
	/>
);

export const FaqSearchIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={faqSearchIcon}
		imageAlt="faq-search-icon"
		width={{ xs: "0.875rem", md: "1rem" }}
	/>
);

export const NewsSearchIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={newsSearchIcon}
		imageAlt="news-search-icon"
		width={{ xs: "0.875rem", md: "1rem" }}
	/>
);

export const BranchSearchIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={branchSearchIcon}
		imageAlt="branch-search-icon"
		width={{ xs: "0.875rem", md: "1rem" }}
	/>
);

export const BranchSearchPhoneIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={branchSearchPhoneIcon}
		imageAlt="branch-search-phone-icon"
		width="0.875rem"
	/>
);

export const BranchSearchFaxIcon: React.FC = () => (
	<GenericImageContainer
		imageSrc={branchSearchFaxIcon}
		imageAlt="branch-search-fax-icon"
		width="0.875rem"
	/>
);

interface ExtendedProps {
	imageSrc: string;
	imageSrcSelected: string;
	imageAlt: string;
	isSelected: boolean;
}

export const SearchFilterIcon: React.FC<ExtendedProps> = ({
	imageSrc,
	imageSrcSelected,
	imageAlt,
	isSelected
}) => (
	<GenericImageContainer
		secondaryImageRendered={isSelected}
		imageSrc={imageSrc}
		secondaryImageSrc={imageSrcSelected}
		imageAlt={imageAlt}
		width={{ xs: "0.875rem", md: "1rem" }}
	/>
);

interface Props {
	isSelected: boolean;
}

export const SearchNoneFilterIcon: React.FC<Props> = ({ isSelected }) => (
	<SearchFilterIcon
		imageSrc={searchNoneFilter}
		imageSrcSelected={searchNoneFilterSelected}
		imageAlt="search-none-filter"
		isSelected={isSelected}
	/>
);

export const SearchFaqFilterIcon: React.FC<Props> = ({ isSelected }) => (
	<SearchFilterIcon
		imageSrc={searchFaqFilter}
		imageSrcSelected={searchFaqFilterSelected}
		imageAlt="search-faq-filter"
		isSelected={isSelected}
	/>
);

export const SearchDocumentFilterIcon: React.FC<Props> = ({ isSelected }) => (
	<SearchFilterIcon
		imageSrc={searchDocumentFilter}
		imageSrcSelected={searchDocumentFilterSelected}
		imageAlt="search-document-filter"
		isSelected={isSelected}
	/>
);

export const SearchNewsFilterIcon: React.FC<Props> = ({ isSelected }) => (
	<SearchFilterIcon
		imageSrc={searchNewsFilter}
		imageSrcSelected={searchNewsFilterSelected}
		imageAlt="search-news-filter"
		isSelected={isSelected}
	/>
);

export const SearchBranchFilterIcon: React.FC<Props> = ({ isSelected }) => (
	<SearchFilterIcon
		imageSrc={searchBranchFilter}
		imageSrcSelected={searchBranchFilterSelected}
		imageAlt="search-branch-filter"
		isSelected={isSelected}
	/>
);
