import { Box, Grid, Stack, useTheme } from "@mui/material";
import React from "react";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import {
	ColoredCard,
	ThreeCardIcon1,
	ThreeCardIcon2,
	ThreeCardIcon3
} from "../components";
import { AdminEditPageIdProps, PageIdProps } from "../types";
import { TitleAndDescriptionWrapper } from "./wrappers";

interface Props extends PageIdProps {
	title_1: string;
	subtitle_1: string;
	subtitle_2: string;
	subtitle_3: string;
	body_1: string;
	body_2: string;
	body_3: string;
	body_4: string;
	illustrated: boolean;
}

interface CardProps extends AdminEditPageIdProps {
	title: string;
	body: string;
	background: "primary" | "secondary" | "tertiary";
}

interface CardWithIconProps extends CardProps {
	image: JSX.Element;
}

export const ColoredCardContainer: React.FC<CardProps> = ({
	title,
	titleProperty,
	body,
	bodyProperty,
	background,
	pageId
}) => {
	const color = useTheme().palette[background].main;
	return (
		<Box
			width={{ xs: "100%", lg: "30%" }}
			sx={{
				minWidth: 214,
				maxWidth: { xs: "unset", md: 420 },
				mr: "1.25rem"
			}}
		>
			<ColoredCard
				title={title}
				titleProperty={titleProperty}
				body={body}
				bodyProperty={bodyProperty}
				background={color}
				pageId={pageId}
			/>
		</Box>
	);
};

export const CardWithIconContainer: React.FC<CardWithIconProps> = ({
	title,
	titleProperty,
	body,
	bodyProperty,
	background,
	pageId,
	image
}) => {
	const color = useTheme().palette[background].main;
	return (
		<Stack
			direction="column"
			alignItems="center"
			width={{ xs: "810%", lg: "30%" }}
			sx={{ minWidth: 214, maxWidth: { xs: "unset", md: 420 }, mr: "1.25rem" }}
		>
			{image}
			<ColoredCard
				title={title}
				titleProperty={titleProperty}
				body={body}
				bodyProperty={bodyProperty}
				background={color}
				pageId={pageId}
			/>
		</Stack>
	);
};

export const ThreeCardsContainer: React.FC<Props> = ({
	title_1,
	subtitle_1,
	subtitle_2,
	subtitle_3,
	body_1,
	body_2,
	body_3,
	body_4,
	pageId,
	illustrated
}) => {
	const { isMobile } = useResponsive();

	const cardContent: CardWithIconProps[] = [
		{
			title: subtitle_1,
			titleProperty: "subtitle_1",
			body: body_2,
			bodyProperty: "body_2",
			background: "tertiary",
			image: <ThreeCardIcon1 />,
			pageId
		},
		{
			title: subtitle_2,
			titleProperty: "subtitle_2",
			body: body_3,
			bodyProperty: "body_3",
			background: "secondary",
			image: <ThreeCardIcon2 />,
			pageId
		},
		{
			title: subtitle_3,
			titleProperty: "subtitle_3",
			body: body_4,
			bodyProperty: "body_4",
			background: "primary",
			image: <ThreeCardIcon3 />,
			pageId
		}
	];

	const cards = cardContent.map(({ image, ...props }, index) =>
		illustrated && !isMobile ? (
			<CardWithIconContainer key={index} {...props} image={image} />
		) : (
			<ColoredCardContainer key={index} {...props} />
		)
	);

	const titleAndDescriptionProps = {
		headingProps: {
			variants: { desktop: "h2-secondary", mobile: "h3-secondary" },
			text: title_1
		},
		bodyProps: { variant: "body2-primary", innerHTML: body_1 },
		pageId
	};

	return (
		<TitleAndDescriptionWrapper {...titleAndDescriptionProps}>
			<Grid item xs={12} mt={{ xs: 0, md: "3.75rem" }}>
				<Stack
					direction="row"
					alignItems="flex-end"
					justifyContent={{ xs: "center", lg: "start" }}
					sx={{ flexWrap: "wrap" }}
				>
					{cards}
				</Stack>
			</Grid>
		</TitleAndDescriptionWrapper>
	);
};
