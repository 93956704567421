import React from "react";
import { TableBody, TableRow, TableCell, Box, Skeleton } from "@mui/material";
import { useTableContext } from "../../../util/contexts/table.context";
import { Column, DashboardTableConfig } from "../../../util/configs/dashboard-table";

const getSkeletonCell = (column: Column, columnIndex: number) => {
	let cellContent = null;

	switch (column.type) {
		case "userInformation":
			cellContent = (
				<Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
					<Skeleton
						animation="wave"
						height="2.25rem"
						width="2.25rem"
						variant="circular"
					/>
					<Skeleton
						animation="wave"
						height="1.5rem"
						width="10rem"
						sx={{ borderRadius: "0.4rem" }}
					/>
				</Box>
			);
			break;
		case "userRoles":
			cellContent = (
				<Box sx={{ display: "flex", columnGap: "0.75rem" }}>
					<Skeleton
						animation="wave"
						height="2.75rem"
						width="4.25rem"
						sx={{ borderRadius: "0.4rem" }}
					/>
					<Skeleton
						animation="wave"
						height="2.75rem"
						width="4.25rem"
						sx={{ borderRadius: "0.4rem" }}
					/>
					<Skeleton
						animation="wave"
						height="2.75rem"
						width="4.25rem"
						sx={{ borderRadius: "0.4rem" }}
					/>
				</Box>
			);
			break;
		case "select":
			cellContent = (
				<Skeleton
					animation="wave"
					height="1.2rem"
					width="1.2rem"
					variant="rectangular"
					sx={{ borderRadius: "0.15rem", ml: "1rem" }}
				/>
			);
			break;
		case "index":
			cellContent = (
				<Skeleton animation="wave" height="2.15rem" width="2.15rem" variant="circular" />
			);
			break;
		case "delete":
			cellContent = (
				<Skeleton
					variant="rectangular"
					animation="wave"
					width="3rem"
					sx={{ borderRadius: "0.4rem" }}
				/>
			);
			break;
		default:
			cellContent = (
				<Skeleton
					variant="rectangular"
					animation="wave"
					sx={{ borderRadius: "0.4rem" }}
				/>
			);
			break;
	}

	return <TableCell key={columnIndex}>{cellContent}</TableCell>;
};

export const SkeletonTable: React.FC = () => {
	const { table, pageSize } = useTableContext();
	const columns = DashboardTableConfig[table].visible;

	return (
		<TableBody>
			{Array.from(new Array(pageSize)).map((_, index) => (
				<TableRow key={index} sx={{ height: "3.5rem" }}>
					{columns.map((column, columnIndex) => getSkeletonCell(column, columnIndex))}
				</TableRow>
			))}
		</TableBody>
	);
};
