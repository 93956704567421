import React from "react";
import { ActivateUserPrimaryIcon, EditUserIcon } from "../images";
import { useTableContext } from "../../../util/contexts/table.context";
import { TableSelectSnackbar } from "../snackbars";

interface Props {
	toggleSelectDialogOpen: () => void;
	handleDialogOpen: () => void;
}

export const AdminTableSelectBar: React.FC<Props> = ({
	handleDialogOpen,
	toggleSelectDialogOpen
}) => {
	const { selectedElements } = useTableContext();

	const numberOfSelectedElements = selectedElements.length;

	const multipleElementsSelected = numberOfSelectedElements > 1;

	const localizationKeyPluralAddition = multipleElementsSelected ? "_plural" : "";

	const activateUserVariant = selectedElements[0].active
		? "deactivateUser"
		: "activateUser";

	const buttonProps = [
		{
			handleClick: handleDialogOpen,
			localizationKey: "select_bar_edit_user_button",
			startIcon: <EditUserIcon />,
			sx: multipleElementsSelected ? { display: "none" } : undefined
		},
		{
			handleClick: toggleSelectDialogOpen,
			localizationKey:
				`select_bar_${activateUserVariant}_button` + localizationKeyPluralAddition,
			startIcon: <ActivateUserPrimaryIcon active={selectedElements[0].active} />
		}
	];
	return (
		<TableSelectSnackbar
			buttonProps={buttonProps}
			selectedElementCountLocalizationKey="select_bar_selected_user_count"
		/>
	);
};
