import React from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";
import { useFormContext } from "../../../../util/contexts/form.context";
import {
	base,
	focused,
	accept,
	reject
} from "../../../../util/theme/components/dnd.theme";
import { DndImage } from "../../images";
import { LocalizedGenericBody } from "../../../elements";

interface Props {
	uploadFiles: (files: File[]) => void;
}

export const DragAndDropInputBase: React.FC<Props> = ({ uploadFiles, ...props }) => {
	const { setValues } = useFormContext();

	const onDrop = React.useCallback((acceptedFiles: File[]) => {
		if (!acceptedFiles || acceptedFiles.length === 0) return;
		const document = acceptedFiles[0];

		uploadFiles(acceptedFiles);
		setValues((prev: Record<string, any>) => ({ ...prev, document }));
	}, []);

	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
		useDropzone({ onDrop, maxFiles: 1 });

	const style = React.useMemo(
		() => ({
			...base,
			...(isFocused ? focused : {}),
			...(isDragAccept ? accept : {}),
			...(isDragReject ? reject : {})
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	return (
		<Box {...getRootProps({ style })}>
			<input {...props} {...getInputProps()} />
			<DndImage />
			<LocalizedGenericBody
				variant="body3-secondary-semi-bold"
				localizationKey="drag_and_drop_input_click_text"
			/>
			<LocalizedGenericBody
				variant="body3-primary"
				localizationKey="drag_and_drop_input_drop_text"
			/>
		</Box>
	);
};
